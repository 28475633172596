import api from './../../utils/api';
export default {
  namespaced: true,
  state: {
    loading_content: false,
    content: {}
  },
  mutations: {
    update: (state, payload) => state[payload[0]] = payload[1]
  },
  actions: {
    getContent({commit, state, rootState}, {companyId, contentId}) {
      commit('update', ['loading_content', true]);
      return new Promise((resolve, reject) => {
        if(state.content && state.content.id && state.content.id === contentId && ignoreSameId === true) {
          commit('update', ['loading_content', false]);
          return resolve();
        }
        companyId = companyId || rootState.company.company.id;
        api.get(`/v1/companies/${companyId}/content/${contentId}}`).then(r => {
          commit('update', ['content', r.data.data]);
          commit('update', ['loading_content', false]);
          resolve()
        }).catch(e => {
          reject(e)
        })
      })
    },
  }
}
