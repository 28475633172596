import api from './../../utils/api';
export default {
  namespaced: true,
  state: {
    sidebarVisible: true,
    sidebarCollapsed: false,
    dropdownVisible: false,
    breadcrumbs: [
      {
        label: 'Jobs',
        path: '#'
      },
      {
        label: 'Engineering',
        path: '#'
      },
      {
        label: 'Back End Developer',
        path: '#'
      }
    ],
    notifications: [],
    saved_table_sorts: {},
    saved_table_filters: {}
  },
  actions: {
    setLastBreadcrumb:({commit}, payload) => commit('setLastBreadcrumb', payload),
    addNotification: ({commit, state}, payload) => state.notifications.push(payload),
    clearNotifications: ({commit}) => commit('update', ['notifications', []]),
    setNotifications: ({commit}, payload) => commit('update', ['notifications', payload]),
    setTableFilter: ({commit}, payload) => commit('setTableFilter', payload),
    saveTableSorting: ({commit, state, rootState}, payload) => {
      commit('saveTableSorting', payload)
      //console.log('over here')
      if(rootState.auth.user.id) {
        api.put(`/v1/user/alerts/table-order`, { definition: state.saved_table_sorts })
      }
    },
    saveTableFilters: ({commit, state, rootState}, payload) => {
      commit('saveTableFilters', payload)
    },
  },
  mutations: {
    toggleSidebar: context => {
      context.sidebarVisible = !context.sidebarVisible
    },
    saveTableSorting: (state, payload) => {
      state.saved_table_sorts[payload.name] = payload.order
    },
    setTableSorting: (state, payload) => {
      state.saved_table_sorts = payload
    },
    saveTableFilters: (state, payload) => {
      state['table_state_' + payload.name] = payload.value
    },
    setTableFilters: (state, payload) => {
      state.saved_table_filters = payload
    },
    setTableFilter: (state, payload) => state.saved_table_filters[payload[0]] = payload[1],
    update: (state, payload) => state[payload[0]] = payload[1],
    collapseSidebar: context => {
      context.sidebarCollapsed = !context.sidebarCollapsed
    },
    setBreadcrumbs: (state, payload) => {
      state.breadcrumbs = payload
    },
    setLastBreadcrumb: (state, payload) => {
      const bread = state.breadcrumbs
      const index = bread.length ? (bread.length - 1): 0;
      bread[index] = {
        label: payload
      }
      state.breadcrumbs = []
      state.breadcrumbs = bread
    },
    appendBreadcrumbs: (state, payload) => {
      //console.log('appending', payload)
      state.breadcrumbs = Array.isArray(payload) ? state.breadcrumbs.concat(payload) : state.breadcrumbs.push(payload)
    }
  }
}
