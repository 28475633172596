import store from './../store';

export default class {
  static async setTimer(type, id, startTimer = true) {
    this.stopTimer();
    await store.dispatch('auth/resetTimer');
    await store.dispatch('auth/setTimer', { type, id });
    if(startTimer) {
      await this.startTimer();
    }
  }

  static async startTimer() {
    setTimeout(() => {
      this.addTime();
      this.startTimer();
      //console.log('Timer run', store.state.auth.timer.timestamp);
    }, 5 * 1000);
  }

  static async addTime() {
    await store.dispatch('auth/addTime', 5);
  }

  static stopTimer() {
    if(self.timer) {
      clearTimeout(self.timer);
      self.timer = null;
    }
  }
}
