<template>
  <div v-loading="loading">
    <div>
      <div class="p-4">
        <div class="page-title">
          <h1> Content information
          </h1>
          <span class="text-xs font-base text-blue-900">Fill in all sections marked with an <span class="text-red-600">*</span> </span>
        </div>
        <el-form
          ref="detailsForm"
          :model="project"
          :rules="rules.details"
          label-width="300px"
          label-position="top"
          :disabled="disabled"
        >
          <el-form-item label="Content length" pr op="content_length">
            <vue-editor v-model="project.content_length" :disabled="disabled"   />
          </el-form-item>
          <el-form-item label="Link type" prop="link_type">
            <vue-editor v-model="project.content_link_type" :disabled="disabled"  />
          </el-form-item>
          <el-form-item label="Anchor text" prop="anchor_text">
            <vue-editor v-model="project.content_anchor_text" :disabled="disabled"  />
          </el-form-item>
          <el-form-item label="Url" prop="url">
            <vue-editor v-model="project.content_url" :disabled="disabled"  />
          </el-form-item>
          <el-form-item label="Type" prop="type">
            <vue-editor v-model="project.content_type" :disabled="disabled"  />
          </el-form-item>
          <el-form-item label="Internal link number" prop="internal_link_number">
            <vue-editor v-model="project.content_internal_link_number" :disabled="disabled"  />
          </el-form-item>
          <el-form-item label="Outbound link requirements" prop="outbound_link_requirements">
            <vue-editor v-model="project.content_outbound_link_requirements" :disabled="disabled"  />
          </el-form-item>
          <el-form-item label="Other user info" prop="other_useful_info">
            <vue-editor v-model="project.content_other_useful_info" :disabled="disabled"  />
          </el-form-item>
        </el-form>
      </div>
    </div>
  </div>
</template>
<script>
  const moment = require('moment')
  export default {
    props: {
      value: {
        type: Object,
        required: true
      },
      monthAllocations: {
        type: Array,
        default: () => []
      },
      disabled: {
        type: Boolean,
        default: false
      }
    },
    data() {
      return {
        project: this.value,
        loading: false,
        company_users: [],
        months_allocations: this.monthAllocations,
        rules: {
        }
      }
    },
    methods: {
      async validate() {
        return true
      },
    },
    watch: {
      project: {
        deep: true,
        handler: function(value) {
          this.$emit('update:input', value)
        }
      },
      value: {
        deep: true,
        handler: function(value) {
          this.project = value
        }
      }
    }
  }
</script>
