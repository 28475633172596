module.exports = {
  name: 'Companies',
  single_name: 'company',
  fields: [
    {
      name: 'name',
      sortField: 'name',
      filterable: true,
      rules: [
        { required: true, min: 3, message: 'Please enter a valid first name'}
      ]
    },
    {
      name: 'url',
      sortField: 'url',
      filterable: true,
      rules: [
        { required: true, type: 'url', message: 'Please enter a valid url'}
      ]
    },
    {
      name: 'active',
      sortField: 'active',
      type: 'boolean',
      defaultValue: true,
      filterable: true,
      filterSelectOptions: [
        {label: 'Active', value: true},
        {label: 'Disabled', value: false},
      ]
    },
    {
      name: 'created_at',
      label: 'Created at',
      sortField: 'created_at',
      type: 'date',
      filterable: true,
      hideOnCreate: true,
    },
    {
      name: 'updated_at',
      sortField: 'updated_at',
      label: 'Updated at',
      type: 'date',
      filterable: true,
      hideOnCreate: true
    },
  ],
  includes: [
    {
      model: 'user',
      type: 'manyThrough',
      through: 'companyUser'
    },
  ]
}

