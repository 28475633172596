<template>
  <div v-loading="loading" class="h-full">
    <div class="page-title">
      <h1>
        Note templates
      </h1>
    </div>
    <trieste-table
      :end-point="`/v1/companies/${$store.state.company.company.id}/note-templates`"
      :fields="fields"
      title="Note templates"
      item-type="note templates"
      :show-title="false"
      ref="table"
      @on-load="loading = false"
      :filters="filters"
      saved-table-sort-name="note-index"
    >
      <template v-slot:header-right>
        <el-button type="success" size="medium" icon="el-icon-edit-outline" v-if="$store.getters['auth/hasPermission']('note-template.create')" @click="show_create_edit_dialog = true">Create</el-button>
      </template>
      <template v-slot:actions-slot="props">
        <el-button
          size="small"
          icon="el-icon-view"
          @click="setUpEdit(props.row)"
          type="primary">
          Edit
        </el-button>
      </template>
    </trieste-table>
    <trieste-dialog :show.sync="show_create_edit_dialog" :title="noteTemplate.id ? 'Edit ' + noteTemplate.name : 'Create a note template'">
      <div class="p-6 pb-2">
        <el-form :rules="form_rules" :model="noteTemplate" label-position="left" label-width="120px" ref="form" v-loading="loading">
          <el-form-item label="Name" prop="name">
            <el-input type="text" v-model="noteTemplate.name" />
          </el-form-item>
          <el-form-item label="Body" prop="body">
            <el-input type="textarea" v-model="noteTemplate.body" />
          </el-form-item>
        </el-form>
      </div>
      <template v-slot:footer-left v-if="noteTemplate.id">
        <el-button @click="deleteNote" type="danger" icon="el-icon-close" size="small" v-if="$store.getters['auth/hasPermission']('note-template.delete')">Delete</el-button>
      </template>
      <template v-slot:footer-right class="dialog-footer">
        <el-button @click="closeDialog" type="danger" icon="el-icon-close" size="small">Cancel</el-button>
        <el-button type="success" size="small" icon="el-icon-edit-outline" v-if="canSave" @click="createNoteTemplate">
          {{ noteTemplate.id ? 'Update' : 'Create' }}
        </el-button>
      </template>
    </trieste-dialog>
  </div>
</template>
<script>
  const defaultTemplate = {
    id: null,
    name: '',
    body: ''
  }
  export default {
    computed: {
      canSave() {
        return (this.noteTemplate.id && this.$store.getters['auth/hasPermission']('note-template.update')) ||
        (!this.noteTemplate.id && this.$store.getters['auth/hasPermission']('note-template.create'))
      }
    },
    data: function() {
      return {
        loading: false,
        show_create_edit_dialog: false,
        noteTemplate: defaultTemplate,
        form_rules: [
          {
            name: [
              { required: true, min: 3, message: 'Please enter a name' }
            ],
            body: [
              { required: true, min: 3, message: 'Please enter a body' }
            ]
          }
        ],
        fields: [
          {
            name: 'name',
            title: 'name',
            width: '300px'
          },
          {
            name: 'body',
            title: 'body'
          },
          {
            title: '',
            name: 'actions-slot',
            sortField: 'created_at',
            width: '110px',
            fixed: 'right'
          },
        ],
        filters: [
          {
            name: 'created_by',
            type: 'user',
            label: 'User',
            multiple: true
          },
          {
            name: 'updated_by',
            type: 'user',
            label: 'User',
            multiple: true
          },
          {
            name: 'body',
            label: 'body'
          },
          {
            name: 'name',
            label: 'name'
          }
        ]
      }
    },
    methods: {
      closeDialog() {
        this.noteTemplate = defaultTemplate;
        this.show_create_edit_dialog = false;
      },
      setUpEdit(nt) {
        this.noteTemplate = Object.assign({}, nt);
        this.show_create_edit_dialog = true;
      },
      deleteNote(note) {
        this.$confirm('Are you sure you want to delete this note template?').then(() => {
          this.loading = true;
          this.$http.delete(`/v1/companies/${this.$store.state.company.company.id}/note-templates/${this.noteTemplate.id}`).then(() => {
            this.$message.success('Successfully deleted the note template.');
            this.closeDialog();
            this.$refs.table.getData();
          }).catch((e) => {
            console.error(e)
            this.$message.error('Failed to delete note template.')
          }).finally(() => {
            this.loading = false;
          })
        }).catch(() => {})
      },
      createNoteTemplate() {
        this.$refs.form.validate(valid => {
          if(valid) {
            this.loading = true;
            let url = `/v1/companies/${this.$store.state.company.company.id}/note-templates`;
            const method = this.noteTemplate.id ? this.$http.put : this.$http.post
            url  += this.noteTemplate.id ? `/${this.noteTemplate.id}` : ''
            method(url, this.noteTemplate).then(() => {
              this.$message.success('Successfully ' + (this.noteTemplate.id ? 'updated' : 'created') + ' the note template.');
              this.$refs.table.getData();
              this.closeDialog()
            }).catch((e) => {
              console.error(e)
              this.$message.error('Failed to create/update the note template.')
            }).finally(() => {
              this.loading = false;
            })
          }
        })
      }
    },
  }
</script>
