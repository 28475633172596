<template>
  <div v-loading="loading">
    <div class="page-title">
      <h1>
        Billing
      </h1>
    </div>
    <div class="flex flex-row mb-4">
      <div class="mr-4">
        <span class="block font-medium text-gray-700 text-sm mb-2">Client</span>
        <el-select v-model="filter_params.client_id" size="small" @change="getSummaries" filterable>
          <el-option :value="null" label="All" />
          <el-option :value="c.id" :label="c.name" v-for="(c, i) in clients" :key="'client'+i" />
        </el-select>
      </div>
      <div class="mr-4">
        <span class="block font-medium text-gray-700 text-sm mb-2">Project</span>
        <el-select v-model="filter_params.project_id" size="small" @change="getSummaries" filterable>
          <el-option :value="null" label="All" />
          <el-option :value="c.id" :label="c.name" v-for="(c, i) in allowedProjects" :key="'project'+i" />
        </el-select>
      </div>
      <div class="mr-4">
        <span class="block font-medium text-gray-700 text-sm mb-2">Month</span>
        <el-date-picker class="w-full" type="month" format="MMM yyyy" @change="defaultDate" :clearable="false" value-format="yyyy-MM-01" v-model="filter_params.built_month" size="small" />
        <div class="flex flex-row items-center">
          <el-checkbox v-model="filter_params.include_next_month" @change="getSummaries"  />
          <div class="inline-block ml-2 mt-1 text-sm font-bold">
            include next month
          </div>
        </div>
      </div>
      <div class="mr-4">
        <span class="block font-medium text-gray-700 text-sm mb-2">Currency</span>
        <el-select v-model="select_currency" size="small">
          <el-option value="USD" label="USD" />
          <el-option value="GBP" label="GBP" />
        </el-select>
      </div>
    </div>
    <el-tabs class="custom-tabs-v1" v-model="tab_name" :key="tab_key">
      <el-tab-pane name="all" label="For billing" v-if="$store.getters['auth/hasPermission']('lead.read')" lazy>
        <div class="columns">
          <div class="column w-4/5">
            <links include-exchange-prices :active-only="true" ref="links_for_billing"
                   billing-status="APPROVED"
                   :custom-fields="getCustomFields(true, '230px')"
                   allow-selection :selected-rows.sync="selectedRows"
                   export-mode="LINKBUILDING"
                   export-name="all links report"
                   custom-title="For billing"
                   custom-item-type="links"
                   :custom-params="filter_params"
                   include-billing-history
                   :custom-filters="filters"
                   sort-store-name="links-billing-all"
                   :allow-mass-lead-update="false"
                   :show-filters="false"
                   :show-pagination-options="true"
            >
              <template v-slot:custom-actions="props">
                <el-button type="success" icon="el-icon-money" size="mini" @click="setUpSingularBilling(props.row.id)">Bill</el-button>
                <el-button type="warning" icon="el-icon-remove-outline" size="mini" @click="updateLinkStatus(props.row, 'POSTPONED')">Postpone</el-button>
                <el-popover placement="right" width="80" trigger="hover">
                  <div class="flex flex-col">
                    <div class="mb-2 flex flex-col">
                      <el-button type="danger" icon="el-icon-close" size="small" @click="updateLinkStatus(props.row, 'REJECTED')">Reject</el-button>
                    </div>
                    <el-button type="warning" icon="el-icon-video-pause" size="small" @click="updateLinkStatus(props.row, 'IN_CORRECTION')">Correction</el-button>
                  </div>
                  <div slot="reference" class="inline-block font-bold ml-1">
                    >
                  </div>
                </el-popover>
              </template>
              <template v-slot:currency_price_slot="props">
                {{ select_currency === 'USD' ? props.row.usd_price : props.row.gbp_price }} ({{ select_currency }})
              </template>
            </links>
          </div>
          <div class="column w-1/5">
            <trieste-card title="Selection">
              <div class="p-6 py-2">
                <div class="flex flex-row items-center">
                  <span class="font-bold text-3xl block mr-2">{{ selectedRows.length.toLocaleString() }}</span>
                  <span class="text-sm font-medium text-gray-700">links</span>
                </div>
                <div class="flex flex-row items-center">
                  <span class="font-bold text-3xl block mr-2">{{ getSelectedCost }}</span>
                  <span class="text-sm font-medium text-gray-700">
                    <el-dropdown :hide-on-click="false" @command="(val) => priceMode = val">
                      <span class="el-dropdown-link">
                        {{ priceMode.toUpperCase() }}<i class="el-icon-arrow-down el-icon--right"></i>
                      </span>
                      <el-dropdown-menu slot="dropdown">
                        <el-dropdown-item command="usd">USD</el-dropdown-item>
                        <el-dropdown-item command="gbp">GBP</el-dropdown-item>
                      </el-dropdown-menu>
                    </el-dropdown>
                  </span>
                </div>
              </div>
              <template v-slot:footer>
                <div class="text-right">
                  <el-button type="success" icon="el-icon-money" size="small" @click="setUpMultipleBilling" :disabled="!selectedRows.length">Bill</el-button>
                  <el-button type="warning" icon="el-icon-remove-outline" size="small" @click="massUpdate('POSTPONED')" :disabled="!selectedRows.length">Postpone</el-button>
                </div>
              </template>
            </trieste-card>
          </div>
        </div>
      </el-tab-pane>
      <el-tab-pane name="postponed" label="Postponed" v-if="$store.getters['auth/hasPermission']('lead.read')" lazy>
        <div class="columns">
          <div class="column w-4/5">
            <links :active-only="true" billing-status="POSTPONED" ref="link_postponed" include-exchange-prices include-billing-history :custom-fields="getCustomFields(true, '120px')"
                   export-name="Postponed links report" export-mode="LINKBUILDING" custom-title="Postponed" :custom-filters="filters" custom-item-type="links"
                   :custom-params="filter_params" sort-store-name="links-billing-postponed" :allow-mass-lead-update="false" :show-filters="false">
              <template v-slot:custom-actions="props">
                <el-button type="success" icon="el-icon-money" size="mini" @click="setUpSingularBilling(props.row.id)">Bill</el-button>
                <el-popover placement="right" width="80" trigger="hover">
                  <div class="flex flex-col">
                    <div class="mb-2 flex flex-col">
                      <el-button type="success" icon="el-icon-remove-outline" size="mini" @click="updateLinkStatus(props.row, 'APPROVED')">Approve</el-button>
                    </div>
                    <div class="mb-2 flex flex-col">
                      <el-button type="danger" icon="el-icon-close" size="mini" @click="updateLinkStatus(props.row, 'REJECTED')">Reject</el-button>
                    </div>
                    <el-button type="warning" icon="el-icon-video-pause" size="mini" @click="updateLinkStatus(props.row, 'IN_CORRECTION')">Correction</el-button>
                  </div>
                  <div slot="reference" class="inline-block font-bold ml-1">
                    >
                  </div>
                </el-popover>
              </template>
              <template v-slot:currency_price_slot="props">
                {{ select_currency === 'USD' ? props.row.usd_price : props.row.gbp_price }} ({{ select_currency }})
              </template>
            </links>
          </div>
          <div class="column w-1/5">
            <trieste-card title="Summary">
              <div class="p-6 py-2">
                <div class="flex flex-row items-center">
                  <span class="font-bold text-3xl block mr-2">{{ postphoned_summary.total.toLocaleString() }}</span>
                  <span class="text-sm font-medium text-gray-700">links</span>
                </div>
                <div class="flex flex-row items-center">
                  <span class="font -bold text-3xl block mr-2">{{ this.priceMode === 'gbp' ? postphoned_summary.gbpPrice : postphoned_summary.usdPrice }}</span>
                  <span class="text-sm font-medium text-gray-700">
                    <el-dropdown :hide-on-click="false" @command="(val) => priceMode = val">
                      <span class="el-dropdown-link">
                        {{ priceMode.toUpperCase() }}<i class="el-icon-arrow-down el-icon--right"></i>
                      </span>
                      <el-dropdown-menu slot="dropdown">
                        <el-dropdown-item command="usd">USD</el-dropdown-item>
                        <el-dropdown-item command="gbp">GBP</el-dropdown-item>
                      </el-dropdown-menu>
                    </el-dropdown>
                  </span>
                </div>
              </div>
            </trieste-card>
          </div>
        </div>
      </el-tab-pane>
      <el-tab-pane name="billed" label="Billed" v-if="$store.getters['auth/hasPermission']('lead.read')" lazy>
        <div class="columns">
          <div class="column w-4/5">
            <links :active-only="true" billing-status="BILLED" ref="link_billed" :custom-fields="getCustomFields(false, null, true)" export-mode="LINKBUILDING" :custom-filters="filters"
               export-name="billed links report" include-billing-history include-exchange-prices custom-title="Billed" custom-item-type="links"
                   :custom-params="filter_params" sort-store-name="links-billing-billed"  :allow-mass-lead-update="false" :show-filters="false">
              <template v-slot:currency_price_slot="props">
                {{ select_currency === 'USD' ? props.row.usd_price : props.row.gbp_price }} ({{ select_currency }})
              </template>
            </links>
          </div>
          <div class="column w-1/5">
            <trieste-card title="Summary">
              <div class="p-6 py-2">
                <div class="flex flex-row items-center">
                  <span class="font-bold text-3xl block mr-2">{{ billed_summary.total.toLocaleString() }}</span>
                  <span class="text-sm font-medium text-gray-700">links</span>
                </div>
                <div class="flex flex-row items-center">
                  <span class="font-bold text-3xl block mr-2">{{ this.priceMode === 'gbp' ? billed_summary.gbpPrice : billed_summary.usdPrice }}</span>
                  <span class="text-sm font-medium text-gray-700">
                    <el-dropdown :hide-on-click="false" @command="(val) => priceMode = val">
                      <span class="el-dropdown-link">
                        {{ priceMode.toUpperCase() }}<i class="el-icon-arrow-down el-icon--right"></i>
                      </span>
                      <el-dropdown-menu slot="dropdown">
                        <el-dropdown-item command="usd">USD</el-dropdown-item>
                        <el-dropdown-item command="gbp">GBP</el-dropdown-item>
                      </el-dropdown-menu>
                    </el-dropdown>
                  </span>
                </div>
              </div>
            </trieste-card>
          </div>
        </div>
      </el-tab-pane>
    </el-tabs>
    <trieste-dialog :show.sync="showBillingDialog" :title="'Bill link' + (this.multipleBilling ? 's' : '')">
      <div class="p-6 pb-2">
        <el-form :model="billingForm" label-position="left" label-width="130px" ref="billingForm" :rules="billFormValidation" v-loading="isBilling">
          <el-form-item label="invoice number" prop="invoice_number">
            <el-input v-model="billingForm.invoice_number" />
          </el-form-item>
          <el-form-item label="Invoice date" prop="invoice_date">
            <el-date-picker class="w-full" format="dd MMM yyyy" value-format="yyyy-MM-dd" v-model="billingForm.invoice_date" />
          </el-form-item>
        </el-form>
      </div>
      <template v-slot:footer-right>
        <el-button type="danger" icon="el-icon-close" size="small" @click="() => { $refs.billingForm.resetFields(); showBillingDialog = false }">Cancel</el-button>
        <el-button type="success" icon="el-icon-money" size="small" @click="billLink">Bill</el-button>
      </template>
    </trieste-dialog>
  </div>
</template>
<script>
  import Links from "../../Links/Index";
  import moment from 'moment'

  export default {
    beforeRouteEnter(to, from, next) {
      next(vm => {
        vm.$store.commit('global/setBreadcrumbs', [
          {
            path: '/',
            label: 'Home'
          },
          {
            label: 'Link Building',
            path: '/link-building'
          },
          {
            label: 'Billing'
          }
        ])
      })
    },
    components: { Links },
    data: function() {
      return {
        clients: [],
        projects: [],
        currentSubscriptionId: null,
        showWidget: false,
        loading: false,
        priceMode: 'usd',
        showBillingDialog: false,
        multipleBilling: false,
        tab_key: 0,
        tab_name: "all",
        billingLink: null,
        billingForm: {
          invoice_number: null,
          invoice_date: null,
          note: null
        },
        billFormValidation: {
          invoice_number: [
            { required: true, message: 'Please add a invoice number' }
          ],
          invoice_date: [
            { required: true, message: 'Please add a invoice date'}
          ]
        },
        postphoned_summary: {
          total: 0,
          usdPrice: 0,
          gbpPrice: 0
        },
        billed_summary: {
          total: 0,
          usdPrice: 0,
          gbpPrice: 0
        },
        isBilling: false,
        query: {
          rpp: 20,
          page: 1
        },
        select_currency: 'USD',
        filter_params: {
          client_id: null,
          project_id: null,
          built_month: moment().subtract(1, 'month').toDate(),
          billing: true,
          include_next_month: null,
        },
        base_fields: [
          {
            title: 'ID',
            name: 'id',
            width: '150px'
          },
          {
            title: 'Acquired on',
            name: 'acquired_date',
            dateField: true
          },
          {
            title: 'URL',
            name: 'url-slot',
            sortField: 'from_url',
          },
          {
            title: 'Client (site)',
            name: 'Client',
            formatter: (v, row) => row.Client + ' (' + row.Site + ')'
          },
          {
            title: 'Project',
            name: 'project'
          },
          {
            title: 'Type',
            name: 'link_type',
            formatter: (v) => v ? v.replace(/_/g, ' ') : ''
          },
          {
            title: 'Target',
            name: 'target-slot',
            sortField: 'site_target_id',
          },
          {
            title: 'Price',
            name: 'currency_price_slot',
            sortField: 'price',
          },
        ],
        selectedRows: [],
        filters: [
          {
            label: 'Url'
          },
          {
            label: 'Client',
            type: 'client',
            multiple: true
          },
          {
            label: 'Site',
            type: 'site',
            multiple: true
          },
          { label: 'TF', type: 'number', max: 2 },
          { label: 'CF', type: 'number', max: 2 },
          { label: 'TF/CF', name: 'tf_cf', type: 'number', max: 2 },
          { label: 'Ref Domains', name: 'ref_domains', type: 'number', max: 2 },
          { label: 'DR', type: 'number', max: 2 },
          { label: 'DA', type: 'number', max: 2 },
          { label: 'status', type: 'select', multiple: true, options: this.$store.state.link.statuses },
          { label: 'Type', type: 'select', multiple: true, options: this.$store.state.link.types },
        ]
      }
    },
    computed: {
      getFilterCount() {
        let count = 0;
        Object.keys(this.search_params).forEach(k => {
          if(this.search_params[k].length > 0) {
            count++;
          }
        })
        return count;
      },
      getSelectedCost() {
        let cost = 0;
        this.selectedRows.forEach(i => {
          const p = ((this.priceMode === 'gbp' ? i.gbp_price : i.usd_price) || 0) / 1
          if(p && p > 0) {
            cost += p;
          }
        })
        return cost.toLocaleString()
      },
      allowedProjects() {
        if(this.filter_params.client_id) {
          return this.projects.filter(p => p.client_id === this.filter_params.client_id)
        }
        return this.projects
      }
    },
    mounted() {
      if(!this.$store.getters['auth/hasPermission']('linkbuilding.read')) {
        this.$message.error('You do not have permissions to view this resource.');
        this.$router.push('/');
      }
      this.getAllClients()
      this.getSummaries()
    },
    methods: {
      getAllClients() {
        this.$http.get(`/v1/companies/${this.$store.state.company.company.id}/clients`, {
          params: {
            all: true,
            include_projects: true,
            has_billable_projects: true,
          }
        })
          .then(r => {
            this.clients = r.data.data
            this.projects = r.data.data.map(r => r.projects)
              .reduce((a,b) => [...a, ...b], [])
              .filter(p => p.billable)
              .sort((a,b) => a.name.replace(/[^a-zA-Z0-9]/g)[0] > b.name.replace(/[^a-zA-Z0-9]/g)[0] ? 1 : -1)
          })
          .catch(() => {
            this.$message.error('Failed to load the clients list.')
          })
      },
      getCustomFields(hasActions, actionsWidth = '110px', showInvoiceNumber) {
        let f = [].concat(this.base_fields)
        if(showInvoiceNumber) {
          //console.log('got to show invoice number?')
          f = f.concat({
            title: 'Invoice number',
            name: 'invoice_number'
          }, {
            title: 'Invoice date',
            name: 'invoice_date',
            dateField: true
          })
        }
        if(hasActions === true) {
          f = f.concat({
            title: '',
            name: 'actions-slot',
            titleClass: 'text-left',
            dataClass: 'text-left',
            width: actionsWidth,
            fixed: 'right'
          })
        }
        return f
      },
      defaultDate(v) {
        this.getSummaries()
      },
      getSummaries() {
        const requests = [
          this.$http.get(`/v1/companies/${this.$store.state.company.company.id}/billing/total`, {
            params: {
              client_id: this.filter_params.client_id,
              type: 'BILLED',
              month: this.filter_params.built_month,
              project_id: this.filter_params.project_id,
              include_next_month: this.filter_params.include_next_month
            }
          }),
          this.$http.get(`/v1/companies/${this.$store.state.company.company.id}/billing/total`, {
            params: {
              client_id: this.filter_params.client_id,
              type: 'POSTPONED',
              month: this.filter_params.built_month,
              project_id: this.filter_params.project_id,
              include_next_month: this.filter_params.include_next_month
            }
          })
        ]

        this.$http.all(requests).then(this.$http.spread((billedSummary, postponedSummary) => {
          this.billed_summary = billedSummary.data.data;
          this.postphoned_summary = postponedSummary.data.data;
        }))
      },
      billLink() {
        const ids = this.multipleBilling ? this.selectedRows.map(i => i.id) : [ this.billingLink ]
        this.loading = true;
        this.$http.put(`/v1/companies/${this.$store.state.company.company.id}/links/mass-update`, {
          ids,
          billing_status: 'BILLED',
          invoice_date: this.billingForm.invoice_date,
          invoice_number: this.billingForm.invoice_number,
          billing_status_note: this.billingForm.billing_status_note,
        })
          .then(() => {
            this.$message.success('Successfully billed the link' + (this.multipleBilling ? 's' : '') + '.')
            this.showBillingDialog = false
            this.$refs.billingForm.resetFields()
            setTimeout(() => { this.tab_key++ }, 600)
          })
          .catch(() => {
            this.$message.error('failed billing. Please try again later.')
          })
          .finally(() => {
            this.loading = false;
          })
      },
      setUpMultipleBilling() {
        this.multipleBilling = true
        if(this.selectedRows.length) {
          this.showBillingDialog = true
        }
      },
      setUpSingularBilling(id) {
        this.billingLink = id
        this.multipleBilling = false
        this.showBillingDialog = true
      },
      updateLinkStatus(link, status) {
        this.$prompt('Please enter a note for this state change', 'Why are you updating this link to ' + status.toLowerCase().replace(/_/g, ' '), {
          confirmButtonText: 'OK',
          cancelButtonText: 'Cancel',
        }).then(({ value }) => {
          const url = `/v1/companies/${link.company_id}/links/${link.id}`;
          this.loading = true;
          this.$http.put(url, { billing_status: status, billing_status_note: value }).then(() => {
            this.$message.success('Successfully updated the link.');
            //console.log('refs', this.$refs.linktable)
            setTimeout(() => { this.tab_key++ }, 600)
          })
            .catch((e) => {
              this.$message.error('Failed to update the link. Please try again later.')
              console.error(e)
            })
            .finally(() => {
              this.loading = false;
            })
        }).catch(() => {});
      },
      massUpdate(status) {
        if(this.selectedRows.length) {
          this.$prompt('Please enter a note for this state change', 'Why are you updating this link to ' + status.toLowerCase().replace(/_/g, ' '), {
            confirmButtonText: 'OK',
            cancelButtonText: 'Cancel',
          }).then(({ value }) => {
            const ids = this.selectedRows.map(i => i.id)
            this.loading = true
            this.$http.put(`/v1/companies/${this.$store.state.company.company.id}/links/mass-update`, {
              ids,
              billing_status: status,
              billing_status_note: value
            })
              .then(() => {
                this.$message.success('Successfully updated the links.');
                setTimeout(() => { this.tab_key++ }, 600);
              })
              .catch(() => {
                this.$message.error('failed updated the links.')
              })
              .finally(() => {
                this.loading = false;
              })
          }).catch(() => {});
        }
      },
      refreshTable() {
        this.$refs.table.getData()
      }
    }
  }
</script>
