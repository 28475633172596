import user from './resources/user'
import role from './resources/role'
import company from './resources/company'
import client from './resources/client'
import roleUser from './resources/roleUser'
import companyUser from './resources/companyUser'
import permission from './resources/permission'
import rolePermission from './resources/rolePermission'
import site from './resources/site'
import approvalClientUser from './resources/approvalClientUser'
import userGroup from './resources/userGroup'
import userParticipation from './resources/userParticipation'
export default {
  resources: [
    {
      name: 'company',
      options: company
    },
    {
      name: 'client',
      options: client
    },
    {
      name: 'site',
      options: site
    },
    {
      name: 'user',
      options: user
    },
    {
      name: 'companyUser',
      options: companyUser
    },
    {
      name: 'role',
      options: role
    },
    {
      name: 'permission',
      options: permission
    },
    {
      name: 'roleUser',
      options: roleUser
    },
    {
      name: 'rolePermission',
      options: rolePermission
    },
    {
      name: 'group',
      options: userGroup
    },
    {
      name: 'userParticipation',
      options: userParticipation
    },
    {
      name: 'approvalClientUser',
      options: approvalClientUser
    }
  ]
}
