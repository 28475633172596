<template>
  <div class="w-full h-screen" v-loading="loading"></div>
</template>
<script>
const qs = require('query-string')
export default {
  data() {
    return {
      loading: true
    }
  },
  mounted() {
    this.checkHash()
  },
  methods: {
    checkHash() {
      const url = new URL(window.location.href)
      const queryString = qs.parse(url.search || url.hash)

      if(!queryString.code && !queryString.state || !queryString.scope || queryString.state.split('_').length !== 2) {
        return this.pushBackToEmailPage('The response from google was invalid.', true)
      }

      if(queryString.scope !== 'https://mail.google.com/') {
        return this.pushBackToEmailPage('The scope returned was invalid.', true)
      }

      const stateSplit = queryString.state.split('_')

      // okay, now lets do this request
      this.$http.post(`/v1/google-code-authentication`, {
        code: queryString.code,
        account_id: stateSplit[1],
        company_id: stateSplit[0],
        redirect_uri: process.env.VUE_APP_URL + '/google-sign-in'
      }).then(() => {
        return this.pushBackToEmailPage('The email account was successfully authenticated.')
      }).catch((e) => {
        if(e.response && e.response.data && e.response.data.error && e.response.data.error) {
          return this.pushBackToEmailPage(e.response.data.error, true)
        } else {
          return this.pushBackToEmailPage('A unknown error occurred. Please try again later', true)
        }
      })
    },
    pushBackToEmailPage(message, isError) {
      if(!isError) {
        this.$router.push(`/company/${this.$store.state.company.company.id}/email-accounts`)
      }
      setTimeout(() => {
        if(isError) {
          this.$message.error(message)
        } else {
          this.$message.success(message)
        }
      }, 300)
    }
  }
}
</script>
