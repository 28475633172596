<template>
  <div v-loading="loading">
    <trieste-table
      item-type="Users"
      default-sort-direction="ascending"
      default-sort-prop="firstname"
      ref="table"
      title="Email accounts" :end-point="'/v1/companies/' + $store.state.company.company.id + '/email-accounts/users'"
      :fields="fields"
      :show-title="false"
      saved-table-sort-name="emailaccounts-users-index"
      :filters="filters"
    >
      <template v-slot:user-slot="props">
        {{ `${props.row.firstname} ${props.row.lastname}` }}
      </template>
      <template v-slot:count-slot="props">
        {{ props.row.email_account.length.toLocaleString() }}
      </template>
      <template v-slot:actions-slot="props">
        <div class="flex flex-row justify-end">
          <el-button
            size="small"
            icon="el-icon-edit"
            @click="openEditDialog(props.row)"
            v-if="$store.getters['auth/hasPermission']('user.edit') && $store.getters['auth/hasPermission']('emailaccount.update')"
            type="primary">
            Edit
          </el-button>
          <a
            :href="`/company/${$route.params.companyId}/users/${props.row.id}`"
            v-if="$store.getters['auth/hasPermission']('emailaccount.read')"
            class="ml-2"
          >
            <el-button
              size="small"
              v-if="$store.getters['auth/hasPermission']('emailaccount.read')"
              type="primary">
              Inbox
            </el-button>
          </a>
        </div>
      </template>
    </trieste-table>
    <trieste-dialog :show.sync="show_edit_dialog"
      :title="selected_user ? `Edit email accounts for ${selected_user.firstname} ${selected_user.lastname}` : 'Edit email accounts'"
    >
      <div class="p-6 pb-2">
        <el-form label-width="120px" label-position="left">
          <el-form-item label="Email account" prop="email_account_id">
            <el-select filterable v-model="selected_user_emails" class="w-full" multiple>
              <el-option :value="account.id" :label="account.email" v-for="(account, i) in email_accounts" :key="'ea_' + i">
                {{ `${account.email} - ${account.first_name} ${account.last_name}` }}
              </el-option>
            </el-select>
          </el-form-item>
        </el-form>
      </div>
      <template v-slot:footer-right>
        <el-button @click="closeEditDialog" icon="el-icon-close" type="danger" size="small">Cancel</el-button>
        <el-button type="success" icon="el-icon-edit-outline" @click="updateAssignedAccounts" size="small">Confirm</el-button>
      </template>
    </trieste-dialog>
  </div>
</template>
<script>

export default {
  data: function () {
    return {
      loading: false,
      fields: [
        {
          sortField: 'firstname',
          name: 'user-slot',
          title: 'User'
        },
        {
          name: 'count-slot',
          title: 'Accounts',
        },
        {
          title: '',
          name: 'actions-slot',
          fixed: 'right',
          width: '100px'
        },
      ],
      filters: [
        {
          label: 'First name',
          name: 'first_name'
        },
        {
          label: 'Last name',
          name: 'last_name'
        },
        {
          label: 'Email',
          name: 'email'
        }
      ],
      email_accounts: [],
      selected_user: null,
      selected_user_emails: [],
      show_edit_dialog: false
    }
  },
  mounted() {
    this.getResources()
  },
  methods: {
    getResources() {
      const requests = [
        this.$http.get('/v1/companies/' + this.$store.state.company.company.id + '/email-accounts', {
          params: {
            all: true
          }
        })
      ]
      this.loading = true
      this.$http.all(requests).then(this.$http.spread((emailAccounts) => {
        this.email_accounts = emailAccounts.data.data
      })).finally(() => {
        this.loading = false
      })
    },
    openEditDialog(account) {
      this.selected_user = account
      this.selected_user_emails = account.email_account.map(e => e.id)
      this.show_edit_dialog = true
    },
    closeEditDialog() {
      this.show_edit_dialog = false
      this.selected_user = null
      this.selected_user_emails = []
    },
    updateAssignedAccounts() {
      this.loading = true
      const userId = this.selected_user.id
      this.$http.post(`/v1/companies/${this.$store.state.company.company.id}/users/${userId}/email-accounts/mass-assign`, {
        ids: this.selected_user_emails
      }).then(() => {
        this.$message.success('Successfully updated the users assigned email accounts');
        this.closeEditDialog()
        this.$refs.table.getData()
      }).catch((e) => {
        this.$message.error('An error has occurred. Please try again later.')
        console.error(e)
      }).finally(() => {
        this.loading = false
      })
    }
  }
}
</script>
