<template>
  <div v-loading="loading">
    <trieste-card title="External Domains" collapsable>
      <div class="p-4">
        <div class="flex flex-row items-center justify-between">
          <div class="font-bold">{{ total_domains.toLocaleString() }} External domains linked.</div>
          <el-button size="mini" type="primary" @click="show_domain_list = !show_domain_list; collapse_keys = []">
            {{ show_domain_list ? 'Collapse all' : 'uncollapse all' }}
          </el-button>
        </div>
        <div v-for="(d, i) in domains" :key="i" class="mt-2">
          <div>
            <div class="inline-block mr-2"><el-tag dark size="small">{{ d.total.toLocaleString() }}</el-tag></div>
            <span class="font-medium font-sm">{{ d.domain }}</span>
            <div class="inline-block ml-2"> <a href="#" @click.prevent="toggleCollapseKey(i)"><i class="el-icon-sort"></i></a></div>
          </div>
          <ul class="text-xs" v-show="show_domain_list || collapse_keys.includes(i)">
            <li v-for="(u, ui) in d.urls" :key="ui"><a :href="u" target="_blank">{{ u }}</a></li>
          </ul>
        </div>
        <div class="mt-2 font-bold">{{ total_links.toLocaleString() }} total external links.</div>
      </div>
    </trieste-card>
  </div>
</template>
<script>
export default {
  props: {
    value: {
      type: Object
    }
  },
  data() {
    return {
      domains: [],
      total_links: 0,
      total_domains: 0,
      loading: true,
      show_domain_list: false,
      collapse_keys: []
    }
  },
  mounted() {
    this.getData()
  },
  methods: {
    getData() {
      this.$http.post('/v1/proxy/link/external', {url: this.value.from_url})
        .then((r) => {
          //console.log('The r', r)
          this.domains = r.data.data.domains
          this.total_links = r.data.data.total_external_links
          this.total_domains = r.data.data.total_domains
        }).catch(() => {
        this.$message.error('Failed to load the external sites')
      })
        .finally(() => {
          this.loading = false
        })
    },
    toggleCollapseKey(key) {
      const index = this.collapse_keys.findIndex(k => k === key)
      if (index === -1) {
        this.collapse_keys.push(key)
      } else {
        this.collapse_keys.splice(index, 1)
      }
    }
  }
}
</script>
