<template>
  <div v-loading="!loaded">
  <el-form
    ref="targetForm"
    :model="target"
    :rules="getTargetRules"
    label-width="120px"
    label-position="left"
  >
    <el-form-item
      label="Target name"
      prop="name"
      required
    >
      <el-input
        v-model="target.name"
      />
    </el-form-item>
    <el-form-item label="Type" prop="type">
      <el-select v-model="target.type" class="w-full">
        <el-option value="FIXED" label="Fixed" />
        <el-option value="SEMI_RANGE" label="Semi free range" />
        <el-option value="RANGE" label="Free range" />
      </el-select>
    </el-form-item>
    <el-form-item
      label="To URL"
      prop="to_url"
    >
      <el-input
        v-model="target.to_url"
      />
      <div class="text-right pt-2" v-if="['SEMI_RANGE','RANGE'].includes(target.type)">
        <el-button type="primary" size="mini" icon="el-icon-edit-outline" @click="addAdditionalUrl">Add another url</el-button>
      </div>
    </el-form-item>
    <template v-if="target.additional_urls && target.additional_urls.length">
      <el-form-item
        v-for="(row, i) in target.additional_urls"
        :label="'Url ' + (i + 2)"
        :prop="'additional_url|' + i"
        :key="i"

      >
        <el-input
          v-model="target.additional_urls[i]"
        />
        <div class="text-right -mb-4">
          <el-button type="danger" size="mini" icon="el-icon-delete" @click="deleteAdditionalUrl(i)">Delete url</el-button>
        </div>
      </el-form-item>
    </template>
    <el-form-item
      label="description"
      prop="description"
    >
      <el-input
        v-model="target.description"
      />
    </el-form-item>
    <el-form-item
      label="status"
      prop="status"
    >
      <el-select
        v-model="target.status"
        class="w-full"
      >
      <el-option value="active" label="Active" />
      <el-option value="suspended" label="Suspended" />
      </el-select>
    </el-form-item>
  </el-form>
  <div class="flex" v-if="showButtons">
    <div class="ml-auto">
      <el-button
        v-if="!visible"
        type="text"
        @click="()=>{ $refs['targetForm'].resetFields(); }"
      >
        Reset
      </el-button>
      <el-button
        v-if="visible"
        size="small"
        type="danger"
        @click="closeParentDialog"
      >
        Cancel
      </el-button>
      <el-button
        size="small"
        icon="el-icon-circle-plus"
        type="success"
        @click="createTarget"
      >
        {{ targetId  ? 'Create' : 'Edit' }} Target
      </el-button>
    </div>
  </div>
</div>
</template>

<script>
  export default {
    props: {
      visible: {
        type: [Boolean, Function],
        default: null
      },
      targetId: {
        type: [Number, String],
        default: null
      },
      siteUrl: {
        type: String,
        default: null,
      },
      siteId: {
        type: [String, Number],
        default: null
      },
      showButtons: {
        type: Boolean,
        default: true
      }
    },
    data() {
      return {
        loaded: false,
        mode: 'create',
        target: {
          name: '',
          to_url: '',
          description: '',
          status: 'active',
          type: 'FIXED',
          additional_urls: []
        },
        new_target_rules: {
          name: [
            {required: true, message: 'Please enter a target name', trigger: 'blur'},
          ],
          to_url: [
            {required: true, type: 'url', message: 'Please enter a valid target URL', trigger: 'blur'},
          ]
        },
      }
    },
    created() {
      if(this.getTargetId) {
        this.getTarget()
      } else {
        this.loaded = true;
        this.target.to_url = (this.siteUrl || this.$store.state.site.site.base_url).replace(/^(.*)\/$/, '$1') + '/';
      }
    },
    beforeRouteEnter(to, from, next) {
      next(vm => {
        vm.$store.dispatch('company/getCompany', vm.$route.params.companyId).then(() => {
          vm.$store.commit('global/setBreadcrumbs', [
            {
              path: '/',
              label: 'Home'
            },
            {
              path: '/companies',
              label: 'Companies'
            },
            {
              path: `/companies/${vm.$store.state.company.company.id}`,
              label: vm.$store.state.company.company.name
            },
            {
              path: `/companies/${vm.$store.state.company.company.id}/${vm.getTargetId ? 'edit' : 'create'}`,
              label: vm.getTargetId ? 'Edit target' : 'Create target'
            }
          ])
        })
      })
    },
    computed: {
      getTargetId() {
        return this.targetId || this.$route.params.targetId || null
      },
      getTargetRules() {
        if(Array.isArray(this.target.additional_urls) && this.target.additional_urls.length) {
          const additionalUrls = {};
          const validator = this.validateTargetUrlRule;
          this.target.additional_urls.forEach((value, index) => {
            additionalUrls['additional_url|' + index] = [
              {
                validator,
                message: 'Please enter a valid url',
              }
            ]
          })

          return Object.assign({}, this.new_target_rules, additionalUrls);
        }

        return this.new_target_rules;
      }
    },
    methods: {
      validateTargetUrlRule(rule, value, callback) {
        try {
          const field_parts = rule.field.split('|');
          const siteIndex = parseInt(field_parts[1]);
          const testUrl = new URL(this.target.additional_urls[siteIndex]);
        } catch(e) {
          return callback(new Error('Please enter a valid site'));
        }
        return callback();
      },
      getTarget() {
        const siteId = this.siteId || this.$route.params.siteId;
        const urlPath = `/v1/companies/${this.$route.params.companyId}/sites/${siteId}/targets/${this.getTargetId}`;
        this.$http.get(urlPath).then(r => {
          this.target = r.data.data;
          this.mode = 'edit';
        }).catch(() => {
          this.$message.error('Failed to load the target.')
        }).finally(() => {
          this.loaded = true;
        })
      },
      async checkSiteTargetExists() {
        this.loaded = false
        const siteId = this.siteId || this.$route.params.siteId;
        const result = await this.$http.get(`/v1/companies/${this.$route.params.companyId}/sites/${siteId}/targets`, {
          params: {
            filter_params: {
              to_url_exact: this.target.to_url
            }
          }
        })

        this.loaded = true

        if(result.data.data.length) {
          //console.log('over here')
          try {
            await this.$confirm(`The target URL ${this.target.to_url} already exists for this site. Are you sure you want to re-create this?`)
            return true;
          } catch(e) {
            return false
          }
        } else {
          //console.log('not her', result.data.data)
          return true;
        }
      },
      async createTarget(callback) {
        this.$refs.targetForm.validate(async (valid) => {
          if(valid) {
            const siteId = this.target.site_id || this.siteId || this.$route.params.siteId;
            let URL = `/v1/companies/${this.$route.params.companyId}/sites/${siteId}/targets`;

            let actionType = this.$http.post;

            const Entity = Object.assign({}, this.target);
            if(Entity.keywords) {
              Entity.keywords = Entity.keywords.trim().split("\n");
            }

            if(this.mode !== 'create') {
              actionType = this.$http.put;
              URL += `/${this.getTargetId}`;
            } else {
              const canCreate = await this.checkSiteTargetExists()
              if(!canCreate) {
                return;
              }
            }

            this.loaded = false;

            actionType(URL, Entity).then((r) => {
              this.$message.success('Successfully '+(this.mode==='create' ? 'created' : 'updated')+' the target');
              this.$refs.targetForm.resetFields();
              this.$emit('updated', r.data);
              if(!this.visible) {
                const targetId = r.data.id;
                this.$router.push(`/v1/companies/${this.$route.params.companyId}/sites/${this.$route.params.siteId}/targets/${targetId}`);
              } else {
                if(callback) callback(r.data);
                if(typeof this.visible === "function") {
                  this.visible(r.data)
                } else {
                  this.$emit('update:visible', false)
                }
              }
            }).catch(() => {
              if(callback) callback(false);
              this.$message.error('An error occurred while creating the target.');
            }).finally(() => {
              this.loaded = true;
            })
          } else {
            if(callback) callback(false);
          }
        });
      },
      addAdditionalUrl() {
        if(!Array.isArray(this.target.additional_urls)) {
          this.target.additional_urls = [];
        }
        this.target.additional_urls.push('');
      },
      deleteAdditionalUrl(i) {
        this.target.additional_urls.splice(i, 1);
      },
      closeParentDialog() {
        this.$refs['targetForm'].resetFields();
        if(typeof this.visible === 'function') {
          this.visible(false)
        } else {
          this.$emit('update:visible', false)
        }
      }
    }
  }
</script>

<style scoped>
</style>
