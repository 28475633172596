require('dotenv').config()

import Vue from 'vue'
import VueMeta from 'vue-meta';

import psl from 'psl'

import VueScrollTo from 'vue-scrollto'

// Third Party Services
import VueAxios from 'vue-axios'

import App from './App.vue'

import './assets/tailwind.scss'
import '@fortawesome/fontawesome-free/css/all.css'
import store from './store'
import router from './router'
import './plugins/element.js'
import api from './utils/api'

import linkStatus from "./utils/link/status"
import linkType from "./utils/link/type"

import LogoWidget from './components/incs/LogoWidget'
import Loader from './components/incs/Loader'

import Vue2Editor from "vue2-editor";

import Vuetable from 'vuetable-2'
import VuetablePagination from 'vuetable-2/src/components/VuetablePagination'
import VuetablePaginationInfo from 'vuetable-2/src/components/VuetablePaginationInfo'
import Lottie from "vue-lottie"
import TriesteCard from "@/components/incs/TriesteCard";
import TriesteTable from "@/components/incs/TriesteTable";
import TriesteInfoColumn from "@/components/incs/TriesteInfoColumn";
import TriesteDialog from "@/components/incs/TriesteDialog";
import TriesteTabbedDialog from "./components/incs/TriesteTabbedDialog";
import TriesteTabbedDialogSection from "./components/incs/TriesteTabbedDialogSection";
import moment from 'moment-timezone'


import FlagIcon from 'vue-flag-icon'
Vue.use(FlagIcon);

Vue.use(Vue2Editor);

Vue.config.productionTip = false

Vue.use(VueAxios, api)
Vue.use(VueScrollTo, {
  container: 'main'
})
Vue.component('Vuetable', Vuetable)
Vue.component('loader', Loader)
Vue.component('lottie', Lottie)
Vue.component('VuetablePagination', VuetablePagination)
Vue.component('VuetablePaginationInfo', VuetablePaginationInfo)
Vue.component('TriesteCard', TriesteCard)
Vue.component('TriesteTable', TriesteTable)
Vue.component('TriesteInfoColumn', TriesteInfoColumn)
Vue.component('TriesteDialog', TriesteDialog)
Vue.component('TriesteTabbedDialog', TriesteTabbedDialog)
Vue.component('TriesteTabbedDialogSection', TriesteTabbedDialogSection)
Vue.use(require('vue-moment'));
Vue.use(VueMeta, {
  refreshOnceOnNavigation: true
})

Vue.component('Logo', LogoWidget)


Vue.filter('extractDomain', (value) => {
  try {
    const host = (new URL(value)).host
    const parsed = psl.parse(host)
    return parsed.domain
  } catch (e) {
    return value
  }
})

Vue.filter('prettyLinkStatus', (value) => {

  let object = {};

  linkStatus.forEach((status)=>{
      object[status.value] = status.label;
  });

  return object[value] ? object[value] : value;
})

Vue.filter('prettyLinkType', (value) => {

  let object = {};

  linkType.forEach((status)=>{
    object[status.value] = status.label;
  });

  return object[value] ? object[value] : value;
})

Vue.filter('shortenText', (value, length) => {
  if(length === undefined) {
    length = 100;
  }
  if(Number.isInteger(value)) {
    return value.toLocaleString()
  } else if(value) {
    if (value.length > length) return value.substring(0, length) + '...';
    return value;
  }

  return '';
})

Vue.filter('capitalize', (value) => {

  if(value && value.length) {
    return value[0].toUpperCase() + value.substring(1)
  }

  return '';
})

Vue.filter('momentLocal', (value, format) => {
  format = format || 'dddd MMMM DD YYYY hh:mm A Z'
  const timezone = store.state.auth.user.timezone

  // let time = moment(value)
  //
  // if(timezone) {
  //   time = moment(value).tz(timezone)
  // }

  const time = moment(value)//.tz('Greenwich Mean Time')

  return time.format(format)
})

Vue.mixin({
  methods: {
    triesteApiUrl(path) {
      return process.env.VUE_APP_TRIESTE_API + path;
    },
    addGlobalNotification(message, type = 'warning') {
      this.$store.dispatch('global/addNotification', { message, type })
    },
    getOutboundSafeUrl(url) {
      const base = process.env.VUE_APP_OUTBOUND_REDIRECT_URL || 'http://161.35.224.71'
      return base + '?url=' + encodeURIComponent(url)
    }
  }
});

new Vue({
  store,
  router,
  render: h => h(App)
}).$mount('#app')
