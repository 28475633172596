module.exports = {
  name: 'Permissions',
  single_name: 'permission',
  fields: [
    {
      name: 'name',
      sortField: 'name',
      filterable: true,
      rules: [
        { required: true, min: 3, message: 'Please enter a valid permission name'}
      ]
    },
    {
      name: 'enabled',
      sortField: 'enabled',
      type: 'boolean',
      defaultValue: true,
      filterable: true,
      filterSelectOptions: [
        {label: 'Active', value: true},
        {label: 'Disabled', value: false},
      ]
    },
    {
      name: 'created_at',
      label: 'Created at',
      sortField: 'created_at',
      type: 'date',
      filterable: true,
      hideOnCreate: true,
    },
    {
      name: 'updated_at',
      sortField: 'updated_at',
      label: 'Updated at',
      type: 'date',
      filterable: true,
      hideOnCreate: true
    },
  ],
  includes: [
    {
      model: 'role',
      type: 'manyThrough',
      through: 'rolePermission'
    },
  ]
}

