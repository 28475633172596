import api from './../../utils/api';
export default {
  namespaced: true,
  state: {
    loading_client: false,
    client: {},
    clients: [],
    loading_all_clients: false,
    selected_billing_client: null
  },
  mutations: {
    update: (state, payload) => state[payload[0]] = payload[1]
  },
  actions: {
    loadAllClients({commit, state, rootState}, { companyId, force } = {}) {
      // only call this only.
      if(!force && (state.clients.length || state.loading_all_clients)) {
        return;
      }
      commit('update', ['loading_all_clients', true])
      const params = {
        all: true
      };
      const cId = companyId || rootState.company.company.id;
      api.get(`/v1/companies/${cId}/clients`, { params }).then(response => {
        commit('update', ['clients', response.data.data])
        commit('update', ['loading_all_clients', false])
      })
    },
    getClient({commit, state, rootState}, {companyId, clientId}) {
      commit('update', ['loading_client', true]);
      return new Promise((resolve, reject) => {
        /*if(state.client && state.client.id && state.client.id === clientId) {
          state.loading_client = false;
          commit('update', ['loading_client', false]);
          return resolve(Object.assign({}, state.client));
        }*/
        const cp = companyId || rootState.company.company.id
        api.get(`/v1/companies/${cp}/clients/${clientId}`).then(r => {
          commit('update', ['client', r.data.data]);
          commit('update', ['loading_client', false]);
          resolve(Object.assign({}, r.data.data))
        }).catch(e => {
          reject(e)
        })
      })
    },
  }
}
