<template>
  <div v-loading="loading" class="min-h-40">
    <trieste-table
      :title="resource.label"
      :end-point="`${endpoint}/${resource.resource}/index`"
      :params="{relations: this.resource.relations, relation_filters: this.relationFilters}"
      :fields="getFields"
      ref="table"
      :filters="getFilters()"
      v-if="ready"
    >
      <template v-for="(slot, si) in resource.fields.filter(i => i.model)" v-slot:[slot.model]="props">
        <a :href="`/admin/${slot.model}/${props.row[slot.model] ? props.row[slot.model].id : props.row.id}`" :key="si">
          <span class="font-bold">{{ getSlotNiceName(props.row, slot) }}</span>
        </a>
      </template>
      <template v-slot:header-right v-if="canCreate">
        <el-button type="success" size="small" icon="el-icon-edit-outline" @click="goToUrl">Create</el-button>
      </template>
      <template v-slot:actions="props">
        <el-button type="success" size="small" icon="el-icon-view" @click="viewResource(props.row)">View</el-button>
        <el-button @click="deleteItem(props.row)" icon="el-icon-close" type="danger" size="small">Delete</el-button>
      </template>
    </trieste-table>
  </div>
</template>
<script>
  export default {
    props: {
      resource: {
        type: Object,
        required: true
      },
      endpoint: {
        type: String,
        required: true
      },
      relationFilters: {
        type: Object,
        default: null
      }
    },
    data() {
      return {
        ready: false,
        loading: true,
        relations: []
      }
    },
    mounted() {
      if(this.resource.relations) {
        this.getRelations();
      } else {
        this.ready = true;
      }
    },
    computed: {
      canCreate() {
        if(this.relationFilters) {
          return ['manyThrough', 'many'].includes(this.relationFilters.type)
        }
        return true;
      },
      getFields () {
        const fields = []
        for(const f of this.resource.fields) {
          if(f.showOnIndex === false) {
            continue;
          }
          const newFields = {}
          newFields.name = f.name;
          newFields.title = f.label ? f.label : (f.name[0].toUpperCase() + f.name.substring(1));
          if(f.sortField) {
            newFields.sortField = f.sortField
          }
          if(f.type === 'date') {
            newFields.dateField = true;
          }
          if(f.type === 'boolean') {
            newFields.booleanField = true;
          }
          if (f.model) {
            newFields.name = f.model
            if(typeof f.val === 'function') {
              newFields.formatter = (val) => {
                return f.val(val)
              }
            } else if(typeof f.val === "string") {
              newFields.formatter = (val) => {
                return val[f.val] || null
              }
            }
          } else if (f.type === 'select') {
            if(f.options) {
              newFields.formatter = (val) => {
                const find = f.options.map(o => !o.value ? { value: o, label: o } : o).find(o => o.value === val)
                return find ? find.label : null
              }
            }
          }
          fields.push(newFields);
        }
        fields.push({
          name: 'actions',
          width: '210px',
          fixed: 'right'
        });
        return fields
      }
    },
    methods: {
      getSlotNiceName(val, f) {
        return typeof f.val === 'function' ? f.val(val[f.model]) : (val[f.model][f.val] || null)
      },
      getFilters() {
        const filters = [];
        for(const f of this.resource.fields) {
          if (f.filterable) {
            const newFilter = {
              label: f.label ? f.label : (f.name[0].toUpperCase() + f.name.substring(1)),
              name: f.name
            }

            if(f.model) {
              // okay so it looks like we need to do a custom selection option for this filter.
              newFilter.multiple = true;
              newFilter.type = 'select';
              newFilter.options = [];
              for(const r of this.relations[f.model]) {
                newFilter.options.push({
                  value: r.id,
                  label: typeof f.val === 'function' ? f.val(r) : (r[f.val] || null)
                });
              }
            } else if (f.filterSelectOptions) {
              newFilter.type = 'select';
              newFilter.multiple = f.filterSelectMultiple === true;
              newFilter.options = f.filterSelectOptions
            } else if(f.type === 'date') {
              newFilter.type = 'date';
              newFilter.dateType = 'daterange';
            }

            filters.push(newFilter)
          }
        }
        return filters
      },
      viewResource(item) {
        const resourceId = item.admin_relationship_id || item.id
        const resource = this.relationFilters ? (this.relationFilters.through || this.relationFilters.model) : this.resource.name
        window.location = `/admin/${resource}/${resourceId}`;
      },
      goToUrl() {
        let location = null
        if(this.relationFilters) {
          if (this.relationFilters.through) {
            location = `/admin/${this.relationFilters.through}/create?${this.relationFilters.pivotForeign}=${this.relationFilters.foreignValue}`;
          } else {
            location = `/admin/${this.relationFilters.model}/create?${this.relationFilters.column}=${this.relationFilters.value}`;
          }
        } else {
          location = `/admin/${this.resource.name}/create`;
        }
        if (location) {
          window.location = location
        }
        return false;
      },
      getRelations() {
        const requests = [];
        for(const relation of this.resource.relations) {
          const url = `${this.endpoint}/${relation}/index`;
          const params = { all: true };
          requests.push(this.$http.get(url, { params }));
        }

        this.$http.all(requests).then(this.$http.spread((...response) => {
          for(const key in response) {
            const r = this.resource.relations[key]
            this.relations[r] = response[key].data.data
          }
        }))
          .finally(() => {
            this.ready = true
            this.loading = false
          })
      },
      deleteItem(item) {
        this.$confirm('Are you sure you want to delete this item?', 'Are you sure?', {
          confirmButtonClass: 'el-button--danger'
        }).then(() => {
          const resourceId = item.admin_relationship_id || item.id
          const resource = this.relationFilters ? (this.relationFilters.through || this.relationFilters.model) : this.resource.name
          this.loading = true
          this.$http.delete(`/v1/admin/${resource}/${resourceId}`).then(() => {
            this.$message.success('Successfully deleted that item');
            this.$refs.table.getData()
          }).catch(() => {
            this.$message.error('An error occurred trying to delete that item.')
          })
            .finally(() => {
              this.loading = false
            })
        }).catch(() => {

        })
      }
    }
  }
</script>
