<template>
  <div v-loading="loading">
    <trieste-card title="Tasks">
      <template v-slot:header-right>
        <el-button type="success" icon="el-icon-edit-outline" size="small" @click="create('task')">Create</el-button>
      </template>
      <div>
        <div v-if="!value.length" class="text-sm text-gray-500 p-4">
          There are currently no tasks
        </div>
        <div  v-for="(note, i) in value" :key="'note_a_'+i" @click="toggleNote(i)">
          <div class="p-4 flex flex-row items-center justify-between cursor-pointer">
            <div class="flex flex-col">
              <div class="flex flex-row">
                <div class="mr-1"><el-checkbox v-model="note.completed" @change="updateTask(note)" /></div>
                <span class="font-bold text-gray-700 text-base">{{ note.body | shortenText(10) }}</span>
              </div>
              <span class="text-xs text-gray-500">{{ note.created_at | momentLocal("MMMM DD YYYY Z") }}</span>
            </div>
            <div class="flex flex-col">
              <span class="text-xs text-gray-500" v-if="note.assignee"><span class="font-bold text-gray-600">Assigned to </span>{{ `${note.assignee.firstname} ${note.assignee.lastname}` }}</span>
              <span class="text-xs text-gray-500" v-if="note.owner"><span class="font-bold text-gray-600">Created by </span>{{ `${note.owner.firstname} ${note.owner.lastname}` }}</span>
            </div>
          </div>
          <div class="border-b border-solid border-gray-200 collapsable_div" style="height: 0" ref="note">
            <div class="p-4">
              <trieste-info-column label="Note" :value="note.body" />
              <trieste-info-column label="Created by" :value="note.owner ? `${note.owner.firstname} ${note.owner.lastname}` : 'N/A'" />
              <trieste-info-column label="Assigned to" :value="note.assignee ? `${note.assignee.firstname} ${note.assignee.lastname}` : 'N/A'" />
              <trieste-info-column label="Created on" :value="note.created_at" date />
              <trieste-info-column label="Alert on" :value="note.alert_date" date />
              <trieste-info-column label="">
                <el-button
                  type="danger"
                  size="mini"
                  @click="deleteNote(note)"
                >delete</el-button>
              </trieste-info-column>
            </div>
          </div>
        </div>
      </div>
    </trieste-card>
  </div>
</template>
<script>
export default {
  props: {
    value: {
      type: Array
    },
    resourceId: {
      type: [String, Number]
    },
    resourceType: {
      type: String
    },
    create: {
      type: Function
    },
    deleteNote: {
      type: Function
    }
  },
  data() {
    return {
      loading: false
    }
  },
  methods: {
    toggleNote(i) {
      const wrapper = this.$refs.note[i];
      const child = wrapper.children[0];
      if(wrapper.classList.contains('open')) {
        wrapper.classList.remove('open')
        wrapper.style.height = '0px';
      } else {
        wrapper.classList.add('open')
        wrapper.style.height  = child.getBoundingClientRect().height + 'px'
      }
    },
    updateTask(note) {
      this.loading = true;
      this.$http.put(`/v1/companies/${this.$store.state.company.company.id}/notes/${note.id}`, note)
        .then(() => {
          this.$message.success('Successfully updated the task status')
        })
        .catch(() => {
          this.$message.error('Failed to update the status')
        })
        .finally(() => {
          this.loading = false;
        })
    }
  }
}
</script>
