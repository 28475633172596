<template>
  <div v-loading="loading">
    <trieste-dialog :show.sync="show" :title="email_brand.id ? 'Edit ' + email_brand.name : 'Create a new email brand'">
      <div class="p-6 pb-2">
        <el-form :rules="email_brand_rules" :model="email_brand" label-position="left" label-width="180px" ref="form" v-loading="loading" autocomplete="off">
          <el-form-item label="Name" prop="name">
            <el-input type="text" v-model="email_brand.name" />
          </el-form-item>
          <el-form-item label="domain" prop="domain">
            <el-input type="text" v-model="email_brand.domain" />
          </el-form-item>
          <el-form-item label="Unsubscribe message" prop="unsubscribe_message">
            <el-input type="textarea" v-model="email_brand.unsubscribe_message" />
          </el-form-item>
          <el-form-item label="Active" prop="active">
            <el-switch v-model="email_brand.active" />
          </el-form-item>
        </el-form>
      </div>
      <template v-slot:footer-left v-if="email_brand.id">
        <el-button type="danger" size="small" icon="el-icon-delete" v-if="$store.getters['auth/hasPermission']('emailaccount.delete')" @click="deleteEmail(email_brand)">Permanently delete this brand</el-button>
      </template>
      <template v-slot:footer-right>
        <el-button @click="closeCreateEmailAccountDialog" icon="el-icon-close" type="danger" size="small">Cancel</el-button>
        <el-button type="success" icon="el-icon-edit-outline" @click="createEmailAccount" size="small">Confirm</el-button>
      </template>
    </trieste-dialog>
  </div>
</template>
<script>
import { elementDomainValidator, formatResponseErrorMessage } from "@/utils/helpers";

const defaultFields = {
  id: null,
  name: null,
  domain: null,
  unsubscribe_message: null,
  active: true
}
export default {
  props: {
    show: {
      type: Boolean,
      default: null
    },
  },
  data() {
    return {
      loading: false,
      email_brand: defaultFields,
      sites: [],
      email_brand_rules: {
        name: [
          {required: true, min: 3, trigger: 'blur', message: 'Please enter a name'}
        ],
        domain: [
          { required: true, validator: elementDomainValidator, trigger: 'blur', message: 'Please enter a domain'}
        ],
        unsubscribe_message: [
          {required: true, min: 3, trigger: 'blur', message: 'Please enter a valid unsubscribe message'}
        ]
      },
    }
  },
  methods: {
    deleteEmail(email) {
      if(email.id) {
        this.$confirm('Are you sure you want to delete the email brand for '+email.name+'?', 'are you sure?', {
          confirmButtonClass: 'el-button--danger'
        })
          .then(() => {
            this.$http.delete( `/v1/companies/${this.$store.state.company.company.id}/email-brands/${email.id}`)
              .then(() => {
                this.$message.success('The email account has successfully been deleted.');
                this.closeCreateEmailAccountDialog();
                this.$router.push(`/company/${this.$store.state.company.company.id}/email-accounts?tabs=brands`);
                this.$emit('success');
              })
              .catch(() => {
                this.$message.error('The was an error processing that request. Please try again later.')
              })
          })
          .catch(() => {
            // do nothign
          })
      }
    },
    createEmailAccount() {
      this.$refs.form.validate((valid) => {
        if(valid) {
          this.loading = true;

          let method = this.$http.post;
          let url = `/v1/companies/${this.$store.state.company.company.id}/email-brands`;

          if(this.email_brand.id) {
            url += `/${this.email_brand.id}`;
            method = this.$http.put;
          }

          //console.log('The stuff', this.email_brand)

          method(url, this.email_brand)
            .then(() => {
              this.$message.success('Successfully '+(this.email_brand.id ? 'updated':'created')+' the email brand.');
              this.closeCreateEmailAccountDialog()
              this.$emit('success')
            })
            .catch((err) => {
              let message = formatResponseErrorMessage(err, 'createEmailAccount')
              this.$message.error(message)
              console.error(err.toJSON(), message)
            })
            .finally(() => {
              this.loading = false;
            })
        }
      })
    },
    closeCreateEmailAccountDialog() {
      this.$emit('update:show', false)
      this.email_brand = defaultFields
    },
    resetFields() {
      this.email_brand = defaultFields
    },
    setFields(p) {
      this.email_brand =  p
    }
  }
}
</script>
