import api from './../../utils/api';
export default {
  namespaced: true,
  state: {
    loading_campaign: false,
    campaign: {}
  },
  mutations: {
    update: (state, payload) => state[payload[0]] = payload[1]
  },
  actions: {
    getCampaign({commit, state, rootState}, {companyId, campaignId}) {
      commit('update', ['loading_campaign', true]);
      return new Promise((resolve, reject) => {
        companyId = companyId || rootState.company.company.id;
        api.get(`/v1/companies/${companyId}/campaigns/${campaignId}`, {
          params: {
            deleted: true
          }
        }).then(r => {
          commit('update', ['campaign', r.data.data]);
          //console.log('getting campaign', state.campaign)
          commit('update', ['loading_campaign', false]);
          resolve(Object.assign({}, r.data.data))
        }).catch(e => {
          reject(e)
        })
      })
    },
  }
}
