<template>
  <links :active-only="false" :show-filters="false" :custom-fields="fields" :custom-params="customParams" ref="links">
    <template v-slot:Id="props">
      {{props.row.id}}
    </template>
    <template v-slot:custom-actions="props">
      <el-button type="primary" size="small" @click="approveLink(props.row)">Approve</el-button>
      <el-button type="danger" size="small" @click="rejectLink(props.row)">Reject</el-button>
    </template>
  </links>
</template>
<script>
import Links from "@/views/Companies/Links/Index"
export default {
  components: { Links },
  data: function() {
    return {
      loading: false,
      activeTabName: 'leads',
      fields: [
        {
          title: 'ID',
          name: 'id',
          width: '150px'
        },
        {
          title: 'URL',
          name: 'url-slot',
          sortField: 'from_url',
          width: '250px'
        },

        {
          title: 'Target',
          name: 'target-slot',
          sortField: 'site_target_id',
        },
        {
          title: 'TF',
          name: 'trust_flow',
          sortField: 'trust_flow',
        },
        {
          title: 'CF',
          name: 'citation_flow',
          sortField: 'citation_flow',
        },
        {
          title: 'TF/CF',
          name: 'tf_cf',
          sortField: 'tf_cf',
          formatter(value) {
            return value ? value.toFixed(2) : 0
          }
        },
        {
          title: 'Ref. Domains',
          name: 'ref_domains',
          sortField: 'ref_domains',
          formatter(value) {
            return value ? value.toLocaleString() : 0
          }
        },
        {
          title: 'DR',
          name: 'ahrefs_domain_rating',
          sortField: 'ahrefs_domain_rating',
        },
        {
          title: 'Note',
          name: 'client_note'
        },
        {
          title: 'Last Updated',
          name: 'updated_at',
          dateField: true,
          sortField: 'updated_at',
        },
        {
          title: '',
          name: 'actions-slot',
          titleClass: 'text-left',
          dataClass: 'text-left',
          width: '200px',
          fixed: 'right'
        }
      ],
      customParams: {
        filter_params: {
          status: ["awaiting_client_approval"]
        }
      }
    }
  },
  methods: {
    approveLink (link) {
      this.$confirm('Are you sure you want to approve this link')
        .then(() => {
          this.updateLink(link.id, {
            status: 'client_approved'
          })
        })
        .catch(() => {})
    },
    rejectLink (link) {
      this.$prompt('Please enter a reject reason', 'Reject link', {
        confirmButtonText: 'Confirm reject',
        cancelButtonText: 'Cancel',
        inputPattern: /[a-zA-Z0-9]+/,
        inputErrorMessage: 'Please enter a rejection reason'
      }).then(({ value }) => {
        this.updateLink(link.id, {
          status: 'client_declined',
          client_declined_reason: value
        })
      }).catch(() => {});
    },
    updateLink(linkId, params) {
      this.loading = true;
      this.$http.put(
        `/v1/companies/${this.$store.state.company.company.id}/links/${linkId}`, params
      ).then(() => {
        this.$message.success('Successfully updated the link.');
      })
        .catch((e) => {
          this.$message.error('An error occurred updating the link.');
        })
        .finally(() => {
          this.$refs.links.refresh();
          this.loading = false;
        })
    }
  }
}
</script>
