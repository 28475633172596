<template>
  <div>
    <trieste-table
      :end-point="`/v1/companies/${$store.state.company.company.id}/dashboard/linkdev/progress`"
      :params="{ date_start: dateStart, date_end: dateEnd, user_id: userId, include_suspended: includeSuspendedSites }" :fields="fields"
      :title="title || 'Progress'" :show-sub-title="false" collapsable first-field-total
      default-sort-direction="ascending" default-sort-prop="domain"
    >
      <template v-slot:domain="props">
        <span v-if="props.$index === 0" class="font-bold">Total</span>
        <a :href="`/company/${$store.state.company.company.id}/sites/${props.row.id}`" v-else>{{props.row.domain}}</a>
      </template>
      <template v-slot:client="props">
        <span v-if="props.$index === 0" class="font-bold">Total</span>
        <a :href="`/company/${$store.state.company.company.id}/clients/${props.row.client.id}`" v-else>{{props.row.client.name}}</a>
      </template>
    </trieste-table>
  </div>
</template>
<script>
  export default {
    props: {
      dateStart: {
        type: [String, Date]
      },
      dateEnd: {
        type: [String, Date]
      },
      title: {
        type: String,
        default: null
      },
      userId: {
        type: [String, Number],
        default: null
      },
      includeSuspendedSites: {
        type: Boolean,
        default: false
      }
    },
    data() {
      return {
        fields: [
          {
            title: 'domain',
            name: 'domain',
            sortField: 'domain',
          },
          {
            title: 'Client',
            name: 'client',
            sortField: 'client.name',
            formatter: (val) => val ? val.name : ''
          },
          {
            title: 'Initial Emails',
            name: 'initial_email_count'
          },
          {
            title: 'Other Emails',
            name: 'other_email_count'
          },
          {
            title: 'Reviewed',
            name: 'reviewed_count'
          },
          {
            title: 'Links Acquired',
            name: 'acquired_count'
          },
          {
            title: 'Initial Replies',
            name: 'initial_reply_count'
          },
          {
            title: 'All replies',
            name: 'all_replies'
          }
        ]
      }
    }
  }
</script>
