module.exports = {
  name: 'Clients',
  single_name: 'client',
  fields: [
    {
      name: 'name',
      sortField: 'name',
      filterable: true,
      rules: [
        { required: true, min: 3, message: 'Please enter a valid name'}
      ]
    },
    {
      name: 'company_id',
      label: 'Company',
      sortField: 'company.name',
      model: 'company',
      filterable: true,
      val: (val) => {
        return val ? val.name : null
      },
      rules: [
        { required: true,  message: 'Please select a company'}
      ]
    },
    {
      name: 'created_at',
      label: 'Created at',
      sortField: 'created_at',
      type: 'date',
      filterable: true,
      hideOnCreate: true,
    },
    {
      name: 'updated_at',
      sortField: 'updated_at',
      label: 'Updated at',
      type: 'date',
      filterable: true,
      hideOnCreate: true
    },
  ],
  includes: [
    {
      model: 'site',
      type: 'many',
    }
  ]
}

