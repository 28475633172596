import api from './../../utils/api';
export default {
  namespaced: true,
  state: {
    loading_project: false,
    project: {},
    project_list: [],
    getting_all_projects: false,
    got_all_projects: false,
  },
  mutations: {
    update: (state, payload) => state[payload[0]] = payload[1]
  },
  actions: {
    getProject({commit, state, rootState, dispatch}, {companyId, projectId}) {
      commit('update', ['loading_project', true]);
      return new Promise((resolve, reject) => {
        companyId = companyId || rootState.company.company.id;
        api.get(`/v1/companies/${companyId}/projects/${projectId}`).then(r => {
          commit('update', ['project', r.data.data]);
          commit('update', ['loading_project', false]);
          resolve(r.data.data)
        }).catch(e => {
          reject(e)
        })
      })
    }
  }
}
