<template>
  <div v-loading="loading">
    <trieste-table
      item-type="blocked domains"
      title="Blocked domains"
      :end-point="'/v1/companies/' + $route.params.companyId + '/blacklisted-domains'"
      :fields="fields"
      ref="table"
      :show-title="false"
      default-sort-prop="domain"
      default-sort-direction="ascending"
      saved-table-sort-name="blackedlisted-domain"
      :filters="filters"
      :allow-selection="$store.getters['auth/hasPermission']('blacklist.delete')"
      :selected-rows.sync="selected"
    >
      <template v-slot:header-right>
        <el-button type="success" size="medium" icon="el-icon-edit-outline"  @click="showCreateDomain = true">Create</el-button>
        <el-button type="success" size="medium" icon="el-icon-edit-outline" v-if="$store.getters['auth/hasPermission']('blacklist.create')" @click="bulkBlacklist.show = true">Bulk create</el-button>
        <el-button type="danger" size="medium" icon="el-icon-delete" v-if="$store.getters['auth/hasPermission']('blacklist.delete') && selected.length" @click="bulkDelete">Bulk delete</el-button>
      </template>

      <template v-slot:actions-slot="props">
        <el-button
          size="small"
          icon="el-icon-edit"
          @click="() => { new_blacklistedDomain = Object.assign({}, props.row); showCreateDomain = true }"
          type="primary">
          Edit
        </el-button>
      </template>
    </trieste-table>
    <trieste-dialog :show.sync="showCreateDomain" :title="new_blacklistedDomain.id ? 'Edit ' + new_blacklistedDomain.word : 'Create blacklisted domain'">
      <div class="p-6 pb-2">
        <el-form :rules="new_blacklistedDomain_rules" :model="new_blacklistedDomain" label-width="120px" label-position="left" ref="blacklistForm" v-loading="loading">
          <el-form-item label="domain" prop="domain">
            <el-input type="email" v-model="new_blacklistedDomain.domain" />
          </el-form-item>
        </el-form>
      </div>
      <template v-slot:footer-left v-if="new_blacklistedDomain.id">
        <el-button type="danger" size="small" icon="el-icon-delete" v-if="new_blacklistedDomain.id" @click="deletedBlacklistedDomain(new_blacklistedDomain)">Permanently delete this domain</el-button>
      </template>
      <template v-slot:footer-right>
        <el-button @click="closeCreateStopWordDialog" icon="el-icon-close" type="danger" size="small">Cancel</el-button>
        <el-button type="success" icon="el-icon-edit-outline" @click="createBlacklistedDomain" size="small">Confirm</el-button>
      </template>
    </trieste-dialog>
    <trieste-dialog :show.sync="bulkBlacklist.show" title="Bulk create">
      <div class="p-6 pb-2">
        <el-form :rules="bulkRules" :model="bulkBlacklist" label-width="120px" label-position="left" ref="bulkForm" v-loading="loading">
          <el-form-item label="Domains" prop="text">
            <el-input type="textarea" v-model="bulkBlacklist.text" rows="10" resizable="false" />
            <span class="text-xs">Enter each email on a new line.</span>
          </el-form-item>
        </el-form>
      </div>
      <template v-slot:footer-right>
        <el-button @click="bulkBlacklist.show = false" icon="el-icon-close" type="danger" size="small">Cancel</el-button>
        <el-button type="success" icon="el-icon-edit-outline" @click="createBulkList" size="small">Confirm</el-button>
      </template>
    </trieste-dialog>
  </div>
</template>
<script>
import { elementDomainValidator, domainRegex } from "@/utils/helpers";
  export default {
    beforeRouteEnter(to, from, next) {
      next(vm => {
        vm.$store.commit('global/setBreadcrumbs', [
          {
            path: '/',
            label: 'Home'
          },
          {
            label: 'StopWord'
          }
        ])
      })
    },
    mounted() {
      /*if(!this.$store.getters['auth/hasPermission']('domain.read')) {
        this.$message.error('You do not have permissions to view domained contacts');
        this.$router.push('/');
      }*/
    },
    data: function () {
      const bulkDomainValidator = (rule, value, callback) => {
        if(value) {
          const domains = value.split('\n').map(e => e.trim())
          let line = 1;
          for(const domain of domains) {
            let valid = false
            if(value.length > 3) {
              try {
                if(domainRegex.test(domain)) {
                  valid = true
                }
              } catch (e) {
              }
            }
            if(!valid) {
              return callback(`The domain on line ${line} is invalid.`)
            }
            line++;
          }
          return callback()
        } else {
          return callback(new Error('Please enter at least one domain.'))
        }
      }
      return {
        selected_placeholder: null,
        showCreateDomain: false,
        selected: [],
        new_blacklistedDomain: {
          id: null,
          domain: ''
        },
        bulkBlacklist: {
          text: '',
          show: false
        },
        bulkRules: {
          text: [
            { required: true, validator: bulkDomainValidator, trigger: 'blur' }
          ]
        },
        new_blacklistedDomain_rules: {
          domain: [
            {required: true, min: 3, trigger: 'blur', message: 'Please enter a blacklisted domain'},
            { required: true, validator: elementDomainValidator, trigger: 'change' }
          ],
        },
        query: {
          rpp: 20,
          page: 1
        },
        loading: false,
        fields: [
          {
            name: 'domain',
            title: 'domain',
            sortField: 'domain',
            titleClass: 'text-left',
            dataClass: 'text-left',
          },
          {
            title: 'Created at',
            name: 'created_at',
            dateField: true,
            sortField: 'created_at',
          },
          {
            title: '',
            name: 'actions-slot',
            width: '110px',
            fixed: 'right'
          },
        ],
        filters: [
          {
            label: 'domain',
          }
        ]
      }
    },
    methods: {
      deletedBlacklistedDomain(domain) {
        if(domain.id && domain.domain) {
          this.$confirm('Are you sure you want to delete the blacklisted domain '+domain.domain+'?', 'are you sure?', {
            confirmButtonClass: 'el-button--danger'
          })
            .then(() => {
              this.$http.delete( `/v1/companies/${this.$store.state.company.company.id}/blacklisted-domains/${domain.id}`)
                .then(() => {
                  this.$message.success('The stop word has successfully been deleted.');
                  this.$refs.table.getData()
                  this.closeCreateStopWordDialog()
                })
                .catch(() => {
                  this.$message.error('The was an error processing that request. Please try again later.')
                })
            })
            .catch(() => {
              // do nothign
            })
        }
      },
      bulkDelete() {
        if(this.selected.length) {
          this.$confirm('Are you sure you want to delete these domains?').then(() => {
            this.loading = true;
            this.$http.delete(`/v1/companies/${this.$route.params.companyId}/blacklisted-domains`, {
              data: {
                domains: this.selected.map(s => s.id)
              }
            }).then(() => {
              this.$refs.table.clearSelection()
              this.$refs.table.getData()
            }).finally(() => {
              this.loading = false
            })
          }).catch(() => {
          })
        }
      },
      createBulkList() {
        this.$refs.bulkForm.validate((valid) => {
          if(valid) {
            this.loading = true
            this.$http.post(`/v1/companies/${this.$route.params.companyId}/blacklisted-domains/bulk-create`, {
              domains: this.bulkBlacklist.text.split('\n').map(e => e.trim())
            }).then(() => {
              this.$message.success('Successfully created the domains.')
              this.bulkBlacklist.show = false
              this.bulkBlacklist.text = ''
              this.$refs.table.getData()
            }).catch(() => {
              this.$message.error('Failed to create the bulk domains')
            })
            .finally(() => {
              this.loading = false
            })
          }
        })
      },
      createBlacklistedDomain() {
        this.$refs.blacklistForm.validate((valid) => {
          if(valid) {
            this.loading = true;

            let method = this.$http.post;
            let url = `/v1/companies/${this.$store.state.company.company.id}/blacklisted-domains`;

            if(this.new_blacklistedDomain.id) {
              url += `/${this.new_blacklistedDomain.id}`;
              method = this.$http.put;
            }

            method(url, this.new_blacklistedDomain)
              .then(() => {
                this.$message.success('Successfully '+(this.new_blacklistedDomain.id ? 'updated':'created')+' the blacklisted domain.');
                this.closeCreateStopWordDialog();
                this.$refs.blacklistForm.resetFields();
                this.$refs.table.getData()
              })
              .catch((err) => {
                if(err.response && err.response.data && err.response.data.error && err.response.data.error === 'exists') {
                  this.$message.error('Blacklist item already exists.')
                } else {
                  this.$message.error('An error has occurred.')
                }
              })
              .finally(() => {
                this.loading = false;
              })
          }
        })
      },
      closeCreateStopWordDialog() {
        this.new_blacklistedDomain = {
          id: null,
          domain: ''
        };
        this.showCreateDomain = false;
      },
    }
  }
</script>
