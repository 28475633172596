<template>
  <div v-loading="loading">
    <trieste-table
      item-type="Stop words"
      title="stop words"
      :end-point="'/v1/companies/' + $route.params.companyId + '/stop-words'"
      :fields="fields"
      ref="table"
      :show-title="false"
      default-sort-prop="email"
      saved-table-sort-name="blackedlisted-stopwords"
      :filters="filters"
      :allow-selection="$store.getters['auth/hasPermission']('blacklist.delete')"
      :selected-rows.sync="selected"
    >
      <template v-slot:header-right>
        <el-button type="success" size="medium" icon="el-icon-edit-outline"  @click="showCreateStopWord = true">Create</el-button>
        <el-button type="success" size="medium" icon="el-icon-edit-outline" v-if="$store.getters['auth/hasPermission']('blacklist.create')" @click="bulkBlacklist.show = true">Bulk create</el-button>
        <el-button type="danger" size="medium" icon="el-icon-delete" v-if="$store.getters['auth/hasPermission']('blacklist.delete') && selected.length" @click="bulkDelete">Bulk delete</el-button>
      </template>

      <template v-slot:actions-slot="props">
        <el-button
          size="small"
          icon="el-icon-edit"
          @click="() => { new_stopWord = Object.assign({}, props.row); showCreateStopWord = true }"
          type="primary">
          Edit
        </el-button>
      </template>
    </trieste-table>
    <trieste-dialog :show.sync="showCreateStopWord" :title="new_stopWord.id ? 'Edit ' + new_stopWord.word : 'Create a stopWord'">
      <div class="p-6 pb-2">
        <el-form :rules="new_stopWord_rules" :model="new_stopWord" label-width="120px" label-position="left" ref="wordForm" v-loading="loading">
          <el-form-item label="word" prop="word">
            <el-input type="email" v-model="new_stopWord.word" />
          </el-form-item>
        </el-form>
      </div>
      <template v-slot:footer-left v-if="new_stopWord.id">
        <el-button type="danger" size="small" icon="el-icon-delete" v-if="new_stopWord.id" @click="deleteStopWord(new_stopWord)">Permanently delete this stop word</el-button>
      </template>
      <template v-slot:footer-right>
        <el-button @click="closeCreateStopWordDialog" icon="el-icon-close" type="danger" size="small">Cancel</el-button>
        <el-button type="success" icon="el-icon-edit-outline" @click="createStopWord" size="small">Confirm</el-button>
      </template>
    </trieste-dialog>
    <trieste-dialog :show.sync="bulkBlacklist.show" title="Bulk create">
      <div class="p-6 pb-2">
        <el-form :rules="bulkRules" :model="bulkBlacklist" label-width="120px" label-position="left" ref="bulkForm" v-loading="loading">
          <el-form-item label="Domains" prop="text">
            <el-input type="textarea" v-model="bulkBlacklist.text" rows="10" resizable="false" />
            <span class="text-xs">Enter each email on a new line.</span>
          </el-form-item>
        </el-form>
      </div>
      <template v-slot:footer-right>
        <el-button @click="bulkBlacklist.show = false" icon="el-icon-close" type="danger" size="small">Cancel</el-button>
        <el-button type="success" icon="el-icon-edit-outline" @click="createBulkList" size="small">Confirm</el-button>
      </template>
    </trieste-dialog>
  </div>
</template>
<script>
  export default {
    beforeRouteEnter(to, from, next) {
      next(vm => {
        vm.$store.commit('global/setBreadcrumbs', [
          {
            path: '/',
            label: 'Home'
          },
          {
            label: 'StopWord'
          }
        ])
      })
    },
    mounted() {
      /*if(!this.$store.getters['auth/hasPermission']('stopWord.read')) {
        this.$message.error('You do not have permissions to view stopWorded contacts');
        this.$router.push('/');
      }*/
    },
    data: function () {
      const bulkWordValidator = (rule, value, callback) => {
        if(value) {
          const words = value.split('\n').map(e => e.trim())
          let line = 1;
          for(const word of words) {
            if(word.length < 3) {
              return callback(`The word on line ${line} should be at least 3 characters in length.`)
            }
            line++;
          }
          return callback()
        } else {
          return callback(new Error('Please enter at least one word.'))
        }
      }
      return {
        selected: [],
        selected_placeholder: null,
        showCreateStopWord: false,
        new_stopWord: {
          id: null,
          word: ''
        },
        bulkBlacklist: {
          text: '',
          show: false
        },
        new_stopWord_rules: {
          word: [
            {required: true, min: 3, trigger: 'blur', message: 'Please enter a word'}
          ],
        },
        query: {
          rpp: 20,
          page: 1
        },
        loading: false,
        fields: [
          {
            name: 'word',
            title: 'Word',
            sortField: 'word',
            titleClass: 'text-left',
            dataClass: 'text-left',
          },
          {
            title: 'Created at',
            name: 'created_at',
            dateField: true,
            sortField: 'created_at',
          },
          {
            title: '',
            name: 'actions-slot',
            width: '110px',
            fixed: 'right'
          },
        ],
        filters: [
          {
            label: 'Word',
          }
        ],
        bulkRules: {
          text: [
            { required: true, validator: bulkWordValidator, trigger: 'blur' }
          ]
        },
      }
    },
    methods: {
      deleteStopWord(stopWord) {
        if(stopWord.id && stopWord.word) {
          this.$confirm('Are you sure you want to delete the stop word '+stopWord.word+'?', 'are you sure?', {
            confirmButtonClass: 'el-button--danger'
          })
            .then(() => {
              this.$http.delete( `/v1/companies/${this.$store.state.company.company.id}/stop-words/${stopWord.id}`)
                .then(() => {
                  this.$message.success('The stop word has successfully been deleted.');
                  this.$refs.table.getData()
                  this.closeCreateStopWordDialog()
                })
                .catch(() => {
                  this.$message.error('The was an error processing that request. Please try again later.')
                })
            })
            .catch(() => {
              // do nothign
            })
        }
      },
      createBulkList() {
        this.loading = true
        this.$refs.bulkForm.validate((valid) => {
          if(valid) {
            this.$http.post(`/v1/companies/${this.$route.params.companyId}/stop-words/bulk-create`, {
              words: this.bulkBlacklist.text.split('\n').map(e => e.trim())
            }).then(() => {
              this.$message.success('Successfully created the stop words.')
              this.bulkBlacklist.show = false
              this.bulkBlacklist.text = ''
              this.$refs.table.getData()
            }).catch(() => {
              this.$message.error('Failed to create the bulk stop words.')
            })
            .finally(() => {
              this.loading = false
            })
          }
        })
      },
      bulkDelete() {
        if(this.selected.length) {
          this.$confirm('Are you sure you want to delete these words?').then(() => {
            this.loading = true;
            this.$http.delete(`/v1/companies/${this.$route.params.companyId}/stop-words`, {
              data: {
                stop_words: this.selected.map(s => s.id)
              }
            }).then(() => {
              this.$refs.table.clearSelection()
              this.$refs.table.getData()
            }).finally(() => {
              this.loading = false
            })
          }).catch(() => {
          })
        }
      },
      createStopWord() {
        this.$refs.wordForm.validate((valid) => {
          if(valid) {
            this.loading = true;

            let method = this.$http.post;
            let url = `/v1/companies/${this.$store.state.company.company.id}/stop-words`;

            if(this.new_stopWord.id) {
              url += `/${this.new_stopWord.id}`;
              method = this.$http.put;
            }

            method(url, this.new_stopWord)
              .then(() => {
                this.$message.success('Successfully '+(this.new_stopWord.id ? 'updated':'created')+' the stop word.');
                this.closeCreateStopWordDialog();
                this.$refs.wordForm.resetFields();
                this.$refs.table.getData()
              })
              .catch((err) => {
                if(err.response && err.response.data && err.response.data.error && err.response.data.error === 'exists') {
                  this.$message.error('Blacklist item already exists.')
                } else {
                  this.$message.error('An error has occurred.')
                }
              })
              .finally(() => {
                this.loading = false;
              })
          }
        })
      },
      closeCreateStopWordDialog() {
        this.new_stopWord = {
          id: null,
          word: ''
        };
        this.showCreateStopWord = false;
      },
    }
  }
</script>
