<template>
  <div>
    <trieste-table title="Sites" :show-sub-title="false" :show-pagination="false" :params="filters" ref="table"
      :fields="fields" :end-point="`/v1/companies/${this.$route.params.companyId}/reports/content/site`" is-post>
    </trieste-table>
  </div>
</template>
<script>
export default {
  props: {
    filters: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      fields: [
        {
          title: 'domain',
          name: 'domain'
        },
        {
          title: 'Content items',
          name: 'total_content_items',
          numberField: true
        },
        {
          title: 'Site links',
          name: 'content_items_with_site_links',
          numberField: true
        },
        {
          title: '%CONVERSION',
          name: 'content_success_ratio',
          numberField: true
        }
      ]
    }
  },
  methods: {
    refresh() {
      this.$refs.table.getData()
    },
  }
}
</script>
