import statuses from "./../../utils/link/status"
import types from "./../../utils/link/type"
import reject_reasons from "./../../utils/link/reject_reasons"

export default {
  namespaced: true,
  state: {
    rejectionReasons: reject_reasons,
    statuses: statuses,
    types: types,
    leadStatuses: ['not_reviewed', 'declined', 'accepted', 'declined_parked', 'deferred'],
  }
}
