<template>
  <div class="flex flex-shrink-0" v-loading="loading">
    <div class="flex flex-col w-54 relative" id="sidebar" :class="{ collapse: isCollapsed }">
      <a href="/">
      <div class="flex collapse_justify_center flex-row items-center h-16 flex-shrink-0 px-4 bg-blue-900 border-b border-solid border-blue-900">
        <img
          class="mx-2 mr-3 h-6 w-auto collapse_no_mr collapse_no_ml"
          src="/imgs/logo_white.png"
          alt="Trieste V2"
        >
        <h1 class="text-white text-xl font-bold nav_collapse_hidden">Trieste.io</h1>
      </div>
      </a>
      <div class="h-0 flex-1 flex flex-col overflow-y-auto bg-blue-900">
        <div class="p-4 bg-blue-800 border-t border-b border-solid border-blue-600">
          <div class="border-solid border-white  p-2 cursor-pointer" @click="showChangeCompanyDialog">
            <div class="flex flex-row items-center collapse_justify_center">
              <div class="rounded-full overflow-hidden shadow-sm border border-gray-100 border-solid">
                <logo-widget v-model="$store.state.company.company.url" :size="isCollapsed ? 24 : 40" />
              </div>
              <div class="ml-2 nav_collapse_hidden">
                <span class="text-white text-base font-medium">{{ $store.state.company.company.name | shortenText(20)}}</span>
                <div><span class="text-xs text-gray-400"><i class="fas fa-sync-alt"></i> Change company</span></div>
              </div>
            </div>
          </div>
        </div>
        <!-- Sidebar component, swap this element with another sidebar if you like -->
        <nav class="flex-1 p-4 border-t border-solid border-blue-900">
          <div v-if="!isExternalApprover">
            <el-tooltip effect="dark" :disabled="!$store.state.global.sidebarCollapsed" content="Clients" placement="right-start"
                        v-if="$store.getters['auth/hasPermission']('client.read') && companySelected"
            >
              <a
                :href="`/company/${$store.state.company.company.id}/clients`"
                class="collapse_justify_center mt-1 group flex items-center px-2 py-2 text-sm leading-5 font-medium text-white hover:text-white hover:bg-blue-800 focus:outline-none focus:text-white focus:bg-blue-900 transition ease-in-out duration-150"
              >
                <svg xmlns="http://www.w3.org/2000/svg" class="collapse_no_mr mr-3 h-6 w-6 text-white group-hover:text-white group-focus:text-white transition ease-in-out duration-150" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 21V5a2 2 0 00-2-2H7a2 2 0 00-2 2v16m14 0h2m-2 0h-5m-9 0H3m2 0h5M9 7h1m-1 4h1m4-4h1m-1 4h1m-5 10v-5a1 1 0 011-1h2a1 1 0 011 1v5m-4 0h4" />
                </svg>
                <span class="nav_collapse_hidden">Clients</span>
              </a>
            </el-tooltip>
            <el-tooltip effect="dark" :disabled="!$store.state.global.sidebarCollapsed" content="Batches" placement="right-start"
                        v-if="$store.getters['auth/hasPermission']('batch.read') && companySelected">
              <a
                v-if="$store.getters['auth/hasPermission']('batch.read')"
                :href="`/company/${$store.state.company.company.id}/batches`"
                class="collapse_justify_center mt-1 group flex items-center px-2 py-2 text-sm leading-5 font-medium text-white hover:text-white hover:bg-blue-800 focus:outline-none focus:text-white focus:bg-blue-900 transition ease-in-out duration-150"
              >
                <svg class="collapse_no_mr mr-3 h-6 w-6 text-white group-hover:text-white group-focus:text-white transition ease-in-out duration-150" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 10h18M3 14h18m-9-4v8m-7 0h14a2 2 0 002-2V8a2 2 0 00-2-2H5a2 2 0 00-2 2v8a2 2 0 002 2z" />
                </svg>
                <span class="nav_collapse_hidden">Batches</span>
              </a>
            </el-tooltip>
            <el-tooltip effect="dark" :disabled="!$store.state.global.sidebarCollapsed"
                        content="Content"
                        placement="right-start"
                        v-if="$store.getters['auth/hasPermission']('content.read') && companySelected"
            >
              <a
                :href="`/company/${$store.state.company.company.id}/content`"
                class="collapse_justify_center mt-1 group relative flex items-center px-2 py-2 text-sm leading-5 font-medium text-white hover:text-white hover:bg-blue-800 focus:outline-none focus:text-white focus:bg-blue-900 transition ease-in-out duration-150"
              >
                  <svg class="collapse_no_mr  mr-3 h-6 w-6 text-white group-hover:text-white group-focus:text-white transition ease-in-out duration-150" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z" />
                  </svg>
                <span class="nav_collapse_hidden">Content</span>

                <span class="w-6 h-6 nav_collapse_hidden flex items-center shadow border border-white justify-center rounded-full bg-red-400 ml-auto text-center text-xs" v-if="content_item_count > 0">
                  <span>{{ content_item_count }}</span>
                </span>
              </a>
            </el-tooltip>
            <el-tooltip effect="dark" :disabled="!$store.state.global.sidebarCollapsed"
                        content="Leads"
                        placement="right-start"
                        v-if="$store.getters['auth/hasPermission']('lead.read') && companySelected"
            >
              <a
                :href="`/company/${$store.state.company.company.id}/leads`"
                class="collapse_justify_center mt-1 group flex items-center px-2 py-2 text-sm leading-5 font-medium text-white hover:text-white hover:bg-blue-800 focus:outline-none focus:text-white focus:bg-blue-900 transition ease-in-out duration-150"
              >
                <svg xmlns="http://www.w3.org/2000/svg" class="collapse_no_mr mr-3 h-6 w-6 text-white group-hover:text-white group-focus:text-white transition ease-in-out duration-150" viewBox="0 0 20 20" fill="currentColor">
                  <path fill-rule="evenodd" d="M12.586 4.586a2 2 0 112.828 2.828l-3 3a2 2 0 01-2.828 0 1 1 0 00-1.414 1.414 4 4 0 005.656 0l3-3a4 4 0 00-5.656-5.656l-1.5 1.5a1 1 0 101.414 1.414l1.5-1.5zm-5 5a2 2 0 012.828 0 1 1 0 101.414-1.414 4 4 0 00-5.656 0l-3 3a4 4 0 105.656 5.656l1.5-1.5a1 1 0 10-1.414-1.414l-1.5 1.5a2 2 0 11-2.828-2.828l3-3z" clip-rule="evenodd" />
                </svg>
                <span class="nav_collapse_hidden">Leads</span>
              </a>
            </el-tooltip>
            <el-tooltip effect="dark" :disabled="!$store.state.global.sidebarCollapsed"
                        content="Payments"
                        placement="right-start"
                        v-if="$store.getters['auth/hasPermission']('payment.read') && companySelected"
            >
              <a
                :href="`/company/${$store.state.company.company.id}/payments`"
                class="collapse_justify_center mt-1 group flex items-center px-2 py-2 text-sm leading-5 font-medium text-white hover:text-white hover:bg-blue-800 focus:outline-none focus:text-white focus:bg-blue-900 transition ease-in-out duration-150"
              >
                <svg class="collapse_no_mr mr-3 h-6 w-6 text-white group-hover:text-white group-focus:text-white transition ease-in-out duration-150" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 8c-1.657 0-3 .895-3 2s1.343 2 3 2 3 .895 3 2-1.343 2-3 2m0-8c1.11 0 2.08.402 2.599 1M12 8V7m0 1v8m0 0v1m0-1c-1.11 0-2.08-.402-2.599-1M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                </svg>
                <span class="nav_collapse_hidden">Payments</span>
              </a>
            </el-tooltip>
            <el-tooltip effect="dark" :disabled="!$store.state.global.sidebarCollapsed"
                        content="Email Accounts"
                        placement="right-start"
                        v-if="$store.getters['auth/hasPermission']('emailaccount.read') && companySelected"
            >
              <a
                :href="`/company/${$store.state.company.company.id}/email-accounts`"
                class="collapse_justify_center mt-1 group flex items-center px-2 py-2 text-sm leading-5 font-medium text-white hover:text-white hover:bg-blue-800 focus:outline-none focus:text-white focus:bg-blue-900 transition ease-in-out duration-150"
              >
                <svg class="collapse_no_mr mr-3 h-6 w-6 text-white group-hover:text-white group-focus:text-white transition ease-in-out duration-150" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z" />
                </svg>
                <span class="nav_collapse_hidden">Email Accounts</span>
              </a>
            </el-tooltip>
            <el-tooltip effect="dark" :disabled="!$store.state.global.sidebarCollapsed"
                        content="Company Users"
                        placement="right-start"
                        v-if="$store.getters['auth/hasPermission']('user.read') && companySelected"
            >
              <a
                :href="`/company/${$store.state.company.company.id}/users`"
                class="collapse_justify_center mt-1 group flex items-center px-2 py-2 text-sm leading-5 font-medium text-white hover:text-white hover:bg-blue-800 focus:outline-none focus:text-white focus:bg-blue-900 transition ease-in-out duration-150"
              >
                <svg class="collapse_no_mr mr-3 h-6 w-6 text-white group-hover:text-white group-focus:text-white transition ease-in-out duration-150" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 4.354a4 4 0 110 5.292M15 21H3v-1a6 6 0 0112 0v1zm0 0h6v-1a6 6 0 00-9-5.197M13 7a4 4 0 11-8 0 4 4 0 018 0z" />
                </svg>
                <span class="nav_collapse_hidden">Company Users</span>
              </a>
            </el-tooltip>
            <el-tooltip effect="dark" :disabled="!$store.state.global.sidebarCollapsed"
                        content="Blacklisted" placement="right-start"
                        v-if="$store.getters['auth/hasPermission']('blacklist.read') && companySelected"
            >
              <a
                :href="`/company/${$store.state.company.company.id}/blacklist`"
                class="collapse_justify_center mt-1 group flex items-center px-2 py-2 text-sm leading-5 font-medium text-white hover:text-white hover:bg-blue-800 focus:outline-none focus:text-white focus:bg-blue-900 transition ease-in-out duration-150"
              >
                <svg class="collapse_no_mr mr-3 h-6 w-6 text-white group-hover:text-white group-focus:text-white transition ease-in-out duration-150" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M18.364 18.364A9 9 0 005.636 5.636m12.728 12.728A9 9 0 015.636 5.636m12.728 12.728L5.636 5.636" />
                </svg>
                <span class="nav_collapse_hidden">Blacklist</span>
              </a>
            </el-tooltip>
            <el-tooltip effect="dark" :disabled="!$store.state.global.sidebarCollapsed"
                        content="Email Templates" placement="right-start"
                        v-if="$store.getters['auth/hasPermission']('template.read') && companySelected"
            >
              <a
                :href="`/company/${$store.state.company.company.id}/email-templates`"
                class="collapse_justify_center mt-1 group flex items-center px-2 py-2 text-sm leading-5 font-medium text-white hover:text-white hover:bg-blue-800 focus:outline-none focus:text-white focus:bg-blue-900 transition ease-in-out duration-150"
              >
                <svg class="collapse_no_mr mr-3 h-6 w-6 text-white group-hover:text-white group-focus:text-white transition ease-in-out duration-150" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M8 16H6a2 2 0 01-2-2V6a2 2 0 012-2h8a2 2 0 012 2v2m-6 12h8a2 2 0 002-2v-8a2 2 0 00-2-2h-8a2 2 0 00-2 2v8a2 2 0 002 2z" />
                </svg>
                <span class="nav_collapse_hidden">Email Templates</span>
              </a>
            </el-tooltip>
            <el-tooltip effect="dark" :disabled="!$store.state.global.sidebarCollapsed" content="Note templates" placement="right-start">
              <a
                :href="`/company/${$store.state.company.company.id}/note-templates`"
                v-if="$store.getters['auth/hasPermission']('note-template.read') || true"
                class="collapse_justify_center mt-1 group flex items-center px-2 py-2 text-sm leading-5 font-medium text-white hover:text-white hover:bg-blue-800 focus:outline-none focus:text-white focus:bg-blue-900 transition ease-in-out duration-150"
              >
                <svg xmlns="http://www.w3.org/2000/svg" class="collapse_no_mr mr-3 h-6 w-6 text-white group-hover:text-white group-focus:text-white transition ease-in-out duration-150" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path d="M4 5a1 1 0 011-1h14a1 1 0 011 1v2a1 1 0 01-1 1H5a1 1 0 01-1-1V5zM4 13a1 1 0 011-1h6a1 1 0 011 1v6a1 1 0 01-1 1H5a1 1 0 01-1-1v-6zM16 13a1 1 0 011-1h2a1 1 0 011 1v6a1 1 0 01-1 1h-2a1 1 0 01-1-1v-6z" />
                </svg>
                <span class="nav_collapse_hidden">Note templates</span>
              </a>
            </el-tooltip>
            <el-tooltip effect="dark" :disabled="!$store.state.global.sidebarCollapsed" content="Link building" placement="right-start">
              <a
                :href="`/link-building`"
                v-if="$store.getters['auth/hasPermission']('linkbuilding.read')"
                class="collapse_justify_center mt-1 group flex items-center px-2 py-2 text-sm leading-5 font-medium text-white hover:text-white hover:bg-blue-800 focus:outline-none focus:text-white focus:bg-blue-900 transition ease-in-out duration-150"
              >
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" class="collapse_no_mr mr-3 h-6 w-6 text-white group-hover:text-white group-focus:text-white transition ease-in-out duration-150" stroke="currentColor">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 10h18M7 15h1m4 0h1m-7 4h12a3 3 0 003-3V8a3 3 0 00-3-3H6a3 3 0 00-3 3v8a3 3 0 003 3z" />
                </svg>
                <span class="nav_collapse_hidden">Link building</span>
              </a>
            </el-tooltip>
            <el-tooltip effect="dark" :disabled="!$store.state.global.sidebarCollapsed" content="Reports" placement="right-start" v-if="companySelected">
              <a
                :href="`/company/${$store.state.company.company.id}/reports`"
                v-if="$store.getters['auth/hasPermission']('report.read')"
                class="collapse_justify_center mt-1 group flex items-center px-2 py-2 text-sm leading-5 font-medium text-white hover:text-white hover:bg-blue-800 focus:outline-none focus:text-white focus:bg-blue-900 transition ease-in-out duration-150"
              >
                <svg class="collapse_no_mr mr-3 h-6 w-6 text-white group-hover:text-white group-focus:text-white transition ease-in-out duration-150" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 17v-2m3 2v-4m3 4v-6m2 10H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z" />
                </svg>
                <span class="nav_collapse_hidden">Reports</span>
              </a>
            </el-tooltip>
            <el-tooltip effect="dark" :disabled="!$store.state.global.sidebarCollapsed" content="Publisher History" placement="right-start" v-if="companySelected">
              <a
                :href="`/company/${$store.state.company.company.id}/publisher-history`"
                class="collapse_justify_center mt-1 group flex items-center px-2 py-2 text-sm leading-5 font-medium text-white hover:text-white hover:bg-blue-800 focus:outline-none focus:text-white focus:bg-blue-900 transition ease-in-out duration-150"
              >
                <svg xmlns="http://www.w3.org/2000/svg"
                     class="collapse_no_mr mr-3 h-6 w-6 text-white group-hover:text-white group-focus:text-white transition ease-in-out duration-150"
                     viewBox="0 0 24 24"><path fill="currentColor" d="M12 21.327q-1.325 0-2.24-.915q-.914-.914-.914-2.239q0-1.185.756-2.057q.756-.872 1.898-1.049V12.5H7.615q-.666 0-1.14-.475Q6 11.551 6 10.885V8.904H4.404q-.343 0-.576-.232q-.232-.233-.232-.576V3.911q0-.355.232-.585q.233-.23.576-.23h4.185q.355 0 .585.232q.23.233.23.576v4.185q0 .355-.232.585q-.233.23-.576.23H7v1.98q0 .27.173.443t.442.173h8.77q.269 0 .442-.173t.173-.442v-2.03q-1.142-.176-1.898-1.048q-.756-.872-.756-2.057q0-1.325.915-2.24q.915-.914 2.24-.914t2.238.915q.915.914.915 2.239q0 1.185-.756 2.057q-.756.872-1.898 1.049v2.029q0 .666-.475 1.14q-.474.475-1.14.475H12.5v2.567q1.142.177 1.898 1.05q.756.871.756 2.056q0 1.325-.915 2.24q-.915.914-2.24.914"></path></svg>

                <span class="nav_collapse_hidden">Publisher History</span>
              </a>
            </el-tooltip>
            <el-tooltip effect="dark" :disabled="!$store.state.global.sidebarCollapsed" content="Site requirements" placement="right-start">
              <a
                v-if="$store.getters['auth/hasPermission']('site.read')"
                :href="`/company/${$store.state.company.company.id}/site-requirements`"
                class="collapse_justify_center mt-1 group flex items-center px-2 py-2 text-sm leading-5 font-medium text-white hover:text-white hover:bg-blue-800 focus:outline-none focus:text-white focus:bg-blue-900 transition ease-in-out duration-150"
              >
                <svg xmlns="http://www.w3.org/2000/svg" class="collapse_no_mr mr-3 h-6 w-6 text-white group-hover:text-white group-focus:text-white transition ease-in-out duration-150" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M21 12a9 9 0 01-9 9m9-9a9 9 0 00-9-9m9 9H3m9 9a9 9 0 01-9-9m9 9c1.657 0 3-4.03 3-9s-1.343-9-3-9m0 18c-1.657 0-3-4.03-3-9s1.343-9 3-9m-9 9a9 9 0 019-9" />
                </svg>
                <span class="nav_collapse_hidden">Site requirements</span>
              </a>
            </el-tooltip>
            <el-tooltip effect="dark" :disabled="!$store.state.global.sidebarCollapsed" content="Admin" placement="right-start">
              <a
                v-if="$store.getters['auth/hasPermission']('admin')"
                :href="`/admin`"
                class="collapse_justify_center mt-1 group flex items-center px-2 py-2 text-sm leading-5 font-medium text-white hover:text-white hover:bg-blue-800 focus:outline-none focus:text-white focus:bg-blue-900 transition ease-in-out duration-150"
              >
                <svg class="collapse_no_mr mr-3 h-6 w-6 text-white group-hover:text-white group-focus:text-white transition ease-in-out duration-150" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9.75 17L9 20l-1 1h8l-1-1-.75-3M3 13h18M5 17h14a2 2 0 002-2V5a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z" />
                </svg>
                <span class="nav_collapse_hidden">Admin</span>
              </a>
            </el-tooltip>
          </div>
        </nav>
        <a
          @click.prevent="$store.commit('global/collapseSidebar')"
          class="border-b cursor-pointer border-solid border-gray-800 collapse_justify_center mt-1 group flex items-center p-6 text-sm leading-5 font-medium text-white hover:text-white focus:outline-none focus:text-white transition ease-in-out duration-150"
        >
          <div v-if="isCollapsed" class="w-6 mr-3 ml-1 collapse_no_mr">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 15v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2zm10-10V7a4 4 0 00-8 0v4h8z" />
            </svg>
          </div>
          <div v-else  class="w-6 mr-3 ml-1 collapse_no_mr">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M8 11V7a4 4 0 118 0m-4 8v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2z" />
            </svg>
          </div>
          <span class="nav_collapse_hidden mt-1">Collapse navigation</span>
        </a>
      </div>
    </div>
    <trieste-dialog :show.sync="showCompanySelect" title="Select a company">
      <ul class="divide-y divide-gray-200">
        <li v-for="(company, ci) in companyList" :key="'company_' + ci" class="hover:bg-gray-100" @click="changeCompany(company.id)">
          <a href="#" class="block hover:bg-gray-50">
            <div class="flex items-center px-4 py-4 sm:px-6">
              <div class="min-w-0 flex-1 flex items-center">
                <div class="flex-shrink-0">
                  <div class="rounded-full h-12 w-12 overflow-hidden">
                    <logo-widget v-model="company.url" />
                  </div>
                </div>
                <div class="min-w-0 flex-1 px-4 md:grid md:grid-cols-2 md:gap-4">
                  <div>
                    <p class="text-sm font-medium text-indigo-600 truncate">{{ company.name }}</p>
                    <div class="mt-2 flex flex-row items-center text-sm text-gray-500">
                      <!-- Heroicon name: mail -->
                      <i class="far fa-building "></i>
                      <span class="truncate ml-1">{{ company.client_count.toLocaleString() }} clients</span>
                    </div>
                  </div>
                  <div class="flex items-center" v-if="false">
                    <div>
                      <p class="text-sm text-gray-900">
                        Created on
                        <time datetime="2020-01-07">{{ company.created_at | momentLocal("MMMM DD YYYY Z")  }}</time>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <svg class="h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                  <path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd" />
                </svg>
              </div>
            </div>
          </a>
        </li>
      </ul>
      <template v-slot:footer-left>
        <span class="text-gray-700 text-xs font-medium">{{ companyList.length.toLocaleString() }} companies</span>
      </template>
      <template v-slot:footer-right v-if="!$store.state.company.requiresCompanySet">
        <el-button type="danger" size="small" icon="el-icon-close" @click="showCompanySelect = false">Cancel</el-button>
      </template>
    </trieste-dialog>
  </div>
</template>

<script>
import LogoWidget from "./LogoWidget";
export default {
  name: 'SidebarMenu',
  components: {LogoWidget},
  data: function() {
    return  {
      showCompanySelect: false,
      companyList: [],
      loadedCompanies: false,
      loading: false,
      content_item_count: 0,
    }
  },
  computed: {
    isCollapsed() {
      return /*this.$route.meta.forceSidebarCollapse ||*/ this.$store.state.global.sidebarCollapsed
    },
    companySelected() {
      return !!!this.$store.state.company.requiresCompanySet
    },
    isExternalApprover() {
      return this.$store.getters['auth/isExternalUser']();
    }
  },
  mounted() {
    if(!this.companySelected) {
      this.getCompanies(this.showChangeCompanyDialog)
    } else {
      this.getContentCount();
    }
  },
  methods: {
    getContentCount() {
      const allowedRules = [
        'admin',
        'link_manager',
        'content_manager',
        'content_writer',
        'Content Writer',
        'Content Editor'
      ]
      if(!this.$store.state.auth.user.roles.find(r => allowedRules.includes(r.toLowerCase()))) return;
      this.$http.get(`/v1/companies/${this.$store.state.company.company.id}/content/counts`, {
        params: {
          status: 'new',
          owner: false
        }
      }).then(r => {
        this.content_item_count = r.data.data.count;
      })
    },
    showChangeCompanyDialog() {
      this.loading = true;
      if(!this.loadedCompanies) {
        this.getCompanies(this.showChangeCompanyDialog)
      } else {
        this.loading = false;
        this.showCompanySelect = true;
      }
    },
    changeCompany(companyId) {
      this.loading = true;
      this.$store.dispatch('company/getCompany', { companyId }).then(() => {
        this.$store.dispatch('auth/setLastViewedCompany', {companyId: companyId}).then(() => {
          this.$store.dispatch('auth/getAlertCount', {
            alert_tasks: true
          }).then(() => {
            this.loading = false;
            this.showCompanySelect = false;
            this.$message.success('You have now changed company.')
            window.location = '/'
          })
        })
      })
      .catch(() => {
        this.$message.error('Failed to the request. Please try again later or contact admin.')
      })
      .finally(() => {
        this.showCompanySelect = false;
      })
    },
    getCompanies(callback) {
      this.$http.get('/v1/companies').then(res => {
        this.companyList = res.data.data;
        this.loadedCompanies = true;
        if(callback) {
          callback();
        }
      }).catch(() => {
        this.$message.error('Failed to load the available companies.')
      })
    }
  }
}
</script>

<style scoped>

</style>
