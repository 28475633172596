<template>
  <div>
    <div>
      <div class="page-title">
        <h1>
          Reports
        </h1>
      </div>
      <el-tabs class="custom-tabs-v1">
        <el-tab-pane label="Content Items" lazy>
          <content-item />
        </el-tab-pane>
        <el-tab-pane label="Productivity" laz7>
          <productivity />
        </el-tab-pane>
        <el-tab-pane label="Leads" lazy>
          <lead />
        </el-tab-pane>
        <el-tab-pane label="Link allocation" lazy>
          <link-allocation :show-title="false" />
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>
<script>
  import LinkAllocation from './LinkAllocation/Index';
  import ContentItem from './ContentItem';
  import Productivity from "./Productivity";
  import Lead from "./Lead";

  export default {
    components: {Lead, Productivity, LinkAllocation, ContentItem }
  }
</script>
