<template>
  <nav class="flex items-center justify-between border-t border-gray-200 px-4 sm:px-0">
    <div class="-mt-px flex w-0 flex-1">
      <button
        v-if="previous"
        @click="$emit('change', current - 1)"
        class="inline-flex items-center border-t-2 border-transparent pr-1 pt-4 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700">
        Previous
      </button>
    </div>

    <div class="hidden md:-mt-px md:flex">
      <button
        v-for="(r, i) in range"
        :key="'range-' + i"
        @click="$emit('change', r)"
        :class="{
          'border-indigo-500 text-indigo-600': r === current,
          'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300': r !== current
        }"
        class="inline-flex items-center border-t-2 border-transparent px-4 pt-4 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700">
        {{ r }}
      </button>
      <!-- Current: "border-indigo-500 text-indigo-600", Default: "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300" -->
    </div>
    <div class="-mt-px flex w-0 flex-1 justify-end">
      <button
        v-if="next"
        @click="$emit('change', current + 1)"
        class="inline-flex items-center border-t-2 border-transparent pl-1 pt-4 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700">
        Next
      </button>
    </div>
  </nav>
</template>

<script>
export default {
  name: "SimplePagination",
  props: {
    current: {
      type: Number,
      default(){
        return null
      }
    },
    previous: {
      type: Number,
      default(){
        return null
      }
    },
    next: {
      type: Number,
      default(){
        return null
      }
    },
    range: {
      type: Array,
      default(){
        return []
      }
    }
  }
}
</script>

<style scoped>

</style>
