<template>
  <div>
    <div class="page-title">
      <h1>
        Leads
      </h1>

      <div class="controls">
        <bulk-status-button />
      </div>
    </div>
    <links
      active-only
      force-reject-reason-filter
      sort-store-name="singular-leads-page"
      :additional-fields="additionalFields"
      :default-filters="defaultFilters"
    />
  </div>
</template>
<script>
  import BulkStatusButton from '@/components/views/links/BulkStatusButton'
  import Links from '@/views/Companies/Links/Index'
  import moment from 'moment'
  export default {
    metaInfo() {
      return {
        title: 'Leads'
      }
    },
    components: { Links, BulkStatusButton },
    data: function () {
      const df = {
        Owner: [this.$store.state.auth.user.id],
        'Created at': [
          moment().startOf('month').format('YYYY-MM-DD'),
          moment().format('YYYY-MM-DD')
        ]
      }
      return {
        additionalFields: [
          {
            title: 'Site',
            name: 'Site',
            sortField: 'Site',
          },
          {
            title: 'Project',
            name: 'Project',
            sortField: 'Project',
          },
        ],
        defaultFilters: df
      }
    },
    mounted() {
      if(!this.$store.getters['auth/hasPermission']('lead.read')) {
        this.$message.error('You do not have permissions to view leads.');
        this.$router.push('/');
      } else {
        this.$store.dispatch('company/getCompany', this.$route.params).then((company) => {
          this.$store.commit('global/setBreadcrumbs', [
            {
              path: `/`,
              label: company.name
            },
            {
              label: 'Leads'
            }
          ])
        })
      }
    },
    methods: {
    }
  }
</script>
