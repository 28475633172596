<template>
  <div>
    <trieste-tabbed-dialog :show.sync="should_show" v-if="ready" :key="tabKey">
    <trieste-tabbed-dialog-section name="Subscription details">
      <trieste-info-column label="Payment term" :value="subscription.payment_term" />
      <trieste-info-column label="Payment term type" :value="subscription.payment_term_type === '10' ? 'Monthly' : 'Permanent'" />
      <trieste-info-column label="Date start" :value="subscription.date_start" date />
      <trieste-info-column label="Date end" :value="subscription.date_end" date />
      <trieste-info-column label="Date complete" :value="subscription.date_complete" />
      <trieste-info-column label="Acquirer" v-if="ready">
        <template>
          <span class="text-sm font-light" v-if="subscription.acquirer && subscription.acquirer.firstname">{{`${subscription.acquirer.firstname} ${subscription.acquirer.lastname}`}}</span>
          <span class="text-gray-400 text-sm" v-else>N/A</span>
        </template>
      </trieste-info-column>
    </trieste-tabbed-dialog-section>
    <trieste-tabbed-dialog-section name="Payments">
      <span class="text-sm text-gray-500" v-if="!subscription.payments.length"> There are currently no payments made for this subscription.</span>
      <div class="-m-4" v-else>
        <el-table :data="subscription.payments">
          <el-table-column label="Payment">
            <template slot-scope="scope">
              {{ scope.row.price.toLocaleString() }} ({{scope.row.price_currency}})
            </template>
          </el-table-column>
          <el-table-column label="Type">
            <template slot-scope="scope">
              {{ scope.row.paypal_account && scope.row.paypal_account.length > 0 ? 'PayPal' : 'Check' }}
            </template>
          </el-table-column>
          <el-table-column label="Date">
            <template slot-scope="scope">
              {{ scope.row.date_paid | momentLocal("ddd MMMM DD YYYY Z") }}
            </template>
          </el-table-column>
          <el-table-column width="100px">
            <template slot-scope="scope">
              <el-button size="small" icon="el-icon-view" type="success" @click="() => { current_payment_details = scope.row; show_payment_details = true }">View</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <template v-slot:header-right>
        <div class="flex flex-row justify-end">
        <el-button size="small" icon="el-icon-edit-outline" @click="show_payment_form = true" type="primary">Add payment</el-button>
        </div>
      </template>
    </trieste-tabbed-dialog-section>
  </trieste-tabbed-dialog>
    <trieste-dialog :show.sync="show_payment_form" title="Create a payment">
      <div class="p-6 pb-2">
        <el-form :rules="new_payment_rules" :model="new_payment" label-width="120px" ref="paymentForm" v-loading="creating_payment">
          <el-form-item label="Currency" prop="price_currency" required>
            <el-select class="w-full" v-model="new_payment.price_currency">
              <el-option value="USD" label="USD" />
              <el-option value="EURO" label="EURO" />
              <el-option value="GBP" label="GBP" />
              <el-option value="CAD" label="CAD" />
            </el-select>
          </el-form-item>
          <el-form-item label="price" prop="price">
            <el-input type="text" v-model="new_payment.price" />
          </el-form-item>
          <el-form-item label="Paypal account" prop="paypal_account">
            <el-input type="text"  v-model="new_payment.paypal_account" />
          </el-form-item>
          <el-form-item label="Paypal TX Id" prop="paypal_account">
            <el-input type="text"  v-model="new_payment.paypal_tx_id" />
          </el-form-item>
          <el-form-item label="Check number" prop="check_number">
            <el-input type="text"  v-model="new_payment.check_number" />
          </el-form-item>
          <el-form-item label="Fedex tracking" prop="fedex_tracking">
            <el-input type="text"  v-model="new_payment.fedex_tracking" />
          </el-form-item>
          <el-form-item label="Payment date" prop="date_paid">
            <el-date-picker v-model="new_payment.date_paid"  class="w-full" format="dd MMM yyyy" value-format="yyyy-MM-dd" />
          </el-form-item>
        </el-form>
      </div>
      <template v-slot:footer-right class="dialog-footer">
        <el-button @click="show_payment_form = false" icon="el-icon-close" type="danger" size="small">Cancel</el-button>
        <el-button type="success" size="small" icon="el-icon-edit-outline" @click="createPayment">Confirm</el-button>
      </template>
    </trieste-dialog>
    <trieste-dialog :show.sync="show_payment_details" title="Payment details">
      <div class="p-6 py-4">
        <trieste-info-column label="Payment date" :value="current_payment_details.date_paid" />
        <trieste-info-column label="price" :value="current_payment_details.price" number />
        <trieste-info-column label="Currency" :value="current_payment_details.price_currency" />
        <trieste-info-column label="Paypal account" :value="current_payment_details.paypal_account" />
        <trieste-info-column label="Paypal TX Id" :value="current_payment_details.paypal_tx_id" />
        <trieste-info-column label="Check number" :value="current_payment_details.check_number" />
        <trieste-info-column label="Fedex tracking" :value="current_payment_details.fedex_tracking" />
      </div>
      <template v-slot:footer-right class="dialog-footer">
        <el-button @click="show_payment_details = false" icon="el-icon-close" type="danger" size="small">Close</el-button>
      </template>
    </trieste-dialog>
  </div>
</template>
<script>
  export default {
    props: {
      subscriptionId: {
        type: [String, Number],
        required: true
      },
      visible: {
        type: Boolean,
        default: true
      }
    },
    name: 'SubscriptionPaymentsWidget',
    data() {
      const priceValidator = (rule, value, callback) => {
        if(!parseFloat(value)) {
          return callback(new Error('Please enter a valid price'))
        }
        return callback();
      };
      return {
        ready: false,
        subscription: {},
        should_show: this.visible,
        show_payment_form: false,
        show_payment_details: false,
        current_payment_details: {},
        new_payment: {
          site_link_subscription_id: null,
          price: null,
          price_currency: 'USD',
          paypal_account: null,
          date_paid: null,
          check_number: null,
          fedex_tracking: null,
          paypal_tx_id: null
        },
        new_payment_rules: {
          price: [
            { validator: priceValidator, trigger: 'blur'}
          ],
          paypal_account: [
            { type: 'email', message: 'Please enter a valid paypal email', trigger: 'blur'}
          ],
          date_paid: [
            { required: true, message: 'Please enter a payment date', trigger: 'blur'}
          ]
        },
        creating_payment: false,
        tabKey: 0
      }
    },
    mounted() {
      this.getSubscription()
    },
    computed: {
      show() {
        return this.visible && this.ready
      }
    },
    methods: {
      showChanged(val) {
        if(val !== true) {
          this.should_show = false;
          this.$emit('update:visible', false);
        } else {
          this.should_show = false;
          this.$emit('update:visible', false);
        }
      },
      createPayment() {
        this.$refs.paymentForm.validate(valid => {
          if(valid) {
            this.creating_payment = true;
            const sId = this.subscription.id
            const paymentObject = Object.assign({}, this.new_payment);
            paymentObject.site_link_subscription_id = sId;
            this.$http.post(`/v1/companies/${this.$store.state.company.company.id}/subscriptions/${sId}/payments`,
              paymentObject)
              .then(() => {
                this.$message.success('Successfully created the payment')
                this.getSubscription()
                this.new_payment = {
                  site_link_subscription_id: null,
                  price: null,
                  price_currency: 'USD',
                  paypal_account: null,
                  date_paid: null,
                  check_number: null,
                  fedex_tracking: null,
                }
                this.show_payment_form = false;
                this.$emit('payment-created')
              })
              .catch(() => {
                this.$message.error('Failed to create the payment')
              })
              .finally(() => {
                this.creating_payment = false;
              })
          }
        })
      },
      getSubscription() {
        this.$http.get(`/v1/companies/${this.$store.state.company.company.id}/${this.subscriptionId}`)
          .then(r => {
            this.subscription = r.data.data;
            this.ready = true;
            this.tabKey++;
          })
          .catch(() => {
            this.$message.error('Failed to load the subscription')
          })
          .finally(() => {
            this.$emit('ready', true);
            this.$emit('on-load', true);
          })
      }
    },
    watch: {
      'visible': 'showChanged',
      'should_show': 'showChanged'
    }
  }
</script>
