<template>
  <div class="relative trieste-table-filters">
    <div class="flex flex-row w-full">
      <div class="border border-gray-300 rounded shadow-sm flex flex-row flex-wrap items-center flex-1">
        <div v-for="(element, i) in selectedElements" :key="i" class="p-1">
          <el-tag
            closable
            effect="plain"
            @close="removeElement(i)"
          >
            <template v-if="element.type === 'date'">
              {{ element.label }} <el-date-picker size="mini" :ref="'filter_ele_' + i" :type="element.dateType || 'date'" v-model="element.value" @change="emitFilterJson" :clearable="false" format="dd MMM yyyy" value-format="yyyy-MM-dd" />
            </template>
            <template v-else-if="element.type === 'select'">
              {{ element.label }}
              <el-select size="mini" v-model="element.value" :ref="'filter_ele_' + i" :multiple="element.multiple || false" @change="emitFilterJson" collapse-tags filterable>
                <el-option v-for="(opt, i) in element.options" :key="i" :value="opt.value !== undefined ? opt.value : opt.toLowerCase()" :label="opt.label ? opt.label : opt" />
              </el-select>
            </template>
            <template v-else-if="element.type === 'user'">
              {{ element.label }}
              <el-select size="mini" v-model="element.value" :ref="'filter_ele_' + i" :multiple="element.multiple || false" @change="emitFilterJson" collapse-tags filterable>
                <el-option v-for="(cu, i) in users" :key="i" :value="cu.id" :label="`${cu.firstname} ${cu.lastname}`" />
              </el-select>
            </template>
            <template v-else-if="element.type === 'user-group'">
              {{ element.label }}
              <el-select size="mini" v-model="element.value" :ref="'filter_ele_' + i" :multiple="element.multiple || false" @change="emitFilterJson" collapse-tags filterable>
                <el-option v-for="(g, i) in user_groups" :key="i" :value="g.id" :label="g.name" />
              </el-select>
            </template>
            <template v-else-if="element.type === 'site'">
              {{ element.label }}
              <el-select size="mini" v-model="element.value" :ref="'filter_ele_' + i" remote :remote-method="(domain) => loadSites({ domain })" :multiple="element.multiple || true" @change="onSiteChange" collapse-tags filterable>
                <el-option v-for="(site, i) in sites" :key="i" :value="site.id" :label="getSiteLabel(element, site)" />
              </el-select>
            </template>
            <template v-else-if="element.type === 'client'">
              {{ element.label }}
              <el-select size="mini" v-model="element.value" :ref="'filter_ele_' + i" :multiple="element.multiple || false" @change="emitFilterJson" collapse-tags filterable>
                <el-option v-for="(client, i) in clients" :key="i" :value="client.id" :label="client.name" />
              </el-select>
            </template>
            <template v-else-if="element.type === 'project'">
              {{ element.label }}
              <el-select size="mini" @focus="loadProjects" v-model="element.value" remote :ref="'filter_ele_' + i" :remote-method="loadProjects" :multiple="element.multiple || false" @change="onProjectChange" collapse-tags filterable>
                <el-option v-for="(project, i) in getEligibleProjects(element)" :key="i" :value="project.id" :label="project.name" />
              </el-select>
            </template>
            <template v-else-if="element.type === 'site-target'">
              {{ element.label }}
              <el-select size="mini" v-model="element.value" :ref="'filter_ele_' + i" :multiple="element.multiple || false" @change="emitFilterJson" collapse-tags filterable>
                <el-option v-for="(st, i) in getSiteTargetOptions" :key="i" :value="st.id" :label="st.name" />
              </el-select>
            </template>
            <template v-else-if="element.type === 'number'">
              {{ element.label }}
              <el-input placeholder="..." size="mini" type="number" :ref="'filter_ele_' + i" v-model="element.value[1]" class="numbers-input" @input="emitFilterJson">
                <el-select v-model="element.value[0]" slot="prepend" placeholder="Select" size="mini"  @change="emitFilterJson">
                  <el-option v-for="(opt, i) in getNumberOptions(element)" :key="i" :label="opt.label || opt" :value="opt.value || opt" />
                </el-select>
              </el-input>
            </template>
            <template v-else>
              {{ element.label }} <el-input :disabled="!ready" :ref="'filter_ele_' + i" size="mini" placeholder="..." @input="setRenderJsonTimeout" v-model="element.value" />
            </template>
          </el-tag>
        </div>
        <div class="flex w-full flex-1" style="min-width: 400px" v-show="getOptions.length"><el-input v-model="search" size="medium" @focus="onFocus" @blur="onBlur" :placeholder="'filter ' + itemType" /></div>
      </div>
      <div class="ml-2">
        <el-button type="primary" size="medium" @click="createShareLink">Share link</el-button>
      </div>
    </div>
    <div class="dropdown absolute left-0 -bottom-full z-10 bg-white w-full shadow border border-gray-400 border-t-0 rounded-bl rounded-br max-h-32   overflow-y-auto" v-show="show_dropdown">
      <ul>
        <li class="py-2 px-4 text-blue-800 text-sm hover:bg-gray-200 cursor-pointer" v-for="(option, i) in getOptions" :key="i" @click="addElement(option)">
          {{ option.label }}
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
  import { copyToClipboard } from '@/utils/helpers';
  export default {
    props: {
      filters: {
        type: Array
      },
      itemType: {
        type: String,
        default: 'items'
      },
      defaultFilters: {
        type: Object,
        default: () => {}
      },
      ready: {
        type: Boolean,
        default: false
      },
      storeFilterState: {
        type: Boolean,
        default: true
      },
      storeFilterStateName: {
        type: String,
        default: null
      },
      getTableData: {
        type: Function,
        default: null
      }
    },
    data() {
      return {
        search: '',
        show_dropdown: false,
        options: this.filters.filter(f => typeof f.validate === 'function' ? (f.validate() ? f : false) : f),
        selectedElements: [],
        users: [],
        campaigns: [],
        sites: [],
        clients: [],
        projects: [],
        selected_projects: [],
        selected_sites: [],
        site_targets: [],
        selected_site_targets: [],
        user_groups: [],
        renderJsonTimeout: null,
        added_exclude_options: false,
        push_filters_to_url: false,
        billable_projects: undefined
      }
    },
    async mounted() {
      if (this.options.find(i => i.type === 'user')) {
        this.loadUsers()
      }
      if (this.options.find(i => i.type === 'site')) {
        //this.loadSites()
      }
      if (this.options.find(i => i.type === 'client')) {
        this.loadClients()
      }

      const project_type_filter = this.options.find(i => i.type === 'project')
      if (project_type_filter && typeof project_type_filter.billable === 'boolean') {
        this.billable_projects = project_type_filter.billable
        // this.loadProjects()
      }

      if (this.options.find(i => i.type === 'user-group')) {
        this.loadUserGroups()
      }

      this.addExcludeOptions();

      if(typeof this.defaultFilters === "object" && Object.keys(this.defaultFilters).length > 0) {
        await this.setDefaultFilters(this.defaultFilters)
      } /* else if (this.storeFilterStateName && this.$store.state.global['table_state_' + this.storeFilterStateName]) {
        this.setDefaultFilters(this.$store.state.global['table_state_' + this.storeFilterStateName] || {})
      }*/
      if(typeof this.getTableData === 'function') {
        this.getTableData()
      }
      this.push_filters_to_url = true
    },
    computed: {
      getSiteTargetOptions() {
        if(this.selected_sites.length) {
          const site_ids = this.selected_sites.map(s => s.id)
          return this.site_targets.filter(st => site_ids.includes(st.site_id))
        }
        return this.site_targets
      },
      getOptions() {
        return this.options.filter(opt => {
          const existingCount = this.selectedElements.filter(i => i.label === opt.label)
          if(existingCount.length >= (opt.max || 1)) {
            return false
          }
          else if (this.search && opt.label.toLowerCase().indexOf(this.search.toLowerCase()) < 0) {
            return false
          }
          return true
        })
          .sort((a, b) => {
          let aLabel = a.label.toLowerCase();
          let bLabel = b.label.toLowerCase();

          if(aLabel.length > bLabel.length) {
            aLabel = aLabel.substring(0, bLabel.length)
          } else if (bLabel.length > aLabel.length) {
            bLabel = bLabel.substring(0, aLabel.length)
          }

          if(aLabel > bLabel) {
            return 1;
          } else if(bLabel < aLabel) {
            return 0;
          }
          return -1;
        })
      }
    },
    methods: {
      _typeLoaders () {
        return {
          'site-target': (opt = {}, callback = () => {}) => {
            this.loading = true
            this.$http.get(`/v1/companies/${this.$store.state.company.company.id}/targets`, {
              params: {
                all: true,
                client_id: opt.client_id || null,
                site_id: opt.site_id || null
              }
            })
              .then(res=> {
                this.site_targets = res.data.data
              })
            .finally(() => {
              this.loading = false
              callback()
            })
          }
        }
      },
      addExcludeOptions() {
        if(!this.added_exclude_options) {
          for (const option of this.options) {
            if (!option.disableExclude && !option.excludeItem) {
              const newItem = Object.assign({}, option)
              newItem.label += ' (exclude)';
              newItem.excludeItem = true
              newItem.name = (option.name || option.label.toLowerCase()) + '_exclude'
              this.options.push(newItem)
            }
          }
          this.added_exclude_options = false;
        }
      },
      setRenderJsonTimeout() {
        if(this.renderJsonTimeout) {
          clearTimeout(this.renderJsonTimeout)
          this.renderJsonTimeout = null
        }
        this.renderJsonTimeout = setTimeout(this.emitFilterJson, 900)
      },
      addElement (element) {
        const action = () => {
          const newElement = Object.assign({}, element)
          if(element.type === 'number') {
            const firstOption = element.options ? (element.options[0].value ? element.options[0].value : element.options[0] ) : '=';
            newElement.value = [firstOption, null]
          }

          this.selectedElements.push(newElement)
          setTimeout(() => {
            const key = 'filter_ele_' + (this.selectedElements.length - 1)
            if(this.$refs[key]) {
              const i = Array.isArray(this.$refs[key]) ? this.$refs[key][0] : this.$refs[key]
              if(typeof i.focus === 'function') {
                i.focus()
              }
            }
          }, 200)
        }

        if(this._typeLoaders()[element.type] && !element.loaded) {
          this._typeLoaders()[element.type](element, () => {
            element.loaded = true
            action()
          })
        } else {
          action()
        }
      },
      async setDefaultFilters(filters) {
        let changed = false
        for(const filterName in filters) {
          const opt = this.options.find(o => (o.label || '').toLowerCase() === filterName.toLowerCase() || (o.name || '').toLowerCase() === filterName.toLowerCase())
          const existing = this.selectedElements.find(o => (o.label || '').toLowerCase() === filterName.toLowerCase() || (o.name || '').toLowerCase() === filterName.toLowerCase())
          if (opt && !existing) {
            await this.checkToLoadValues(opt, filters[filterName])
            const newOption = Object.assign({}, opt)
            newOption.value = filters[filterName]
            this.selectedElements.push(newOption)
            changed++
          }
        }
        if(changed) {
          this.emitFilterJson()
        }
      },
      async checkToLoadValues(opt, value) {
        if(opt.type === 'project' && value && value.length) await this.getInitialProjects(value)
        if(opt.type === 'site' && value && value.length) await this.loadSites({ ids: value, set_as_selected: true })
        if(opt.type === 'site-target' && value && value.length) await this._typeLoaders()['site-target']()
      },
      removeElement(key) {
        const element = this.selectedElements[key]
        //console.log('The element', element)
        // if (
        //   element.value !== undefined &&
        //   (
        //     (element.type !== 'number' && Array.isArray(element.value) && element.length) ||
        //     (element.type === 'number' && element.value[1] !== null && element.value[1] !== "" && (element.value[1] + '').length) ||
        //     (element.type !== 'number' && (element.value + '').length > 0)
        //   )
        // ) {
        //   this.selectedElements.splice(key, 1)
        //   this.emitFilterJson()
        // } else {
        //   this.selectedElements.splice(key, 1)
        //   this.emitFilterJson()
        // }
        this.selectedElements.splice(key, 1)
        this.emitFilterJson()
      },
      onFocus() {
        this.show_dropdown = true
      },
      onBlur() {
        setTimeout(() => {
          this.show_dropdown = false
          this.search = ''
        }, 200)
      },
      loadUsers() {
        this.loading = true;
        this.$store.dispatch('users/loadAll', {})
          .then(r => {
            //console.log('We are here for the users', r)
            this.users = r
          })
          .catch(() => {
            this.$message.error('Failed to get company users')
          })
      },
      async loadSites({ domain, ids, set_as_selected } = {}) {
        this.loading = true;
        this.$http.get(`/v1/companies/${this.$store.state.company.company.id}/sites`, {
          params: {
            rpp: 10,
            name,
            domain,
            ids
          }
        })
          .then(r => {
            if(set_as_selected) {
              this.selected_sites = [...r.data.data]
              this.sites = [...this.selected_sites]
            } else {
              this.sites = [...this.selected_sites, ...r.data.data].filter((v, k, i) => i.findIndex(b => b.id === v.id) === k)
            }
          })
          .catch(() => {
            this.$message.error('Failed to get company sites')
          })
      },
      loadClients() {
        this.loading = true;
        this.$http.get(`/v1/companies/${this.$store.state.company.company.id}/clients`, {
          params: {
            all: true
          }
        })
          .then(r => {
            this.clients = r.data.data
          })
          .catch(() => {
            this.$message.error('Failed to get company clients')
          })
      },
      async getInitialProjects(ids) {
        console.log('Hello', this.billable_projects)
        const projects = await this.$http.get(`/v1/companies/${this.$store.state.company.company.id}/projects`, {
          params: { ids, billable: this.billable_projects }
        })

        if(projects.data.data.length) {
          this.selected_projects = [...projects.data.data]
          this.projects = [...projects.data.data]
        }
      },
      getEligibleProjects(filter) {
        // if(filter && filter.billable_only) return this.projects.filter(p => p.billable)
        return this.projects;
      },
      loadProjects(term) {
        term = typeof term === 'string' ? term : ''
        this.loading = true;
        this.$http.get(`/v1/companies/${this.$store.state.company.company.id}/projects`, {
          params: {
            // page: 1,
            // rpp: 30,
            name: term || undefined,
            billable: this.billable_projects
          }
        })
          .then(r => {
            this.projects = [...this.selected_projects, ...r.data.data].filter((v, k, i) => i.findIndex(b => b.id === v.id) === k)
            for(const p of this.projects) {
              for(const siteTargets of p.site_targets) {
                if(siteTargets.site_target && siteTargets.site_target.id) {
                  if (!this.site_targets.find(st => st.id === siteTargets.site_target.id)) {
                    this.site_targets.push(siteTargets.site_target);
                  }
                }
              }
            }
          })
          .catch((e) => {
            console.error(e)
            this.$message.error('Failed to get company projects')
          })
      },
      loadUserGroups() {
        this.loading = true;
        this.$http.get(`/v1/companies/${this.$store.state.company.company.id}/user-groups`, {
          params: {
            all: true
          }
        })
          .then(r => {
            this.user_groups = r.data.data
          })
          .catch((e) => {
            console.error(e)
            this.$message.error('Failed to get user groups')
          })
      },
      getSiteLabel(element, site) {
        if(element.include_site !== false && site.client) {
          return `${site.domain} (${site.client.name})`
        }
        return site.domain
      },
      createShareLink() {
        const queryParams = Object.assign({}, this.$route.query);
        let baseUrl = process.env.VUE_APP_URL + this.$route.path;
        if(queryParams.filters) {
          delete queryParams.filters;
        }

        const filters = this.renderFilterJson(true);
        if(Object.keys(filters).length) {
          queryParams.filters = JSON.stringify(filters);
        }

        if(Object.keys(queryParams).length) {
          baseUrl += '?' + (new URLSearchParams(queryParams)).toString();
        }

        copyToClipboard(baseUrl);

        this.$message.success('The URL has been copied to your clipboard');

        //console.log(this.$route);
      },
      renderFilterJson(useLabel = false) {
        const search_params = {}
        this.selectedElements.forEach(element => {
          // only render elements with a value and that value is not null / empty
          if(
            (element.type === 'number' && element.value[1] !== null && (element.value[1] + '').length > 0) ||
            (element.type !== 'number' && element.hasOwnProperty('value'))
          ) {
            const key = useLabel ? (element.label || element.name) : ( element.name || element.label.toLowerCase() )
            // if we already have a value set, its a multiple data type
            if(search_params[key] && element.type === 'number') {
              //check if we already converted to an array
              if(!Array.isArray(search_params[key][0])) {
                search_params[key] = [search_params[key]]
              }
              search_params[key].push(element.value)
            } else {
              search_params[key] = element.value
            }
          }
        })
        return search_params;
      },
      onSiteChange (items) {
        // remove any not in the selection
        this.selected_sites = this.selected_sites.filter(i => items.includes(i.id))
        this.selected_sites.push(...this.sites.filter(i => items.includes(i.id) && !this.selected_sites.includes(i.id)))
        this.emitFilterJson()
      },
      onProjectChange (items) {
        // remove any not in the selection
        this.selected_projects = this.selected_projects.filter(i => items.includes(i.id))
        this.selected_projects.push(...this.projects.filter(i => items.includes(i.id) && !this.selected_projects.includes(i.id)))
        this.emitFilterJson()
      },
      emitFilterJson() {
        this.storeTableFilterState()
        //console.log('the fit', this.renderFilterJson())
        this.$emit('input', this.renderFilterJson())
      },
      storeTableFilterState() {
        if(!this.push_filters_to_url) {
          return;
        }
        const params = this.$route.query ? Object.assign({}, this.$route.query) : {}
        const push = {
          path: this.$route.path
        }

        delete params.filters

        const filters = this.renderFilterJson(true);
        if(Object.keys(filters).length) {
          params.filters = JSON.stringify(filters);
        }

        if(Object.keys(filters).length) {
          push.query = params
        }

        //console.log(push)

        try {
          this.$router.replace({
            query: params
          })
        } catch (e) {}
        /*if(this.storeFilterState && this.storeFilterStateName) {
          this.$store.dispatch('global/saveTableFilters', {
            name: this.storeFilterStateName,
            value: this.renderFilterJson()
          })
        }*/
      },
      getNumberOptions(element) {
        if (element.options) {
          return element.options
        }
        return ['=', '<', '>', '<=', '=>']
      }
    },
    watch: {
      value: function(val) {
        this.options = val
      },
      filters: function(val) {
        this.options = val
      }
    }
  }
</script>
<style lang="scss">
  .trieste-table-filters {
    .el-input {
      .el-input__inner {
        border: none;
      }
    }

    .el-tag {
      .el-input {
        width: 200px;
      }
      &.el-tag--plain {
        height: auto;
      }
    }
    .el-date-editor--daterange {
      margin: .5em 0em .5em 1em
    }

    .numbers-input {
      margin: .5em 0em .5em 1em;

      .el-select .el-input {
        width: 60px;
      }

      .el-input-group__prepend {
        background-color: #fff;
      }
      input[type=number] {
        width: 200px;
        border: 1px solid #DCDFE6;
      }
    }
  }
</style>
