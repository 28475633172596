<template>
  <div class="min-h-screen bg-gray-200 flex flex-col justify-center py-12 sm:px-6 lg:px-8" v-loading="running">
    <div class="sm:mx-auto sm:w-full sm:max-w-md">
      <img
        class="mx-auto h-12 w-auto"
        src="/imgs/logo.png"
        alt="Workflow"
      >
    </div>

    <div class="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
      <div class="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
        <form
          action="#"
          method="POST"
        >
          <div class="text-sm text-gray-600 mb-4">
            Please enter a new password for your account.
          </div>
          <div class="">
            <label
              class="block text-sm font-medium leading-5 text-gray-700"
            >
              Password
            </label>
            <div class="mt-1 rounded-md shadow-sm">
              <el-input
                v-model="password"
                type="password"
                autocomplete="off"
              />
            </div>
          </div>
          <div class="mt-6">
            <label
              class="block text-sm font-medium leading-5 text-gray-700"
            >
              Confirm password
            </label>
            <div class="mt-1 rounded-md shadow-sm">
              <el-input
                v-model="password_confirm"
                type="password"
                autocomplete="off"
              />
            </div>
          </div>

          <div class="mt-6">
            <span class="block w-full rounded-md shadow-sm">
              <el-button
                class="w-full"
                type="primary"
                @click="submit"
              >
                Reset password
              </el-button>
            </span>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'ForgottenPassword',
  data () {
    return {
      password: '',
      password_confirm: '',
      running: false
    }
  },
    beforeRouteEnter(to, from, next) {
        next((vm) => {
            vm.$store.commit('auth/reset');
        });
    },
  methods: {
    submit() {
      const testPassword = this.password.replace(/\s/g, '');
      if(testPassword < 8) {
        return this.$message.error('Please enter a password length of at least 8 characters');
      }
      /*if(!(/[A-Z]/.test(testPassword))) {
        return this.$message.error('Please enter at least one capital.')
      }
      if(!(/[0-9]/.test(testPassword))) {
        return this.$message.error('Please enter at least one number.')
      }
      if(!(/\W/.test(testPassword))) {
        return this.$message.error('Please enter at least one special character.')
      }*/
      if(this.password !== this.password_confirm) {
        return this.$message.error('Password and password confirm do not match.');
      }

      // awesome, lets try and change the password.
      this.$http.post('/v1/auth/reset-password', {
        password: this.password,
        token: this.$route.params.token
      }).then(() => {
        this.$message.success('Your password reset has been successful.')
        this.$router.push('/login')
      })
      .catch(e => {
        let message = 'An error occurred resetting your password';
        if(e.response && e.response.data && e.response.data.error) {
          message += `: ${e.response.data.error}`
        }
        this.$message.error(message)
      })
    }
  }
}
</script>

<style scoped>

</style>
