<template>
  <div>
    <div class="page-title">
      <h1>
        Admin
      </h1>
    </div>
  <div class="columns">
    <div class="column w-1/5 xl:w-1/6">
      <div class="bg-white rounded shadow overflow-hidden">
      <ul>
        <li
          class="xxl:text-base text-sm md:text-xs text-gray-700 hover:bg-gray-200 cursor-pointer"
          :class="{'admin-current-item': current_resource.name === resource.name}"
          v-for="(resource, i) in viewableResource"
          :key="i"
        >
          <a :href="`/admin/${resource.name}/index`" class="block py-2 px-2">{{ resource.label }}</a>
        </li>
      </ul>
      </div>
    </div>
    <div class="column w-4/5 xl:w-5/6" v-if="current_resource">
      <router-view :resource="current_resource" :resources="resources" :endpoint="base_url" v-if="loaded" />
    </div>
  </div>
  </div>
</template>
<script>
  import config from './../../components/admin/config'
  export default {
    mounted() {
      this.loadResources()
      this.setCurrentResource()
      this.loaded = true;
    },
    data() {
      return {
        loaded: false,
        resources: [],
        current_resource: null,
        resource_keys: [],
        base_url: process.env.VUE_APP_TRIESTE_API + '/v1/admin'
      }
    },
    computed: {
      viewableResource() {
        return this.resources.filter(i => i.showOnMenu !== false)
      }
    },
    methods: {
      setCurrentResource() {
        const resourceKey = this.$route.params.resource;
        const foundResource = this.resources.find(i => i.name === resourceKey);
        if(!resourceKey || !foundResource) {
          const first = this.resources[0].name
          window.location = '/admin/' + first + '/index';
        }
        this.current_resource = foundResource;
      },
      loadResources() {
        if(config && Array.isArray(config.resources)) {
          this.resource_keys = config.resources.map(i => i.name);
          for(const resource of config.resources) {
            this.resources.push({
              name: resource.name,
              showOnMenu: !(resource.options.showOnMenu === false),
              label: resource.options.name || resource.name,
              single_name: resource.options.single_name || null,
              resource: resource.name,
              fields: resource.options.fields,
              relations: this.getResourceRelationships(resource.options),
              includes: this.getResourceIncludes(resource.options)
            })
          }
        }
        this.loaded = true;
      },
      getResourceLabel(options) {
        if(options.resource_name) {
          return options.resource_name
        }
        return options.name.toLowerCase().replace(/\s/g, '_');
      },
      getResourceRelationships(options) {
        const models = [];
        if(options.fields) {
          for (const field of options.fields) {
            if(field.model) {
              const r = config.resources.find(i => i.name === field.model)
              if(r) {
                if (!models.includes(r.name)) {
                  models.push(r.name);
                }
              }
            }
          }
        }
        return models;
      },
      getResourceIncludes(options) {
        const includes = [];
        if(options.includes) {
          for (const include of options.includes) {
            if(!include.model || !config.resources.find(i => i.name === include.model)) {
              continue;
            }
            if(include.through && !config.resources.find(i => i.name === include.through)) {
              continue;
            }
            if(!['manyThrough', 'many', 'one'].includes(include.type)) {
              continue;
            }
            includes.push(include)
          }
        }
        return includes;
      }
    }
  }
</script>
<style lang="scss">
  .admin-current-item {
    @apply bg-blue-800 text-white font-bold;
    &:hover {
      @apply bg-blue-800 text-white font-bold;
    }
  }
</style>
