<template>
  <div v-loading="loading">
    <div class="page-title">
      <h1>
        <logo
          v-model="$store.state.company.company.url"
          class="mr-2"
          :size="30"
        />
        <span v-if="got_account">{{ `${account.firstname} ${account.lastname} (${account.email})` }}</span>
      </h1>
    </div>
    <el-tabs class="custom-tabs-v1" v-model="activeTabName" @tab-click="tabsChanged" ref="tabs" >
      <el-tab-pane label="Email Accounts" v-if="$store.getters['auth/hasPermission']('user.read')" name="accounts">
         <span slot="label">
           <a href="?tab=accounts" @click.prevent>Accounts</a>
         </span>
        <email-accounts v-model="account" v-if="got_account" />
      </el-tab-pane>
      <el-tab-pane label="All emails" name="all">
         <span slot="label">
           <a href="?tab=all" @click.prevent>All</a>
         </span>
        <email-list type="all" is-true="true" :account-id="$store.state.emailAccount.account.id" :end-point="getBasePath()" user-type
        custom-filter-store-name="user-inbox-all"
        />
      </el-tab-pane>
      <el-tab-pane label="Incoming emails" name="incoming">
         <span slot="label">
           <a href="?tab=incoming" @click.prevent>Incoming</a>
         </span>
        <email-list type="incoming" :account-id="$store.state.emailAccount.account.id" :end-point="getBasePath()" user-type
        custom-filter-store-name="user-inbox-incoming"
        />
      </el-tab-pane>
      <el-tab-pane label="Sent emails" name="sent">
       <span slot="label">
         <a href="?tab=sent" @click.prevent>Sent</a>
       </span>
        <email-list type="sent" :account-id="$store.state.emailAccount.account.id" :end-point="getBasePath()" user-type
        custom-filter-store-name="user-inbox-sent"
        />
      </el-tab-pane>
    </el-tabs>
  </div>
</template>
<script>
  import EmailList from "@/components/views/emails/EmailList";
  import EmailAccounts from "./Tabs/EmailAccounts";
  import tabPosition from '@/components/mixins/tab_position';
  export default {
    name: 'ViewCompany',
    components: { EmailAccounts, EmailList },
    mixins: [tabPosition],
    data() {
      return {
        account: null,
        loading: true,
        got_account: false,
        activeTabName: this.$store.getters['auth/hasPermission']('user.read') ? 'accounts' : 'all'
      }
    },
    mounted() {
      this.loading = true
      if(!this.$store.getters['auth/hasPermission']('emailaccount.read') && !this.$store.getters['auth/hasPermission']('user.read')) {
        this.$message.error('You do not have permission to access this page.')
        return this.$router.push('/')
      }

      // this.$store.dispatch('company/getCompany', this.$route.params).then((company) => {
        if(this.$store.getters['auth/hasPermission']('user.read')) {
          this.getAccount()
        } else {
          this.loading = false
        }
      // })
    },
    beforeRouteEnter(to, from, next) {
      next(vm => {
        vm.$store.commit('global/setBreadcrumbs', [
          {
            path: '/',
            label: 'Home'
          },
          {
            path: `/users`,
            label: 'Users'
          }
        ])
      })
    },
    methods: {
      getAccount() {
        this.$http.get(`/v1/companies/${this.$store.state.company.company.id}/users/${this.$route.params.userId}`)
          .then(r => {
            this.account = r.data.data.user;
            this.got_account = true;
          })
          .catch(() => {
            this.$message.error('Failed to load the company user')
          })
          .finally(() => {
            this.loading = false;
          })
      },
      getBasePath() {
        return `/v1/companies/${this.$store.state.company.company.id}/users/${this.$route.params.userId}/emails`
      }
    }
  }
</script>
