<template>
  <div v-loading="loading">
    <div class="mb-8">
      <h1 class="text-2xl text-gray-900 flex items-center">
        <span>Your account</span>
      </h1>
    </div>
    <div class="columns">
      <div class="column w-2/3">
        <trieste-card title="Your details">
          <div class="p-6 pb-2">
            <el-form label-width="150px" label-position="left" :model="user" :rules="detail_rules" ref="detailsForm">
              <el-form-item label="Firstname" prop="firstname">
                <el-input v-model="user.firstname" />
              </el-form-item>
              <el-form-item label="Lastname" prop="lastname">
                <el-input v-model="user.lastname" />
              </el-form-item>
              <el-form-item label="Email" prop="email">
                <el-input v-model="user.email" />
              </el-form-item>
              <el-form-item label="Cost per minute">
                <el-input v-model="user.cost_per_minute" readonly />
              </el-form-item>
            </el-form>
          </div>
          <template v-slot:footer>
            <div class="flex justify-end flex-row">
              <el-button size="small" type="success" icon="el-icon-edit-outline" @click="updateDetails">Update</el-button>
            </div>
          </template>
        </trieste-card>
        <trieste-card title="Change password">
          <div class="p-6 pb-2">
            <el-form label-width="150px" label-position="left">
              <el-form-item label="Password">
                <el-input type=password v-model="password.new" />
              </el-form-item>
              <el-form-item label="Password confirm">
                <el-input type=password v-model="password.confirm" />
              </el-form-item>
            </el-form>
          </div>
          <template v-slot:footer>
            <div class="flex justify-end flex-row">
              <el-button size="small" type="success" @click="updatePassword" icon="el-icon-edit-outline">Update</el-button>
            </div>
          </template>
        </trieste-card>
      </div>
      <div class="column w-1/3">
        <trieste-card title="Your avatar">
          <div class="p-4">
            <el-upload
              class="avatar-uploader w-full"
              action=""
              ref="upload"
              :on-change="changeAvatar"
              :auto-upload="false"
              :limit="1"
              :show-file-list="false">
              <div class="w-full flex justify-center">
              <div class="w-32 h-32 bg-gray-200 flex justify-center items-center rounded-full" :class="{'shadow-lg': user.image}">
                <img v-if="user.image" :src="user.image" class="w-32 h-32 rounded-full">
                <p v-else class="text-xs text-gray-500">No avatar</p>
              </div>
              </div>
            </el-upload>
          </div>
          <template v-slot:footer>
            <div class="flex flex-row">
              <el-button size="small" type="danger" v-if="has_image" @click="updateAvatar(true)" icon="el-icon-delete">
                Delete
              </el-button>
              <div class="ml-auto">
                <el-button
                size="small"
                type="success"
                :disabled="!avatar_changed"
                icon="el-icon-edit-outline"
                @click="updateAvatar">Update</el-button>
              </div>
            </div>
          </template>
        </trieste-card>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'UserProfile',
  data: function() {
    return {
      loading: false,
      user: {},
      password: {
        new: '',
        confirm: ''
      },
      detail_rules: {
        firstname: [
          {required: true, min: 2, message: 'Please enter a valid first name'}
        ],
        lastname: [
          {required: true, min: 2, message: 'Please enter a valid last name'}
        ],
        email: [
          {required: true, type: 'email', message: 'Please enter a valid email'}
        ]
      },
      avatar_changed: false,
      has_image: false,
    }
  },
  mounted() {
    this.loadUser()
  },
  methods: {
    loadUser() {
      this.loading = true;
      this.$store.dispatch('auth/getUserDetails').then(() => {
        this.user = Object.assign({}, this.$store.state.auth.user);
        this.has_image = !!this.user.image;
      })
      .catch(e => {
        this.$message.error('Failed to load the user profile.');
      })
      .finally(() => {
        this.loading = false;
      })
    },
    updateUser(payload, type) {
      this.loading = true;
      this.$http.put('/v1/user', payload)
      .then(() => {
        this.$message.success('Successfully updated your ' + type);
        this.loadUser();
      })
      .catch(() => {
        this.$message.error('Failed to update your ' + type);
      })
      .finally(() => {
        this.loading = false;
      })
    },
    updateDetails() {
      this.$refs.detailsForm.validate(valid => {
        if(valid) {
          this.updateUser({
            firstname: this.user.firstname,
            lastname: this.user.lastname,
            email: this.user.email
          }, 'details');
        }
      })
    },
    updatePassword() {
      const testPassword = this.password.new.replace(/\s/g, '');
      if(testPassword < 8) {
        return this.$message.error('Please enter a password length of at least 8 characters');
      }
      if(!(/[A-Z]/.test(testPassword))) {
        return this.$message.error('Please enter at least one capital.')
      }
      if(!(/[0-9]/.test(testPassword))) {
        return this.$message.error('Please enter at least one number.')
      }
      if(!(/\W/.test(testPassword))) {
        return this.$message.error('Please enter at least one special character.')
      }
      if(this.password.new !== this.password.confirm) {
        return this.$message.error('Password and password confirm do not match.');
      }

      this.updateUser({password: this.password.new}, 'password');
    },
    updateAvatar(isDelete) {
      if(this.avatar_changed || isDelete === true) {
        let image = this.user.image;
        if(isDelete === true) {
          image = null;
          this.avatar_changed = false;
        }
        this.updateUser({ image }, 'avatar');
      } else {
        this.$message.error('Please change your avatar to update it.')
      }
    },
    changeAvatar(file) {
      //console.log(file);
      let error = false;

      if(!['image/jpg','image/png','image/jpeg'].includes(file.raw.type)) {
        this.$message.error('Please select an image that is either a JPG or a PNG.');
        error = true;
      }

      if((file.size / 1024 / 1024) > 1) {
        this.$message.error('Please select an image that is less than 1MB in size.');
      }

      if(!error) {
        const fileReader = new FileReader();
        fileReader.readAsDataURL(file.raw);
        const vm = this;
        fileReader.onload = function() {
          vm.user.image = fileReader.result;
          vm.avatar_changed = true;
        }
      }

      this.$refs.upload.uploadFiles = [];
    }
  }
}
</script>
