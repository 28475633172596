<template>
  <div v-loading="loading">
    <trieste-table
      :end-point="`/v1/companies/${$store.state.company.company.id}/dashboard/linkdev/email-accounts`"
      :params="{ date_start: dateStart, date_end: dateEnd, user_id: userId }" :fields="fields"
      :title="title || 'Email accounts'" :show-sub-title="false"
      ref="table" collapsable first-field-total
    >
      <template v-slot:domain="props">
        <a :href="`/company/${$store.state.company.company.id}/sites/${props.row.id}`">{{props.row.domain}}</a>
      </template>
      <template v-slot:orphan-slot="props">
        <a :href="`/company/${$store.state.company.company.id}/email-accounts/${props.row.id}?tab=orphans`" v-if="props.row.id">
          {{props.row.total_orphan_count.toLocaleString()}}({{props.row.new_orphan_count.toLocaleString()}} new)
        </a>
        <span v-else>{{props.row.total_orphan_count.toLocaleString()}}({{props.row.new_orphan_count.toLocaleString()}} new)</span>
      </template>
      <template v-slot:received_emails="props">
        <a :href="generateNavigationUrl(props.row.id, 'incoming')" v-if="props.row.id">
          {{props.row.received_emails.toLocaleString()}}
        </a>
        <a :href="generateNavigationUrl(props.row.id, 'incoming', true)" v-if="props.row.received_emails_new">
          ({{props.row.received_emails_new.toLocaleString()}} new)
        </a>
      </template>
      <template v-slot:actions-slot="props">
        <a :href="generateNavigationUrl(props.row.id)" v-if="props.$index !== 0">
        <el-button size="small" icon="el-icon-view"
                    type="primary">
          View
        </el-button>
        </a>
        <el-tooltip effect="dark" content="Currently testing the account"
                    placement="left-start" :disabled="!['TO_TEST', 'TESTING'].includes(props.row.tested)" v-if="props.$index !== 0 && false">
        <div class="inline-block ml-2">
          <el-button size="small" icon="el-icon-view"
                     :disabled="['TO_TEST', 'TESTING'].includes(props.row.tested)"
                     :loading="['TO_TEST', 'TESTING'].includes(props.row.tested)"
                     @click="test(props.row.id)" type="primary">
            Test
          </el-button>
        </div>
        </el-tooltip>
      </template>
    </trieste-table>
  </div>
</template>
<script>
  export default {
    props: {
      title: {
        type: String,
        default: null
      },
      userId: {
        type: [String, Number],
        default: null
      },
      dateStart: {
        type: [String, Date]
      },
      dateEnd: {
        type: [String, Date]
      }
    },
    data() {
      return {
        loading: false,
        fields: [
          {
            title: 'Account',
            name: 'email',
          },
          {
            title: 'Orphan',
            name: 'orphan-slot'
          },
          {
            title: 'Emails sent',
            name: 'sent_emails',
            numberField: true
          },
          {
            title: 'Emails received',
            name: 'received_emails',
            numberField: true
          },
          {
            name: 'actions-slot',
            width: '200px'
          }
        ],
      }
    },
    methods: {
      test(ids) {
        if(ids) {
          ids = Array.isArray(ids) ? ids : [ids]
        }
        this.loading = true
        this.$http.post(`/v1/companies/${this.$store.state.company.company.id}/email-accounts/test`, {
          ids
        }).then(() => {
          this.$refs.table.getData()
        })
        .catch(() => {
          this.$message.error('Failed to test the email account(s)')
        })
        .finally(() => {
          this.loading = false
        })
      },
      generateNavigationUrl(accountId, tab = 'all', unread_only = false) {
        const filters = {}
        if(this.dateStart && this.dateEnd) filters.Date = [this.dateStart, this.dateEnd]
        if(this.userId) filters.User = [this.userId]
        if(unread_only) filters.Read = false;

        if(!Object.keys(filters).length) {
          return `/company/${this.$store.state.company.company.id}/email-accounts/${accountId}`
        }

        return `/company/${this.$store.state.company.company.id}/email-accounts/${accountId}/?tab=${tab}&filters=${JSON.stringify(filters)}`

        // if(!this.userId) {
        //   return `/company/${this.$store.state.company.company.id}/email-accounts/${accountId}`
        // }
        // return `/company/${this.$store.state.company.company.id}/email-accounts/${accountId}/?tab=all&filters={"User":[${this.userId}]}`
        // return `/company/${this.$store.state.company.company.id}/users/${this.userId}?tab=all`
      }
    }
  }
</script>
