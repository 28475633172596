import api from './../../utils/api';
export default {
  namespaced: true,
  state: {
    loading_batch: false,
    batch: {},
    bulk_mailing: {
      active: false,
      defaultEmailAccount: null,
      defaultTemplate: null,
      defaultLinkType: null
    },
    automatically_go_to_next_link: false,
    show_contact_form_on_accept: false,
  },
  mutations: {
    update: (state, payload) => state[payload[0]] = payload[1]
  },
  actions: {
    resetBulkEmailing({ commit }) {
      commit('update', [
        'bulk_mailing',
        {
          active: false,
          defaultEmailAccount: null,
          defaultTemplate: null,
          defaultLinkType: null
        }
      ])
    },
    startBulkEmailing({ commit }, { defaultEmailAccount, defaultTemplate, defaultLinkType }) {
      commit('update', [
        'bulk_mailing',
        {
          active: true,
          defaultEmailAccount,
          defaultTemplate,
          defaultLinkType
        }
      ])
    },
    setBulkStatus({state}, payload) {
      state.bulk_mailing.active = payload
    },
    getBatch({commit, state, rootState, dispatch}, {companyId, campaignId, batchId}) {
      commit('update', ['loading_batch', true]);
      return new Promise((resolve, reject) => {
        companyId = companyId || rootState.company.company.id;
        campaignId = campaignId || rootState.campaign.campaign.id;
        api.get(`/v1/companies/${companyId}/batches/${batchId}`).then(r => {
          if(r.data.data.id !== state.batch.id) {
            dispatch('resetBulkEmailing')
          }
          commit('update', ['batch', r.data.data]);
          commit('update', ['loading_batch', false]);
          resolve(Object.assign({}, r.data.data))
        }).catch(e => {
          reject(e)
        })
      })
    },
  }
}
