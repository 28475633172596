<template>
  <div class="-mx-8 -my-6 h-full">
    <create-edit />
  </div>
</template>
<script>
  import CreateEdit from "@/components/views/Project/CreateEdit";
  export default {
    components: { CreateEdit },
    mounted() {
      this.$store.dispatch('company/getCompany', this.$route.params).then(company => {
        this.$store.dispatch('client/getClient', this.$route.params).then((client) => {
          this.$store.commit('global/setBreadcrumbs', [
            {
              path: '/',
              label: company.name
            },
            {
              path: `/company/${company.id}/clients`,
              label: 'Clients'
            },
            {
              path: `/company/${company.id}/clients/${client.id}`,
              label: client.name
            },
            {
              path: `/company/${company.id}/clients/${client.id}?tab=projects`,
              label: 'Projects'
            },
            {
              label: 'Create'
            }
          ])
        })
      })
    }
  }
</script>
