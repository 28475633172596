<template>
  <div v-loading="loading" class="h-full">
    <div class="page-title">
      <h1>
        All company notes
      </h1>
    </div>
    <trieste-table
      :end-point="`/v1/companies/${$store.state.company.company.id}/notes`"
      :fields="fields"
      title="Notes"
      item-type="notes"
      :show-title="false"
      ref="table"
      @on-load="loading = false"
      :filters="filters"
      saved-table-sort-name="note-index"
    >
      <template v-slot:title-slot="props">
        <div v-if="getNoteLink(props.row)" class="font-bold">
          <a :href="getNoteLink(props.row)">{{ getNiceAlertType(props.row.resource_type) }}: {{ getNiceResourceName(props.row) }}</a>
        </div>
      </template>
      <template v-slot:body-slot="props">
        {{ props.row.body }}
      </template>
    </trieste-table>
  </div>
</template>
<script>
  const note_type_options =  { LinkLead: 'lead', SiteLink: 'link', ContentItem: 'content', LinkLeadsBatch: 'batch', EmailAccount: 'email account' }
  export default {
    data: function() {
      return {
        fields: [
          {
            name: 'title-slot',
            title: 'Reference',
            width: '300px'
          },
          {
            name: 'body-slot',
            title: 'Note'
          },
          {
            name: 'owner',
            title: 'Owner',
            formatter: v => v ? `${v.firstname} ${v.lastname}` : 'N/A'
          },
          {
            name: 'assignee',
            title: 'Assignee',
            formatter: v => v ? `${v.firstname} ${v.lastname}` : 'N/A'
          },
          {
            name: 'created_at',
            title: 'Date',
            dateField: true
          },
          {
            name: 'alert_date',
            title: 'Assigned for',
            dateField: true
          }
        ],
        filters: [
          {
            name: 'owner_id',
            type: 'user',
            label: 'User',
            multiple: true
          },
          {
            name: 'assigned_user_id',
            type: 'user',
            label: 'Assignee',
            multiple: true
          },
          {
            name: 'note_type',
            label: 'Type',
            type: 'select',
            options: ['Note', 'Task']
          },
          {
            name: 'complete',
            label: 'Status',
            type: 'select',
            options: ['incomplete', 'complete']
          },
          {
            name: 'created_at',
            label: 'Created at',
            type: 'date',
            dateType: 'daterange'
          },
          {
            name: 'assigned_at',
            label: 'Assigned for',
            type: 'date',
            dateType: 'daterange'
          },
          {
            name: 'body',
            label: 'Search'
          }
        ]
      }
    },
    beforeRouteEnter(to, from, next) {
      next(vm => {
        vm.$store.commit('global/setBreadcrumbs', [
          {
            path: '/',
            label: 'Home'
          },
          {
            label: 'Notes'
          }
        ])
      })
    },
    methods: {
      getNoteLink(note) {
        if(['LinkLead', 'SiteLink'].includes(note.resource_type) && note.link_item) {
          return `/company/${this.$store.state.company.company.id}/${note.link_item.lead_status === 'accepted' ? 'leads': 'links'}/${note.link_item.id}`;
        }
        if(note.resource_type === 'LinkLeadsBatch' && note.batch_item) {
          return `/company/${this.$store.state.company.company.id}/batch/${note.batch_item.id}`;
        }
        if(note.resource_type === 'EmailAccount' && note.email_account) {
          return `/company/${this.$store.state.company.company.id}/email-accounts/${note.email_account.id}`;
        }
        if(note.resource_type === 'ContentItem') {
          return `/company/${this.$store.state.company.company.id}/content/${note.resource_id}`;
        }
        return '/';
      },
      getNiceAlertType(name) {
        if(note_type_options[name]) {
          return note_type_options[name];
        }
        return name;
      },
      getNiceResourceName(alert) {
        if(['LinkLead', 'SiteLink'].includes(alert.resource_type)) {
          if(alert.link_item) return alert.link_item.id;
        }
        if(alert.resource_type === 'ContentItem') {
          if(alert.content_item) return alert.content_item.id;
        }
        if(alert.resource_type === 'LinkLeadsBatch') {
          if(alert.batch_item) return alert.batch_item.id;
        }
        if(alert.resource_type === 'EmailAccount') {
          if(alert.email_account) return alert.email_account.email || 'N/A';
        }
        return 'N/A';
      },
    }
  }
</script>
