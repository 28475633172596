<template>
  <div v-loading="loading">
    <trieste-table
      :end-point="`/v1/companies/${this.$store.state.company.company.id}/sites`"
      :fields="fields"
      :is-loading.sync="loading"
      title="Sites"
      ref="table"
      default-sort-prop="domain"
      :filters="filters"
      :show-title="false"
      :params="{ client_id: $route.params.clientId }"
      saved-table-sort-name="client-sites"
    >
      <template v-slot:header-right>
        <el-button icon="el-icon-edit-outline" size="medium" type="success" @click="showCreateSite = true" v-if="$store.getters['auth/hasPermission']('site.create')">Create</el-button>
      </template>
      <template v-slot:actions-slot="props">
        <a :href="`/company/${$route.params.companyId}/sites/${props.row.id}`" v-if="$store.getters['auth/hasPermission']('site.read')">
        <el-button
          size="small"
          icon="el-icon-view"
          type="primary">
          View
        </el-button>
        </a>
      </template>
    </trieste-table>
    <create-edit :show.sync="showCreateSite" @updated="updateTable" v-if="$store.getters['auth/hasPermission']('site.create')" />
  </div>
</template>
<script>
  import CreateEdit from "../../../../components/views/Sites/CreateEdit";
  export default {
    components: {CreateEdit},
    data: function () {
      return {
        showCreateSite: false,
        query: {
          rpp: 20,
          page: 1
        },
        canView: true,
        loading: false,
        fields: [
          {
            name: 'domain',
            sortField: 'domain',
            title: 'domain',
            titleClass: 'text-left',
            dataClass: 'text-left',
            width: '200px',
            fixed: true
          },
          {
            name: 'status',
            title: 'status',
            sortField: 'status',
            titleClass: 'text-left',
            dataClass: 'text-left',
          },
          {
            name: 'campaigns',
            sortField: 'campaigns',
            title: 'campaigns',
          },
          {
            name: 'batches',
            sortField: 'batches',
            title: 'batches',
          },
          {
            title: 'Created',
            name: 'created_at',
            dateField: true,
            sortField: 'created_at',
          },
          {
            title: '',
            name: 'actions-slot',
            sortField: 'created_at',
            width: '130px',
            fixed: 'right'
          },
        ],
        filters: [
          {
            label: 'domain'
          }
        ]
      }
    },
    methods: {
      updateTable() {
        this.$refs.table.getData()
      }
    }
  }
</script>
