<template>
  <div ref="top">
    <div class="mb-4" v-if="sendingEmailsIsDisabled">
      <el-alert
        :title="!email_accounts.length ? 'No outgoing email accounts configured' : 'No contact emails found.'"
        type="warning"
        :description="'We were unable to find at least one '+(!email_accounts.length ? 'email account' : 'email contact')+' to use for communications.'"
        :closable="false"
        show-icon />
    </div>
    <trieste-card :title="email.in_reply_to ? 'Reply' : 'Send New Email'" v-loading="sending_email" :collapsable="true" :collapsed="collapsed">
      <div class="p-4">
        <el-form
          ref="emailForm"
          :model="email"
          :rules="email.contact_form ? rules.email_contact_form : rules.email"
          label-width="120px"
        >
          <el-form-item label="Contact form?" v-if="contact_url">
            <el-switch v-model="email.contact_form" @change="clearValidation" />
            <iframe :src="'https://sfo.redirect.systems/?url=' + contact_url" v-if="email.contact_form" width="100%" height="500px" />
          </el-form-item>
          <el-form-item
            label="To"
            prop="to"
            required
            size="small"
            v-if="!email.contact_form"
            @change="emailContentKeyUp"
          >
            <el-select
              v-model="email.to"
              size="small"
              class="w-1/3"
              v-if="!email.contact_form"
              @change="emailContentKeyUp"
              filterable
            >
              <el-option
                v-for="(account, i) in contacts"
                :key="'emails_accounts_' + i"
                :value="account.email"
                :label="account.email"
              />
            </el-select>
          </el-form-item>
          <el-form-item
            label="CC"
            prop="cc"
            size="small"
            v-if="!email.contact_form"
          >
            <el-select
              v-model="email.cc"
              size="small"
              multiple
              class="w-1/3"
              :disabled="sendingEmailsIsDisabled"
              @change="emailContentKeyUp"
              v-if="!email.contact_form"
              filterable
            >
              <el-option
                v-for="(account, i) in contacts"
                :key="'emails_accounts_' + i"
                :value="account.email"
                :label="account.email"
              />
            </el-select>
          </el-form-item>
          <el-form-item
            label="From"
            prop="from"
            required
          >
            <el-select
              v-model="email.from"
              size="small"
              class="w-1/3"
              filterable
              :disabled="!canChangeFromField"
              @change="emailContentKeyUp"
            >
              <el-option
                v-for="(account, i) in email_accounts"
                :key="'emails_accounts_' + i"
                :value="account.id"
                :label="getFromLabel(account)"
              />
            </el-select>
            <div v-if="showChangeCheckbox">
              <div class="mt-2">
                <el-checkbox v-model="allow_change_email">
                <span class="text-xs">
                <span class="text-red-600">{{ defaultFrom }}
                  was the last email account used for this site link.
                  {{ default_email_not_found ? 'This account is not attached to your account or is suspended.' : '' }}
                </span>
                  <br />
                  <span class="font-bold">Tick here to change.</span>
                </span>
                </el-checkbox>
              </div>
            </div>
          </el-form-item>
          <el-form-item
            label="Subject"
            prop="subject"
          >
            <el-input
              v-model="email.subject"
              size="small"
              class="w-1/3"
              :disabled="sendingEmailsIsDisabled"
              @input="emailContentKeyUp"
            />
          </el-form-item>
          <el-form-item>
            <div class="columns">
              <div class="column ml-auto">
                <div class="el--form-item">
                  <div class="flex">
                    <div class="mr-2">
                      <span class="text-xs text-gray-600">Template ({{ getAllowedTemplateList.length || 0 }})</span>
                    </div>
                    <div class="mr-2">
                      <el-select
                        v-model="email.template"
                        size="small"
                        :disabled="sendingEmailsIsDisabled"
                        filterable
                      >
                        <el-option
                          v-for="(template, i) in getAllowedTemplateList"
                          :key="'email_template_' + i"
                          :value="template.id"
                          :label="template.name"
                        />
                      </el-select>
                    </div>
                    <div v-if="email.template">
                      <el-button-group>
                        <el-button size="small" :disabled="!email.template || sendingEmailsIsDisabled" @click="addEmailTemplate">
                          Add
                        </el-button>
                        <el-button size="small" :disabled="!email.template || sendingEmailsIsDisabled" @click="prependEmailTemplate">
                          Prepend
                        </el-button>
                        <el-button size="small" :disabled="!email.template || sendingEmailsIsDisabled" @click="appendEmailTemplate">
                          Append
                        </el-button>
                      </el-button-group>
                    </div>
                  </div>
                </div>
              </div>
              <div class="column w-full">
                <div class="flex">
                  <div class="mr-2 ml-auto">
                    <span class="text-xs text-gray-600">Placeholders</span>
                  </div>
                  <div class="mr-2">
                    <el-select
                      v-model="placeholder"
                      size="small"
                      :disabled="sendingEmailsIsDisabled"
                    >
                      <el-option
                        v-for="(placeholder, i) in placeholders"
                        :key="'placeholders_' + i"
                        :label="placeholder.label"
                        :value="placeholder.code"
                      />
                    </el-select>
                  </div>
                  <div>
                    <el-button size="small" :disabled="!placeholder || sendingEmailsIsDisabled" @click="insertPlaceholder" >
                      Insert
                    </el-button>
                  </div>
                </div>
              </div>
              <div class="column w-full">
                <div class="flex">
                  <div class="mr-2 ml-auto">
                    <span class="text-xs text-gray-600">Live preview</span>
                  </div>
                  <div class="mr-2">
                    <el-switch v-model="show_live_preview" @change="emailContentKeyUp"  />
                  </div>
                </div>
              </div>
            </div>
          </el-form-item>
          <el-form-item
            label="Body"
            prop="content"
            required
          >
            <div class="columns">
              <div class="column w-full">
                <div :class="{'el-textarea is-disabled': sendingEmailsIsDisabled}">
                <textarea
                  v-model="email.content"
                  ref="EmailBodyInput"
                  rows="10"
                  :disabled="sendingEmailsIsDisabled"
                  @input="emailContentKeyUp"
                  style="min-height: 200px"
                  class="w-full border border-gray-400 rounded p-4 py-2 hover:outline-none el-textarea__inner"

                ></textarea>
                </div>
              </div>
            </div>
          </el-form-item>
          <el-form-item label="Attachments">
            <div class="columns">
              <div class="column w-full -mb-6" v-show="email.fileList.length > 0">
                <div>
                  <div v-for="(upload, i) in email.fileList" :key="i" class="mb-4">
                    <div class="border rounded p-2 text-xs flex flex-row justify-between leading-snug items-center">
                      <div>
                      <span class="font-medium">{{ upload.name || shortenText(100) }}</span>
                      <span class="text-xs text-gray-600 block">{{ formatBytes(upload.size) }}</span>
                      </div>
                      <div>
                      <el-button
                        icon="el-icon-delete"
                        size="mini" type="danger" circle @click="() => {removeFileFromList(i)}" />
                      </div>
                    </div>
                  </div>
                </div>
                <div class="rounded border border-gray-300 overflow-hidden" v-show="false">
                  <el-table :data="email.fileList">
                    <el-table-column label="name" prop="name" />
                    <el-table-column label="file size" prop="size">
                      <template slot-scope="scope">
                        {{ formatBytes(scope.row.size) }}
                      </template>
                    </el-table-column>
                    <el-table-column label="remove">
                      <template slot-scope="scope">
                        <el-button size="small" type="danger" @click="() => {removeFileFromList(scope.$index)}">remove</el-button>
                      </template>
                    </el-table-column>
                  </el-table>
                </div>
              </div>
              <div class="column w-full">
                <el-upload
                  class="upload-demo"
                  ref="upload"
                  action="''"
                  :auto-upload="false"
                  :on-change="uploadFilesChanged"
                  :show-file-list="false"
                  multiple
                  :before-upload="beforeFileUpload"
                  :limit="5"
                >
                  <el-button slot="trigger" size="small" type="primary" :disabled="sendingEmailsIsDisabled">Click to add File</el-button>
                </el-upload>
              </div>
            </div>
          </el-form-item>
          <el-form-item v-if="email.from && emailAccountFailed && !sendingEmailsIsDisabled">
            <template v-slot:label><div class="text-red-600 font-bold">Warning</div></template>
            <div class="text-red-600">
              The email account selected has failed and is unable to be used for mailing. Please resolve the issue or select another account.
            </div>
          </el-form-item>
          <el-form-item v-if="invalidFromAddress">
            <template v-slot:label><div class="text-red-600 font-bold">Warning</div></template>
            <div class="text-red-600">
              The selected email account is not the last to be used to corresponded with. To continue, please tick the checkbox above.
            </div>
          </el-form-item>
          <el-form-item
            label="Live subject"
            v-if="show_live_preview"
          >
            <div class="columns" style="padding-top: 10px;">
              <div class="column w-full" v-loading="loading_live_preview">
                <div v-html="live_preview_subject || ''" class="leading-snug text-sm"></div>
              </div>
            </div>
          </el-form-item>
          <el-form-item
            label="Live preview"
            v-if="show_live_preview"
          >
            <div class="columns" style="padding-top: 10px;">
              <div class="column w-full" v-loading="loading_live_preview">
                <div v-html="live_preview_content" class="leading-snug text-sm live_preview_content"></div>
              </div>
            </div>
          </el-form-item>
        </el-form>
      </div>
      <template v-slot:footer>
        <div class="flex">
          <div class="ml-auto">
            <el-button
              type="text"
              @click="()=>{ $refs['emailForm'].resetFields(); email.in_reply_to = null; email.fileList = []; }"
            >
              Reset
            </el-button>
            <el-button
              size="small"
              icon="el-icon-view"
              type="primary"
              @click="previewEmail"
              :disabled="sendingEmailsIsDisabled"
              v-if="email.to && email.from && email.subject && email.content"
            >
              Preview
            </el-button>
            <el-button
              size="small"
              icon="el-icon-s-promotion"
              type="primary"
              @click="sendEmail"
              :disabled="disableSend"
            >
              Send
            </el-button>
          </div>
        </div>
      </template>
    </trieste-card>
    <trieste-dialog :show.sync="email_preview.show" :title="email_preview.subject">
      <div class="p-4">
        <div v-html="email_preview.content" class="break-normal"></div>
      </div>
      <template v-slot:footer-right>
        <el-button size="small" icon="el-icon-close" type="danger" @click="email_preview.show = false">Close</el-button>
      </template>
    </trieste-dialog>
  </div>
</template>
<script>
  import emailPlaceholders from '@/json/email_placeholders.json';
  const moment = require('moment')
  export default {
    props: {
      contacts: {
        type: Array,
        default: () => []
      },
      value: {
        type: Object,
        default: () => {}
      },
      collapsed: {
        type: Boolean,
        default: false
      },
      nextEmailLink: {
        type: Object,
        default: null
      },
      defaultTo: {
        type: [String, Number],
        default: null
      },
      defaultFrom: {
        type: [String, Number],
        default: null
      },
      lastEmail: {
        type: Object,
        default: null
      }
    },
    data() {
      const validateEmptyTags = (value, rule, callback) => {
        if(this.email.content.length && this.email.content.match(/<<>>/g)) {
          return callback(new Error('There are one or more blank dynamic tags (<<>>).'))
        }

        // check that we have the right placeholder text
        for (const match of [...this.email.content.matchAll(/<<<(.+?)>>>/g)]) {
          if(!this.placeholders.find(p => p.code === match[0])) {
            return callback(new Error(`There is no dynamic tag for ${match[1]} (${match[0]}).`))
          }
        }
        return callback();
      };
      return {
        email_accounts: [],
        email_templates: [],
        contact_url: this.value.contact_form_url,
        sending_email: false,
        send_email_data_loaded: false,
        send_email_disabled: false,
        file_limit_in_kb: 700,
        placeholders: emailPlaceholders,
        placeholder: null,
        email_preview: {
          subject: null,
          content: null,
          show: false
        },
        show_live_preview: true,
        live_preview_content: '',
        live_preview_subject: '',
        email_preview_timer: null,
        loading_live_preview: false,
        default_email_not_found: false,
        email: {
          content: '',
          to: null,
          from: null,
          cc: [],
          subject: null,
          body: null,
          template: null,
          fileList: [],
          in_reply_to: null,
          contact_form: false
        },
        rules: {
          email: {
            subject: [
              {required: true, message: 'Please enter a email subject', trigger: 'blur'},
              {required: true, message: 'Please enter a email subject', trigger: 'focus'},
            ],
            from: [
              {required: true, message: 'Please select a sender', trigger: 'blur'},
              {required: true, message: 'Please select a sender', trigger: 'focus'},
              {required: true, message: 'Please select a sender', trigger: 'change'}
            ],
            to: [
              {
                required: true,
                type: 'email',
                message: 'Please select at least one recipient',
                trigger: 'blur'
              },
              {
                required: true,
                type: 'email',
                message: 'Please select at least one recipient',
                trigger: 'focus'
              },
              {
                required: true,
                type: 'email',
                message: 'Please select at least one recipient',
                trigger: 'change'
              }
            ],
            content: [
              {required: true, message: 'Please enter email content', trigger: 'blur'},
              {required: true, message: 'Please enter email content', trigger: 'focus'},
              {validator: validateEmptyTags, trigger: 'submit'},
            ]
          },
          email_contact_form: {
            content: [
              {required: true, message: 'Please enter email content', trigger: 'blur'},
              {required: true, message: 'Please enter email content', trigger: 'focus'}
            ]
          },
        },
        plainCursorPosition: null,
        allow_change_email: false
      }
    },
    mounted() {
      this.loadResources();
    },
    computed: {
      showChangeCheckbox () {
        return this.email_accounts.length > 1 && this.defaultFrom
      },
      canChangeFromField () {
        return !this.sendingEmailsIsDisabled && (!this.defaultFrom || (this.defaultFrom && this.allow_change_email)) && !this.send_email_disabled
      },
      sendingEmailsIsDisabled () {
        return (!this.contacts.filter(c => c.email).length || !this.email_accounts.length) && !this.email.contact_form
      },
      emailAccountFailed () {
        return this.email.from && this.email_accounts.find(i => i.id === this.email.from)?.status === 'failed'
      },
      getReplyToEmail() {
        return this.emails.find(e => e.message_id === this.email.in_reply_to)
      },
      getAllowedTemplateList() {
        if(this.email.from) {
          const account = this.email_accounts.find(c => c.id === this.email.from)
          if(account && account.allowed_templates && account.allowed_templates.length) {
            return this.email_templates.filter(template => account.allowed_templates.includes(template.id));
          }
          // return false;
        }
        return this.email_templates
      },
      invalidFromAddress () {
        const fromAccount = this.email_accounts.find(e => typeof this.defaultTo === "number" ? this.defaultFrom === e.id : this.defaultFrom === e.email)
        if(!fromAccount) return false
        return !this.allow_change_email && this.defaultFrom && this.email.from && fromAccount.id !== this.email.from
      },
      disableSend () {
        return this.sendingEmailsIsDisabled || this.emailAccountFailed || this.invalidFromAddress
      }
    },
    methods: {
      setReplyEmail(settings) {
        let from_id = settings.email_account_id || settings.from || null;
        let to_id = settings.to || null;
        if(from_id && !this.email_accounts.find(e => e.id === from_id)) from_id = null;
        if(to_id && !this.contacts.find(e => e.id === to_id)) to_id = null;
        if(settings.is_failed) this.email.id = settings.id || null;
        this.email.to = settings.to || null;
        this.email.from = from_id
        this.email.subject = settings.subject || null;
        this.email.in_reply_to = settings.in_reply_to || null;
        this.email.cc = settings.cc || [];
        this.email.content = '';
        this.addPreviousEmailContent(settings);
        this.emailContentKeyUp();
      },
      setTo(to, only_if_null = false) {
        if(!only_if_null || !this.email.to) this.email.to = to
        if(this.email.content.length) {
          this.emailContentKeyUp()
        }
      },
      bindPlainCursorPosition() {
        setTimeout(() => {
          ['keyup', 'click'].forEach(ev => {
            this.$refs.EmailBodyInput.addEventListener(ev, () => {
              this.plainCursorPosition = this.$refs.EmailBodyInput.selectionEnd || this.$refs.EmailBodyInput.selectionStart || 0
            })
          })
        }, 200)
      },
      addPreviousEmailContent(email) {
        const emailLines = email.content.split(/<br\s?\/?>/g)
        let content = emailLines.map(line => (email.is_failed ? '' : '> ') + line.replace(/(<([^>]+)>)/gi, "")).join('\n');
        if(email.is_failed) {
          this.email.content += (this.email.content.length ? '\n' : '') + content;
          return;
        }

        const date = moment.utc(email.date).format('MMMM D, YYYY') + ' at ' + moment.utc(email.date).format('hh:mm A +0000')

        // console.log('The email', email)

        const fromText = email.from_email || email.from

        const prepend = `On ${date}, ${fromText} wrote:\n`;

        this.email.content += (this.email.content.length ? '\n' : '') + (prepend + content);
      },
      addEmailTemplate() {
        const emailTemplate = this.email_templates.find(i => i.id === this.email.template);
        this.email.content = emailTemplate.body + '\n\n';
        if(!this.email.subject || this.email.subject.trim() === '') this.email.subject = emailTemplate.subject
        this.email.template = null;
        this.plainCursorPosition = this.email.content.length
        this.emailContentKeyUp()
      },
      appendEmailTemplate() {
        const template = this.email_templates.find(i => i.id === this.email.template)
        this.email.content = this.email.content + (this.email.content.length ? '\n\n' : '') + template.body;
        if(!this.email.subject || this.email.subject.trim() === '') this.email.subject = template.subject
        this.plainCursorPosition = this.email.content.length
        this.email.template = null;
        this.emailContentKeyUp()
      },
      prependEmailTemplate() {
        const template = this.email_templates.find(i => i.id === this.email.template)
        this.email.content = template.body + (this.email.content.length ? '\n' : '') + this.email.content;
        if(!this.email.subject || this.email.subject.trim() === '') this.email.subject = template.subject
        this.plainCursorPosition = template.body.length
        this.email.template = null;
        this.emailContentKeyUp()
      },
      loadResources() {
        this.send_email_data_loaded = false;
        // get the available email accounts to use to send.
        const emailAccountsRequest = this.$http.get('/v1/companies/' + this.$store.state.company.company.id + '/user/email-accounts', {
          params: {
            all: true,
            include_allowed_templates: true,
            site_id: this.value.site_id
          }
        });
        const emailTemplatesRequest = this.$http.get('/v1/companies/' + this.$store.state.company.company.id + '/email-templates', { params: {site_id: this.value.site_id, all: true }});

        this.$http.all([emailAccountsRequest, emailTemplatesRequest]).then(this.$http.spread((emailAccounts, emailTemplates) => {
          this.email_accounts = emailAccounts.data.data;
          if(!this.email_accounts.length) {
            this.send_email_disabled = true
          }
          this.email_templates = emailTemplates.data.data;
          this.setDefaultEmailAccounts()
          this.bindPlainCursorPosition();
        }))

      },
      uploadFilesChanged(file, filelist) {
        this.email.fileList = filelist;
        this.emailContentKeyUp();
      },
      // TODO for some reason this doesn't work, lets look at a way to fix this.
      beforeFileUpload(file) {
        const fileSizeInKb = file.size / Math.pow(1024, 1);
        //console.log('Mx file size in KB', this.file_limit_in_kb);
        //console.log('File size', fileSizeInKb);
        if(fileSizeInKb > this.file_limit_in_kb) {
          return false;
        }
        return true;
      },
      insertPlaceholder() {
        // TODO add logic to add it to the cursor position
        if(this.email.content && this.email.content.length > 1) {
          this.email.content = this.email.content.substring(0, this.plainCursorPosition) + this.placeholder + this.email.content.substring(this.plainCursorPosition);
          this.plainCursorPosition += this.placeholder.length
        } else {
          this.email.content += this.placeholder;
          this.plainCursorPosition = this.placeholder.length;
        }
        this.placeholder = null;
        this.emailContentKeyUp()
      },
      formatBytes(bytes, decimals = 2) {
        if (bytes === 0) return '0 Bytes';
        const k = 1024;
        const dm = decimals < 0 ? 0 : decimals;
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

        const i = Math.floor(Math.log(bytes) / Math.log(k));

        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
      },
      removeFileFromList(index) {
        this.email.fileList.splice(index, 1)
        this.emailContentKeyUp()
      },
      generateFormData() {
        if(!this.email.from) {
          return
        }
        const formData = new FormData();
        formData.append('content', this.email.content || '');
        formData.append('to', this.email.to || '');
        formData.append('from', this.email.from || '');
        if(this.email.cc) formData.append('cc', this.email.cc);
        if(this.email.id) formData.append('id', this.email.id);
        formData.append('subject', this.email.subject || '');
        formData.append('link_id', this.$route.params.linkId || '');
        formData.append('reply_to', this.email.in_reply_to || '');

        this.email.fileList.forEach((file, index) => {
          formData.append(`files[${index}]`, file.raw);
        });
        return formData
      },
      setDefaultEmailAccounts() {
        this.checkDefaultTo()
        this.checkDefaultFrom()

        if(this.$route.query.bulk) {
          this.setUpQueryBulk()
        } else if(this.$store.state.batch.bulk_mailing.active) {
          if(this.$store.state.batch.bulk_mailing.defaultEmailAccount) {
            const emailAccount = this.email_accounts.find(i => i.id === this.$store.state.batch.bulk_mailing.defaultEmailAccount);
            if (emailAccount) {
              this.email.from = this.$store.state.batch.bulk_mailing.defaultEmailAccount;
            }
          }

          if(this.$store.state.batch.bulk_mailing.defaultTemplate) {
            const emailTemplate = this.email_templates.find(i => i.id === this.$store.state.batch.bulk_mailing.defaultTemplate)
            if (emailTemplate) {
              this.email.template = this.$store.state.batch.bulk_mailing.defaultTemplate;
              this.email.content = emailTemplate.body;
              this.email.subject = emailTemplate.subject;
              this.emailContentKeyUp()
            }
          }
        }
      },
      checkDefaultFrom () {
        if(this.defaultFrom) {
          const fromAccount = this.email_accounts.find(e => typeof this.defaultFrom === "number" ? this.defaultFrom === e.id : this.defaultFrom === e.email)
          if(fromAccount) {
            this.email.from = fromAccount.id
          }
          this.default_email_not_found = !fromAccount
        } else {
          this.default_email_not_found = true
          //this.email.from = this.email_accounts[0].id;
        }
      },
      checkDefaultTo() {
        if(this.defaultTo && !this.email.to) {
          const toAccount = this.contacts.find(e => typeof this.defaultTo === "number" ? this.defaultTo === e.id : this.defaultTo === e.email)
          if(toAccount) {
            this.email.to = toAccount.email
          }
        }
      },
      setUpQueryBulk() {
        if(this.$route.query.bulk) {
          //determine if we can use the template
          // we need to set the template and the email account.
          const emailAccounts = this.$route.query.bulk_email_accounts ? this.$route.query.bulk_email_accounts.split(',').map(i => i / 1) : null
          const templates = this.$route.query.template_id ? this.$route.query.template_id.split(',').map(i => i / 1) : null

          const selectedEmailAccounts = emailAccounts ? this.email_accounts.filter(e => emailAccounts.includes(e.id)) : this.email_accounts
          const selectedTemplates = templates ? this.email_templates.filter(e => templates.includes(e.id)): this.email_templates

          let defaultTemplate = templates && selectedTemplates.length ? selectedTemplates[0] : null

          if(!this.email.from) {
            const accountWithTemplate = selectedEmailAccounts.find(i => {
              if(!templates) return i
              if(i.allowed_templates && i.allowed_templates.length) return false;
              const first_match = i.allowed_templates.find(t => selectedTemplates.find(st => st.id === t))
              if(first_match) {
                defaultTemplate = first_match;
                return i;
              }
            })

            if (accountWithTemplate) {
              this.email.from = accountWithTemplate.id
            } else if (selectedEmailAccounts[0]) {
              // no success here.
              this.email.from = selectedEmailAccounts[0].id
            }
          }

          if(defaultTemplate) {
            this.email.template = defaultTemplate.id;
            this.addEmailTemplate()
          }

          if (this.lastEmail) {
            this.addPreviousEmailContent(this.lastEmail);
            this.emailContentKeyUp();
          }
        }
      },
      previewEmail() {
        this.$refs.emailForm.validate((valid) => {
          if(valid) {
            this.sending_email = true;

            this.$http.post('/v1/companies/' + this.$store.state.company.company.id + '/preview-email', this.generateFormData(), {
              headers: {
                'Accept': 'application/json',
                'Content-Type': 'multipart/form-data'
              }
            }).then((response) => {
              this.email_preview.subject = response.data.subject;
              this.email_preview.content = response.data.content;
              this.email_preview.show = true;
              setTimeout(() => {
                this.$emit('preview', true);
              }, 1500)
            }).catch(e => {
              this.$message.error('Loading the email preview was unsuccessful.');
              console.error('The error', e);
              this.$emit('err', e);
            })
              .finally(() => {
                this.sending_email = false;
              })
          }
        })
      },
      clearValidation() {
        setTimeout(() => {
          this.$refs.emailForm.clearValidate();
        }, 400)
      },
      resetEmailFields () {
        this.email.id = null
        this.email.content = ''
        this.email.cc = []
        this.email.subject = null
        this.email.body = null
        this.email.template = null
        this.email.fileList = []
        this.email.in_reply_to = null
        this.email.contact_form = false
      },
      sendEmail() {
        this.$refs.emailForm.validate((valid) => {
          if(valid) {
            this.sending_email = true;
            const baseUrl = '/v1/companies/' + this.$store.state.company.company.id
            const endPoint = baseUrl + (this.email.contact_form ? '/store-contact-form' : '/send-email')
            this.$http.post(endPoint, this.generateFormData(), {
              headers: {
                'Accept': 'application/json',
                'Content-Type': 'multipart/form-data'
              }
            }).then(() => {
              this.sending_email = false;
              this.$message.success('The email has been succesfully sent.');
              this.email.contact_form = false;
              this.resetEmailFields()
              this.email.in_reply_to = null;
              this.email.fileList = [];
              this.$scrollTo(this.$refs.top);
              if(this.$route.query.bulk) {
                this.attemptBulkForward()
              }
              else if(this.$store.state.batch.bulk_mailing.active && ((this.nextEmailLink && this.nextEmailLink.id) || this.value.bulk_link_leads_batch_id)) {
                const url = this.nextEmailLink ? `/company/${this.$route.params.companyId}/leads/${this.nextEmailLink.id}` :
                  `/company/${this.$route.params.companyId}/batch/${this.value.bulk_link_leads_batch_id}`

                setTimeout(() => {
                  this.$emit('success', true);
                  window.location.href = url;
                }, 600)
              } else {
                setTimeout(() => {
                  this.$emit('success', true);
                }, 2500)
              }
            }).catch(e => {
              const failed_msg_id = e?.response?.data?.id || e?.response?.data?.error?.id
              const error = e?.response?.data?.error || 'Email was unsuccessful.'
              if(failed_msg_id) {
                this.email.id = failed_msg_id
                this.$emit('success', true);
              }
              this.$message.error(error);
              console.error('The error', e);
              this.$emit('err', e);
            })
              .finally(() => {
                this.sending_email = false;
              })
          }
        })
      },
      setContactUrl(url) {
        this.contact_url = url
      },
      generateBulkLink () {
        return `/company/${this.$route.params.companyId}/leads/${this.value.next_link.id}?` + (new URLSearchParams(this.$route.query)).toString()
      },
      attemptBulkForward() {
        if(this.value.next_link && this.$route.query.bulk) {
          window.location = this.generateBulkLink()
        } else if (this.value.bulk_link_leads_batch_id) {
         window.location = `/company/${this.$route.params.companyId}/batch/${this.value.bulk_link_leads_batch_id}`
        }
      },
      emailContentKeyUp() {
        const content = this.email.content
        if(this.email_preview_timer) {
          clearTimeout(this.email_preview_timer)
          this.email_preview_timer = null
        }
        if (!content || !content.length) {
          this.live_preview_content = ''
        } else if(this.show_live_preview) {
          this.email_preview_timer = setTimeout(this.setLivePreviewContent, 1500)
        }
      },
      setLivePreviewContent() {
        let formData = this.generateFormData()
        if(!formData) return
        this.loading_live_preview = true;
        this.$http.post('/v1/companies/' + this.$store.state.company.company.id + '/preview-email', formData, {
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'multipart/form-data'
          }
        }).then((response) => {
          this.live_preview_content = response.data.content;
          this.live_preview_subject = response.data.subject || '';
        }).catch(e => {
          this.$message.error('Loading the email preview was unsuccessful.');
          console.error('The error', e);
        })
          .finally(() => {
            this.loading_live_preview = false;
          })
      },
      getFromLabel(acc) {
        return acc.email + (acc.status === 'failed' ? ' (failed)' : '')
      }
    },
    watch: {
      defaultTo: 'checkDefaultTo',
      contacts: 'checkDefaultTo',
      defaultFrom: 'checkDefaultFrom'
    }
  }
</script>
<style lang="scss">
.el-checkbox {
  @apply flex flex-row;
  .el-checkbox__input {
    @apply mt-2;
  }
}

.live_preview_content {
  h1 {
    @apply text-2xl;
  }
  h2 {
    @apply text-xl;
  }
  h3 {
    @apply text-lg;
  }
  h4 {
    @apply text-base;
  }
  h5 {
    @apply text-sm;
  }
  h6 {
    @apply text-xs;
  }

  a {
    @apply text-blue-600 underline;
  }
}
</style>
