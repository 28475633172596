<template>
  <div>
    <div class="columns">
      <div class="column w-1/2  xxl:w-1/3">
        <span class="text-sm font-bold text-gray-700">{{ label }}</span>
      </div>
      <div class="column w-1/2 ml-auto xxl:w-2/3">
        <div class="text-sm font-light">
          <slot>
            <span
              :class="{'text-green-600': success, 'text-red-600': error && !success, 'font-medium': success || error}"
            >
              <span v-if="value && number">{{ value.toLocaleString() }}</span>
              <span v-else-if="value && date">{{ value | momentLocal("DD MMMM YYYY HH:mm:ss A Z")  }}</span>
              <span v-else-if="typeof value === 'boolean'">{{ value ? 'Yes' : 'No' }}</span>
              <span v-else-if="value && raw" v-html="value"></span>
              <span v-else-if="value">{{ value }}</span>
              <span class="text-gray-400" v-else>N/A</span>
            </span>
          </slot>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    label: {
      type: String,
      default: ''
    },
    value: {
      type: [String, Number, Boolean],
      default: ''
    },
    number: {
      type: Boolean,
      default: false
    },
    date: {
      type: Boolean,
      default: false
    },
    raw: {
      type: Boolean,
      default: false
    },
    error: {
      type: Boolean,
      default: false
    },
    success: {
      type: Boolean,
      default: false
    }
  }
}
</script>
