<template>
  <div>
    <trieste-table
      :end-point="`/v1/companies/${$store.state.company.company.id}/dashboard/content-writer/content`"
      :params="{ date_start: dateStart, date_end: dateEnd, user_id: userId, include_suspended: includeSuspendedSites  }" :fields="fields"
      :title="title || 'Content'" :show-sub-title="false" collapsable first-field-total
    >
      <template v-slot:domain="props">
        <span v-if="props.$index === 0" class="font-bold">Total</span>
        <a :href="`/company/${$store.state.company.company.id}/sites/${props.row.id}`" v-else>{{props.row.domain}}</a>
      </template>
      <template v-slot:client="props">
        <span v-if="props.$index === 0" class="font-bold">Total</span>
        <a :href="`/company/${$store.state.company.company.id}/clients/${props.row.client.id}`" v-else>{{props.row.domain}}</a>
      </template>
      <template v-slot:new-slot="props">
        <a :href='buildContentLink(props.row.id, ["new", "assigned"])'>
          {{ props.row.new_count }}
        </a>
      </template>
      <template v-slot:on-hold-slot="props">
        <a :href='buildContentLink(props.row.id, "on_hold")'>
          {{ props.row.on_hold_count }}
        </a>
      </template>
      <template v-slot:cancelled-slot="props">
        <a :href='buildContentLink(props.row.id, "cancelled")'>
          {{ props.row.cancelled_count }}
        </a>
      </template>
      <template v-slot:draft-slot="props">
        <a :href='buildContentLink(props.row.id, "draft")'>
          {{ props.row.draft_count }}
        </a>
      </template>
      <template v-slot:returned-slot="props">
        <a :href='buildContentLink(props.row.id, "returned")'>
          {{ props.row.returned_count }}
        </a>
      </template>
      <template v-slot:editor-review-slot="props">
        <a :href='buildContentLink(props.row.id, "editor_review")'>
          {{ props.row.editor_review_count }}
        </a>
      </template>
      <template v-slot:revision-slot="props">
        <a :href='buildContentLink(props.row.id, "revision")'>
          {{ props.row.revision_count }}
        </a>
      </template>
      <template v-slot:approved-slot="props">
        <a :href='buildContentLink(props.row.id, "published")'>
          {{ props.row.approved_count }}
        </a>
      </template>
    </trieste-table>
  </div>
</template>
<script>
  export default {
    props: {
      dateStart: {
        type: [String, Date]
      },
      dateEnd: {
        type: [String, Date]
      },
      title: {
        type: String,
        default: null
      },
      userId: {
        type: [String, Number],
        default: null
      },
      includeSuspendedSites: {
        type: Boolean,
        default: false
      }
    },
    data() {
      return {
        fields: [
          {
            title: 'domain',
            name: 'domain',
            sortField: 'domain',
          },
          {
            title: 'Client',
            name: 'client',
            sortField: 'client.name',
            formatter: (val) => val ? val.name : ''
          },
          {
            title: 'New/Assigned',
            name: 'new-slot'
          },
          {
            title: 'On hold',
            name: 'on-hold-slot'
          },
          {
            title: 'Cancelled',
            name: 'cancelled-slot'
          },
          {
            title: 'Draft',
            name: 'draft-slot'
          },
          {
            title: 'Returned',
            name: 'returned-slot'
          },
          {
            title: 'Editor review',
            name: 'editor-review-slot'
          },
          /*{
            title: 'Revision',
            name: 'revision-slot'
          },*/
          {
            title: 'Approved',
            name: 'approved-slot'
          },
        ]
      }
    },
    methods: {
      buildContentLink(siteId, status) {
        const baseLink = `/company/${this.$store.state.company.company.id}/content`;
        const filters = {}
        filters['Created at'] = [this.dateStart, this.dateEnd]
        if(this.userId) {
          filters['Owner'] = [this.userId]
        }

        if(siteId) {
          filters['Site'] = [siteId]
        }

        filters['status'] = Array.isArray(status) ? status : [status]

        return baseLink + '?filters=' + JSON.stringify(filters)
      }
    }
  }
</script>
