<template>
  <div>
    <trieste-card title="Payment Instructions" :collapsable="true" :collapsed="collapsed">
      <div class="p-4">
        <el-form
          ref="paymentForm"
          :model="link"
          label-width="140px"
          label-position="left"
          :rules="rules"
        >
          <el-form-item
            label="Additional Instructions"
            prop="payment_additional_instructions"
          >
            <el-input
              v-model="link.payment_additional_instructions"
            />
          </el-form-item>
        </el-form>
      </div>
      <template v-slot:footer>
        <div class="text-right">
          <el-button type="success" icon="el-icon-edit-outline" @click="updateNote" size="small">
            {{ !isNew ? 'Update' : 'Create' }} Payment note
          </el-button>
        </div>
      </template>
    </trieste-card>
  </div>
</template>
<script>
  export default {
    props: {
      value: {
        type: Object,
        default: () => {}
      },
      collapsed: {
        type: Boolean,
        default: false
      }
    },
    data() {
      return {
        link: this.value,
        isNew: !!!this.value.payment_additional_instructions,
        rules: {},
      }
    },
    computed: {
      showManualOrUpdateOnlyFields() {
        return (!this.value.payment_status && this.manual_payment) || this.value.payment_status
      },
    },
    methods: {
      updateNote() {
        this.$refs.paymentForm.validate(valid => {
          if(valid) {
            this.$emit('input', this.link);
            this.$emit('update');
            this.$emit('on-update');
          }
        })
      }
    },
    watch: {
      value(val) {
        this.link = val;
      }
    }
  }
</script>
