<template>
  <div v-loading="$store.state.client.loading_all_clients">
    <el-select v-model="val" @change="onSelect" clearable="clearable" filterable>
      <el-option v-for="(client, i) in clientList" :key="i" :value="client.id" :label="client.name">
        {{ client.name }}
      </el-option>
    </el-select>
  </div>
</template>
<script>
export default {
  props: {
    value: {
      type: [Number, String],
      default: null
    },
    clearable: {
      type: Boolean,
      default: true
    },
    billable: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      val: this.value
    }
  },
  mounted() {
    this.getClients();
  },
  computed: {
    clientList() {
      if(this.billable) {
        return this.$store.state.client.clients.filter(client => client.billable)
      }
      return this.$store.state.client.clients
    }
  },
  methods: {
    getClients() {
      this.$store.dispatch('client/loadAllClients')
    },
    onSelect() {
      this.$emit('input', this.val)
      this.$emit('change', this.val)
    }
  },
  watch: {
    value(val) {
      this.val = val
    },
  }

}
</script>
