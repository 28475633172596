<template>
  <div v-loading="loading" class="h-full" ref="top">
    <div class="columns">
      <div class="column w-2/6  xxl:w-1/6">
        <div class="p-4 pr-0 -mr-2 mt-12">
          <ul class="itemList">
            <li v-for="(comp, i) in components" :key="i" :class="{
            'active': visibleIndex === i,
            'visited': visibleIndex >= i || ($route.params.projectId &&  project.saved_index >= i)
            }" class="cursor-pointer" @click="changeIndex(i)">
              <i :class="{
              'el-icon-check': visibleIndex !== i,
              'el-icon-arrow-right': visibleIndex === i,
              }" class="icon"></i>
              {{ comp.label }}
            </li>
            <li v-if="this.id && $store.getters['auth/hasPermission']('project.create')"><a href="#" class="text-red-600 underline" @click.prevent="deleteProject"><i></i>Delete Project</a></li>
          </ul>
        </div>
      </div>
      <div class="column w-4/6  xxl:w-5/6 bg-white relative">
        <div class="absolute top-0 left-0 bg-white w-full h-screen"></div>
        <div class="max-w-screen-md relative">
          <cloner v-if="mode === 'create' && visibleIndex === 0" v-model="project" />
          <component
            v-for="(comp, i) in components" :is="comp.component"
            v-model="project" :key="i"
            v-show="i === visibleIndex"
            ref="projectComponents"
            :disabled="!comp.enabled"
            :month-allocations.sync="months_allocations"
            :allowVariation="project.link_price_type === 'VARIABLE'"
          />
          <div class="text-right px-4 -mt-3">
            <el-button size="medium" type="primary" @click="processForm" v-if="showNextButton && !isLastPage">
              Next
            </el-button>
            <el-button size="medium" type="primary" @click="processForm(true)" v-if="canEditProject">
              Save
            </el-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import Price from "@/components/views/Project/Price";
  import LinkAllocation from "@/components/views/Project/LinkAllocation";
  import SitesAndTargets from "@/components/views/Project/SitesAndTargets";
  import ContentInformation from "@/components/views/Project/ContentInformation";
  import TargetInformation from "@/components/views/Project/TargetInformation";
  import Cloner from "@/components/views/Project/Cloner";
  const moment = require('moment')
  import TargetCreateWidget from '@/components/views/SiteTargets/Create';
  import SiteCreateWidget from '@/components/views/Sites/CreateEdit';
  import ProjectDetails from './Details';
  export default {
    components: { Price, TargetCreateWidget, SiteCreateWidget, ProjectDetails, Cloner },
    metaInfo() {
      return {
        title: this.tabTitle,
        titleTemplate: '%s | Projects'
      }
    },
    props: {
      id: {
        type: [String, Number],
        default: null
      },
      showTitle: {
        type: Boolean,
        default: true
      },
      value: {
        type: Object,
      }
    },
    data() {
      let canEdit = false;
      if(
        !this.id && this.$store.getters['auth/hasPermission']('project.create') ||
        this.id && this.$store.getters['auth/hasPermission']('project.update')
      ) {
        canEdit = true;
      }

      let canModifyTargetsAndAllocations = canEdit || (this.id && this.$store.getters['auth/hasPermission']('project.allocation.update'));

      return {
        loading: false,
        visibleIndex: 0,
        components: [
          {
            label: 'Details',
            component: ProjectDetails,
            enabled: canEdit
          },
          {
            label: 'price',
            component: Price,
            enabled: canEdit
          },
          {
            label: 'Link allocation',
            component: LinkAllocation,
            enabled: canModifyTargetsAndAllocations
          },
          {
            label: 'Sites allocation',
            component: SitesAndTargets,
            enabled: canModifyTargetsAndAllocations
          },
          {
            label: 'Target information',
            component: TargetInformation,
            enabled: canEdit
          },
          {
            label: 'Content Information',
            component: ContentInformation,
            enabled: canEdit
          }
        ],
        project: {
          links_monthly_allocation: {},
          sites_and_targets: [],
          target_link_allocation_type: 'FIXED',
          campaign_type: 'paid_link_development',
          end_date: null,
          start_date: null,
          sites: [],
          base_link_price: null,
          price_variables: [],
          billable: true
        },
        company_users: [],
        mode: this.id === null ? 'create' : 'edit',
        months_allocations: [],
        site_targets: [],
        sites: [],
        selected_site: null,
        selected_site_target: null,
        showTargetCreate: false,
        showCreateSite: false,
        first_load: true
      }
    },
    computed: {
      showNextButton() {
        if(this.mode !== 'create' && !this.$store.getters['auth/hasPermission']('project.update') && !this.$store.getters['auth/hasPermission']('project.allocation.update')) {
          if(this.visibleIndex === (this.components.length - 1)) {
            return false
          }
        }

        return true
      },
      isLastPage() {
        return this.visibleIndex === ( this.components.length - 1 )
      },
      canEditProject() {
        return !this.id && this.$store.getters['auth/hasPermission']('project.create') ||
          this.id && this.$store.getters['auth/hasPermission']('project.update')
      },
      tabTitle() { return  (this.$route.params.projectId ? 'Edit' : 'Create') + ' ' + (this.project.name || '') }
    },
    mounted() {
      if(!this.$store.getters['auth/hasPermission']('project.update')) {
        this.$message.error('You do not have the permissions to access this resource.')
        return this.$router.push('/')
      }
      if(!this.id) {
        // if(!this.$store.getters['auth/hasPermission']('project.create')) {
        //   this.$message.error('You do not have the permissions to create a project.')
        //   this.$router.push('/')
        // }
        this.first_load = false;
      } else {
        // if(!this.$store.getters['auth/hasPermission']('project.read')) {
        //   this.$message.error('You do not have the permissions to read a project.')
        //   this.$router.push('/')
        // }
        this.getProject();
      }
    },
    methods: {
      changeIndex(index) {
        if(
          (this.$route.params.projectId && (this.project.saved_index + 1) >= index) ||
          (index <= this.visibleIndex && index !== this.visibleIndex)
        ) {
          this.visibleIndex = index
        }
      },
      async processForm (saveIndex) {
        //console.log(saveIndex)
        // hello world.
        this.loading = true
        this.$scrollTo(this.$refs.top)
        const target = this.$refs.projectComponents[this.visibleIndex]

        if(typeof target.validate === 'function') {
          const result = await target.validate();
          if(!result) {
            this.loading = false
            return false;
          }
        }

        // okay we are proceeding.
        if (this.visibleIndex === (this.components.length - 1) || saveIndex === true) {
          // processing the form
          this.createOrUpdate(this.visibleIndex)
        } else {
          this.visibleIndex++;
          this.loading = false;
        }
      },
      getProject() {
        this.loading = true;
        this.$store.dispatch('project/getProject', this.$route.params).then((r) => {
          this.project = Object.assign({}, this.project, r);

          const monthIds = {}
          if(this.project.month_allocations) {
            this.project.links_monthly_allocation = {}
            this.project.month_allocations.forEach(m => {
              monthIds[m.id] = moment(m.month).format('MMMM YYYY')
              this.project.links_monthly_allocation = Object.assign({}, this.project.links_monthly_allocation, { [monthIds[m.id]]: m.allocation })
            })
          }

          this.project.sites = r.site_targets.map(target => {
            const monthly = (target.monthly_allocations || [])
              .map(m => ({[monthIds[m.monthly_allocation_id]]: m.allocation }))
              .reduce((a, b) => Object.assign(a, b), {}) || {}

            return {
              id: target.site.id,
              default: target.allocation,
              domain: target.site.domain,
              anchor_text: target.anchor_text,
              traffic: target.traffic,
              dr: target.dr,
              ...monthly
            }
          });
          this.first_load = false
          //this.setAllocationMonthRange();
        })
        .catch((e) => {
          this.$message.error('Failed to load the project.');
          console.error(e)
        })
        .finally(() => {
          this.loading = false;
        })
      },
      async createOrUpdate(saveIndex) {
        if(!this.$store.getters['auth/hasPermission']('project.update') && !this.$store.getters['auth/hasPermission']('project.allocation.update')) {
          this.$message.error('You do not have the neccessary permissions to update this project.')
          this.loading = false;
          return;
        }

        this.loading = true
        this.$scrollTo(this.$refs.top)
        if(await this.checkAllValidation(saveIndex)) {
          //console.log('did here')
          const cleanedProject = this.createProjectDataBeforeCreate(this.project);

          if(Number.isInteger(saveIndex)) {
            cleanedProject.save_index = saveIndex
          }

          let url = `/v1/companies/${this.$store.state.company.company.id}/projects`;
          let method = this.$http.post;

          if (this.id) {
            url += `/${this.id}`;
            method = this.$http.put;
          }

          this.loading = true;
          method(url, cleanedProject)
            .then((d) => {
              this.$message.success('Successfully '+( this.id ? 'updated' : 'created' )+' the project!');
              if (!this.id) {
                setTimeout(() => {
                  this.$router.push(`/company/${this.$store.state.company.company.id}/projects/${d.data.data.id}`)
                }, 400)
              }
            })
            .catch(e => {
              console.error(e)
              this.$message.error('An error has occurred.')
            })
            .finally(() => {
              this.loading = false;
            })
        } else {
          this.loading = false
        }
      },
      createProjectDataBeforeCreate(Main) {
        const project = Object.assign({}, Main);
        let allowedKeys = ['id', 'default', 'dr', 'anchor_text', 'traffic'];
        let isMonthlyAllocated = false;
        if(this.project.links_target_allocation_type === 'MONTHLY_ALLOCATED') {
          isMonthlyAllocated = true;
          allowedKeys = allowedKeys.concat(this.months_allocations);
        }

        //console.log('The allowed keys', allowedKeys)

        project.sites_and_targets.forEach(site => {
          for(const target of site.targets) {
            //console.log('The original target', Object.assign({}, target))
            Object.keys(target).forEach(targetKey => {
              if(!allowedKeys.includes(targetKey)) {
                delete target[targetKey];
              }
            })
          }
        })

        if (!isMonthlyAllocated) {
          project.links_monthly_allocation = {};
        }

        project.client_id = this.$route.params.clientId;

        return project;
      },
      async checkAllValidation(saveIndex) {
        //console.log('triggered')
        let counter = 0
        for(const key in this.components) {
          const target = this.$refs.projectComponents[key]
          //console.log('the validation target and key', target, key, await target.validate())
          if(typeof target.validate === 'function') {
            const result = await target.validate();
            if (!result) {
              //console.log('We are ober here?', parseInt(key))
              this.visibleIndex = parseInt(key);
              this.$scrollTo(this.$refs.top);
              this.$message.error('Sorry, the was some errors in your form.');
              return false
            }
          }
          if(Number.isInteger(saveIndex) && counter >= saveIndex) {
            break;
          }
          counter++;
        }

        return true
      },
      deleteProject() {
        this.$confirm('Are you sure you want to delete this project?', 'Are you sure?', {
          confirmButtonClass: 'el-button--danger'
        }).then(() => {
          this.$http.delete(`/v1/companies/${this.$store.state.company.company.id}/projects/${this.$store.state.project.project.id}`).then(() => {
            this.$message.success('Successfully deleted the project');
            setTimeout(() => {
              this.$router.push(`/company/${this.$store.state.company.company.id}/clients/${this.$store.state.client.client.id}/?tab=projects`);
            }, 400)
          }).catch(() => {
            this.$message.error('An error occurred trying to delete that project.')
          })
            .finally(() => {
              this.loading = false
            })
        }).catch(() => {})
      },
    }
  }
</script>
<style lang="scss" scoped>
  .itemList {
    @apply text-sm text-gray-600 font-medium;

    .icon {
      @apply bg-gray-400 rounded-full text-white inline-flex items-center justify-center mr-1;

      width: 1.5em;
      height: 1.5em;
    }

    > li {
      @apply mb-4 p-2;

      &.active, &.visited {
        @apply text-blue-800;

        .icon {
          @apply bg-green-400;
        }
      }
      &.active {
        @apply bg-white;
      }

    }
  }
</style>
