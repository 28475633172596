<template>
  <div v-loading="loading">
    <el-tabs class="custom-tabs-v1" :active-name="activeTabName" >
      <el-tab-pane label="All site settings" name="sites">
        <div class="columns">
          <div class="column w-full">
            <site-settings :custom-name-function="generateSiteName" all />
          </div>
        </div>
      </el-tab-pane>
      <el-tab-pane label="All Project allocations" name="projects">
        <project-allocations />
      </el-tab-pane>
    </el-tabs>
  </div>
</template>
<script>
import SiteSettings from "@/views/Companies/Clients/Tabs/SiteSettings";
import Links from '@/views/Companies/Links/Index.vue';
import ProjectAllocations from '@/views/Companies/Clients/Projects/Allocations/Index.vue';

export default {
  components: {ProjectAllocations, SiteSettings },
  data() {
    return {
      loading: false,
      include_suspended: true,
      activeTabName: 'sites'
    }
  },
  mounted() {
    if (!this.$store.getters['auth/hasPermission']('site.read')) {
      this.$message.error('You do not have permissions to view sites.');
      this.$router.push('/');
    } else {
      this.$store.dispatch('company/getCompany', this.$route.params).then(company => {
        this.$store.commit('global/setBreadcrumbs', [
          {
            path: '/',
            label: company.name
          },
          {
            label: 'Site requirements'
          }
        ])
      })
    }
  },
  methods: {
    generateSiteName(item) {
      return `${item.domain} (${item.client.name}) - ${item.status}`;
    }
  }
}
</script>
