<template>
  <div v-loading="loading">
    <div>
      <trieste-card :title="title">
        <template v-slot:title>
          <div>
            <div><span class="text-base font-bold text-blue-800">Types</span></div>
            <div><el-input v-model="search" size="mini" clearable  placeholder="filter types"  /></div>
          </div>
        </template>
        <template v-slot:header-right>
          <div>
            <el-button type="danger" size="small" @click="unSelectAll" v-if="val.length">Unselect all</el-button>
          </div>
        </template>
        <div class="p-4 overflow-y-auto" style="max-height: 400px" :key="reindexKey">
          <div class="columns">
            <div
              class="column mb-0" :class="{['w-' + defaultColumn]: true}"
              v-for="(u, i) in allowedTypes" :key="i">
              <div class="border-b p-1 -mt-3 text-xs font-medium">
                <el-checkbox v-model="selectedValues[u.value]" @change="optionSelected" />
                <span class="ml-4">{{ u.label }}</span>
              </div>
            </div>
          </div>
        </div>
      </trieste-card>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    value: {
      type: Array,
      default: () => []
    },
    title: {
      type: String,
      default: 'Type'
    },
    defaultColumn: {
      type: [String, Number],
      default: '1/4'
    },
  },
  data() {
    return {
      users: [],
      loading: false,
      val: this.value || [],
      selectedValues: {},
      selectedAll: false,
      reindexKey: 0,
      search: ''
    }
  },
  mounted() {
    this.syncDefaultValues()
  },
  computed: {
    allowedTypes() {
      return this.$store.state.link.types.filter(p => {
        if(this.search) return p.label.toLowerCase().includes(this.search.toLowerCase());
        return true
      })
    }
  },
  methods: {
    syncDefaultValues() {
      this.selectedValues = {}
      for(const v of this.value) {
        this.selectedValues[v] = true
      }
      this.$emit('change', this.value)
    },
    unSelectAll () {
      this.selectedValues = {}
      this.val = []
      this.optionSelected()
      this.reindexKey++;
    },
    optionSelected() {
      this.val = Object.keys(this.selectedValues).filter(key => this.selectedValues[key]).map(key => key)
      this.$emit('input', this.val)
    }
  },
  watch: {
    value: 'syncDefaultValues',
    includeSuspended: 'onSuspendedChange'
  }
}
</script>
