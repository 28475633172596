<template>
  <div v-loading="loading || !loaded">
    <div class="page-title" v-if="showTitle">
      <h1>
        Link allocation report
      </h1>
    </div>
    <div class="flex flex-row mb-4">
      <div class="mr-4">
        <span class="block font-medium text-gray-700 text-sm mb-2">Month</span>
        <el-date-picker class="w-full" type="month" format="MMM yyyy" :clearable="false" value-format="yyyy-MM-01" v-model="currentDate" size="small" />
      </div>
      <div class="mr-4">
        <span class="block font-medium text-gray-700 text-sm mb-2">Download</span>
        <el-button type="primary" icon="el-icon-download" @click="downloadReport" size="small">Download</el-button>
      </div>
      <div class="mr-4">
        <span class="block font-medium text-gray-700 text-sm mb-2">View</span>
       <el-switch active-text="Site" inactive-text="Project" v-model="isTargetView" />
      </div>
    </div>
    <div v-show="!isTargetView">
      <trieste-table
        title="Link allocations"
        item-type="Link allocations"
        :end-point="`/v1/companies/${$store.state.company.company.id}/reports/link-allocation`"
        :fields="getFields"
        :params="getParams"
        default-sort-prop="from_url"
        ref="table"
        :filters="getFilters"
        v-if="loaded"
        saved-table-sort-name="report-link-allocation-project"
      >
        <template v-slot:client-slot="props">
          <a :href="`/company/${props.row.company_id}/clients/${props.row.client.id}`">{{  props.row.client.name }}</a>
        </template>
        <template v-slot:name-slot="props">
          <a :href="`/company/${props.row.company_id}/projects/${props.row.id}`">{{  props.row.name }}</a>
        </template>
        <template v-slot:previous_months_target="props">
          <month-breakdown-pop-over v-model="props.row" :reference="props.row.previous_months_target" />
        </template>
        <template v-slot:previous_months_billed="props">
          <month-breakdown-pop-over v-model="props.row" :reference="props.row.previous_months_billed" />
        </template>
      </trieste-table>
    </div>
    <div v-show="isTargetView">
      <trieste-table
        title="Link allocations"
        item-type="Link allocations"
        :end-point="`/v1/companies/${$store.state.company.company.id}/reports/link-allocation/targets`"
        :fields="getTargetFields"
        :params="getParams"
        default-sort-prop="from_url"
        ref="table"
        :filters="getFilters"
        v-if="loaded"
        saved-table-sort-name="report-link-allocation-site-target"
      >
        <template v-slot:client-slot="props">
          <a :href="`/company/${props.row.site.client.company_id}/clients/${props.row.site.client.id}`">
            {{  props.row.site.client.name }}
          </a>
        </template>
        <template v-slot:name-slot="props">
          <a :href="`/company/${props.row.site.client.company_id}/projects/${props.row.project.id}`">
            {{  props.row.project.name }}
          </a>
        </template>
      </trieste-table>
    </div>
  </div>
</template>
<script>
  import MonthBreakdownPopOver from '@/components/views/Reporting/LinkAllocation/MonthBreakdownPopOver.vue';

  const moment = require('moment')
  const FileDownload = require('js-file-download');
  export default {
    components: {MonthBreakdownPopOver},
    props: {
      showTitle: {
        type: Boolean,
        default: true
      }
    },
    data: function() {
      return {
        loading: false,
        currentDate: new Date(),
        company_list: [],
        loaded: false,
        isTargetView: false
      }
    },
    mounted() {
      this.getResources()
    },
    computed: {
      getFields() {
        const theDate = moment(this.currentDate)
        const niceCurrentMonthText = theDate.format('MMMM');
        const niceLastMonthText = theDate.subtract(1, 'month').format('MMMM');
        return [
          {
            title: 'Client',
            name: 'client-slot',
            //formatter: (val) => val.name,
            //sortField: 'client.name',
          },
          {
            title: 'Project',
            name: 'name-slot',
            //sortField: 'name'
          },
          {
            title: `Accumulated target`,
            name: 'previous_months_target',
            numberField: true
            //sortField: 'month_target'
          },
          {
            title: `Accumulated billed`,
            name: 'previous_months_billed',
            numberField: true
          },
          {
            title: 'Rollover',
            name: 'roll_over',
            formatter: (val) => val ? 'Yes' : 'No'
          },
          {
            title: 'Accumulated Rollover',
            name: 'rollover_count',
            numberField: true,
            formatter: (val) => val === 0 ? `0 (OK)` : (`${val} (${val > 0 ? 'Short' : 'Over'})`)
          },
          {
            title: `${niceCurrentMonthText} target`,
            name: 'month_target',
            numberField: true
            //sortField: 'month_target'
          },
          {
            title: `${niceCurrentMonthText} calulated target`,
            name: 'rollover_target',
            numberField: true
          },
          {
            title: `${niceCurrentMonthText} billed`,
            name: 'current_month_billed',
            formatter: (val, l) => (l.rollover_estimate === 0) ? `${val} (OK)` : (`${val} (${l.rollover_estimate > 0 ? 'Short' : 'Over'} by ${this.reverseNeg(l.rollover_estimate)})`)
          }
        ]
      },
      getTargetFields() {
        return [
          {
            title: 'Site',
            name: 'site',
            formatter: (val) => val.domain,
          },
          ...this.getFields
        ]
      },
      getParams() {
        return {
          month: this.currentDate
        }
      },
      getFilters() {
        return [
          {
            label: 'Companies',
            type: 'select',
            multiple: true,
            options: this.company_list.map(c => { return { label: c.name, value: c.id } })
          },
          {
            label: 'Projects',
            type: 'project',
            multiple: true
          },
          {
            label: 'Project name',
            name: 'name',
            type: 'text'
          },
          {
            label: 'Clients',
            type: 'client',
            multiple: true
          },
          {
            label: 'Sites',
            type: 'site',
            multiple: true
          }
        ]
      }
    },
    methods: {
      reverseNeg(v) {
        if(v < 0) return v * -1
        return v
      },
      downloadReport() {
        this.loading = true
        this.$http.get(`/v1/companies/${this.$store.state.company.company.id}/reports/link-allocation/download`, {
          responseType: 'blob',
          params: {
            month: this.currentDate
          }
        }).then(r => {
          const theDate = moment(this.currentDate)
          const niceCurrentMonthText = theDate.format('MMMM YYYY');
          const niceLastMonthText = theDate.subtract(1, 'month').format('MMMM');
          FileDownload(r.data, `${niceLastMonthText} - ${niceCurrentMonthText} link allocations.xlsx`)
        })
        .catch(e => {
          this.$message.error('Failed to download the report')
          console.error('The error', e)
        })
        .finally(() => {
          this.loading = false
        })
      },
      getResources() {
        const requests = [
          this.$http.get(`/v1/companies`, {
            params: {
              all: true
            }
          })
        ]

        this.$http.all(requests).then(this.$http.spread((companies) => {
          this.company_list = companies.data.data.filter(c => c.id !== this.$route.params.companyId)
        }))
          .finally(() => {
            this.loaded = true
          })
      }
    }
  }
</script>
