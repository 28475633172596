<template>
  <div class="bg-white border border-solid border-gray-200 rounded shadow-sm custom-datatable-1 trieste-card mb-4 relative" v-loading="loading">
    <div
      class="p-4 border-b border-gray-200 rounded-tl rounded-tr flex flex-row justify-between items-center"
      :class="{ [headerHeight]: !!headerHeight }"
      v-if="hasHeader"
    >
      <div class="flex flex-row items-center justify-between w-full">
        <slot name="title">
          <span class="text-base font-bold text-blue-800">{{ title }}</span>
        </slot>
      <div>
        <slot name="header-right"></slot>
        <el-button
          v-if="collapsable"
          size="small"
          :icon="is_collapsed ? 'el-icon-arrow-down' : 'el-icon-arrow-up'"
          @click="is_collapsed = !is_collapsed"
        />
      </div>
    </div>
    </div>
    <div v-show="!collapsable || !is_collapsed">
      <slot></slot>
    </div>
    <div v-if="hasFooter" class="p-4 border-t border-solid border-gray-200">
      <slot name="footer"></slot>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    title: {
      type: String,
      default: ''
    },
    loading: {
      type: Boolean,
      default: false
    },
    collapsable: {
      type: Boolean,
      default: false,
    },
    collapsed: {
      type: Boolean,
      default: false
    },
    showTitle: {
      type: Boolean,
      default: true
    },
    headerHeight: {
      type: String,
      default: 'h-16'
    }
  },
  data() {
    return {
      is_collapsable: this.collapsable,
      is_collapsed: this.collapsed
    }
  },
  computed: {
    hasFooter() {
      return !!this.$slots.footer
    },
    hasHeader() {
      return this.showTitle || !!this.$slots['header-right'] || !!this.$slots.title
    }
  },
  watch: {
    collapsed: function(val) {
      this.is_collapsed = val
    },
    is_collapsed: function(val) {
      this.$emit('update:collapsed', val)
    }
  }
}
</script>
