<template>
  <div v-loading="loading" class="p-4">
    <div>
      <span v-if="false">Project Requirments</span>
      <div v-if="project">
        <div class="requirement-row">
          <span class="title">
            X paid links/articles in X published on site
          </span>
          <span class="value">
            {{ project.paid_links_ratio || 'N/A'}}
          </span>
        </div>
        <div class="requirement-row">
          <span class="title">
            GEO Restrictions
          </span>
          <span class="value">
            {{ project.geo_restrictions || 'N/A'}}
          </span>
        </div>
        <div class="requirement-row">
          <span class="title">
            TF
          </span>
          <span class="value">
            {{ project.target_tf || 'N/A'}}
          </span>
        </div>
        <div class="requirement-row">
          <span class="title">
            CF
          </span>
          <span class="value">
            {{ project.target_cf || 'N/A'}}
          </span>
        </div>
        <div class="requirement-row">
          <span class="title">
            TF/CF
          </span>
          <span class="value">
            {{ project.target_tf_cf || 'N/A'}}
          </span>
        </div>
        <div class="requirement-row">
          <span class="title">
            Referring domains
          </span>
          <span class="value">
            {{ project.target_referring_domains || 'N/A'}}
          </span>
        </div>
        <div class="requirement-row">
          <span class="title">
            SEMRUSH organic score
          </span>
          <span class="value">
            {{ project.semrush_organic_score || 'N/A'}}
          </span>
        </div>
        <div class="requirement-row">
          <span class="title">
            S-TS US
          </span>
          <span class="value">
            {{ project.semrush_traffic_score || 'N/A'}}
          </span>
        </div>
        <div class="requirement-row">
          <span class="title">
            S-TS UK
          </span>
          <span class="value">
            {{ project.semrush_traffic_score_uk || 'N/A'}}
          </span>
        </div>
        <div class="requirement-row">
          <span class="title">
            S-TS Global
          </span>
          <span class="value">
            {{ project.semrush_traffic_score_global || 'N/A'}}
          </span>
        </div>
        <div class="requirement-row">
          <span class="title">
            Ahref monthly traffic
          </span>
          <span class="value">
            {{ project.ahref_traffic || 'N/A'}}
          </span>
        </div>
        <div class="requirement-row">
          <span class="title">
            Target region
          </span>
          <span class="value">
            {{ project.target_region || 'N/A'}}
          </span>
        </div>
        <div class="requirement-row">
          <span class="title">
            Other information
          </span>
          <span class="value">
            {{ project.target_other_info || 'N/A'}}
          </span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  export default {
    props: {
      project: {
        type: Object,
        default: null
      },
      siteTarget: {
        type: Object,
        default: null
      }
    },
    data() {
      return {
        loading: false,
      }
    },
  }
</script>
<style lang="scss" scoped>
  .requirement-row {
    @apply flex flex-col flex-wrap mb-4;

    span {
      &.title {
        @apply font-medium text-base text-blue-900 mr-2;
      }
      &.value {
        @apply  text-sm text-gray-800;
      }
    }
  }
</style>
