<template>
  <div v-loading="loading">
    <div class="page-title">
      <a :href="`/company/${this.$store.state.company.company.id}/leads/${this.$route.params.linkId}`"><h1>
        {{$route.params.linkId}} metrics history
      </h1></a>
    </div>
    <trieste-table
      item-type="Records"
      title="Metrics history"
      :end-point="`/v1/companies/${$route.params.companyId}/links/${$route.params.linkId}/metrics`"
      :fields="fields"
      ref="table"
      default-sort-prop="created_at"
      default-sort-direction="descending"
      saved-table-sort-name="lead-metrics"
      @on-load="onTableLoad"
    >
      <template v-slot:header-right v-if="show_refresh_button">
        <el-button type="primary" size="small" @click="refreshStats">Get today's stats</el-button>
      </template>
    </trieste-table>
  </div>
</template>
<script>
export default {
  data: function () {
    return {
      loading: false,
      show_refresh_button: false,
      fields: [
        {
          title: 'Date',
          name: 'created_at',
          dateField: true,
        },
        {
          title: 'Domain Authority',
          name: 'domain_authority',
        },
        {
          title: 'Nofollow Percent',
          name: 'nofollow_percent',
        },
        {
          title: 'Trust Flow',
          name: 'trust_flow',
        },
        {
          title: 'Citation Flow',
          name: 'citation_flow',
        },
        {
          title: 'TF/CF',
          name: 'tf_cf',
    }    ,
        {
          title: 'Domain Rating',
          name: 'ahrefs_domain_rating',
        },
        {
          title: 'ref_domains',
          name: 'ref_domains',
        },
        /*{
          title: 'S-TS US',
          name: 'semrush_traffic_score',
          numberField: true
        },
        {
          title: 'S-TS UK',
          name: 'semrush_traffic_score_uk',
          numberField: true
        },*/
        {
          title: 'Ahrefs Traffic',
          name: 'ahrefs_monthly_traffic',
          numberField: true
        }
      ]
    }
  },
  mounted() {
    if(!this.$store.getters['auth/hasPermission']('lead.read')) {
      this.$message.error('You do not have permissions to view leads metrics.');
      this.$router.push('/');
    } else {
      this.loading= true;
      this.$store.dispatch('company/getCompany', this.$route.params).then((company) => {
        this.$store.commit('global/setBreadcrumbs', [
          {
            path: `/`,
            label: company.name
          },
          {
            label: 'Leads'
          },
          {
            label: this.$route.params.linkId,
            path: `/company/${this.$store.state.company.company.id}/leads/${this.$route.params.linkId}`
          },
          {
            label: 'Metrics history'
          }
        ])
        this.loading = false;
      })
    }
  },
  methods: {
    onTableLoad(response) {
      this.show_refresh_button = typeof response.has_current_days_data === 'boolean' ? !response.has_current_days_data : true
    },
    refreshStats() {
      this.loading = true;
      const url = `/v1/companies/${this.$store.state.company.company.id}/links/${this.$route.params.linkId}/refresh-stats`
      this.$http.get(url).then(() => {
        this.$refs.table.getData();
      }).finally(() => {
        this.loading = false;
      })
    }
  }
}
</script>
