import Vue from 'vue'
import Vuex from 'vuex'
import VuePersistedState from 'vuex-persistedstate';
import createMutationsObserver from "vuex-shared-mutations";
import global from './modules/global'
import link from './modules/link'
import company from './modules/company'
import auth from './modules/auth'
import campaign from "./modules/campaign";
import batch from "./modules/batch";
import site from "./modules/site";
import client from "./modules/client";
import content from "./modules/content";
import emailAccount from "./modules/emailaccount";
import project from "./modules/project";
import users from "./modules/users";
import router from "@/router";
import jsonStatues from '../utils/link/status.json'

Vue.use(Vuex)

let firstLoad = true
let lastRoute = null

export default new Vuex.Store({
  modules: {
    global,
    link,
    company,
    auth,
    campaign,
    batch,
    site,
    client,
    content,
    emailAccount,
    project,
    users
  },
  plugins: [
    VuePersistedState({
      key: 'trieste',
      reducer: state => {
        if(firstLoad) {
          state.site.sites = []
          state.site.loading_all_sites = false
          state.client.loading_all_clients = false
          state.client.clients = []
          state.link.statuses = jsonStatues
          state.users.all_users = []
          state.users.loading_all_users = false
          state.users.loaded_all_users = false
          state.auth.show_login_model = false
          firstLoad = false
        }
        if(lastRoute !== router.history.current) {
          lastRoute = router.history.current
          state.global.notifications = []
        }
        return state
      }
    }),
    createMutationsObserver({
      predicate: (mutation, state) => {
        const predicate = ['auth/reset', 'auth/loginModelSuccess', 'auth/setAccessToken', 'auth/setRefreshToken', 'auth/setUser'];
        return predicate.indexOf(mutation.type) >= 0
      }
    })
  ]
})
