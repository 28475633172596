<template>
  <nav class="hidden sm:flex items-center text-sm leading-5 font-medium mx-8" v-if="showBreadcumbs">
    <template
      v-for="(breadcrumb, key) in $store.state.global.breadcrumbs.slice(0, $store.state.global.breadcrumbs.length - 1)"
    >
      <a
        :href="breadcrumb.path"
        class="text-gray-500 hover:text-gray-700 transition duration-150 ease-in-out text-xs"
        :key="'breadcrumb_' + key"
      >{{  breadcrumb.label }}
      </a>
      <svg
        class="flex-shrink-0 mx-2 h-5 w-5 text-gray-400"
        viewBox="0 0 20 20"
        fill="currentColor"
        :key="'breadcrumb_arrow_' + key"
      >
      <path
        fill-rule="evenodd"
        d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
        clip-rule="evenodd"
      />
    </svg>
    </template>
    <a class="text-gray-500 hover:text-gray-700 transition duration-150 ease-in-out pointer-events-none text-xs">
      {{ $store.state.global.breadcrumbs.slice(-1)[0].label }}
    </a>
  </nav>
</template>

<script>
export default {
  name: 'Breadcrumbs',
  mounted() {
  },
  computed: {
    showBreadcumbs() {
      return this.$store.state.global.breadcrumbs && this.$store.state.global.breadcrumbs.length;
    }
  }
}
</script>

<style scoped>

</style>
