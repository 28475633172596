<template>
  <div>
    <trieste-dialog :show.sync="shouldShow" :title="campaign.id ? 'Edit a campaign' : 'Create a campaign'" :loading="updating_campaign">
      <div class="p-6 pb-2">
        <el-form
          ref="campaignForm"
          :model="campaign"
          :rules="campaign_rules"
          label-width="120px"
          label-position="left"
        >
          <el-form-item
            label="Name"
            prop="name"
            required
          >
            <el-input
              v-model="campaign.name"
            />
          </el-form-item>
          <el-form-item
            label="description"
            prop="description"
          >
            <el-input
              v-model="campaign.description"
            />
          </el-form-item>
          <el-form-item label="Project" prop="default_project_id">
            <el-select v-model="campaign.default_project_id" class="w-full">
              <el-option :value="null" label="N/A" />
              <el-option v-for="(project, i) in project_list" :key="i" :value="project.id" :label="project.name" />
            </el-select>
          </el-form-item>
        </el-form>
      </div>
      <template v-slot:footer-left v-if="campaign.id">
        <el-button type="danger" size="small" icon="el-icon-delete" v-if="campaign.id && $store.getters['auth/hasPermission']('campaign.delete')" @click="deleteCampaign">
          Permanently delete this campaign
        </el-button>
      </template>
      <template v-slot:footer-right>
        <el-button type="danger" icon="el-icon-close" size="small" @click="$emit('update:show', false)">Cancel</el-button>
        <el-button type="success"
                   icon="el-icon-edit-outline" size="small" @click="editCampaign"
                   v-if="!(campaign.id && $store.getters['auth/hasPermission']('campaign.create')) || (campaign.id && $store.getters['auth/hasPermission']('campaign.update'))">
          {{ campaign.id ? 'Edit' : 'Create' }}
        </el-button>
      </template>
    </trieste-dialog>
  </div>
</template>
<script>
  export default {
    props: {
      show: {
        default: false,
        type: Boolean
      },
      clientId: {
        type: [Number, String],
        default: null,
      },
      siteId: {
        type: [Number, String],
        default: null
      },
      value: {
        type: Object,
        default: () => {}
      }
    },
    data() {
      return {
        campaign: this.value || {},
        updating_campaign: false,
        shouldShow: this.show,
        campaign_rules: {
          name: [
            {required: true, message: 'Please enter a campaign name'},
          ]
        },
        project_list: [],
      }
    },
    mounted() {
      this.getResources()
    },
    methods: {
      editCampaign() {
        this.$refs.campaignForm.validate((valid) => {
          if(valid) {
            const p = this.$route.params;
            this.updating_campaign = true;
            const site_id = this.siteId || p.siteId;
            let URL = `/v1/companies/${this.$store.state.company.company.id}/campaigns`;

            let actionType = this.$http.post;
            let isCreate = true;
            const Entity = Object.assign({}, this.campaign, {site_id});

            if(Entity.id) {
              URL += `/${Entity.id}`;
              actionType = this.$http.put;
              isCreate = false;
            }

            actionType(URL, Entity).then((r) => {
              this.$message.success('Successfully '+(isCreate ? 'created' : 'updated')+' the campaign');
              this.$refs.campaignForm.resetFields();
              this.$emit('update:show', false);
              this.$emit('success', r.data.data)
            }).catch((e) => {
              this.$message.error('An error occurred while '+(isCreate ? 'creating' : 'updating')+' the campaign.');
              this.$emit('error', e)
            }).finally(() => {
              this.updating_campaign = false;
            })
          }
        });
      },
      deleteCampaign() {
        this.$confirm('This will delete all site links and batches in the campaign', 'Are you sure?',{
          confirmButtonText: 'OK',
          cancelButtonText: 'Cancel',
          type: 'warning',
          confirmButtonClass: 'el-button--danger'
        }).then(() => {
          const p = this.$route.params;
          this.updating_campaign = true;
          const urlPath = `/v1/companies/${this.$store.state.company.company.id}/campaigns/${this.campaign.id}`;
          this.$http.delete(urlPath).then(() => {
            this.$message.success('Successfully deleted the campaign');
            this.updating_campaign = false;
            this.$router.push(`/company/${this.$store.state.company.company.id}/sites/${this.$store.state.site.site.id}?tab=campaigns`);
          }).catch(() => {
            this.$message.error('An error occurred deleting the campaign. Please try again later.')
          })
        }).catch(() => {})
      },
      getResources() {
        const requests = [
          this.$http.get(`/v1/companies/${this.$store.state.company.company.id}/projects`, {
            params: {
              all: true,
              site_id: this.$route.params.siteId
            }
          })
        ]

        this.$http.all(requests).then(this.$http.spread((projects) => {
          this.project_list = projects.data.data
        }))
      }
    },
    watch: {
      show: function(val) { this.shouldShow = val },
      shouldShow: function(val) { this.$emit('update:show', val) },
      value: function(val) { this.campaign = val }
    }
  }
</script>
