<template>
  <div class="relative flex-shrink-0 flex h-16 bg-white border-b border-solid border-gray-300">
    <button
      v-show="false"
      class="px-4 border-r border-gray-200 text-gray-500 focus:outline-none focus:bg-gray-100 focus:text-gray-600 md:hidden"
      aria-label="Open sidebar"
      @click="$store.commit('global/toggleSidebar')"
    >
      <svg
        class="h-6 w-6"
        stroke="currentColor"
        fill="none"
        viewBox="0 0 24 24"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="M4 6h16M4 12h16M4 18h7"
        />
      </svg>
    </button>
    <breadcrumbs />
    <div class="px-4 flex justify-between ml-auto">
      <div class="ml-4 flex items-center md:ml-6">
        <div
          @click="show_search = !show_search, show_notifications = false"
          class="cursor-pointer h-full text-3xl flex items-center justify-center px-4 text-blue-800"
          :class="{'bg-gray-300': show_search}"
        >
          <i class="el-icon-search"></i>
        </div>
        <div
          @click="show_notifications = !show_notifications, show_search = false"
          class="cursor-pointer h-full text-3xl flex items-center justify-center px-4 text-blue-800 alerts-button"
          :class="{'bg-gray-300': show_notifications}"
        >
          <el-badge :value="$store.state.auth.alert_count || null" ><i class="el-icon-bell"></i></el-badge>
        </div>
        <!-- Profile dropdown -->
        <div
          class="ml-3 relative py-3"
        >
          <el-dropdown @command="dropdownClick">
            <div>
              <button
                id="user-menu"
                class="max-w-xs flex items-center text-sm rounded-full focus:outline-none focus:shadow-outline"
                aria-label="User menu"
                aria-haspopup="true"
              >
                <img
                  v-if="$store.state.auth.user.image"
                  class="h-8 w-8 rounded-full"
                  :src="$store.state.auth.user.image"
                  alt=""
                >
                <div class="h-8 w-8 rounded-full bg-gray-300 flex items-center justify-center" v-else>
                  <span class="text-xs font-bold">{{ $store.state.auth.user.firstname[0] }}</span>
                </div>
              </button>
            </div>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item command="profile">Your profile</el-dropdown-item>
              <el-dropdown-item command="logout">Sign out</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </div>
    </div>
    <div class="search-container" v-show="show_search">
      <search :visible.sync="show_search" />
    </div>
    <div class="search-container" v-show="show_notifications">
      <notifications :visible.sync="show_notifications" clear-bell v-if="show_notifications" />
    </div>
  </div>
</template>

<script>
import Breadcrumbs from './../../components/incs/Breadcrumbs'
import Search from "@/components/incs/Search";
import Notifications from "@/components/incs/Notifications";

export default {
  name: 'TopBar',
  components: {Search, Breadcrumbs, Notifications },
  data: function() {
    return {
      show_search: false,
      show_notifications: false
    }
  },
  methods: {
    dropdownClick(event) {
      if(event === 'logout') this.$store.commit('auth/reset', true);
      if(event === 'profile') this.$router.push(`/account`)
    }
  },
}
</script>

<style lang="scss">
.search-container {
  @apply absolute right-0 bg-white w-3/5 h-screen z-10 p-6 shadow-lg max-w-screen-sm;
  height: calc(100vh - 4rem);
  top: 4rem
}

.alerts-button {
  .el-badge__content {
    &.is-fixed {
      top: 1em
    }
  }
}
</style>
