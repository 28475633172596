<template>
  <div>
    <trieste-table
      :end-point="`/v1/companies/${$store.state.company.company.id}/campaigns`"
      :fields="fields"
      title="Campaigns"
      item-type="campaigns"
      ref="table"
      default-sort-prop="name"
      :filters="filters"
      :show-title="false"
      :params="{site_id: $route.params.siteId}"
      v-if="$store.getters['auth/hasPermission']('campaign.read')"
      saved-table-sort-name="campaign-index"
    >
      <template v-slot:header-right>
        <el-button type="success" size="medium" @click="showCreate=true" icon="el-icon-edit-outline" v-if="$store.getters['auth/hasPermission']('campaign.create')">Create</el-button>
      </template>
      <template v-slot:actions-slot="props">
        <a
          :href="`/company/${$route.params.companyId}/campaigns/${props.row.id}`"
          v-if="$store.getters['auth/hasPermission']('campaign.read')"
          >
        <el-button
          size="small"
          icon="el-icon-view"
          type="primary">
          View
        </el-button>
        </a>
      </template>
    </trieste-table>
    <create-edit :show.sync="showCreate" @success="refreshTable" v-if="$store.getters['auth/hasPermission']('campaign.create')" />
  </div>
</template>

<script>
  import CreateEdit from '@/components/views/Campaign/CreateEdit';
  import VueTableControlsMixin from './../../../../components/mixins/vuetable-controls'
  export default {
    name: 'Clients',
    mixins: [VueTableControlsMixin],
    components: { CreateEdit },
    data() {
      return {
        status: {
          options: [
            {value: 'all', label: 'All'},
            {value: 'active', label: 'Active'},
            {value: 'suspended', label: 'Suspended'}
          ],
          value: 'active'
        },
        loading: false,
        creating_campaign: false,
        fields: [
          {
            title: 'Name',
            name: 'name',
            sortField: 'name',
          },
          {
            title: 'Batches',
            name: 'batch_count',
            sortField: 'batch_count',
          },
          {
            title: 'Created',
            name: 'created_at',
            dateField: true,
            sortField: 'created_at',
          },
          {
            title: '',
            name: 'actions-slot',
            width: '110px',
            fixed: 'right'
          },
        ],
        filters: [
          {
            label: 'Name'
          }
        ],
        showCreate: false,
        campaignEditId: null
      }
    },
    beforeRouteEnter(to, from, next) {
      next(vm => {
        vm.$store.dispatch('company/getCompany', vm.$store.state.company.company.id).then(() => {
          vm.$store.commit('global/setBreadcrumbs', [
            {
              path: '/',
              label: 'Home'
            },
            {
              path: '/companies',
              label: 'Companies'
            },
            {
              path: `/companies/${vm.$store.state.company.company.id}`,
              label: vm.$store.state.company.company.name
            },
            {
              path: `/companies/${vm.$store.state.company.company.id}/campaigns`,
              label: 'Campaigns'
            }
          ])
        })
      })
    },
    methods: {
      closeShowCreate(shouldRefresh) {
        if(shouldRefresh !== false) {
          this.refresh()
        }
        this.showCreate = false;
      },
      refreshTable() {
        this.$refs.table.getData();
      },
    }
  }
</script>
