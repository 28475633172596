import api from './../../utils/api';

export default {
  namespaced: true,
  state: {
    all_users: [],
    loading_all_users: false,
    loaded_all_users: false
  },
  mutations: {
    setAllUsers: (state, payload) => state.all_users = payload,
    set: (state, payload) => state[payload[0]] = payload[1]
  },
  actions: {
    async loadAll({commit, state, rootState}, { companyId } = {}) {

      // If already called, keep waiting until we are returned....
      if(state.loading_all_users) {
        const awaiter = async function () {
          await new Promise(resolve => setTimeout(resolve, 500))
          if(state.loading_all_users) await awaiter()
        }
        await awaiter()
      }

      if(state.loaded_all_users) {
        return state.all_users
      }

      await commit('set', ['loading_all_users', true])
      const cId = companyId || rootState.company.company.id;
      const res = await api.get(`/v1/companies/${cId}/users`, { params: { all: true }})
      await commit('setAllUsers', res.data.data)
      await commit('set', ['loaded_all_users', true])
      await commit('set', ['loading_all_users', false])

      return res.data.data
    },
  }
}
