<template>
  <div v-loading="loading">
    <trieste-table
      :end-point="`/v1/companies/${$store.state.company.company.id}/sites/${$route.params.siteId}/targets/${$route.params.targetId}/content`"
      :fields="fields"
      title="Content"
      item-type="content items"
      :is-loading="loading"
      @on-load="(val) => loading=val"
      default-sort-prop="title"
      saved-table-sort-name="site-target-content"
    >
      <template v-slot:date-slot="props">
        {{ props.row.created_at | momentLocal("ddd MMMM DD YYYY Z") }}<br/>
        <span class="text-xs">
                {{  props.row.created_at | moment("from", "now") }}
              </span>
      </template>
      <template v-slot:sitelink-slot="props">
        <div>
          <a
            :href="`/company/${$route.params.companyId}/leads/${props.row.site_link.id}`"
            v-if="props.row.site_link">
            <div class="flex flex-row items-center font-bold">
              <div class="mr-1" v-if="false">
                <logo-widget v-model="props.row.site_link.domain" :size="12" />
              </div>
              {{ props.row.site_link.from_url }}
            </div>
          </a>
          <div v-else>
            <span class="text-gray-400">N/A</span>
          </div>
        </div>
      </template>
      <template v-slot:actions-slot="props">
        <a
          :href="`/company/${$route.params.companyId}/content/${props.row.id}`"
          v-if="$store.getters['auth/hasPermission']('content.update')"
          >
        <el-button
          size="small"
          icon="el-icon-edit"
          type="success">
          Edit
        </el-button>
      </a>
      </template>
    </trieste-table>
  </div>
</template>
<script>
  import LogoWidget from "@/components/incs/LogoWidget";
  import { formatResponseErrorMessage } from "@/utils/helpers";

  export default {
    components: {LogoWidget},
    data: function () {
      return {
        selected_placeholder: null,
        showCreateContent: false,
        showIfStatementDialog: false,
        new_content: {
          id: null,
          title: null,
          body: null,
          description: null,
          item_type: null,
          source: null,
          status: null,
        },
        new_content_rules: {
          title: [
            {required: true, min: 3, trigger: 'blur', message: 'Please enter a title'}
          ],
          body: [
            {required: true, min: 3, trigger: 'blur', message: 'Please enter a body'}
          ],
          description: [
            {required: true, min: 3, trigger: 'blur', message: 'Please enter a description'}
          ],
          item_type: [
            {required: true, trigger: 'blur', message: 'Please select a type'}
          ],
          source: [
            {required: true, trigger: 'blur', message: 'Please select a source'}
          ],
          status: [
            {required: true, trigger: 'blur', message: 'Please select a status'}
          ],
        },
        query: {
          rpp: 20,
          page: 1
        },
        loading: true,
        fields: [
          {
            name: 'title',
            title: 'Title',
            sortField: 'Title',
          },
          {
            name: 'description',
            sortField: 'description',
            title: 'description',
          },
          {
            name: 'sitelink-slot',
            title: 'Site link',
            breakAll: true
          },
          {
            name: 'status',
            sortField: 'status',
            title: 'status',
          },
          {
            name: 'item_type',
            sortField: 'item_type',
            title: 'Type',
            formatter: (val) => {
              if(val) {
                return val.replace(/_/g, ' ')
              }
              return '<span class="text-gray-400">N/A</span>'
            }
          },
          {
            name: 'source',
            sortField: 'source',
            title: 'Source',
          },
          {
            title: 'Created at',
            name: 'created_at',
            sortField: 'created_at',
            dateField: true,
          },
          {
            title: '',
            name: 'actions-slot',
            width: '110px',
            fixed: 'right'
          },
        ],
        status_types: [
          'assigned', 'cancelled', 'draft', 'published', 'waiting_for_tier_2_approval', 'new',
          'waiting_for_tier_1_approval', 'awaiting_client_approval', 'pending'
        ],
        source_types: ['internal', 'textbroker', 'internal_not_validate_link'],
        item_types: ['title_and_content', 'title_only', 'content_only']
      }
    },
    mounted() {
      //this.getSites();
    },
    methods: {
      deleteContent(content) {
        //console.log(content)
        if(content.id && content.title) {
          this.$confirm('Are you sure you want to delete the content '+content.title+'?', 'warning')
            .then(() => {
              this.$http.delete( `/v1/companies/${this.$store.state.company.company.id}/sites/${this.$route.params.siteId}/targets/${this.$route.params.targetId}/content/${content.id}`)
                .then(() => {
                  this.$message.success('The content has successfully been deleted.');
                  this.refresh()
                })
                .catch(() => {
                  this.$message.error('The was an error processing that request. Please try again later.')
                })
            })
            .catch(() => {
              // do nothign
            })
        }
      },
      createContent() {
        this.$refs.siteForm.validate((valid) => {
          if(valid) {
            this.loading = true;

            let method = this.$http.post;
            let url = `/v1/companies/${this.$store.state.company.company.id}/sites/${this.$route.params.siteId}/targets/${this.$route.params.targetId}/content`;

            if(this.new_content.id) {
              url += `/${this.new_content.id}`;
              method = this.$http.put;
            }

            method(url, this.new_content)
              .then(() => {
                this.$message.success('Successfully '+(this.new_content.id ? 'updated':'created')+' the content.');
                this.closeCreateContentDialog();
                this.refresh()
              })
              .catch((err) => {
                let message = formatResponseErrorMessage(err, 'createContent')
                this.$message.error(message)
                console.error(err.toJSON(), message)
              })
              .finally(() => {
                this.loading = false;
              })
          }
        })
      },
      closeCreateContentDialog() {
        this.new_content = {
          id: null,
          title: null,
          body: null,
          description: null,
          item_type: null,
          source: null,
          status: null,
        };
        this.showCreateContent = false;
      },
    }
  }
</script>
