<template>
  <div class="columns" v-loading="loading">
    <div class="column w-full lg:w-3/5 xxl:w-4/5">
      <div class="columns py-4">
        <div class="column items-center flex">
          <div v-if="!editMode">
            <span class="text-xs text-gray-800"><a :href="getOutboundSafeUrl(link.from_url)" target="_blank"><i
              class="el-icon-link mr-1"></i>{{ link.from_url }}</a></span>
            <div v-if="canUpdateLead" class="ml-2 inline-block">
              <el-button size="mini" type="primary" @click="editMode = true">Edit</el-button>
            </div>
          </div>
          <div v-else class="w-96">
            <el-form :model="editUrl.form" label-width="0" label-position="left" size="large" :rules="editUrl.validation"
                     ref="form">
              <el-form-item prop="from_url">
                <div class="flex flex-row items-center">
                  <el-input v-model="editUrl.form.new_from_url" size="small">
                    <template slot="append">
                      <el-button @click="updateUrl">Update</el-button>
                    </template>
                  </el-input>
                  <div class="ml-2">
                    <el-button size="small" type="danger" @click="editMode = false">Cancel</el-button>
                  </div>
                </div>
              </el-form-item>
            </el-form>
          </div>
        </div>
        <div class="column ml-auto">
          <el-button
            size="small"
            type="info"
            icon="el-icon-info"
            v-if="link.host"
            @click="openUrl('https://whois.domaintools.com/' + link.host )"
          >
            WHOIS
          </el-button>
          <el-button
            size="small"
            type="info"
            icon="el-icon-document"
            v-if="link.from_url"
            @click="openUrl('http://translate.google.com/translate?js=n&sl=auto&tl=destination_language&u=' + link.from_url )"
          >
            Translate
          </el-button>
          <el-button
            size="small"
            type="info"
            icon="el-icon-search"
            v-if="link.host"
            @click="openUrl('https://www.google.com/search?q=site:' + link.host )"
          >
            Search
          </el-button>
        </div>
      </div>

      <iframe
        v-if="link.from_url"
        ref="websitePreview"
        :src="baseProxyUrl"
        class="shadolink.Fw rounded bg-white w-full block--iframe bg-white border border-gray-100 shadow"
        sandbox="allow-forms allow-popups allow-same-origin allow-scripts"
      ></iframe>
    </div>
    <div class="column w-full lg:w-2/5 xxl:w-1/5">
      <trieste-card title="Site / Target">
        <div class="p-4">
          <project-and-target-form form-label-width="110px" :project-id.sync="editSiteTarget.form.project_id"
                                   :target-id.sync="editSiteTarget.form.site_target_id"
                                   :target-site-id="editSiteTarget.form.site_id"
                                   :show-project-select="false" show-site-select
                                   allow-suspended-selection
                                   ref="patform" v-if="link.id" />
          <div class="mt-2 text-right">
            <el-button type="primary" size="small" :disabled="!canEditTarget" @click="updateTarget">
              Update
            </el-button>
          </div>
        </div>
      </trieste-card>
      <scores v-model="link" v-if="link"/>
      <external-links v-model="link" v-if="link" />
      <notes-widget :resource-id="link.id" resource-type="SiteLink" v-if="link.id"
                    :default-assignee-id="link.owner_id || link.created_by_id"/>
      <contacts :id="link.id" ref="contactWidget"
                include-links :exclude-domain="link.domain" v-if="link.id"
                :collapsed="true"/>
    </div>
  </div>
</template>

<script>

import Scores from "@/components/views/links/Scores";
import NotesWidget from "@/components/notes/index";
import Contacts from "@/components/views/links/Contacts";
import ProjectAndTargetForm from "@/components/views/global/ProjectAndTargetForm";
import ExternalLinks from "@/components/views/links/ExternalLinks";

export default {
  name: 'SiteLink',
  components: { Scores, NotesWidget, Contacts, ProjectAndTargetForm, ExternalLinks },
  props: {
    value: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  data() {
    return {
      link: this.value,
      loading: false,
      ttfExpanded: false,
      iframeError: false,
      editMode: false,
      editUrl: {
        form: {
          new_from_url: this.value.from_url
        },
        validation: {
          new_from_url: [
            {type: 'url', required: true, message: 'Please enter a valid URL.'}
          ]
        }
      },
      editSiteTarget: {
        form: {
          site_id: this.value.site_id,
          site_target_id: this.value.site_target_id,
          project_id: this.value.project_id
        }
      }
    }
  },
  watch: {
    value(val) {
      this.link = val;
    }
  },
  methods: {
    openUrl(url) {
      let win = window.open(url, '_blank');
      win.focus();
    },
    refreshStats() {
      this.loading = true
      const url = `/v1/companies/${this.$store.state.company.company.id}/links/${this.$route.params.linkId}/refresh-stats`
      this.$http.get(url)
        .then(r => {
          const stats = r.data
          const updatedLink = Object.assign({}, this.link, stats)
          this.link = updatedLink
          this.$emit('input', updatedLink)
        })
        .catch(() => {
          this.$message.error('Failed to update the stats')
        })
        .finally(() => {
          this.loading = false
        })
    },
    updateLink(params) {
      this.loading = true
      this.$http.put(
        `/v1/companies/${this.$store.state.company.company.id}/links/${this.$route.params.linkId}`, params
      ).then(() => {
        this.$message.success('Successfully updated the link.');
        setTimeout(() => {
          window.location.reload();
        }, 500);
      })
        .catch((e) => {
          this.$message.error('An error occurred updating the link.');
        })
        .finally(() => {
          this.loading = false
        })
    },
    updateUrl() {
      this.$refs.form.validate(valid => {
        if (valid) {
          return this.updateLink(this.editUrl.form)
        }
      })
    },
    async updateTarget() {
     await this.$refs.patform.validate((valid) => {
       if(valid) {
         return this.updateLink(this.editSiteTarget.form)
       }
     });
    }
  },
  computed: {
    baseProxyUrl() {
      // let baseURl = process.env.VUE_APP_TRIESTE_API + '/v1/proxy?url=' + encodeURIComponent(this.link.from_url) +
      // '&trieste_proxy_token=' + this.$store.state.auth.access_token;
      // return baseURl;

      return 'https://sfo.redirect.systems/?url=' + encodeURIComponent(this.link.from_url)
    },
    canUpdateLead() {
      return !!(this.$store.getters['auth/hasPermission']('other.lead.status.update') ||
        (this.$store.getters['auth/hasPermission']('own.lead.status.update') && this.$store.state.auth.user.id === this.link.owner_id));
    },
    canEditTarget () {
      return this.editSiteTarget.form.site_id && this.editSiteTarget.form.site_target_id
    }
  },
}
</script>

<style>
.block--iframe {
  height: 100vh;
}

.borderless--popper {
  padding: 0 !important;
}
</style>
