<template>
  <div class="w-full h-screen" v-loading="loading"></div>
</template>
<script>
const qs = require('query-string')
export default {
  data() {
    return {
      loading: true,
      return_url: null
    }
  },
  mounted() {
    this.checkHash()
  },
  methods: {
    checkHash() {
      const url = new URL(window.location.href)
      const queryString = qs.parse(url.search || url.hash)

      if(!queryString.state) {
        return this.showMessage('The response from google was invalid.', true)
      }

      const stateSplit = (atob(queryString.state)).split(':::')
      const return_url = stateSplit[0]
      this.return_url = return_url
      const client_id = stateSplit[1]
      const email = stateSplit[2]


      if(!queryString.code && !queryString.state || !queryString.scope) {
        return this.showMessage('The response from google was invalid.', true)
      }

      if(queryString.scope !== 'https://mail.google.com/') {
        return this.showMessage('The scope returned was invalid.', true)
      }

      const returnParams = new URL(return_url)

      // okay, now lets do this request
      this.$http.post(`/v1/legacy/gmail/auth/create`, {
        code: queryString.code,
        account_id: stateSplit[1],
        gmail_client_id: client_id,
        redirect_uri: process.env.VUE_APP_URL + '/legacy-google-sign-in',
        email
      }).then(() => {
        returnParams.searchParams.append('success', 'true');
        window.location.href = returnParams.toString();
        return this.showMessage('The email account was successfully authenticated.')
      }).catch((e) => {
        if(e.response && e.response.data && e.response.data.error && e.response.data.error) {
          return this.showMessage(e.response.data.error, true)
        } else {
          return this.showMessage('A unknown error occurred. Please try again later', true)
        }
      })
    },
    showMessage(message, isError) {
      setTimeout(() => {
        if(isError) {
          this.$message.error(message)
          if(this.return_url) {
            setTimeout(() => {
              const returnParams = new URL(this.return_url)
              returnParams.searchParams.append('success', 'false')
              returnParams.searchParams.append('error', 'message')
              window.location.href = returnParams.toString()
            }, 300)
          }
        } else {
          this.$message.success(message)
        }
      }, 10)
    }
  }
}
</script>
