<template>
  <div v-loading="loading">
    <div>
      <div class="columns">
        <div v-for="(site, i) in results" :key="i" class="dashboard-card column w-full lg:w-1/2 xl:w-1/3 xxl:w-1/4">
          <h2 class="dc-title">{{ site.domain }} ({{site.client.name}})</h2>
          <div class="dc-body">
            <div class="dc-header">
              <a class="dc-view-button" :href="`/company/${$store.state.company.company.id}/sites/${site.id}`">
                <i class="el-icon-view"></i>
              </a>
              <div class="mr-4">
                <el-progress type="circle"
                             :percentage="((site.billed_links)  / site.billed_target * 100 ) || 0"
                             :color="getCircleColor(((site.billed_links)  / site.billed_target * 100 ) || 0)" />
              </div>
              <div class="text-xs">
                <div class="mb-2">{{ site.billed_links }} / {{ site.billed_target }} links built.</div>
                <div class="mb-2"><a :href="`/company/${$store.state.company.company.id}/sites/${site.id}?tab=leads`">Go to leads -></a></div>
                <div><a href="#" @click.prevent="openCreateBatch(site)">Create a new batch -></a></div>
              </div>
            </div>
            <div>
              <ul class="dc-body-list">
                <li>
                  <div class="number">{{  site.awaiting_payment }}</div>
                  leads awaiting payment
                  <a :href='`/company/${$store.state.company.company.id}/sites/${site.id}?tab=leads&filters=%7B"status"%3A%5B"awaiting_payment"%5D%7D`' class="btn">View</a>
                </li>
                <li><div class="number">
                  {{ site.awaiting_placement }}</div>
                  leads awaiting placement
                  <a :href='`/company/${$store.state.company.company.id}/sites/${site.id}?tab=leads&filters=%7B"status"%3A%5B"awaiting_placement"%5D%7D`' class="btn">View</a>
                </li>
                <li>
                  <div class="number">{{ site.unread_emails }}</div>
                  Unread emails
                  <a :href="`/company/${$store.state.company.company.id}/sites/${site.id}?tab=unread%20emails`" class="btn">View</a></li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    <batch-quick-create
      :show.sync="batchQuickCreate.show"
      v-if="$store.getters['auth/hasPermission']('batch.create') && batchQuickCreate.show"
      :site-id="batchQuickCreate.siteId"
      :client-id="batchQuickCreate.clientId"
    />
  </div>
</template>
<script>
  import BatchQuickCreate from "@/components/views/batch/QuickCreate";
  export default {
    name: 'DashboardSitesTab',
    components: {BatchQuickCreate},
    data() {
      return {
        results: [],
        loading: true,
        pagination: {
          page_length: 20,
          page: 1,
          total: 0,
          total_pages: 0
        },
        batchQuickCreate: {
          show: false,
          clientId: null,
          siteId: null
        }
      }
    },
    mounted() {
      this.getData()
    },
    methods: {
      getData() {
        this.loading = true
        this.$http.get(`/v1/companies/${this.$store.state.company.company.id}/dashboard/sites`, {
          params: {
            page: this.pagination.page,
            rpp: this.pagination.page_length
          }
        }).then(res => {
          this.results = res.data.data
          // this.results = [res.data.data[0], res.data.data[0], res.data.data[0], res.data.data[0], res.data.data[0], res.data.data[0]]
        }).catch((e) => {
          console.error('An error occurred', e)
          this.$message.error('Failed to load site dashboard')
        })
          .finally(() => {
            this.loading = false
            this.$emit('onload')
          })
      },
      openCreateBatch(site) {
        //console.log('triggr')
        this.batchQuickCreate.siteId = site.id
        this.batchQuickCreate.clientId = site.client.id
        this.batchQuickCreate.show = true
      },
      getCircleColor(percent) {
        if(percent < 49) {
          return '#ff4e42';
        } else if(percent < 89) {
          return '#ffa300';
        }
        return '#0cce6b';
      },
    }
  }
</script>
<style lang="scss">
  .dashboard-card {
    .dc-title {
      @apply font-bold text-blue-900 text-lg my-4;
    }
    .dc-body {
      @apply bg-white text-gray-600 rounded-tl rounded-tr;
      .dc-header {
        @apply flex flex-row items-center bg-blue-900 text-white p-4 rounded relative;

        .el-progress-circle {
          width: 5em !important;
          height: 5em !important;
        }
        .el-progress__text {
          @apply text-white;
        }

        .dc-view-button {
          @apply text-white p-1 absolute bg-blue-800 rounded cursor-pointer flex items-center justify-center;
          top: 1em;
          right: 1em;
          width: 2em;
          height: 2em;
        }
      }

      ul {
        &.dc-body-list {
          @apply p-4 pb-4;
          > li {
            @apply mb-4 p-2 bg-gray-200 text-sm flex flex-row items-center;

            .number {
              @apply rounded-full bg-blue-900 w-6 h-6 text-white inline-flex items-center justify-center mr-2 text-xs;
            }

            .btn {
              @apply ml-auto bg-blue-900 text-white py-1 px-6 rounded-full;
            }

            &:last-child {
              @apply mb-0;
            }
          }
        }
      }
    }
  }
</style>
