<template>
  <div v-loading="loading">
    <div>
      <trieste-card :title="(this.mode ==='create' ? 'Create ' : 'Update ') + (resource.single_name || resource.name)">
        <div>
          <div class="p-6 min-h-40" v-if="ready">
            <el-form label-width="120px" label-position="left" v-loading="loading" ref="form" :model="item" :rules="getRules">
              <el-form-item v-for="(field, i) in getResourceFields" :key="i" :label="getFieldLabel(field)" :prop="field.name">
                <template v-if="field.model">
                  <el-select v-model="item[field.name]" filterable @change="(val) => changeSelect(val, field.name)">
                    <el-option v-for="(opt, oi) in getFilteredRelations(field)" :key="oi" :value="opt.id" :label="renderRelationLabel(field, opt)" />
                  </el-select>
                </template>
                <template v-else-if="field.type === 'boolean'">
                  <el-switch v-model="item[field.name]" />
                </template>
                <template v-else-if="field.type === 'date'">
                  <el-date-picker v-model="item[field.name]" format="dd MMM yyyy" value-format="yyyy-MM-dd"  />
                </template>
                <template v-else-if="field.type === 'select'">
                  <el-select v-model="item[field.name]" filterable>
                    <el-option v-for="(opt, oi) in field.options" :key="oi" :value="opt.value" :label="opt.label" />
                  </el-select>
                </template>
                <template v-else>
                  <el-input v-model="item[field.name]" :type="field.type || 'text'" />
                </template>
              </el-form-item>
            </el-form>
          </div>
        </div>
        <template v-slot:footer>
          <div class="flex flex-row items-center">
            <div v-if="mode !== 'create'">
              <el-button type="danger" icon="el-icon-delete" @click="deleteItem" size="small">Delete</el-button>
            </div>
            <div class="ml-auto">
              <el-button type="success" icon="el-icon-edit-outline" @click="save" size="small">{{ mode || capitalize }}</el-button>
            </div>
          </div>
        </template>
      </trieste-card>
      <div v-for="(include, i) in includes" :key="i">
        <edit-list :resource="include.target" :endpoint="$attrs.endpoint" :target="item"
                   :relation-filters="include.filter" v-if="include.type === 'manyThrough'" />
        <List :resource="include.target" :endpoint="$attrs.endpoint" :relation-filters="include.filter" v-else />
      </div>
    </div>
  </div>
</template>
<script>
  import List from "../../components/admin/List";
  import EditList from "@/components/admin/EditList";
  export default {
    components: {EditList, List },
    data() {
      return {
        ready: false,
        loading: true,
        resource: this.$attrs.resource,
        relations: {},
        item: {},
        mode: 'create',
        includes: [],
        after_create_redirect: null
      }
    },
    mounted() {
      if(!this.$store.getters['auth/hasPermission']('admin')) {
        this.$message.error('You do not have permissions to view the admin section.');
        this.$router.push('/');
      }
      if(this.$route.params.resourceId) {
        this.mode = 'edit';
        this.getResource();
      } else {
        if (this.resource.relations) {
          this.getRelations();
        } else {
          this.loading = false;
          this.ready = true
        }
      }
    },
    computed: {
      getRules() {
        const rules = {};
        this.resource.fields.forEach(f => {
          if(f.rules) {
            rules[f.name] = f.rules
          }
        })
        return rules;
      },
      getResourceFields() {
        return this.resource.fields.filter(i => {
          if(i.hideOnCreate) {
            return false;
          }
          return true;
        })
      }
    },
    methods: {
      changeSelect(val, field_name) {
        this.item[field_name] = val;
      },
      prepareIncludes() {
        this.resource.includes.forEach(include => {
          const resource = this.resource.name;
          const target = this.$attrs.resources.find(i => i.name === include.model);
          if(include.type === 'manyThrough') {
            const pivotKey = include.localKey || this.$attrs.resources.find(i => i.name === include.through).fields.find(i => i.model === include.model).name;
            const pivotForeign = include.pivotForiegn || this.$attrs.resources.find(i => i.name === include.through).fields.find(i => i.model === this.resource.name).name;
            const localKey = include.localKey || 'id'
            const foreignKey = include.foreignKey || 'id'
            const foreignValue = this.item[foreignKey]
           this.includes.push({
             target,
             type: 'manyThrough',
             filter: {
               pivotKey,
               pivotForeign,
               localKey,
               foreignKey,
               resource,
               foreignValue,
               ...include
             }
            });
          } else if (include.type === 'many') {
            const foreignKey = include.foreignKey || 'id'
            const foreignValue = this.item[foreignKey]
            const targetModel = this.$attrs.resources.find(i => i.name === include.model)
            const field = targetModel.fields.find(i => i.model === this.resource.name)
            let column = this.resource.name + '_id_here';
            if(field) {
              column = field.name;
            }
            if(targetModel)
            this.includes.push({
              target,
              type: 'many',
              filter: {
                ...include,
                foreignResource: this.$attrs.resource.name,
                value: foreignValue,
                column
              }
            });
          }
        })
      },
      getResource() {
        const url = this.$attrs.endpoint + '/' + this.resource.name + '/' + this.$route.params.resourceId;
        this.$http.get(url).then((r) => {
          this.item = r.data.data
          this.getRelations();
          this.prepareIncludes();
        }).catch((e) => {
          this.$message.error('Failed to load the item')
          console.error('The error', e,  'The resource\n', this.resource)
        })
      },
      setDefaultValues() {
        for (const key of Object.keys(this.$route.query)) {
          const findResource = this.resource.fields.find(i => i.name === key)
          if(findResource && findResource.model) {
            this.item[key] = parseInt(this.$route.query[key])
            this.after_create_redirect = `/admin/${findResource.model}/${this.item[key]}`;
          }
        }
      },
      save() {
        this.$refs.form.validate(valid => {
          if(valid) {
            let url = this.$attrs.endpoint + '/' + this.resource.name;
            let method = this.$http.post;
            if (this.$route.params.resourceId) {
              url += '/' + this.$route.params.resourceId;
              method = this.$http.put;
            }
            this.loading = true;
            const m = this.mode === 'create' ? 'created' : 'updated';
            method(url, { body: this.item }).then((r) => {
              this.$message.success('Successfully '+m+' the ' + this.resource.name);
              if(this.mode === 'create') {
                setTimeout(() => {
                  window.location = this.after_create_redirect || `/admin/${this.resource.name}/${r.data.data.id}`;
                }, 800);
              } else {
                setTimeout(() => {
                 window.location.reload()
                }, 600);
              }
            })
            .catch((e) => {
              if(e && e.response && e.response.data && e.response.data.error_message) {
                this.$message.error(e.response.data.error_message)
              } else {
                this.$message.error('An error occurred. please try again later.');
              }
            })
            .finally(() => {
              this.loading = false
            })
          }
        })
      },
      renderRelationLabel(f, val) {
        if(typeof f.val === 'function') {
            return f.val(val)
        }
        else if(typeof f.val === "string") {
            return val[f.val] || null
          }

        return null
      },
      getFieldLabel(f) {
        return f.label ? f.label : (f.name[0].toUpperCase() + f.name.substring(1))
      },
      getRelations() {
        const requests = [];
        for(const relation of this.resource.relations) {
          const url = `${this.$attrs.endpoint}/${relation}/index`;
          const params = { all: true };
          requests.push(this.$http.get(url, { params }));
        }

        this.$http.all(requests).then(this.$http.spread((...response) => {
          for(const key in response) {
            const r = this.resource.relations[key]
            this.relations[r] = response[key].data.data
          }
        }))
          .finally(() => {
            this.ready = true
            this.loading = false
            this.setDefaultValues();
          })
      },
      deleteItem() {
        this.$confirm('Are you sure you want to delete this item?', 'Are you sure?', {
          confirmButtonClass: 'el-button--danger'
        }).then(() => {
          const resourceId = this.item.id
          const resource = this.resource.name
          this.loading = true
          this.$http.delete(`/v1/admin/${resource}/${resourceId}`).then(() => {
            this.$message.success('Successfully deleted that item');
            setTimeout(() => {
              window.location = `/admin/${this.resource.name}/index`;
            }, 600)
          }).catch(() => {
            this.$message.error('An error occurred trying to delete that item.')
          })
            .finally(() => {
              this.loading = false
            })
        }).catch(() => {

        })
      },
      getFilteredRelations(field) {
        if(typeof field.filterSelection === 'function') {
          const data = field.filterSelection(this.item, this.relations[field.model])
          if(!data.find(row => row.id === this.item[field.name])) {
            this.item[field.name] = null
          }
          return data
        }
        return this.relations[field.model]
      }
    }
  }
</script>
