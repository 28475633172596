<template>
  <trieste-dialog :show.sync="should_show" title="Request content">
    <div class="p-6 pb-2">
      <el-form :rules="rules" :model="new_content_item" label-position="left" label-width="120px" ref="contentItemForm" v-loading="creating_content_item">
        <el-form-item label="description" prop="description">
          <el-input v-model="new_content_item.description" />
        </el-form-item>
        <el-form-item label="Words count" prop="words_count">
          <el-input type="number" v-model="new_content_item.words_count" />
        </el-form-item>
        <el-form-item label="Type" prop="item_type">
          <el-select v-model="new_content_item.item_type" class="w-full">
            <el-option v-for="(item, a) in item_types" :key="'item_types_' + a" :label="item.label" :value="item.value"/>
          </el-select>
        </el-form-item>
        <el-form-item label="Source" prop="source">
          <el-select v-model="new_content_item.source" class="w-full">
            <el-option v-for="(item, a) in source_types" :key="'source_type_' + a" :label="item.label" :value="item.value"/>
          </el-select>
        </el-form-item>
        <el-form-item label-width="0px">
          <span class="text-lg text-gray-600 font-bold">Guest Post Requirements</span>
        </el-form-item>
        <el-form-item label="Guidelines URL" v-model="new_content_item.guidelines_url">
          <el-input v-model="new_content_item.guidelines_url" />
        </el-form-item>
        <el-form-item label="Guidelines" prop="guidelines">
          <el-input type="textarea" :rows="4" v-model="new_content_item.guidelines" />
        </el-form-item>
        <el-form-item label="Keywords" prop="guidelines_keywords">
          <el-input type="text"  v-model="new_content_item.guidelines_keywords" />
          <span class="text-xs text-gray-700">comma seperated.</span>
        </el-form-item>
        <el-form-item label="link allowed in" prop="guidelines_link_allowed_in">
          <el-select  v-model="new_content_item.guidelines_link_allowed_in">
            <el-option value="author_byline" label="Author byline" />
            <el-option value="body" label="Body" />
            <el-option value="both" label="Both" />
          </el-select>
        </el-form-item>
      </el-form>
    </div>
    <template v-slot:footer-right>
      <el-button type="danger" icon="el-icon-close" size="small" @click="should_show=false">Cancel</el-button>
      <el-button type="success" icon="el-icon-edit-outline" size="small" @click="createContentItem" v-if="$store.getters['auth/hasPermission']('content.create')">Create</el-button>
    </template>
  </trieste-dialog>
</template>
<script>
  export default {
    props: {
      siteTargetId: {
        type: [String, Number],
        dafault: null
      },
      projectId: {
        type: [String, Number],
        dafault: null
      },
      linkId: {
        type: [String, Number],
        default: null
      },
      show: {
        type: Boolean,
        default: false
      }
    },
    data() {
      return {
        should_show: this.show,
        creating_content_item: false,
        new_content_item: {
          source: 'internal',
          item_type: 'title_and_content',
          words_count: null,
          description: '',
          guidelines_url: null,
          guidelines: '',
          guidelines_keywords: null,
          guidelines_link_allowed_in: null
        },
        rules: {
          item_type: [
            {required: true, trigger: 'blur', message: 'Please select a type'}
          ],
          guidelines: [
            {required: true, min: 1, trigger: 'blur', message: 'Please enter a guideline'}
          ],
          source: [
            {required: true, trigger: 'blur', message: 'Please select a source'}
          ],
          guidelines_url: [
            { type: 'url', message: 'Please provide a valid URL', trigger: 'blur' }
          ],
        },
        source_types: [
          {
            value: 'internal',
            label: 'internal'
          },
          {
            value: 'textbroker',
            label: 'textbroker'
          },
          {
            value: 'internal_not_validate_link',
            label: 'internal not validate link'
          }
        ],
        item_types: [
          {
            value: 'title_and_content',
            label: 'title and content'
          },
          {
            value: 'title_only',
            label: 'title only'
          },
          {
            value: 'content_only',
            label: 'content only'
          },
        ],
      }
    },
    methods: {
      toggleWidget(val) {
        this.should_show = val || false
        this.$emit('update:show', this.should_show)
      },
      createContentItem() {
        this.$refs.contentItemForm.validate(valid => {
          if(valid) {
            this.creating_content_item = true;
            // create content item here...
            const url = `/v1/companies/${this.$store.state.company.company.id}/content`
            const data = Object.assign({}, this.new_content_item, {
              site_target_id: this.siteTargetId,
              link_id: this.linkId,
              project_id: this.projectId
            });
            this.$http.post(url, data).then((r) => {
              this.$message.success('Successfully created the content item.');
              this.new_content_item = {
                source: null,
                item_type: null,
                words_count: null,
                description: '',
                guidelines_url: null,
                guidelines: '',
                guidelines_keywords: null,
                guidelines_link_allowed_in: null
              }
              this.$emit('success', r.data.data)
              this.toggleWidget()
            })
              .catch(() => {
                this.$message.error('An error occurred getting creating the content item');
                this.$emit('error')
              })
              .finally(() => {
                this.creating_content_item = false;
              })
          }
        });
      },
    },
    watch: {
      show: 'toggleWidget',
      should_show: 'toggleWidget'
    }
  }
</script>
