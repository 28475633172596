<template>
  <div v-loading="loading">
    <trieste-dialog :show.sync="show" :title="new_email_account.id ? 'Edit ' + new_email_account.email : 'Create a new email account'">
      <div class="p-6 pb-2">
        <el-form :rules="new_email_account_rules" :model="new_email_account" label-position="left" label-width="180px" ref="siteForm" v-loading="loading" autocomplete="off">
          <el-form-item label="Is gmail" prop="is_gmail">
            <el-switch v-model="new_email_account.is_gmail" @change="val => val ? new_email_account.is_microsoft = false : null" />
            <p class="leading-tight break-words">If this is a gmail account, you will be required to authenticate this account via google before it can be used to send/receive emails.</p>
          </el-form-item>
          <el-form-item label="Is microsoft" prop="is_microsoft">
            <el-switch v-model="new_email_account.is_microsoft" @change="val => val ? new_email_account.is_gmail = false : null"  />
            <p class="leading-tight break-words">If this is a microsoft/outlook/hotmail account, you will be required to authenticate this account via microsoft before it can be used to send/receive emails.</p>
          </el-form-item>
          <el-form-item label="First name" prop="first_name">
            <el-input type="text" v-model="new_email_account.first_name" />
          </el-form-item>
          <el-form-item label="Last name" prop="last_name">
            <el-input type="text" v-model="new_email_account.last_name" />
          </el-form-item>
          <el-form-item label="Email Address" prop="email">
            <el-input type="text" v-model="new_email_account.email" />
          </el-form-item>
          <el-form-item label="Username" prop="username" v-if="showAllInputFields">
            <el-input type="text" v-model="new_email_account.username" />
          </el-form-item>
          <el-form-item label="Password" prop="password" v-if="showAllInputFields">
            <el-input type="password" v-model="new_email_account.password" />
          </el-form-item>
          <el-form-item label="POP3 host" prop="pop3" v-if="showAllInputFields">
            <el-input type="text" v-model="new_email_account.pop3" />
          </el-form-item>
          <el-form-item label="POP3 port" prop="pop3_port" v-if="showAllInputFields">
            <el-input type="number" v-model="new_email_account.pop3_port" />
          </el-form-item>
          <el-form-item label="POP3 SSL" prop="pop3_ssl" v-if="showAllInputFields">
            <el-switch v-model="new_email_account.pop3_ssl" />
          </el-form-item>
          <el-form-item label="SMTP host" prop="smtp" v-if="showAllInputFields">
            <el-input type="text" v-model="new_email_account.smtp" />
          </el-form-item>
          <el-form-item label="SMTP port" prop="smtp_port" v-if="showAllInputFields">
            <el-input type="number" v-model="new_email_account.smtp_port" />
          </el-form-item>
          <el-form-item label="SMTP tls" prop="smtp_tls" v-if="showAllInputFields">
            <el-switch v-model="new_email_account.smtp_tls" />
          </el-form-item>
          <el-form-item label="Email Signature" prop="email_signature">
            <el-input type="textarea" v-model="new_email_account.email_signature" />
          </el-form-item>
          <el-form-item label="Append Email Signature" prop="append_greeting_signature">
            <el-switch v-model="new_email_account.append_greeting_signature" />
          </el-form-item>
          <el-form-item label="Enabled" prop="enabled">
            <el-switch v-model="new_email_account.enabled" />
          </el-form-item>
          <el-form-item label="Brand" prop="brand">
            <el-select v-model="new_email_account.email_account_brand_id" filterable>
              <el-option :value="0" label="None">None</el-option>
              <el-option v-for="(b, i) in brands" :value="b.id" :label="b.name" :key="i" />
            </el-select>
          </el-form-item>
          <el-form-item label="Owner" prop="Owner">
            <el-select v-model="new_email_account.owner_id" filterable>
              <el-option :value="null" label="None">None</el-option>
              <el-option v-for="(u, i) in users" :value="u.id" :label="`${u.firstname} ${u.lastname}`" :key="i" />
            </el-select>
          </el-form-item>
          <el-form-item label="Warmup email" prop="warmup">
            <el-select v-model="new_email_account.warmup">
              <el-option :value="true" label="Yes" />
              <el-option :value="false" label="No" />
            </el-select>
          </el-form-item>
          <el-form-item label="Can reply to warmup" prop="warmup_can_reply">
            <el-select v-model="new_email_account.warmup_can_reply">
              <el-option :value="true" label="Yes" />
              <el-option :value="false" label="No" />
            </el-select>
          </el-form-item>
          <el-form-item label="Auto add sites" prop="all_sites">
            <el-select v-model="new_email_account.all_sites">
              <el-option :value="true" label="Yes" />
              <el-option :value="false" label="No" />
            </el-select>
            <br/>
            <small class="leading-tight">If enabled, newly created company sites will automatically be added to the allowed list.</small>
          </el-form-item>
          <el-form-item label="Sites" prop="sites">
            <el-select v-model="new_email_account.site_ids" multiple filterable class="w-full">
              <el-option v-for="(site, i) in sites" :key="i" :value="site.id" :label="`${site.domain} (${site.client.name})`" />
            </el-select>
            <div class="text-right">
              <a href="#" class="font-medium text-xs text-blue-600" @click.prevent="selectAllSites">Select all sites</a>  /
              <a href="#" class="font-medium text-xs text-red-600" @click.prevent="unselectAllSites">Unselect all sites</a>
            </div>
          </el-form-item>
        </el-form>
      </div>
      <template v-slot:footer-left v-if="new_email_account.id">
        <el-button type="danger" size="small" icon="el-icon-delete" v-if="$store.getters['auth/hasPermission']('emailaccount.delete')" @click="deleteEmail(new_email_account)">Permanently delete this account</el-button>
      </template>
      <template v-slot:footer-right>
        <el-button @click="closeCreateEmailAccountDialog" icon="el-icon-close" type="danger" size="small">Cancel</el-button>
        <el-button type="success" icon="el-icon-edit-outline" @click="createEmailAccount" size="small">Confirm</el-button>
      </template>
    </trieste-dialog>
  </div>
</template>
<script>
import CreateGoogleAuthenticationLink from '@/components/mixins/create_google_authentication_link'
import { formatResponseErrorMessage } from "@/utils/helpers";
  export default {
    mixins: [CreateGoogleAuthenticationLink],
    props: {
      show: {
        type: Boolean,
        default: null
      },
      account: {
        type: Object,
        default: () => {
          return {
            id: null,
            first_name: null,
            last_name: null,
            email: null,
            username: null,
            password: null,
            pop3: null,
            pop3_port: null,
            pop3_ssl: null,
            smtp: null,
            smtp_port: null,
            smtp_tls: null,
            email_signature: null,
            append_greeting_signature: true,
            enabled: true,
            all_sites: true,
            is_gmail: false,
            is_microsoft: false,
            email_account_brand_id: 0,
            owner_id: null,
            warmup: false,
            warmup_can_reply: true
          }
        }
      }
    },
    data() {
      return {
        loading: false,
        new_email_account: this.account,
        sites: [],
        brands: [],
        users: [],
        new_email_account_rules: {
          first_name: [
            {required: true, min: 3, trigger: 'blur', message: 'Please enter a first name'}
          ],
          last_name: [
            {required: true, min: 3, trigger: 'blur', message: 'Please enter a last name'}
          ],
          email: [
            {required: true, min: 3,  type: 'email', trigger: 'blur', message: 'Please enter a valid email address'}
          ],
          username: [
            {required: true, min: 3, trigger: 'blur', message: 'Please enter a username'}
          ],
          password: [
            {required: true, min: 3, trigger: 'blur', message: 'Please enter a password'}
          ],
          pop3: [
            {required: true, min: 3, trigger: 'blur', message: 'Please enter a pop3 host'}
          ],
          pop3_port: [
            {required: true, trigger: 'blur', message: 'Please enter a valid pop3 port'}
          ],
          smtp: [
            {required: true, min: 3, trigger: 'blur', message: 'Please enter a smtp  host'}
          ],
          smtp_port: [
            {required: true, trigger: 'blur', message: 'Please enter a valid smtp port'}
          ],
        },
      }
    },
    mounted() {
      this.getResources()
    },
    computed: {
      showAllInputFields() {
        return !this.new_email_account.is_gmail && !this.new_email_account.is_microsoft
      }
    },
    methods: {
      getResources() {
        const requests = [
          this.$http.get(`/v1/companies/${this.$store.state.company.company.id}/sites`, { params: { all: true } }),
          this.$http.get(`/v1/companies/${this.$store.state.company.company.id}/email-brands`, { params: { all: true } }),
        ]

        this.loading = true;
        this.$store.dispatch('users/loadAll', {}).then(r => {
          this.users = r
        }).finally(() => {
          this.$http.all(requests).then(this.$http.spread((sites, brands) => {
            this.sites = sites.data.data;
            this.brands = brands.data.data;
          }))
            .finally(() => {
              this.loading = false
            })
        })

      },
      deleteEmail(email) {
        if(email.id && email.email) {
          this.$confirm('Are you sure you want to delete the email account for '+email.email+'?', 'are you sure?', {
            confirmButtonClass: 'el-button--danger'
          })
            .then(() => {
              this.$http.delete( `/v1/companies/${this.$store.state.company.company.id}/email-accounts/${email.id}`)
                .then(() => {
                  this.$message.success('The email account has successfully been deleted.');
                  this.$router.push(`/company/${this.$store.state.company.company.id}/email-accounts`)
                })
                .catch(() => {
                  this.$message.error('The was an error processing that request. Please try again later.')
                })
            })
            .catch(() => {
              // do nothign
            })
        }
      },
      createEmailAccount() {
        this.$refs.siteForm.validate((valid) => {
          if(valid) {
            this.loading = true;

            let method = this.$http.post;
            let url = `/v1/companies/${this.$store.state.company.company.id}/email-accounts`;

            if(this.new_email_account.id) {
              url += `/${this.new_email_account.id}`;
              method = this.$http.put;
            }

            method(url, this.new_email_account)
              .then((r) => {
                this.$message.success('Successfully '+(this.new_email_account.id ? 'updated':'created')+' the email account.');
                if((this.new_email_account.is_gmail || this.new_email_account.is_microsoft) && !this.new_email_account.id) {
                  this.$confirm('Before you can send or receive emails using this account, you need to fixed authenticate it. Would you like to do so now?',
                    `Authenticate ${this.new_email_account.is_microsoft ? 'microsoft' : 'gmail'} account now?`
                  )
                  .then(() => {
                    if(this.new_email_account.is_gmail) {
                      window.location.href = this.createGoogleAuthenticationLink(
                        this.new_email_account.email,
                        r.data.id || this.new_email_account.id,
                        this.$store.state.company.company.id,
                        r.data.client.client_id,
                        '/google-sign-in',
                        r.data.client.client_host
                      )
                    } else {
                      window.location.href = this.createMicrosoftAuthenticationLink(this.new_email_account.email, this.$store.state.company.company.id, r.data.id || this.new_email_account.id)
                    }
                  })
                  .catch((e) => {
                    //console.log('ANd i didnt?', e)
                    // Do nothing for now
                  })
                }
                this.closeCreateEmailAccountDialog()
                this.$emit('success')
              })
              .catch((err) => {
                let message = formatResponseErrorMessage(err, 'createEmailAccount')
                this.$message.error(message)
                console.error(err.toJSON(), message)
              })
              .finally(() => {
                this.loading = false;
              })
          }
        })
      },
      closeCreateEmailAccountDialog() {
        this.$emit('update:show', false)
        this.new_email_account = this.account
      },
      selectAllSites () {
        this.new_email_account.site_ids = this.sites.map(s => s.id)
      },
      unselectAllSites () {
        this.new_email_account.site_ids = [];
      }
    }
  }
</script>
