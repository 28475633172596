<template>
  <div v-loading="loading">
    <trieste-card title="Heat" :collapsable="true" :collapsed="collapsed">
      <div class="p-4" v-if="!loading">
        <div class="heat-list">
          <a href="#" class="cold" @click="updateHeatRating('cold')" :class="{active: heat_rating === 'cold'}">Cold</a>
          <a href="#" class="warm" @click="updateHeatRating('warm')" :class="{active: heat_rating === 'warm'}">Warm</a>
          <a href="#" class="hot" @click="updateHeatRating('hot')" :class="{active: heat_rating === 'hot'}">Hot</a>
        </div>
      </div>
    </trieste-card>
  </div>
</template>
<script>
  export default {
    props: {
      value: {
        type: Object,
        default: () => {}
      },
      collapsed: {
        type: Boolean,
        default: false
      },
    },
    data() {
      return {
        loading: false,
        heat_rating: this.value.heat_rating
      }
    },
    mounted() {
    },
    methods: {
      updateHeatRating(heat) {
        this.loading = true;
        this.heat_rating = heat;
        const params = {
          heat_rating: this.heat_rating,
        }
        this.$http.put(
          `/v1/companies/${this.$store.state.company.company.id}/links/${this.$route.params.linkId}`, params
        ).then(() => {
          this.$message.success('Successfully updated the heat rating.');
        })
          .catch((e) => {
            this.$message.error('An error occurred updating the heat rating.');
          })
          .finally(() => {
            this.loading = false
          })
      },
    }
  }
</script>
<style lang="scss" scoped>
  .heat-list {
    a {
      @apply text-sm font-medium border-2 py-2 px-4 rounded-full mr-2 text-gray-500;

      &.cold {
        &:hover, &.active {
          @apply text-blue-600 border-blue-600;
        }
      }

      &.warm {
        &:hover, &.active {
          @apply text-yellow-600 border-yellow-600;
        }
      }

      &.hot {
        &:hover, &.active {
          @apply text-red-600 border-red-600;
        }
      }
    }
  }
</style>
