<template>
  <div class="flex flex-col justify-center items-center flex-grow">
    <h1 class="text-4xl">404: Page not found.</h1>
    <div class="w-48 my-4">
      <svg viewBox="0 0 380 224" version="1.1" xmlns="http://www.w3.org/2000/svg"
           xmlns:xlink="http://www.w3.org/1999/xlink">
        <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
          <g transform="translate(-817.000000, -124.000000)">
            <g transform="translate(817.000000, 123.000000)">
              <g transform="translate(33.000000, 0.808000)">
                <polygon points="0.1757 0.192 307.3307 0.192 307.3307 201.3973 0.1757 201.3973"></polygon>
                <path id="Fill-1"
                      d="M307.3307,201.3973 L0.1757,201.3973 L0.1757,8.3823 C0.1757,3.8583 3.8427,0.1913 8.3667,0.1913 L299.1397,0.1913 C303.6637,0.1913 307.3307,3.8583 307.3307,8.3823 L307.3307,201.3973 Z"
                      fill="#347AD5"></path>
              </g>
              <g transform="translate(0.000000, 202.808000)">
                <polygon id="Clip-5" points="0 0.3969 379.506 0.3969 379.506 21.9999 0 21.9999"></polygon>
                <path id="Fill-4"
                      d="M368.7047,21.9999 L10.8017,21.9999 C4.8357,21.9999 -0.0003,17.1639 -0.0003,11.1989 C-0.0003,5.2329 4.8357,0.3969 10.8017,0.3969 L368.7047,0.3969 C374.6707,0.3969 379.5067,5.2329 379.5067,11.1989 C379.5067,17.1639 374.6707,21.9999 368.7047,21.9999"
                      fill="#347AD5"></path>
              </g>
              <path id="Fill-7"
                    d="M326.6373,192.1179 L46.8693,192.1179 C43.8303,192.1179 41.3663,189.6549 41.3663,186.6159 L41.3663,15.8189 C41.3663,12.7799 43.8303,10.3159 46.8693,10.3159 L326.6373,10.3159 C329.6763,10.3159 332.1393,12.7799 332.1393,15.8189 L332.1393,186.6159 C332.1393,189.6549 329.6763,192.1179 326.6373,192.1179"
                    fill="#FFFFFF"></path>
              <path id="Fill-9"
                    d="M165.1153,105.5593 C164.1023,105.5593 163.0903,105.1723 162.3173,104.4003 L138.2823,80.3653 C136.7373,78.8193 136.7373,76.3143 138.2823,74.7693 C139.8273,73.2243 142.3333,73.2243 143.8783,74.7693 L167.9133,98.8053 C169.4583,100.3503 169.4583,102.8553 167.9133,104.4003 C167.1403,105.1723 166.1283,105.5593 165.1153,105.5593"
                    fill="#347AD5"></path>
              <path id="Fill-11"
                    d="M141.0802,105.5593 C140.0672,105.5593 139.0552,105.1723 138.2822,104.4003 C136.7372,102.8553 136.7372,100.3503 138.2822,98.8053 L162.3172,74.7693 C163.8622,73.2243 166.3682,73.2243 167.9132,74.7693 C169.4582,76.3143 169.4582,78.8193 167.9132,80.3653 L143.8782,104.4003 C143.1052,105.1723 142.0932,105.5593 141.0802,105.5593"
                    fill="#347AD5"></path>
              <path id="Fill-13"
                    d="M235.0377,105.5593 C234.0247,105.5593 233.0127,105.1723 232.2397,104.4003 L208.2047,80.3653 C206.6597,78.8193 206.6597,76.3143 208.2047,74.7693 C209.7497,73.2243 212.2557,73.2243 213.8007,74.7693 L237.8357,98.8053 C239.3807,100.3503 239.3807,102.8553 237.8357,104.4003 C237.0627,105.1723 236.0507,105.5593 235.0377,105.5593"
                    fill="#347AD5"></path>
              <path id="Fill-15"
                    d="M211.0025,105.5593 C209.9895,105.5593 208.9775,105.1723 208.2045,104.4003 C206.6595,102.8553 206.6595,100.3503 208.2045,98.8053 L232.2395,74.7693 C233.7845,73.2243 236.2905,73.2243 237.8355,74.7693 C239.3805,76.3143 239.3805,78.8193 237.8355,80.3653 L213.8005,104.4003 C213.0275,105.1723 212.0155,105.5593 211.0025,105.5593"
                    fill="#347AD5"></path>
              <path id="Fill-17"
                    d="M176.9864,145.7629 C174.0814,145.7629 170.7674,144.3409 166.8234,142.5079 C166.4884,142.3519 166.1644,142.2009 165.8544,142.0599 L165.6274,141.9949 C163.4604,141.3749 161.7464,140.8869 158.2404,144.0439 C156.6164,145.5059 154.1144,145.3739 152.6534,143.7509 C151.1904,142.1269 151.3214,139.6239 152.9454,138.1619 C159.7084,132.0729 164.9744,133.5779 167.8004,134.3869 L168.2624,134.5159 C168.4654,134.5719 168.6624,134.6429 168.8524,134.7299 C169.2614,134.9149 169.7004,135.1179 170.1584,135.3309 C171.8404,136.1139 175.7814,137.9399 177.0314,137.8479 C179.6274,137.5339 180.8304,136.9309 182.2234,136.2319 C184.0764,135.3029 186.1774,134.2489 190.2284,134.2489 C194.4534,134.2489 196.5134,135.2889 198.4174,136.4489 C199.4724,137.0909 200.0544,137.4429 202.3544,137.3599 C203.7014,137.3099 205.1064,136.7759 206.7314,136.1569 C210.6614,134.6609 216.0444,132.6109 222.8524,137.5619 C224.6194,138.8479 225.0104,141.3229 223.7254,143.0899 C222.4394,144.8569 219.9654,145.2459 218.1974,143.9619 C214.8564,141.5319 212.9694,142.2489 209.5454,143.5529 C207.5544,144.3109 205.2974,145.1699 202.6474,145.2679 C198.3024,145.4239 196.2344,144.3849 194.3024,143.2079 C193.2304,142.5559 192.5844,142.1619 190.2284,142.1619 C188.0504,142.1619 187.2404,142.5689 185.7694,143.3059 C184.0654,144.1599 181.9444,145.2239 177.9704,145.7049 C177.6484,145.7439 177.3194,145.7629 176.9864,145.7629"
                    fill="#347AD5"></path>
            </g>
          </g>
        </g>
      </svg>
    </div>
    <p>Sorry, but we can't seem to find that resource.</p>
    <p>If you think this may be an error, please contact us.</p>
  </div>
</template>
<script>
  export default {
  }
</script>
