<template>
  <div>
    <trieste-dialog :show.sync="show" :title="new_client.id ? 'Edit ' + new_client.name : 'Create a client'">
      <div class="p-6 pb-2">
        <el-form :rules="new_client_rules" :model="new_client" label-position="left" label-width="120px" ref="siteForm" v-loading="loading">
          <el-form-item label="Name" prop="name">
            <el-input type="text" v-model="new_client.name" />
          </el-form-item>
        </el-form>
      </div>
      <template v-slot:footer-right class="dialog-footer">
        <el-button @click="closeCreateClientDialog" type="danger" icon="el-icon-close" size="small">Cancel</el-button>
        <el-button type="success" size="small" icon="el-icon-edit-outline" @click="createClient">Create</el-button>
      </template>
    </trieste-dialog>
  </div>
</template>
<script>
  import { formatResponseErrorMessage } from "@/utils/helpers";
  export default {
    props: {
      show: {
        type: Boolean,
        default: false
      },
      client: {
        type: Object,
        default: null
      }
    },
    data() {
      return {
        loading: false,
        new_client: this.client || {
          id: null,
          name: ''
        },
        new_client_rules: {
          name: [
            {required: true, min: 3, trigger: 'blur', message: 'Please enter a name'}
          ],
        },
      }
    },
    methods: {
      createClient() {
        this.$refs.siteForm.validate((valid) => {
          if(valid) {
            this.loading = true;

            let method = this.$http.post;
            let url = `/v1/companies/${this.$store.state.company.company.id}/clients`;

            if(this.new_client.id) {
              url += `/${this.new_client.id}`;
              method = this.$http.put;
            }

            method(url, this.new_client)
              .then((res) => {
                this.$message.success('Successfully '+(this.new_client.id ? 'updated':'created')+' the client.');
                this.closeCreateClientDialog();
                this.$emit('success', res.data)
              })
              .catch((err) => {
                let message = formatResponseErrorMessage(err, 'createClient')
                this.$message.error(message)
                console.error(err.toJSON(), message)
                this.$emit('error', err)

              })
              .finally(() => {
                this.loading = false;
              })
          }
        })
      },
      closeCreateClientDialog() {
        this.new_client = {
          id: null,
          name: ''
        };
        this.$emit('update:show', false)
      },
    }
  }
</script>
