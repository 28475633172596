<template>
  <div v-loading="loading">
  <trieste-table
      :end-point="`/v1/companies/${$store.state.company.company.id}/content`"
      :fields="fields"
      title="Content"
      item-type="content items"
      :show-title="false"
      ref="table"
      saved-table-sort-name="approval-content-index"
      :params="params"
    >
      <template v-slot:title-slot="props">
        <span>{{props.row[props.rowField.field] || '{untitled}'}}</span>
      </template>
      <template v-slot:value-slot="props">
        <el-button type="primary" @click="openContentPreview(props.row)" v-if="props.row[props.rowField.field]" size="small">Preview Content</el-button>
        <span v-else>N/A</span>
      </template>
      <template v-slot:owner-slot="props">
        <div>
          <div v-if="props.row.owner">
            {{ `${props.row.owner.firstname} ${props.row.owner.lastname} (${props.row.owner.email})` }}
          </div>
          <div v-else>
            <span class="text-xs">not assigned.</span>
          </div>
        </div>
      </template>
      <template v-slot:sitelink-slot="props">
        <div>
          <a
            :href="getOutboundSafeUrl(props.row.site_link.from_url)" v-if="props.row.site_link" target="_blank">
            <div class="flex flex-row items-center font-bold">
              {{ props.row.site_link.from_url }}
            </div>
          </a>
          <div v-else>
            <span class="text-gray-400">N/A</span>
          </div>
        </div>
      </template>
      <template v-slot:target-slot="props">
        <div>
          <div v-if="props.row.site_target" class="flex flex-row items-center font-bold">
            {{ props.row.site_target.name }}
          </div>
          <div v-else>
            <span class="text-gray-400">N/A</span>
          </div>
        </div>
      </template>
      <template v-slot:actions-slot="props">
        <el-button type="primary" size="small" @click="approveContentItem(props.row)">Approve</el-button>
        <el-button type="danger" size="small" @click="rejectContentItem(props.row)">Reject</el-button>
      </template>
    </trieste-table>
    <trieste-dialog :show.sync="showContentPreview" width="70%" title="Preview">
      <div class="ql-snow">
        <div class="bg-white p-4 text-black ql-editor" v-html="content_preview"></div>
      </div>
      <template v-slot:footer-right>
        <el-button type="danger" size="small" icon="el-icon-close" @click="showContentPreview=false">Close</el-button>
      </template>
    </trieste-dialog>
  </div>
</template>
<script>
export default {
  data: function() {
    return {
      content_preview: null,
      showContentPreview: false,
      defaultFilters: {},
      loading: false,
      query: {
        rpp: 20,
        page: 1
      },
      attaching_link: false,
      search_params: {
        created_by_id: [],
        owner_id: [],
        site_target_id: [],
        site_id: [],
        status: [],
        type: [],
      },
      fields: [
        {
          title: 'ID',
          name: 'id',
          formatter: (val) => val + ''
        },
        {
          name: 'title-slot',
          title: 'title',
          field: 'title',
          sortField: 'title',
          width: '300px',
          size: 'large'
        },
        {
          name: 'value-slot',
          title: 'Content',
          field: 'body',
          sortField: 'body',
          width: '300px',
          size: 'large'
        },
        {
          name: 'sitelink-slot',
          title: 'Url',
        },
        {
          name: 'target-slot',
          title: 'Target',
          size: 'small'
        },
        {
          name: 'client_note',
          title: 'Note'
        },
        {
          title: '',
          name: 'actions-slot',
          fixed: 'right',
          width: '200px'
        },
      ],
      params: {
        filter_params: {
          status: ["awaiting_client_approval"]
        }
      }
    }
  },
  methods: {
    refreshTable() {
      this.loading = true;
      this.$refs.table.getData();
    },
    openContentPreview (content) {
      this.content_preview = content.body;
      this.showContentPreview = true
    },
    approveContentItem(content) {
      this.$confirm('Are you sure you want to approve this content item?')
        .then(() => {
          this.updateContentItem(content.id, 'client_approved')
        })
        .catch(() => {})
    },
    rejectContentItem(content) {
      this.$prompt('Please enter a reject reason', 'Reject content', {
        confirmButtonText: 'Confirm reject',
        cancelButtonText: 'Cancel',
        inputPattern: /[a-zA-Z0-9]+/,
        inputErrorMessage: 'Please enter a rejection reason'
      }).then(({ value }) => {
        this.updateContentItem(content.id, 'client_declined', value)
      }).catch(() => {});
    },
    updateContentItem(contentId, status, reject_reason) {
      this.loading = true;
      this.$http.put(`/v1/companies/${this.$store.state.company.company.id}/external-approver/content/${contentId}`, {
        status,
        reject_reason
      }).then(() => {
        this.$message.success('Successfully updated the content item.')
        this.refreshTable()
      }).catch(() => {
        this.$message.error('An error occurred updating the content item')
      })
      .finally(() => {
        this.loading = false
      })
    }
  }
}
</script>
