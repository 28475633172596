<template>
  <div v-loading="loading">
    <div class="page-title">
      <h1>
        Users
      </h1>
    </div>
    <trieste-table
      item-type="users"
      title="Users"
      :end-point="'/v1/companies/' + $store.state.company.company.id + '/users'"
      :fields="fields"
      ref="table"
      default-sort-prop="email"
      :filters="filters"
      :show-title="false"
      saved-table-sort-name="company-users"
    >
      <template v-slot:actions-slot="props">
        <a :href="`/company/${$route.params.companyId}/users/${props.row.id}`" v-if="$store.getters['auth/hasPermission']('user.read')">
        <el-button
          size="small"
          icon="el-icon-edit"
          type="primary">
          View
        </el-button>
        </a>
      </template>
    </trieste-table>
  </div>
</template>
<script>
  export default {
    mounted() {
      if(!this.$store.getters['auth/hasPermission']('user.read')) {
        this.$message.error('You do not have permissions to view company users');
        this.$router.push('/');
      } else {
        this.$store.dispatch('company/getCompany', this.$route.params).then(company => {
          this.$store.commit('global/setBreadcrumbs', [
            {
              path: '/',
              label: company.name
            },
            {
              label: 'Users'
            }
          ])
        })
      }
    },
    data: function () {
      return {
        query: {
          rpp: 20,
          page: 1
        },
        loading: false,
        fields: [
          {
            name: 'firstname',
            title: 'firstname',
            sortField: 'firstname',
          },
          {
            name: 'lastname',
            title: 'lastname',
            sortField: 'lastname'
          },
          {
            name: 'email',
            title: 'email',
            sortField: 'email'
          },
          {
            title: '',
            name: 'actions-slot',
            width: '110px',
            fixed: 'right'
          },
        ],
        filters: [
          {
            label: 'First name',
            name: 'first_name'
          },
          {
            label: 'Last name',
            name: 'last_name'
          },
          {
            label: 'Email',
            name: 'email'
          }
        ]
      }
    }
  }
</script>
