<template>
  <div v-loading="loading" class="min-h-40">
    <List :endpoint="$attrs.endpoint" :resource="resource" />
  </div>
</template>
<script>
  import List from "../../components/admin/List";
  export default {
    components: {List},
    mounted() {
      if(!this.$store.getters['auth/hasPermission']('admin')) {
        this.$message.error('You do not have permissions to view the admin section.');
        this.$router.push('/');
      }
    },
    data() {
      return {
        ready: false,
        loading: false,
        resource: this.$attrs.resource,
        relations: {},
      }
    }
  }
</script>
