<template>
  <div v-loading="loading">
    <div>
      <trieste-card title="Site Targets">
        <template v-slot:title>
          <div>
            <div><span class="text-base font-bold text-blue-800">Site Targets</span></div>
            <div><el-input v-model="search" size="mini"  placeholder="filter targets" clearable /></div>
          </div>
        </template>
        <div class="p-4 overflow-y-auto" style="max-height: 400px" :key="reindexKey">
          <div class="columns">
            <div
              class="column mb-0" :class="{['w-' + defaultColumn]: true}"
              v-for="(st, i) in allowedSiteTargets" :key="i">
              <div class="border-b p-1 -mt-3 text-xs font-medium">
                <div class="flex flex-row">
                  <el-checkbox v-model="selectedValues[st.id]" @change="optionSelected" />
                  <span class="ml-4  w-full break-all">{{ st.name }} - {{ st.status }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </trieste-card>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    value: {
      type: Array,
      default: () => []
    },
    defaultColumn: {
      type: [String, Number],
      default: '1/4'
    },
    includeSuspended: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      site_targets: [],
      loading: false,
      val: this.value || [],
      selectedValues: {},
      selectedAll: false,
      reindexKey: 0,
      search: ''
    }
  },
  computed: {
    allowedSiteTargets() {
      return this.site_targets.filter(p => {
        if(!this.includeSuspended && p.status !== 'active') return false
        if(this.search) return p.name.toLowerCase().includes(this.search.toLowerCase());
        return true;
      })
    }
  },
  mounted() {
    this.getSiteTargets()
    this.syncDefaultValues()
  },
  methods: {
    onSuspendedChange () {
      if(!this.includeSuspended) {
        Object.keys(this.selectedValues).forEach(key => {
          if(this.selectedValues[key] && this.site_targets.find(p => p.id === this.selectedValues[key]).status !== 'active') {
            this.selectedValues[key] = false
          }
        })
        this.optionSelected()
      }
    },
    unSelectAll () {
      this.selectedValues = {}
      this.val = []
      this.optionSelected()
      this.reindexKey++;
    },
    syncDefaultValues() {
      this.selectedValues = {}
      for(const v of this.value) {
        this.selectedValues[v] = true
      }
    },
    optionSelected() {
      this.val = Object.keys(this.selectedValues).filter(key => this.selectedValues[key]).map(key => key / 1)
      this.$emit('input', this.val)
    },
    getSiteTargets() {
      this.loading = true;
      this.$http.get(`/v1/companies/${this.$store.state.company.company.id}/targets`, {
        params: {
          all: true,
        }
      }).then(res => {
        this.site_targets = res.data.data
      }).catch(() => {
        this.$message.error('Failed to load the company site targets.')
      }).finally(() => {
        this.loading = false
      })
    },
    setTargetsBySiteIds(siteIds) {
      const sIds = Array.isArray(siteIds) ? siteIds : [siteIds]
      const selected = this.site_targets.filter(st => sIds.includes(st.site_id)).map(st => st.id)
      this.selectedValues = {}
      for(const s of selected) {
        this.selectedValues[s] = true
      }
      this.optionSelected()
    }
  },
  watch: {
    value: 'syncDefaultValues',
    includeSuspended: 'onSuspendedChange'
  }
}
</script>
