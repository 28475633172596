const timezone = require('@/json/timezones.json')

module.exports = {
  name: 'Users',
  single_name: 'user',
  fields: [
    {
      name: 'email',
      sortField: 'email',
      filterable: true,
      rules: [
        { required: true, type: 'email', message: 'Please enter a valid email'}
      ]
    },
    {
      name: 'firstname',
      sortField: 'firstname',
      filterable: true,
      rules: [
        { required: true, min: 2, message: 'Please enter a valid firstname'}
      ]
    },
    {
      name: 'lastname',
      sortField: 'lastname',
      filterable: true,
      rules: [
        { required: true, min: 2, message: 'Please enter a valid lastname'}
      ]
    },
    {
      name: 'status_code',
      label: 'Status',
      type: 'select',
      options: [
        { value: 0, label: 'Unconfirmed' },
        { value: 1, label: 'Confirmed' },
        { value: 2, label: 'Banned' },
        { value: 3, label: 'Site Contact' }
      ],
      filterSelectOptions: [
        { value: 0, label: 'Unconfirmed' },
        { value: 1, label: 'Confirmed' },
        { value: 2, label: 'Banned' },
        { value: 3, label: 'Site Contact' }
      ],
      filterable: true
    },
    {
      name: 'time_zone',
      sortField: 'time_zone',
      label: 'Timezone',
      type: 'select',
      options: timezone
    },
    {
      name: 'password',
      type: 'password',
      showOnIndex: false,
      hideOnCreate: true,
      rules: [
        { required: true, min: 8, message: 'Please enter a password with at least 8 characters'}
      ]
    }
  ],
  includes: [
    {
      model: 'company',
      type: 'manyThrough',
      through: 'companyUser'
    },
    {
      model: 'role',
      type: 'manyThrough',
      through: 'roleUser',
      // note: 'Please note that if the user has the role \'External Link Approver\' active, all other roles are ignored.'
    },
    /*{
      model: 'client',
      type: 'manyThrough',
      through: 'approvalClientUser',
      note: 'Please note selected client list is only used if the user has the role \'External Link Approver\' active.',
      listDisplayName: (val) => {
        return `${val.name} (${val.company ? val.company.name : 'unset'})`;
      }
    },*/
    {
      model: 'group',
      type: 'manyThrough',
      through: 'userParticipation'
    }
  ]
}
