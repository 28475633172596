module.exports = {
  name: 'Roles',
  single_name: 'role',
  fields: [
    {
      name: 'title',
      sortField: 'title',
      filterable: true,
      rules: [
        { required: true, min: 3, message: 'Please enter a valid role title.'}
      ]
    }
  ],
  includes: [
    {
      model: 'user',
      type: 'manyThrough',
      through: 'roleUser'
    },
    {
      model: 'permission',
      type: 'manyThrough',
      through: 'rolePermission'
    }
  ]
}
