module.exports = {
  name: 'Assigned user groups',
  resource_name: 'user_participations',
  single_name: 'assigned user group',
  showOnMenu: false,
  fields: [
    {
      name: 'user_id',
      label: 'user',
      model: 'user',
      sortField: 'user.firstname',
      filterable: true,
      val: (val) => {
        return val ? `${val.firstname} ${val.lastname}` : null
      },
      rules: [
        { required: true, message: 'Please select a user.' }
      ]
    },
    {
      name: 'users_group_id',
      label: 'Group',
      model: 'group',
      sortField: 'group.name',
      filterable: true,
      val: (val) => {
        return val ? val.name : null
      },
      rules: [
        { required: true, message: 'Please select a group.' }
      ]
    },
    {
      name: 'created_at',
      label: 'Created at',
      sortField: 'created_at',
      type: 'date',
      filterable: true,
      hideOnCreate: true,
    },
    {
      name: 'updated_at',
      label: 'Updated at',
      sortField: 'updated_at',
      type: 'date',
      filterable: true,
      hideOnCreate: true,
    },
  ]
}
