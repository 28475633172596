<template>
  <div>
    <trieste-dialog :show="show" title="Create lead">
      <div class="p-4" v-loading="loading">
        <el-form label-width="120px" label-position="left" ref="form" :model="lead" :rules="form_rules">
          <el-form-item label="Client" prop="client" v-if="false">
            <el-select v-model="lead.client" :loading="$store.state.client.loading_all_clients" filterable class="w-full" @change="clientChanged">
              <el-option v-for="(client, i) in $store.state.client.clients" :value="client.id" :label="client.name" :key="i" />
            </el-select>
            <div class="text-right">
              <el-button size="mini" type="primary" icon="el-icon-edit-outline" @click="showClientCreate = true">Create client</el-button>
            </div>
          </el-form-item>
          <el-form-item label="Site" prop="site" v-if="false">
            <el-select v-model="lead.site" :loading="$store.state.site.loading_all_sites" :disabled="!lead.client" filterable class="w-full" @change="siteChanged">
              <el-option v-for="(site, i) in getSites" :key="i" :value="site.id" :label="site.base_url" />
            </el-select>
            <div class="text-right">
              <el-button size="mini" type="primary" icon="el-icon-edit-outline" v-if="lead.client" @click="showSiteCreate = true">Create site</el-button>
            </div>
          </el-form-item>
          <el-form-item label="Campaign" prop="campaign" v-if="false">
            <el-select v-model="lead.campaign" :loading="loading_campaigns" :disabled="!lead.client || !lead.site" filterable class="w-full" @change="campaignChanged">
              <el-option v-for="(campaign, i) in campaigns" :key="i" :value="campaign.id" :label="campaign.name" />
            </el-select>
            <div class="text-right">
              <el-button size="mini" type="primary" icon="el-icon-edit-outline" v-if="lead.client && lead.site" @click="showCampaignCreate = true">Create campaign</el-button>
            </div>
          </el-form-item>
          <el-form-item label="Batch" prop="batch" v-if="false">
            <el-select v-model="lead.batch" :loading="loading_batches" :disabled="!lead.client || !lead.site || !lead.campaign" filterable class="w-full">
              <el-option v-for="(batch, i) in batches" :key="i" :value="batch.id" :label="batch.name" />
            </el-select>
            <div class="text-right" v-if="lead.campaign">
              <a :href="`/company/${$store.state.company.company.id}/campaigns/${lead.campaign}/batch/create`" target="_blank">
                <el-button size="mini" type="primary" icon="el-icon-edit-outline">Create lead</el-button>
              </a>
            </div>
          </el-form-item>
          <el-form-item label="Lead url" prop="url">
            <el-input type="url" v-model="lead.url" />
          </el-form-item>
          <el-form-item label="Lead status" prop="status">
            <el-select
              v-model="lead.status"
              class="w-full"
              filterable
            >
              <el-option
                v-for="(o, i) in $store.state.link.statuses"
                :key="i"
                :value="o.value"
                :label="o.label"
              />
            </el-select>
          </el-form-item>
          <el-form-item label="Lead type" prop="type">
            <el-select
              v-model="lead.type"
              class="w-full"
              filterable
            >
              <el-option
                v-for="(o, i) in $store.state.link.types"
                :key="i"
                :value="o.value"
                :label="o.label"
              />
            </el-select>
          </el-form-item>
        </el-form>
      </div>
      <template v-slot:footer-right class="dialog-footer">
        <el-button @click="$emit('update:show', false)" type="danger" icon="el-icon-close" size="small">Cancel</el-button>
        <el-button type="success" size="small" icon="el-icon-edit-outline" @click="create">
          Create lead
        </el-button>
      </template>
    </trieste-dialog>
    <client-quick-create :show.sync="showClientCreate" @success="setNewClient" />
    <site-create-widget :show.sync="showSiteCreate" @updated="setNewSite" :client-id="lead.client" v-if="lead.client" />
    <campaign-create-widget :show.sync="showCampaignCreate" @success="setNewCampaign" :client-id="lead.client" :site-id="lead.site" v-if="lead.client && lead.site" />
  </div>
</template>
<script>
  import ClientQuickCreate from '@/components/views/client/CreateEdit';
  import SiteCreateWidget from '@/components/views/Sites/CreateEdit';
  import CampaignCreateWidget from '@/components/views/Campaign/CreateEdit';
  export default {
    components: { ClientQuickCreate, SiteCreateWidget, CampaignCreateWidget },
    name: 'batch-quick-create',
    props: {
      show: {
        type: Boolean,
        default: false
      },
      clientId: {
        type: [String, Number],
        default: null
      },
      siteId: {
        type: [String, Number],
        default: null
      },
    },
    data() {
      return {
        lead: {
          client: this.clientId,
          site: this.siteId,
          campaign: null,
          batch: null,
          status: 'new',
          type: null,
          url: ''
        },
        loading_campaigns: false,
        campaigns: [],
        batches: [],
        form_rules: {
          client: [
            { required: true, message: 'Please select a client' }
          ],
          site: [
            { required: true, message: 'Please select a client' }
          ],
          campaign: [
            { required: true, message: 'Please select a campaign' }
          ],
          batch: [
            { required: true, message: 'Please select a batch' }
          ],
          status: [
            { required: true, message: 'Please select a lead status' }
          ],
          type: [
            { required: true, message: 'Please select a lead type' }
          ],
          url: [
            { required: true, type: 'url', message: 'Please enter a lead URL' }
          ]
        },
        showClientCreate: false,
        showSiteCreate: false,
        showCampaignCreate: false,
        loading_batches: false,
        loading: false
      }
    },
    computed: {
      getSites() {
        if(!this.lead.client) {
          return this.$store.state.site.sites;
        }
        return this.$store.state.site.sites.filter(site => site.client_id === this.lead.client)
      }
    },
    mounted() {
      this.$store.dispatch('client/loadAllClients', {}).then(() => {
        this.$store.dispatch('site/loadAllSites', {}).then(() => {
          if(this.clientId && this.siteId) {
            this.getCampaignsList()
          }
        })
      })
    },
    methods: {
      clientChanged() {
        this.lead.site = null
        this.lead.campaign = null
        this.lead.batch = null
      },
      siteChanged() {
        this.lead.campaign = null
        this.lead.batch = null
        if(this.lead.client) {
          this.getCampaignsList()
        }
      },
      campaignChanged() {
        this.lead.batch = null
        if(this.lead.campaign) {
          this.getBatchList()
        }
      },
      setNewClient(client) {
        this.$store.dispatch('client/loadAllClients', { force: true });
        this.lead.client = client.id
        this.lead.site = null
        this.lead.campaign = null
      },
      setNewSite(site) {
        this.$store.dispatch('site/loadAllSites', { force: true });
        this.lead.site = site.id
        this.lead.campaign = null
      },
      setNewCampaign(campaign) {
        this.lead.campaign = campaign.id;
        this.getCampaignsList();
      },
      createBatch() {
        this.$router.push(`/company/${this.$store.state.company.company.id}/campaigns/${this.lead.campaign}/batch/create`);
      },
      getCampaignsList() {
        if(this.lead.client && this.lead.site) {
          this.loading_campaigns = true;
          this.$http.get(`/v1/companies/${this.$store.state.company.company.id}/campaigns`, {
            params: {
              all: true,
              site_id: this.lead.site
            }
          }).then(response => {
            this.campaigns = response.data.data;
          })
          .catch(() => {
            this.$message.error('Failed to load the campaigns list.')
          })
          .finally(() => {
            this.loading_campaigns = false
          })
        }
      },
      getBatchList() {
        if(this.lead.client && this.lead.site && this.lead.campaign) {
          this.loading_batches = true;
          this.$http.get(`/v1/companies/${this.$store.state.company.company.id}/batches`, {
            params: {
              campaign_id: this.lead.campaign,
              all: true
            }
          }).then((r) => {
            this.batches = r.data.data
          })
          .catch((e) => {
            //console.log('An error occurred trying to get the batch list.')
            console.error(e)
          })
          .finally(() => {
            this.loading_batches = false
          })
        }
      },
      create() {
        this.$refs.form.validate(valid => {
          if(valid) {
            // Go to the batch configuration
            this.loading = true
            this.$http.post(`/v1/companies/${this.$store.state.company.company.id}/sites/${this.siteId}/manual-lead`, this.lead).then((r) => {
              this.$message.success('Successfully created the site link')
              setTimeout(() => {
                this.$router.push(`/company/${this.$store.state.company.company.id}/leads/${r.data.id}`)
              }, 200)
            })
            .catch((e) => {
              console.error(e)
              this.$message.error('Failed to create the lead')
            })
            .finally(() => {
              this.loading = false
            })
          }
        })
      }
    }
  }
</script>
