<template>
  <div class="-mx-8 -my-6 h-full">
    <create-edit :id="$route.params.projectId" />
  </div>
</template>
<script>
  import CreateEdit from "@/components/views/Project/CreateEdit";
  export default {
    name: 'ViewProject',
    components: { CreateEdit },
    data() {
      return {
        loading: true,
        show_settings: false,
        updating_settings: false,
        first_load: true,
        project: {},
        company: Object.assign({}, this.$store.state.company.company),
        settings_rules: {
          name: [
            {required: true, min: 3, message: 'Please enter a valid name', trigger: 'blur'}
          ],
        }
      }
    },
    mounted() {
      //console.log('hello?')
      this.$store.dispatch('company/getCompany', this.$route.params).then(company => {
        this.$store.dispatch('project/getProject', this.$route.params).then(project => {
          this.$store.dispatch('client/getClient', {clientId: project.client_id}).then((client) => {
            this.$store.commit('global/setBreadcrumbs', [
              {
                path: '/',
                label: company.name
              },
              {
                path: `/company/${company.id}/clients`,
                label: 'Clients'
              },
              {
                path: `/company/${company.id}/clients/${client.id}`,
                label: client.name
              },
              {
                path: `/company/${company.id}/clients/${client.id}?tab=projects`,
                label: 'Projects'
              },
              {
                label: project.name
              }
            ])
          })
        })
      })
    },
    methods: {
      setBreadcrumbs() {
        this.$store.dispatch('company/getCompany', this.$route.params).then((company) => {
          this.$store.dispatch('project/getProject', this.$route.params).then(project => {
            this.$store.dispatch('client/getClient', { clientId: project.client_id }).then((client) => {
              this.$store.commit('global/setBreadcrumbs', [
                {
                  path: '/',
                  label: company.name
                },
                {
                  path: `/company/${company.id}/clients`,
                  label: 'Clients'
                },
                {
                  path: `/company/${company.id}/clients/${client.id}`,
                  label: client.name
                },
                {
                  path: `/company/${company.id}/clients/${client.id}?tab=projects`,
                  label: 'Projects'
                },
                {
                  label: this.project.name
                }
              ])
            })
          })
        })
      }
    }
  }
</script>
