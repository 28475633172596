<template>
  <el-popover
    placement="right"
    width="400"
    trigger="hover">
    <div style="max-height: 500px; overflow-y: auto; margin: -12px; padding: 1em;">
      <div v-if="value.monthly_built && value.monthly_built.length > 1">
        <div class="flex flex-row mt-2 pb-2 border-b border-solid border-gray-200">
          <span class="font-bold text-base">Build history</span>
        </div>
        <div>
          <ul class="">
            <li v-for="(i, k) in value.monthly_built.slice(0, value.monthly_built.length - 1)" :key="k"
                class="my-2 py-2 border-b border-solid border-gray-200 text-xs">
              <div class="font-medium text-sm">
                {{ i.date_month | moment('MMMM YYYY') }}
              </div>
              <div>
                Target: {{ i.month_target }}
              </div>
              <div>
                Rollover target: {{ i.rollover_target }} (
                {{ i.previous_month_rollover > 0 ? `+${i.previous_month_rollover}` : i.previous_month_rollover }} )
              </div>
              <div>
                Billed: {{ i.built_links }}
              </div>
              <div :class="getStateColour(i)" class="font-bold">
                Status: {{ getMonthState(i) }}
              </div>
            </li>
          </ul>
        </div>
      </div>
      <div v-else>
        No rollover information to show
      </div>
    </div>
    <div slot="reference">
      <span class="font-bold">{{ (reference || 0).toLocaleString() }}<i class="el-icon-view ml-1"></i></span>
    </div>
  </el-popover>
</template>
<script>
export default {
  props: {
    value: {
      type: Object
    },
    reference: {
      type: [String, Number]
    }
  },
  methods: {
    getMonthState(v) {
      return v.built_links < v.rollover_target ? 'Short' : (v.built_links > v.rollover_target) ? 'Over' : 'Okay'
    },
    getStateColour(v) {
      switch (this.getMonthState(v)) {
        case 'Short':
          return 'text-red-600';
        case 'Over':
          return 'text-yellow-600';
        default:
          return 'text-blue-700';
      }
    }
  }
}
</script>
