const VueTableControlsMixin =  {
  computed: {
    getRpp() {
      return this.rpp || 20;
    },
    getPaginationData() {
      return this.paginationData
    }
  },
  data() {
    return  {
      paginationData: {}
    }
  },
  methods: {
    onHttpFetch(apiUrl, {params}) {
      return this.$http.get(apiUrl, {params})
    },
    onPaginationData(paginationData) {
      this.paginationData = paginationData
      if(this.$refs.paginationInfo) {
        this.$refs.paginationInfo.setPaginationData(paginationData)
      }
      this.$refs.pagination.setPaginationData(paginationData)
    },
    onChangePage(page) {
      this.$refs.vuetable.changePage(page)
    },
    refresh() {
      setTimeout(() => {
        this.$refs.vuetable.refresh();
      }, 10)
    },
    transformData(transformData) {
      transformData.links = {
        pagination: {
          total: transformData.meta.totalResult,
          per_page: this.getRpp,
          current_page: transformData.meta.current,
          last_page: transformData.meta.pageCount,
          next_page_url: transformData.meta.next ? '/' + transformData.meta.next : null,
          prev_page_url: transformData.meta.previous ? '/' + transformData.meta.previous : null,
          from: transformData.meta.fromResult,
          to: transformData.meta.toResult
        }
      };

      //console.log(transformData.links.pagination, transformData.meta);
      return transformData;
    },
  }
}

module.exports.VueTableControlsMixin = VueTableControlsMixin;
module.exports = VueTableControlsMixin;
