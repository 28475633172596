<template>
  <div v-loading="loading">
    <trieste-table
      item-type="email account"
      title="Email accounts"
      :end-point="`/v1/companies/${$store.state.company.company.id}/users/${$route.params.userId}/email-accounts`"
      :fields="fields"
      ref="table"
      default-sort-prop="email"
      v-if="ready"
      :show-title="false"
      saved-table-sort-name="user-email-accounts"
    >
      <template v-slot:header-right>
        <el-button type="success" size="small" icon="el-icon-edit-outline" v-if="$store.getters['auth/hasPermission']('emailaccount.read')" @click="showCreateEmailAccount = true">Create</el-button>
      </template>

      <template v-slot:fn-slot="props">
        {{ props.row.email_account.first_name }}
      </template>
      <template v-slot:ln-slot="props">
        {{ props.row.email_account.last_name }}
      </template>
      <template v-slot:status-slot="props">
        {{ props.row.email_account.status }}
      </template>
      <template v-slot:email-slot="props">
        {{ props.row.email_account.email }}
      </template>
      <template v-slot:actions-slot="props">
        <el-button
          size="small"
          icon="el-icon-delete"
          type="danger"
          @click="deleteEmailAccount(props.row.email_account)"
        >
          Delete
        </el-button>
      </template>
    </trieste-table>
    <trieste-dialog :show.sync="showCreateEmailAccount" title="Add a email account">
      <div class="p-6 pb-2">
        <el-form :rules="new_email_account_rules" :model="new_email_account" label-width="120px" label-position="left" ref="siteForm" v-loading="loading">
          <el-form-item label="Email account" prop="email_account_id">
            <el-select filterable v-model="new_email_account.email_account_id" class="w-full" multiple>
              <el-option :value="account.id" :label="account.email" v-for="(account, i) in available_email_clients" :key="'ea_' + i">
                {{ `${account.email} - ${account.first_name} ${account.last_name}` }}
              </el-option>
            </el-select>
          </el-form-item>
        </el-form>
      </div>
      <template v-slot:footer-right>
        <el-button @click="closeCreateEmailAccountDialog" icon="el-icon-close" type="danger" size="small">Cancel</el-button>
        <el-button type="success" icon="el-icon-edit-outline" @click="createEmailAccount" size="small">Confirm</el-button>
      </template>
    </trieste-dialog>
  </div>
</template>
<script>
  import Styling from './../../../../components/vuetable.config';
  import VueTableControlsMixin from './../../../../components/mixins/vuetable-controls';
  import { formatResponseErrorMessage } from "@/utils/helpers";

  export default {
    mixins: [ VueTableControlsMixin ],
    data: function () {
      return {
        showCreateEmailAccount: false,
        available_email_clients: [],
        new_email_account: {
          email_account_id: null,
        },
        new_email_account_rules: {
          email_account_id: [
            {required: true, trigger: 'blur', message: 'Please select a email account.'}
          ],
        },
        query: {
          rpp: 20,
          page: 1
        },
        loading: false,
        ready: false,
        fields: [
          {
            title: 'Firstname',
            name: 'fn-slot',
            sortField: 'first_name'
          },
          {
            title: 'Lastname',
            name: 'ln-slot',
            sortField: 'last_name'
          },
          {
            title: 'Email',
            name: 'email-slot',
            sortField: 'email'
          },
          {
            title: 'status',
            name: 'status-slot',
            sortField: 'status'
          },
          {
            title: '',
            name: 'actions-slot',
            width: '110px',
            float: 'right'
          },
        ],
        sortOrder: [
          {
            field: 'name',
            direction: 'asc'
          }
        ],
        styling: {
          table: Styling.table,
          pagination: Styling.pagination
        },
      }
    },
    mounted() {
      if(!this.$store.getters['auth/hasPermission']('emailaccount.read')) {
        this.$message.error('You do not have the permission to view email accounts.')
        this.$router.push('/')
      }
      this.getAllEmailClients();
    },
    methods: {
      getAllEmailClients() {
        this.loading = true;
        this.$http.get(`/v1/companies/${this.$store.state.company.company.id}/email-accounts`, {
          params: {
            active_only: true,
            rpp: 1000
          }
        })
          .then(r => {
              this.available_email_clients = r.data.data
          })
          .catch(() => {
            this.$message.error('Failed to load the available clients list')
          })
          .finally(() => {
            this.loading = false;
            this.ready = true;
          })
      },
      deleteEmailAccount(account) {
        if(account.id && account.email) {
          this.$confirm('Are you sure you want to remove the email account '+account.email+'?', 'warning', {
            confirmButtonClass: 'el-button--danger'
          })
            .then(() => {
              this.$http.delete( `/v1/companies/${this.$store.state.company.company.id}/users/${this.$route.params.userId}/email-accounts/${account.id}`)
                .then(() => {
                  this.$message.success('The email account has successfully been removed.');
                  this.$refs.table.getData()
                })
                .catch(() => {
                  this.$message.error('The was an error processing that request. Please try again later.')
                })
            })
            .catch(() => {
              // do nothign
            })
        }
      },
      createEmailAccount() {
        this.$refs.siteForm.validate((valid) => {
          if(valid) {
            this.loading = true;

            let method = this.$http.post;
            let url = `/v1/companies/${this.$store.state.company.company.id}/users/${this.$route.params.userId}/email-accounts`;

            method(url, this.new_email_account)
              .then(() => {
                this.$message.success('Successfully '+(this.new_email_account.id ? 'updated':'added')+' the email account.');
                this.closeCreateEmailAccountDialog();
                this.$refs.table.getData()
              })
              .catch((err) => {
                let message = formatResponseErrorMessage(err, 'createEmailAccount')
                this.$message.error(message)
                console.error(err.toJSON(), message)
              })
              .finally(() => {
                this.loading = false;
              })
          }
        })
      },
      closeCreateEmailAccountDialog() {
        this.new_email_account = {
          email_account_id: null,
        };
        this.showCreateEmailAccount = false;
      },
    }
  }
</script>
