<template>
  <div class="w-full h-screen" v-loading="loading">
  </div>
</template>
<script>
import CreateGoogleAuthenticationLink from '@/components/mixins/create_google_authentication_link'
export default {
  mixins: [CreateGoogleAuthenticationLink],
  data() {
    return {
      loading: true,
      email: this.$route.query.email,
      return_url: this.$route.query.return_url,
      client_id: null,
      email_id: null,
      client_record_id: null,
      email_hint: null,
      client_host: null
    }
  },
  mounted() {
    if(!this.email) {
      return this.$message.error('No email detected.')
    }

    if(!this.return_url) {
      return this.$message.error('No return url detected.')
    }

    this.getResources(this.proceedToMicrosoft)

  },
  methods: {
    getResources(callback) {
      const resources = [
        this.$http.post('/v1/legacy/gmail/auth/get-email-account-id', {
          email: this.email
        }),
      ]

      this.$http.all(resources).then(this.$http.spread((emailId) => {
        this.email_id = emailId.data.email_account_id
        this.email_hint = emailId.data.email

        if(typeof callback === 'function') {
          callback()
        }
      }))
      .catch((e) => {
        this.$message.error('Unable to get a client ID at this time')
        console.error(e)
      })
    },
    proceedToMicrosoft () {
      window.location.href = this.createMicrosoftAuthenticationLink(
        this.email_hint,
        btoa(this.return_url + ':::' + this.email_id),
        0,
        process.env.VUE_APP_URL + '/legacy-microsoft-sign-in'
      )
    }
  }
}
</script>
