<template>
  <div v-loading="loading">
    <div class="page-title">
      <h1>
        <logo
          v-model="$store.state.company.company.url"
          class="mr-2"
          :size="30"
          v-if="false"
        />
        <span>{{ $store.state.client.client.name }}<span class="faded-text"><span class="faded-strike">/</span>Client</span></span>
      </h1>
      <div class="controls">
        <el-button type="success" icon="el-icon-edit"  size="mini" @click="show_settings = true" v-if="$store.getters['auth/hasPermission']('client.update')">Edit</el-button>
      </div>
    </div>
    <div class="columns">
      <div class="column w-full">
        <el-tabs class="custom-tabs-v1" @tab-click="tabsChanged" v-model="activeTabName" ref="tabs">
          <el-tab-pane label="Projects" v-if="$store.getters['auth/hasPermission']('project.update')" name="projects">
             <span slot="label">
               <a href="?tab=projects" @click.prevent>Projects</a>
             </span>
            <project-index v-if="client.id" :client-id="client.id" />
          </el-tab-pane>
          <el-tab-pane label="Projects allocation" v-if="$store.getters['auth/hasPermission']('project.update')" name="projects-allocation">
             <span slot="label">
               <a href="?tab=projects" @click.prevent>Project allocation</a>
             </span>
            <project-allocations v-if="client.id" :client-id="client.id" />
          </el-tab-pane>
          <el-tab-pane label="Sites" v-if="$store.getters['auth/hasPermission']('site.read')" name="sites">
             <span slot="label">
               <a href="?tab=sites" @click.prevent>Sites</a>
             </span>
            <sites />
          </el-tab-pane>
          <el-tab-pane label="Sites settings" v-if="$store.getters['auth/hasPermission']('site.read')" name="site settings">
             <span slot="label">
               <a href="?tab=sites" @click.prevent>Site settings</a>
             </span>
            <site-settings />
          </el-tab-pane>
          <el-tab-pane label="Links"  v-if="$store.getters['auth/hasPermission']('link.read')" name="links" lazy>
             <span slot="label">
               <a href="?tab=links" @click.prevent>Links</a>
             </span>
            <links export-mode="BATCHLINKS" export-name="all batch links" :show-title="false" allow-mass-update />
          </el-tab-pane>
          <el-tab-pane label="Leads"  v-if="$store.getters['auth/hasPermission']('lead.read')" name="leads" lazy>
             <span slot="label">
               <a href="?tab=leads" @click.prevent>Leads</a>
             </span>
            <links :active-only="true" :batch-id="$store.state.batch.batch.id" :show-title="false" include-exchange-prices export-mode="LINKBUILDING" export-name="all approved leads"  />
          </el-tab-pane>
          <el-tab-pane label="Info" name="info">
             <span slot="label">
               <a href="?tab=info" @click.prevent>Info</a>
             </span>
            <div class="max-w-screen-xl">
              <trieste-card title="Details">
                <div class="p-4">
                  <trieste-info-column label="Name" :value="$store.state.client.client.name"  />
                </div>
              </trieste-card>
            </div>
          </el-tab-pane>
        </el-tabs>
      </div>
      <div class="column w-1/3" v-if="false" style="margin-top: 3.4em">
        <trieste-card title="Client Information">
          <template v-slot:header-right>
            <el-button type="success" icon="el-icon-edit" size="small" @click="show_settings = true" v-if="$store.getters['auth/hasPermission']('client.update')">Edit</el-button>
          </template>
          <div class="p-4">
            <trieste-info-column label="Name" :value="$store.state.client.client.name"  />
          </div>
        </trieste-card>
      </div>
    </div>
    <trieste-dialog :title="`Edit ${$store.state.client.client.name}`" :show.sync="show_settings" :loading="updating_settings">
      <div class="p-6 pb-2">
        <el-form label-position="left" label-width="120px" :model="client" :rules="settings_rules" ref="updateForm">
          <el-form-item label="Client name" prop="name">
            <el-input v-model="client.name"/>
          </el-form-item>
        </el-form>
      </div>
      <template v-slot:footer-left>
        <el-button size="small" icon="el-icon-delete" type="danger" @click="deleteClient" v-if="$store.getters['auth/hasPermission']('client.delete')">Permanently delete this client</el-button>
      </template>
      <template v-slot:footer-right>
        <el-button type="danger" icon="el-icon-close" size="small" @click="show_settings = false">Cancel</el-button>
        <el-button type="success" icon="el-icon-edit-outline" size="small" @click="updateClient" v-if="$store.getters['auth/hasPermission']('client.update')">Update</el-button>
      </template>
    </trieste-dialog>
  </div>
</template>
<script>
  import Sites from './Tabs/Sites';
  import SiteSettings from './Tabs/SiteSettings';
  import Links from "../Links/Index"
  import ProjectIndex from "./Projects/Index";
  import ProjectAllocations from "./Projects/Allocations/Index";
  import tabPosition from '@/components/mixins/tab_position'
  export default {
    name: 'ViewClient',
    components: {Sites, Links, ProjectIndex, SiteSettings, ProjectAllocations },
    mixins: [tabPosition],
    metaInfo() {
      return {
        title: this.tabTitle,
        titleTemplate: '%s | Clients'
      }
    },
    data() {
      return {
        loading: true,
        show_settings: false,
        updating_settings: false,
        activeTabName: this.$store.getters['auth/hasPermission']('project.update') ? 'projects' : 'sites',
        client: {},
        settings_rules: {
          name: [
            {required: true, min: 3, message: 'Please enter a valid name', trigger: 'blur'}
          ],
        }
      }
    },
    computed: {
      tabTitle() { return  this.client.name ? this.client.name : this.$store.state.company.company.name }
    },
    mounted() {
      if(!this.$store.getters['auth/hasPermission']('client.read')) {
        this.$message.error('You do not have permissions to view clients.');
        this.$router.push('/');
      }
      this.setBreadcrumbs();
    },
    methods: {
      setBreadcrumbs() {
        this.$store.dispatch('company/getCompany', this.$route.params).then((company) => {
          this.$store.dispatch('client/getClient', this.$route.params).then((client) => {
            this.$store.commit('global/setBreadcrumbs', [
              {
                path: '/',
                label: company.name
              },
              {
                path: `/company/${company.id}/clients`,
                label: 'Clients'
              },
              {
                label: client.name,
                path: `/company/${company.id}/clients/${client.id}`,
              },
              {
                label: this.activeTabName
              }
            ])
            this.client = Object.assign({}, client);
            this.loading = false;
          })
        })
      },
      updateClient() {
        this.$refs.updateForm.validate(valid => {
          if(valid) {
            this.updating_settings = true;
            this.$http.put(`/v1/companies/${this.$store.state.company.company.id}/clients/${this.$route.params.clientId}`, this.client)
              .then(() => {
                this.$message.success('Successfully updated the client.')
                this.show_settings = false;
                this.$store.dispatch('client/getClient', {
                  companyId: this.$store.state.company.company.id,
                  clientId: this.$route.params.clientId
                }).then(client => {
                  this.client = Object.assign({}, client)
                })
              })
              .catch(() => {
                this.$message.error('An error occurred updating the client.')
              })
            .finally(() => {
              this.updating_settings = false;
            })
          }
        })
      },
      deleteClient() {
        this.$confirm('This will delete all the data that belongs to this client, including sites and leads', 'are you sure?', {
          confirmButtonClass: 'el-button--danger'
        })
        .then(() => {
          this.updating_settings = true;
          this.$http.delete(`/v1/companies/${this.$store.state.company.company.id}/clients/${this.client.id}`)
            .then(() => {
              this.$message.success('Successfully deleted the client.')
              this.show_settings = false;
              this.$router.push(`/company/${this.$store.state.company.company.id}/clients`)
              this.$store.commit('client/update', ['client', {}])
            })
            .catch(() => {
              this.$message.error('An error occurred updating the client.')
            })
            .finally(() => {
              this.updating_settings = false;
            })
        })
        .catch(() => {})
      }
    }
  }
</script>
