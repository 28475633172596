import api from './../../utils/api';
import store from '../index'
export default {
  namespaced: true,
  state: {
    loading_site: false,
    site: {},
    sites: [],
    loading_all_sites: false,
  },
  mutations: {
    setLoading: (state, payload) => state.loading_site = payload,
    setSite: (state, payload) => state.site = payload,
    set: (state, payload) => state[payload[0]] = payload[1]
  },
  actions: {
    loadAllSites({commit, state, rootState}, { companyId, clientId, force }) {
      // only call this only.
      if(!force && (state.sites.length || state.loading_all_sites)) {
        return;
      }
      commit('set', ['loading_all_sites', true])

      return new Promise((resolve, reject) => {
        const params = {
          all: true
        };
        if(clientId) {
          params.client_id = clientId
        }
        const cId = companyId || rootState.company.company.id;
        api.get(`/v1/companies/${cId}/sites`, { params }).then(response => {
          commit('set', ['sites', response.data.data])
          commit('set', ['loading_all_sites', false])
          resolve(response.data.data)
        }).catch(e => {
          reject(e)
        })
      })
    },
    getSite({commit, state, rootState}, {companyId, siteId}) {
      commit('setLoading', true);
      return new Promise((resolve, reject) => {
        const cId = companyId || rootState.company.company.id;
        api.get(`/v1/companies/${cId}/sites/${siteId}`, {
          params: {
            deleted: true
          }
        }).then(r => {
          state.site = r.data.data;
          commit('setSite', r.data.data);
          commit('setLoading', false);
          resolve(Object.assign({}, state.site))
        }).catch(e => {
          reject(e)
        })
      })
    },
  }
}
