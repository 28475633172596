<template>
  <div v-loading="loading">
    <div v-if="!first_load">
      <div class="p-4">
        <div class="page-title" v-if="showTitle">
          <h1> Sites
          </h1>
          <span class="text-xs font-base text-blue-900">Fill in all sections marked with an <span class="text-red-600">*</span> </span>
        </div>
        <el-form
          ref="site_form"
          :model="project"
          :rules="site_rules"
          label-width="300px"
          label-position="left"
          :disabled="disabled"
        >
          <el-form-item label="Add site" prop="sites_and_targets" v-if="allowAddSites">
            <el-select v-model="selected_site" @change="addSite" size="small" filterable>
              <el-option
                v-for="(s, i) in sites"
                :key="i" :value="s.id"
                :label="`${s.base_url}`"
                :disabled="project.sites.includes(s.id)"
              />
            </el-select>
            <div class="ml-2 inline-block">
              <el-button type="primary" icon="el-icon-edit-outline" @click="showCreateSite = true" size="small">Create site</el-button>
            </div>
          </el-form-item>
          <el-form-item label-width="0px" v-if="project.sites && project.sites.length">
            <div class="border border-b-0"></div>
          </el-form-item>
        </el-form>
        <el-form
          ref="form"
          :model="project"
          label-width="300px"
          label-position="top"
          :disabled="disabled"
        >
          <template v-for="(s, i) in project.sites">
            <el-form-item  :prop="'site|' + s" :key="i" label="" class="is-no-asterisk">
              <trieste-card>
                <template v-slot:title>
                  <div class="flex flex-row items-center">
                    <div>{{ s.domain }}</div>
                  </div>
                </template>
                <template v-slot:header-right>
                  <i class="absolute el-icon-error text-red-600 cursor-pointer deleteicon"
                     @click="removeSite(s.id, i)" v-if="!disabled && allowAddSites"></i>
                </template>
                <div class="p-4">
                  <el-form :model="s" ref="siteTargetsForm" :rules="getSiteTargetRules" label-width="200px"
                           label-position="left" class="forceFormLeft" :disabled="disabled">
                    <template v-if="project.links_target_allocation_type === 'MONTHLY_ALLOCATED'">
                      <el-form-item label="Allocations">
                        <el-form :model="s" ref="siteTargetsForm" :rules="getSiteTargetRules"
                                 label-position="top" inline>
                          <el-form-item
                            :prop="`${s.id}|${m}`"
                            :label="m"
                            label-width="50px"
                            v-for="(m, i) in monthAllocations" :key="i">
                            <el-input type="number"
                                      v-model="project.sites.find(si => si.id === s.id)[m]"
                                      size="mini"
                                      class="inline-allocation-cost"
                                      :disabled="disabled"
                            />
                          </el-form-item>
                        </el-form>
                      </el-form-item>
                    </template>
                    <template v-else>
                      <el-form-item label="Monthly allocation" :prop="`${s.id}|default`">
                        <el-input type="number" v-model.number="project.sites.find(si => si.id === s.id).default" />
                      </el-form-item>
                    </template>
                  </el-form>
                </div>
              </trieste-card>
            </el-form-item>
            <el-form-item label-width="0px" :key="'ww' + i">
              <div class="border border-b-0"></div>
            </el-form-item>
          </template>
        </el-form>
      </div>
    </div>
    <site-create-widget :show.sync="showCreateSite" :client-id="$route.params.clientId || value.client_id" @updated="setSite" />
    <site-target-update-widget :target="current_updatable_target" :show.sync="show_edit_target" :on-delete="onTargetDelete" :on-edit="onTargetEdit" />
  </div>
</template>
<script>
  const moment = require('moment')
  import TargetCreateWidget from '@/components/views/SiteTargets/Create';
  import SiteCreateWidget from '@/components/views/Sites/CreateEdit';
  import SiteTargetUpdateWidget from '@/components/views/SiteTargets/Update'
  export default {
    components: { SiteCreateWidget, SiteTargetUpdateWidget },
    props: {
      value: {
        type: Object,
        required: true
      },
      monthAllocations: {
        type: Array,
        default: () => []
      },
      disabled: {
        type: Boolean,
        default: false
      },
      showTitle: {
        type: Boolean,
        default: true
      },
      allowAddSites: {
        type: Boolean,
        default: true
      },
      allowAddSiteTargets: {
        type: Boolean,
        default: true
      },
      allowRemoveSite: {
        type: Boolean,
        default: true
      },
      allowRemoveTarget: {
        type: Boolean,
        default: true
      }
    },
    data() {

      return {
        project: this.value,
        loading: false,
        first_load: true,
        selected_site: null,
        selected_site_target: null,
        site_targets: [],
        sites: [],
        new_target: {
          siteId: null,
          site_url: null
        },
        rules: {
          target_link_allocation_type: [
            { required: true, message: 'Please specify a allocation type.' }
          ]
        },
        showTargetCreate: false,
        showCreateSite: false,
        current_updatable_target: {},
        show_edit_target: false,
        site_rules: {
          sites_and_targets: [
            { validator: this.hasOneSiteRules }
          ]
        }
      }
    },
    mounted() {
     this.getResources()
    },
    computed: {
      getSiteRules() {
        if(this.project.sites.length) {
          const additionalRules = {};
          // const validator = this.siteRule;
          // for(const siteId of this.project.sites) {
          //   additionalRules['site|' + siteId] = [
          //     {
          //       validator,
          //       required: true,
          //       type: 'number'
          //     }
          //   ]
          // }
          return Object.assign({}, this.rules, additionalRules);
        }
        return this.rules;
      },
      getSiteTargetRules() {
        const additionalRules = {};
        const validator = this.siteTargetAssociateRule;
        if(this.project.links_target_allocation_type === 'MONTHLY_ALLOCATED') {
          if(this.monthAllocations.length) {
            this.project.sites.forEach(site => {
              this.monthAllocations.map(i => additionalRules[`${site.id}|${i}`] = [
                {
                  validator,
                  required: true,
                  type: 'number'
                }
              ]);
            })
          }
        } else {
          this.project.sites.forEach(site => {
            additionalRules[`${site.id}|default`] = [
              {
                validator,
                required: true,
                type: 'number'
              }
            ]
          })
        }
        return Object.assign({}, additionalRules);
      },
    },
    methods: {
      async validate() {
        try {
          await this.$refs.site_form.validate()
          const standardForms = Array.isArray(this.$refs.form) ? this.$refs.form : [this.$refs.form]
          for (const st of standardForms) {
            await st.validate()
          }
          const siteTargetComponents = !this.$refs.siteTargetsForm ? [] :
            (!Array.isArray(this.$refs.siteTargetsForm) ? [this.$refs.siteTargetsForm] : this.$refs.siteTargetsForm);
          for(const stc of siteTargetComponents) {
            await stc.validate();
          }


          return this.validateLinkCount();
        } catch (e) {
          console.error(e)
          return false
        }
      },
      setSite(site) {
        const callback = () => {
          this.addSite(site.id)
        }
        this.getResources(callback)
      },
      getResources(callback) {
        this.loading = true;
        const requests = [
          this.$http.get(`/v1/companies/${this.$store.state.company.company.id}/sites`, { params: {
              all: true,
              client_id: this.$route.params.clientId || this.project.client_id
            }
          }),
        ];

        this.$http.all(requests).then(this.$http.spread((sites) => {
          this.sites = sites.data.data;
          if(typeof callback === "function") {
            callback()
          }
        }))
        .finally(() => {
          this.loading = false;
          this.first_load = false;
        })
      },
      siteRule(rule, value, callback) {
        const field_parts = rule.field.split('|');
        const siteId = parseInt(field_parts[1]);
        const site = this.project.sites_and_targets.find(site => site.id === siteId);
        if(!site || !site.targets || !site.targets.length) {
          return callback(new Error('Please add one or more sites'));
        }
        return callback();
      },
      hasOneSiteRules(rule, value, callback) {
        if(!Array.isArray(this.project.sites) || !this.project.sites.length) {
          return callback(new Error('Please add one or more sites'));
        }
        return callback();
      },
      onTargetEdit () {
        this.getResources()
      },
      onTargetDelete () {
        this.getResources()
      },
      siteTargetAssociateRule (rule, value, callback)  {
        const field_parts = rule.field.split('|');
        const siteId = parseInt(field_parts[0]);
        const valueTarget = field_parts[1];

        const item = this.project.sites.find(i => i.id === siteId)

        if(!item[valueTarget]) {
          return callback(new Error('Please enter a link allocation for ' + this.sites.find(s => s.id === item.id).domain));
        }
        return callback();
      },
      addSite(id) {
        if(!Array.isArray(this.project.sites)) {
          this.project.sites = [];
        }

        const tryfind = this.project.sites.find(e => e.id === id);

        if(!tryfind) {
          const site = this.sites.find(s => s.id === id)
          this.project.sites.push({
            id: id,
            domain: site.domain,
            default: null,
          });
        }
        this.selected_site = null;
      },
      getSiteLabel(id) {
        const site = this.sites.find(i => i.id === id);
        if(site) {
          return `${site.domain}`;
        }
        return null;
      },
      removeSite(siteId, key) {
        this.project.sites.splice(key, 1);
      },
      validateLinkCount() {
        const isPercent = this.project.target_link_allocation_type === 'PERCENT';
        const isMonthlyCalculated = this.project.links_target_allocation_type === 'MONTHLY_ALLOCATED';
        if(!Array.isArray(this.project.sites) || !this.project.sites.length) {
          this.$message.error(`Please add at least one site.`);
          return false;
        }

        if(isMonthlyCalculated) {
          const monthsToCheck = Object.keys(this.project.links_monthly_allocation);
          for (const month of monthsToCheck) {
            const total = this.project.links_monthly_allocation[month];
            let monthlyTargetTotal = 0;
            this.project.sites.forEach(site => {
              if (site && site[month]) {
                monthlyTargetTotal += parseFloat(site[month])
              }
            })

            if(isPercent && monthlyTargetTotal !== 100) {
              this.$message.error(`The percentage for ${month} needs to be 100%. It is ${monthlyTargetTotal.toLocaleString()}`)
              return false;
            } else if(!isPercent && total !== monthlyTargetTotal) {
              this.$message.error(`The total for ${month} across targets is ${monthlyTargetTotal.toLocaleString()}. It needs be ${total.toLocaleString()}.`)
              return false;
            }
          }
        } else {
          const total = this.project.links_target_count;
          let targetTotal = 0;
          this.project.sites.forEach(site => {
            targetTotal += parseFloat(site.default)
          })
          if(isPercent && targetTotal !== 100) {
            this.$message.error(`The links allocation across the site targets needs to be 100%. It is currently ${targetTotal.toLocaleString()}.`)
            return false;
          } else if(!isPercent && total !== targetTotal) {
            this.$message.error(`The total link allocation across the site targets is ${targetTotal.toLocaleString()}. It needs be ${total.toLocaleString()}.`)
            return false;
          }
        }

        return true;
      }
    },
    watch: {
      project: {
        deep: true,
        handler: function(value) {
          this.$emit('update:input', value)
        }
      },
      'project.client_id': {
        handler: function(value) {
          this.getResources()
        }
      },
      value: {
        deep: true,
        handler: function(value) {
          this.project = value
        }
      }
    }
  }
</script>
<style lang="scss">
  .forceFormLeft {
    .el-form-item__label {
      float: left !important
    }
  }
  .inline-allocation-cost {
    max-width: 90px;
  }
  .deleteicon {
    font-size: 2em;
    top: -.5em;
    z-index: 10;
    right: -.5em;
    background: #fff;
  }
</style>
