<template>
  <div>
    <trieste-table
      :end-point="`/v1/companies/${$store.state.company.company.id}/dashboard/linkdev/leads`"
      :params="{ date_start: dateStart, date_end: dateEnd, user_id: userId, include_suspended: includeSuspendedSites }" :fields="fields"
      :title="title || 'Leads'" :show-sub-title="false" collapsable first-field-total
    >
      <template v-slot:domain="props">
        <span v-if="props.$index === 0" class="font-bold">Total</span>
        <a :href="`/company/${$store.state.company.company.id}/sites/${props.row.id}`" v-else>{{props.row.domain}}</a>
      </template>
      <template v-slot:client="props">
        <span v-if="props.$index === 0" class="font-bold">Total</span>
        <a :href="`/company/${$store.state.company.company.id}/clients/${props.row.client.id}`" v-else>{{props.row.domain}}</a>
      </template>
      <template v-slot:all-leads-slot="props">
        <a :href="buildLeadLink(props.row.id)">
          {{props.row.all_leads.toLocaleString()}}
        </a>
        <a href="#" v-if="$store.getters['auth/hasPermission']('batch.update') && props.$index !== 0"
           class="border-blue-600 text-blue-700" @click="setUpLeadCreate(props.row.id, props.row.client.id)"
        >
          +
        </a>
      </template>
      <template v-slot:batches-slot="props">

        <a :href='buildBatchLink(props.row.id)'>
          {{ props.row.total_batches }}
        </a>
        <a :href='buildBatchLink(props.row.id, true)'>
          ({{props.row.completed_batches}} ready)
        </a>
        <a
          :href="`/company/${$store.state.company.company.id}/sites/${props.row.id}/batch/create`"
          class="border-blue-600 text-blue-700"
          target="_blank"
          v-if="$store.getters['auth/hasPermission']('link.update') && props.$index !== 0"
        >
          +
        </a>
      </template>
      <template v-slot:new-slot="props">
        <a :href='buildLeadLink(props.row.id, "new")'>
        {{ props.row.new_leads }}
        </a>
      </template>
      <template v-slot:negotiation-leads-slot="props">
        <a :href='buildLeadLink(props.row.id, ["negotiation", "negotiation_reminder_sent", "negotiation_waiting_for_reply"])'>
        {{ props.row.negotiation_leads }}
        </a>
      </template>
      <template v-slot:on-hold-leads-slot="props">
        <a :href='buildLeadLink(props.row.id, "on_hold")'>
        {{ props.row.on_hold_leads }}
        </a>
      </template>
      <template v-slot:content-slot="props">
        <a :href="buildContentLink(props.row.id, ['cancelled','published'])">{{ props.row.pending_content }}</a>
        /
        <a :href="buildContentLink(props.row.id, ['cancelled'])">{{props.row.total_contents}}</a>
        <a :href="buildContentLink(props.row.id, null, 'published', 'content_available')"> ({{props.row.ready_content}} ready)</a>
      </template>
      <template v-slot:placement-slot="props">
        <a :href='buildLeadLink(props.row.id, "awaiting_placement")'>
        {{ props.row.placement_leads }}
        </a>
      </template>
      <template v-slot:fulfillment-slot="props">
        <a :href='buildLeadLink(props.row.id, "awaiting_fulfillment")'>
        {{ props.row.fulfillment_leads }}
        </a>
      </template>
      <template v-slot:active-slot="props">
        <a :href='buildLeadLink(props.row.id, "active", true)'>
        {{ props.row.active_leads }}
        </a>
      </template>
      <template v-slot:corrections-slot="props">
        <a :href='buildLeadLink(props.row.id, "correction")'>
          {{ props.row.correction_leads }}
        </a>
      </template>
      <template v-slot:corrections-reply-slot="props">
        <a :href='buildLeadLink(props.row.id, "correction_waiting_for_reply")'>
          {{ props.row.correction_reply_leads }}
        </a>
      </template>
    </trieste-table>
    <batch-quick-create :show.sync="batchCreate.show" v-if="batchCreate.show" :client-id="batchCreate.clientId" :site-id="batchCreate.siteId" />
    <lead-quick-create :show.sync="leadCreate.show" v-if="leadCreate.show" :client-id="leadCreate.clientId" :site-id="leadCreate.siteId" />
  </div>
</template>
<script>
  import BatchQuickCreate from "@/components/views/batch/QuickCreate";
  import LeadQuickCreate from "@/components/views/links/LeadQuickCreate";
  export default {
    components: { BatchQuickCreate, LeadQuickCreate },
    props: {
      dateStart: {
        type: [String, Date]
      },
      dateEnd: {
        type: [String, Date]
      },
      title: {
        type: String,
        default: null
      },
      userId: {
        type: [String, Number],
        default: null
      },
      includeSuspendedSites: {
        type: Boolean,
        default: false
      }
    },
    data() {
      return {
        fields: [
          {
            title: 'domain',
            name: 'domain',
            sortField: 'domain',
          },
          {
            title: 'Client',
            name: 'client',
            sortField: 'client.name',
            formatter: (val) => val ? val.name : ''
          },
          {
            title: 'All leads',
            name: 'all-leads-slot'
          },
          {
            title: 'Batches',
            name: 'batches-slot'
          },
          {
            title: 'new',
            name: 'new-slot'
          },
          {
            title: 'Negotiation',
            name: 'negotiation-leads-slot'
          },
          {
            title: 'On hold',
            name: 'on-hold-leads-slot'
          },
          {
            title: 'Content',
            name: 'content-slot'
          },
          {
            title: 'Placement',
            name: 'placement-slot'
          },
          {
            title: 'Fulfillment',
            name: 'fulfillment-slot'
          },
          {
            title: 'Corrections',
            name: 'corrections-slot'
          },
          {
            title: 'Corrections awaiting reply',
            name: 'corrections-reply-slot'
          },
          {
            title: 'Active',
            name: 'active-slot'
          }
        ],
        batchCreate: {
          siteId: null,
          clientId: null,
          show: false
        },
        leadCreate: {
          siteId: null,
          clientId: null,
          show: false
        }
      }
    },
    methods: {
      buildBatchLink(siteId, completed_only = false) {
        const baseLink = `/company/${this.$store.state.company.company.id}/batches`;
        const filters = {}
        // filters['Created at'] = [this.dateStart, this.dateEnd]
        if(siteId) {
          filters['Site'] = [siteId]
        }
        if(this.userId) {
          filters['Owner'] = [this.userId]
        }
        if(completed_only) {
          filters['status'] = ['completed']
        }

        return baseLink + '?filters=' + JSON.stringify(filters)
      },
      buildContentLink(siteId, status_excludes, status, content_stats) {
        const baseLink = `/company/${this.$store.state.company.company.id}/content`;
        const filters = {}
        // filters['Created at'] = [this.dateStart, this.dateEnd]
        if(siteId) {
          filters['Site'] = [siteId]
        }
        if(this.userId) {
          filters['Acquired by'] = [this.userId]
        }
        if(status_excludes) {
          filters['Status (exclude)'] = Array.isArray(status_excludes) ? status_excludes : [status_excludes]
        }
        if(status) {
          filters['status'] = Array.isArray(status) ? status : [status]
        }
        if(content_stats) {
          filters['Site link status'] = Array.isArray(content_stats) ? content_stats : [content_stats]
        }

        return baseLink + '?filters=' + JSON.stringify(filters)
      },
      buildLeadLink(siteId, status, checkDate) {
        let baseLink = `/company/${this.$store.state.company.company.id}/sites/${siteId}?tab=leads`;
        if(!siteId) {
          baseLink = `/company/${this.$store.state.company.company.id}/leads`;
        }

        const filters = {}
        if(checkDate) {
          filters['Acquired on'] = [this.dateStart, this.dateEnd]
        }
        if(this.userId) {
          filters['Acquired by'] = [this.userId]
        }
        if(status) {
          filters['status'] = Array.isArray(status) ? status : [status]
        }

        return baseLink + (baseLink.indexOf('?') !== -1 ? '&' : '?') + 'filters=' + JSON.stringify(filters)
      },
      setUpBatchCreate(siteId, clientId) {
        this.batchCreate.siteId = siteId
        this.batchCreate.clientId = clientId
        this.batchCreate.show = true
      },
      setUpLeadCreate(siteId, clientId) {
        this.leadCreate.siteId = siteId
        this.leadCreate.clientId = clientId
        this.leadCreate.show = true
      }
    },
  }
</script>
