<template>
  <div v-loading="loading">
    <div v-if="!projects.length">
      <span>No active projects were found.</span>
    </div>
    <div v-else>
      <div v-for="(project, i) in projects" :key="i">
        <div class="page-title px-2">
          <h1> {{project.name}} </h1>
        </div>
        <project-details
          v-model="projects[i]" :month-allocations.sync="projects[i].months_allocations"
          :show-title="false" dates-only :ref="`project${i}Components`"  />
        <link-allocation
          v-model="projects[i]" :month-allocations.sync="projects[i].months_allocations"
          :show-title="false" :ref="`project${i}Components`" />
        <sites-and-targets
          v-model="projects[i]" :month-allocations.sync="projects[i].months_allocations" :show-title="false"
          :allow-add-sites="false" :allow-add-site-targets="false" :allow-remove-site="false" :allow-remove-target="false"
           :ref="`project${i}Components`"
        />
        <div class="text-right">
          <el-button icon="el-icon-edit-outline" @click.prevent="updateProject(project, `project${i}Components`)" type="primary" size="medium">Save {{ project.name }}</el-button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import ProjectDetails from "@/components/views/Project/Details";
import LinkAllocation from "@/components/views/Project/LinkAllocation";
import SitesAndTargets from "@/components/views/Project/SitesAndTargets";
const moment = require('moment');
export default {
  name: 'ProjectAllocations',
  components: {SitesAndTargets, LinkAllocation, ProjectDetails},
  props: {
    clientId: {
      type: [Number, String],
      default: null
    }
  },
  data: function () {
    return {
      loading: false,
      projects: []
    }
  },
  mounted() {
    this.getProjects()
  },
  methods: {
    async updateProject(project, componentRefs, successAlert = true) {
      const projectComponents = Array.isArray(this.$refs[componentRefs]) ? this.$refs[componentRefs] : [this.$refs[componentRefs]]
      this.loading = true;

      for(const target of projectComponents) {
        if(typeof target.validate === 'function') {
          const result = await target.validate();
          if(!result) {
            this.loading = false
            return false;
          }
        }
      }

      const cleanedProject = this.createProjectDataBeforeCreate(project);

      this.loading = true;
      this.$http.put(`/v1/companies/${this.$store.state.company.company.id}/projects/${project.id}`, cleanedProject)
        .then(() => {
          if(successAlert) {
            this.$message.success('Successfully updated the project');
          }
        })
        .catch(e => {
          console.error(e)
          this.$message.error('An error has occurred.')
        })
        .finally(() => {
          this.loading = false;
        })
    },
    getProjects() {
      this.loading = true;
      const clientId = this.clientId || this.$route.params.clientId || undefined
      this.$http.get(`/v1/companies/${this.$store.state.company.company.id}/projects`, {
        params: {
          client_id: clientId,
          active_only: true,
          all: true
        }
      }).then((res) => {
        this.projects = res.data.data.map(p => {
          p.months_allocations = []
          const monthIds = {}
          p.links_monthly_allocation = {}
          if(p.month_allocations) {
            p.month_allocations.forEach(m => {
              monthIds[m.id] = moment(m.month).format('MMMM YYYY')
              p.links_monthly_allocation = Object.assign({}, p.links_monthly_allocation, { [monthIds[m.id]]: m.allocation })
            })
          }

          p.sites = p.site_targets.map(target => {
            //console.log(target)
            const monthly = (target.monthly_allocations || [])
              .map(m => ({[monthIds[m.monthly_allocation_id]]: m.allocation }))
              .reduce((a, b) => Object.assign(a, b), {}) || {}

            return {
              id: target.site ? target.site.id : null,
              domain: target.site ? target.site.domain : null,
              default: target.allocation,
              anchor_text: target.anchor_text,
              traffic: target.traffic,
              dr: target.dr,
              ...monthly
            }
          });
          return p
        })
      }).catch((e) => {
        this.$message.error('Failed to load the projects')
        console.error(e)
      }).finally(() => {
        this.loading = false;
      })
    },
    createProjectDataBeforeCreate(Main) {
      const project = Object.assign({}, Main);
      let allowedKeys = ['id', 'default', 'dr', 'anchor_text', 'traffic'];
      let isMonthlyAllocated = false;
      if(Main.links_target_allocation_type === 'MONTHLY_ALLOCATED') {
        isMonthlyAllocated = true;
        allowedKeys = allowedKeys.concat(this.months_allocations);
      }

      project.sites.forEach(site => {
        Object.keys(site).forEach(targetKey => {
          if(!allowedKeys.includes(targetKey)) {
            delete site[targetKey];
          }
        })
      })

      if (!isMonthlyAllocated) {
        project.links_monthly_allocation = {};
      }

      project.client_id = this.clientId || this.$route.params.clientId;

      return project;
    },
  }
}
</script>
