<template>
  <div v-loading="loading || !loaded">
    <div class="page-title" v-if="showTitle">
      <h1>
        Productivity report
      </h1>
    </div>
    <div>
      <trieste-table
        :end-point="`/v1/companies/${this.$route.params.companyId}/reports/productivity`" :fields="fields"
        title="Productivity reports" item-type="reports" ref="table" :show-title="false"
      >
        <template v-slot:header-right>
          <el-button icon="el-icon-edit-outline"  size="medium" @click="showCreateDialog = true" type="primary">Create</el-button>
        </template>
        <template v-slot:filename="props">
          <a href="#" @click.prevent="downloadReport(props.row)" v-if="props.row.status === 'COMPLETE'">{{ props.row.filename }}</a>
          <span class="font-medium" v-else>{{ props.row.status.toLowerCase() }}</span>
        </template>
      </trieste-table>
    </div>
    <trieste-dialog title="Create Report" :show.sync="showCreateDialog" width="800px">
      <div class="p-4">
        <el-form label-position="left" label-width="120px" ref="createForm" :model="formData" :rules="rules">
          <el-form-item label="Spent Cost" prop="cost">
            <el-input type="number" v-model="formData.cost" />
          </el-form-item>
          <el-form-item label="Date range" prop="date_start">
            <date-range-selector default-column="1/2" :start.sync="formData.date_start" :end.sync="formData.date_end" />
          </el-form-item>
          <el-form-item label="Projects">
            <project-selector default-column="1/2" v-model="formData.projects" @selected-sites="projectSelectedSites" />
          </el-form-item>
          <el-form-item label="Sites">
            <site-selector default-column="1/2" v-model="formData.sites" />
          </el-form-item>
          <el-form-item label="User groups">
            <user-group-selector default-column="1/2" v-model="formData.user_groups" @change="setUsersFromGroups" />
          </el-form-item>
          <el-form-item label="Users">
            <user-selector default-column="1/2" v-model="formData.users" ref="userComponent" />
          </el-form-item>
        </el-form>
      </div>
      <template v-slot:footer-right>
        <el-button @click="() => { $refs.createForm.resetFields(); showCreateDialog = false }" icon="el-icon-close" type="danger" size="small">Cancel</el-button>
        <el-button type="success" icon="el-icon-edit-outline" @click="createReport" size="small">Create</el-button>
      </template>
    </trieste-dialog>
  </div>
</template>
<script>
  import ProjectSelector from "@/components/views/Reporting/ProjectSelector";
  import SiteSelector from "@/components/views/Reporting/SiteSelector";
  import UserGroupSelector from "@/components/views/Reporting/UserGroupSelector";
  import UserSelector from "@/components/views/Reporting/UserSelector";
  import DateRangeSelector from "@/components/views/Reporting/DateSelector";
  const moment = require('moment')
  const FileDownload = require('js-file-download');
  export default {
    components: {
      UserSelector, UserGroupSelector, SiteSelector, ProjectSelector, DateRangeSelector
    },
    props: {
      showTitle: {
        type: Boolean,
        default: true
      }
    },
    data: function() {
      return {
        loading: false,
        loaded: true,
        formData: {
          cost: null,
          users: [],
          sites: [],
          date_start: null,
          date_end: null,
          projects: [],
          user_groups: []
        },
        options: {
          date_start: null,
          date_end: null,
          projects: [],
          sites: [],
          site_targets: [],
          user_groups: [],
          users: [],
          site_link_users: [],
          site_link_user_groups: [],
          show_options: false
        },
        showCreateDialog: false,
        user_view: false,
        rules: {
          cost: [
            { required: true, message: 'Please provide a cost' }
          ],
          date_start: [
            { required: true, message: 'Please provide a start and end date.' }
          ]
        },
        fields: [
          {
            name: 'filename',
            title: 'Filename'
          },
          {
            title: 'Sites',
            name: 'site_domains',
            formatter: (val) => val ? val.join(', ') : '',
            width: '300px'
          },
          {
            title: 'Users',
            name: 'user_names',
            formatter: (val) => val ? val.join(', ') : '',
            width: '300px'
          },
          {
            title: 'Total spend',
            name: 'total_spend',
            formatter: (val) => `$${val}`
          },
          {
            title: 'Start date',
            name: 'start_date',
            dateField: true
          },
          {
            title: 'End date',
            name: 'end_date',
            dateField: true
          },
          {
            title: 'Created at',
            name: 'created_at',
            dateField: true
          }
        ]
      }
    },
    methods: {
      projectSelectedSites(sites) {
        this.formData.sites = sites
      },
      setUsersFromGroups() {
        this.$refs.userComponent.selectByUserGroups(this.options.user_groups)
      },
      createReport() {
        this.$refs.createForm.validate(valid => {
          if(valid) {
            this.$http.post(`/v1/companies/${this.$route.params.companyId}/reports/productivity`, this.formData).then((res) => {
              this.$message.success('Successfully created the report')
              this.$refs.table.getData();
              this.$refs.createForm.resetFields();
              this.showCreateDialog = false
            }).catch(e => {
              console.error('error:' + e)
              this.$message.error('Failed to create the report')
            })
          }
        })
      },
      downloadReport(report) {
        //console.log('Downloading report')
        this.loading = true;
        const url = `/v1/companies/${this.$route.params.companyId}/reports/productivity/download/${report.id}`
        const options = {
          responseType: 'blob'
        };

        let  downloadName = report.filename

        this.loading = true;
        this.$http.get(url, options).then(r => {
          FileDownload(r.data, downloadName)
        })
          .finally(() => {
            this.loading = false;
          })
      }
    }
  }
</script>
