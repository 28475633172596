<template>
  <trieste-dialog :show.sync="show" custom-class="login-model" max-body-height="700px">
    <Login :should-reset-auth="false" session-expired :on-login-success="onLoginSuccess" />
  </trieste-dialog>
</template>
<script>
import Login from '@/components/views/Login/Form.vue';

export default {
  components: { Login },
  data() {
    return {
      show: true
    }
  },
  methods: {
    onLoginSuccess(response) {
      this.$store.commit('global/setTableSorting', response.data.data.tableOrder)
      this.$message.success('You have successfully logged in, reloading the page.')
      this.$store.commit('auth/loginModelSuccess')
    }
  }
}
</script>
<style lang="scss">
.login-model {
  .el-dialog__header, .td-header, .td-footer {
    display: none;
  }
  .el-dialog__body {
    padding: 0;
    background: transparent;
    .min-h-screen {
      min-height: auto;
      padding-top: 0;
      padding-bottom: 0;
      background: transparent;
    }
  }
  .td-body {
    padding: 0;
    background: transparent;
  }
  .logo {
    display: none;
  }
}
</style>
