<script>
export default {
  props: {
    value: {type: [Object, Array], required: true},
    tag: {type: String, default: 'div'},
    options: {
      type: Object, default: () => {
      }
    },
  },
  render: function (h) {
    let nodes = this.value
    if (!Array.isArray(nodes)) {
      nodes = [nodes]
    }
    //console.log('rendering?', nodes)
    return h(this.tag, this.options, nodes)
  }
}
</script>
