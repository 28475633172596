<template>
  <div v-loading="loading">
    <div class="page-title">
      <h1>
        <logo
          v-model="$store.state.site.site.base_url"
          class="mr-2"
          :size="30"
        />
        <span>{{ $store.state.campaign.campaign.name }}<span class="faded-text"><span class="faded-strike">/</span>Campaign</span></span>
      </h1>
      <div class="controls">
        <el-button type="success" icon="el-icon-edit" size="mini" @click="show_settings = true" v-if="$store.getters['auth/hasPermission']('campaign.update') && campaign.id">Edit</el-button>
      </div>
    </div>
    <div class="columns">
      <div class="column w-full">
        <el-tabs class="custom-tabs-v1" v-if="loaded" v-model="activeTabName" @tab-click="tabsChanged" ref="tabs">
          <el-tab-pane label="Batches" name="batches" v-if="$store.getters['auth/hasPermission']('batch.read')" lazy>
            <span slot="label">
               <a href="?tab=batches" @click.prevent>Batches</a>
             </span>
            <batch />
          </el-tab-pane>
          <el-tab-pane label="Links" name="links" v-if="$store.getters['auth/hasPermission']('link.read')" lazy>
            <span slot="label">
               <a href="?tab=links" @click.prevent>Links</a>
             </span>
            <links export-mode="BATCHLINKS" :show-title="false" export-name="all batch links" allow-mass-update />
          </el-tab-pane>
          <el-tab-pane label="Leads" name="leads" v-if="$store.getters['auth/hasPermission']('lead.read')" lazy>
              <span slot="label">
               <a href="?tab=leads" @click.prevent>Leads</a>
             </span>
            <links :active-only="true" :show-title="false" include-exchange-prices export-mode="LINKBUILDING" export-name="all approved leads"  />
          </el-tab-pane>
          <el-tab-pane label="Info" name="info">
            <span slot="label">
               <a href="?tab=info" @click.prevent>Info</a>
           </span>
            <trieste-card title="Details">
              <div class="p-4">
                <trieste-info-column label="Name" :value="$store.state.campaign.campaign.name" />
                <trieste-info-column label="status" :value="$store.state.campaign.campaign.status" />
                <trieste-info-column label="Created at">
                  <span class="text-xs font-light">{{ $store.state.campaign.campaign.created_at | momentLocal("ddd MMMM DD YYYY Z") }}</span>
                </trieste-info-column>
              </div>
            </trieste-card>
          </el-tab-pane>
        </el-tabs>
      </div>
    </div>
    <create-edit v-model="campaign" v-if="campaign.id && $store.getters['auth/hasPermission']('campaign.update')" :show.sync="show_settings" @success="refreshCampaign" />
  </div>
</template>
<script>
  import Batch from "./Batches/Index";
  import Links from "../../Links/Index";
  import tabPosition from '@/components/mixins/tab_position';
  import CreateEdit from '@/components/views/Campaign/CreateEdit';
  export default {
    name: 'ViewCompany',
    components: {Batch, Links, CreateEdit},
    mixins: [tabPosition],
    metaInfo() {
      return {
        title: () => `Campaign ${this.$route.params.campaignId}`,
      }
    },
    data() {
      return {
        loading: false,
        loaded: false,
        updating_campaign: false,
        campaign: this.$store.state.campaign.campaign,
        show_settings: false,
        updating_settings: false,
        activeTabName: "batches",
        company: Object.assign({}, this.$store.state.company.company),
        settings_rules: {
          name: [
            {required: true, min: 3, message: 'Please enter a valid name'}
          ],
          url: [
            {required: true, min: 3, message: 'Please enter a valid url', type: 'url'}
          ]
        }
      }
    },
    mounted() {
      if(!this.$store.getters['auth/hasPermission']('campaign.read')) {
        this.$message.error('You do not have permissions to view campaigns.');
        this.$router.push('/');
      }
      this.loading = true;

      this.$store.dispatch('company/getCompany', this.$route.params).then((company) => {
        this.$store.dispatch('campaign/getCampaign', this.$route.params).then((campaign) => {
          this.$store.dispatch('site/getSite', { siteId: campaign.site_id }).then((site) => {
            this.$store.dispatch('client/getClient', {clientId: site.client_id}).then((client) => {
              this.$store.commit('global/setBreadcrumbs', [
                {
                  path: '/',
                  label: company.name
                },

                {
                  path: `/company/${company.id}/clients`,
                  label: 'clients'
                },
                {
                  path: `/company/${company.id}/clients/${client.id}`,
                  label: client.name
                },
                {
                  path: `/company/${company.id}/clients/${client.id}?tab=sites`,
                  label: 'sites'
                },
                {
                  path: `/company/${company.id}/sites/${site.id}`,
                  label: site.domain
                },
                {
                  path: `/company/${company.id}/sites/${site.id}?tab=campaigns`,
                  label: 'campaigns'
                },
                {
                  label: this.$store.state.campaign.campaign.name,
                  path: `/company/${company.id}/campaigns/${campaign.id}`,
                },
                {
                  label: this.activeTabName[0].toUpperCase() + this.activeTabName.substring(1)
                }
              ])
              this.campaign = Object.assign({}, this.$store.state.campaign.campaign);
              this.loading = false;
              this.loaded = true;
            })
          })
        })
      })
    },
    methods: {
      refreshCampaign() {
        this.loading = true
        //console.log('Refreshing the campaign')
        this.$store.dispatch('campaign/getCampaign', this.$route.params).then((campaign) => {
          //console.log('The campgin', campaign)
          this.campaign = campaign
          this.loading = false
        })
      }
    }
  }
</script>
