module.exports = {
  name: 'User Roles',
  resource_name: 'roles_users',
  single_name: 'role user',
  showOnMenu: false,
  fields: [
    {
      name: 'role_id',
      label: 'Role',
      model: 'role',
      sortField: 'role.title',
      filterable: true,
      val: (val) => {
        return val ? val.title.replace(/_/g, ' ') : null
      },
      rules: [
        { required: true, message: 'Please select a role.' }
      ]
    },
    {
      name: 'user_id',
      label: 'User',
      model: 'user',
      filterable: true,
      sortField: 'user.firstname',
      val: (val) => val ? `${val.firstname} ${val.lastname}` : null,
      rules: [
        { required: true, message: 'Please select a user.' }
      ]
    }
  ]
}
