<template>
  <trieste-dialog :show.sync="show" title="Edit site" width="800px">
    <div class="p-6 pb-2">
      <el-form :rules="new_site_rules" :model="new_site" label-position="left" label-width="250px" ref="siteForm" v-loading="updating_settings">
        <el-form-item label="Site Url" prop="base_url">
          <el-input type="text" v-model="new_site.base_url" />
        </el-form-item>
        <el-form-item label="status" prop="status">
          <el-select v-model="new_site.status" class="w-full">
            <el-option value="active" label="Active" />
            <el-option value="suspended" label="Suspended" />
          </el-select>
        </el-form-item>
        <el-form-item label="Run initial backlink batch" v-if="!new_site.id">
          <el-switch v-model="new_site.create_backlink_batch" />
        </el-form-item>
        <el-form-item label-width="0px">Batch settings</el-form-item>
        <el-form-item prop="requirements" label="Requirements">
          <el-input type="textarea" v-model="new_site.requirements" />
        </el-form-item>
        <el-form-item prop="stop_words" label="Stop words">
          <el-input type="textarea" v-model="new_site.stop_words" />
        </el-form-item>
        <el-form-item label="Allowed leads per domain">
          <div style="max-width: 200px">
            <el-input type="number" v-model="new_site.max_link_leads_per_domain" />
          </div>
        </el-form-item>
        <el-form-item label="Minimum domain trust flow">
          <div style="max-width: 200px">
            <el-input type="number" v-model="new_site.filter_tf" />
          </div>
        </el-form-item>
        <el-form-item label="Minimum domain citation">
          <div style="max-width: 200px">
            <el-input type="number" v-model="new_site.filter_cf" />
          </div>
        </el-form-item>
        <el-form-item label="Minimum domain flow metrics ratio">
          <div style="max-width: 200px">
            <el-input type="number" v-model="new_site.filter_tf_cf" />
          </div>
        </el-form-item>
        <el-form-item label="Maximum domain flow metrics ratio">
          <div style="max-width: 200px">
            <el-input type="number" v-model="new_site.max_domain_flow_metrics_ratio" />
          </div>
        </el-form-item>
        <el-form-item label="Minimum domain Refs">
          <div style="max-width: 200px">
            <el-input type="number" v-model="new_site.filter_rd" />
          </div>
        </el-form-item>
        <el-form-item label="Maximum SEM Rush volume score">
          <div style="max-width: 200px">
            <el-input type="number" v-model="new_site.filter_semrush_volume_score" />
          </div>
        </el-form-item>
        <el-form-item label="Maximum SEM Rush traffic score">
          <div style="max-width: 200px">
            <el-input type="number" v-model="new_site.filter_semrush_traffic_score" />
          </div>
        </el-form-item>
        <el-form-item label="Minimum domain authority">
          <div style="max-width: 200px">
            <el-input type="number" v-model="new_site.min_domain_authority" />
          </div>
        </el-form-item>
        <el-form-item label="Maximum % nofollow links">
          <div style="max-width: 200px">
            <el-input type="number" v-model="new_site.filter_nofollow_percent" />
          </div>
        </el-form-item>
        <el-form-item label="Minimum Ahrefs domain rating">
          <div style="max-width: 200px">
            <el-input type="number" v-model="new_site.filter_ahrefs_domain_rating" />
          </div>
        </el-form-item>
        <el-form-item label="Minimum host trust flow">
          <div style="max-width: 200px">
            <el-input type="number" v-model="new_site.filter_host_tf" />
          </div>
        </el-form-item>
        <el-form-item label="Minimum host citation">
          <div style="max-width: 200px">
            <el-input type="number" v-model="new_site.filter_host_cf" />
          </div>
        </el-form-item>
        <el-form-item label="Minimum host flow metrics ratio">
          <div style="max-width: 200px">
            <el-input type="number" v-model="new_site.filter_host_tf_cf" />
          </div>
        </el-form-item>
        <el-form-item label="Ahref traffic score">
          <div style="max-width: 200px">
            <el-input type="number" v-model="new_site.ahref_traffic_score" />
          </div>
        </el-form-item>
        <el-form-item label="Return homepages only">
          <el-switch v-model="new_site.only_home" />
        </el-form-item>
        <el-form-item label="Discard previously found leads in Company">
          <el-switch v-model="new_site.discard_company_site_links" />
        </el-form-item>
        <el-form-item label="Allow previously found links">
          <el-switch v-model="new_site.not_discard_old_links" />
        </el-form-item>
        <el-form-item label="Discard links reviewed over 3 times">
          <el-switch v-model="new_site.discard_leads_reviewed_3_times" />
        </el-form-item>
        <el-form-item label="Discard url's over 1 year old" v-if="false">
          <el-switch v-model="new_site.discard_urls_over_1_year_old" />
        </el-form-item>
        <el-form-item label="Allow same domain leads">
          <el-switch v-model="new_site.allow_same_domain_site_links" />
        </el-form-item>
        <el-form-item label="Red alert mode">
          <el-switch v-model="new_site.red_alert_mode" />
        </el-form-item>
        <el-form-item label="Capture RLD data" v-if="false">
          <el-switch v-model="new_site.capture_rld_data" />
        </el-form-item>
      </el-form>
    </div>
    <template v-slot:footer-left>
      <el-button size="small" icon="el-icon-delete" type="danger" @click="deleteSite" v-if="$store.getters['auth/hasPermission']('site.delete') && new_site.id">Permanently delete this site</el-button>
    </template>
    <template v-slot:footer-right class="dialog-footer">
      <el-button @click="closeDialog" icon="el-icon-close" type="danger" size="small">Cancel</el-button>
      <el-button type="success" size="small" icon="el-icon-edit-outline" @click="updateSite"
                 v-if="!(new_site.id && $store.getters['auth/hasPermission']('site.create')) || (new_site.id && $store.getters['auth/hasPermission']('site.update'))">
        Confirm
      </el-button>
    </template>
  </trieste-dialog>
</template>
<script>
  import { formatResponseErrorMessage } from "@/utils/helpers";
  export default {
    props: {
      value: {
        type: Object,
        default: () => {
          return {
            only_home: false,
            capture_rld_data: true,
            status: 'active',
            ahref_traffic_score: null
          }
        }
      },
      show: {
        type: Boolean,
        default: false
      },
      clientId: {
        type: [Number, String],
        default: null
      }
    },
    data() {
      return {
        updating_settings: false,
        new_site: this.value,
        new_site_rules: {
          base_url: [
            {required: true, min: 3, type: 'url', trigger: 'blur', message: 'Please enter a valid website URL'}
          ],
          status: [
            {required: true, message: 'Please select a valid status', trigger: 'blur'}
          ],
        },
      }
    },
    methods: {
      closeDialog() {
        this.$emit('update:show', false)
      },
      updateSite() {
        this.$refs.siteForm.validate((valid) => {
          if(valid) {
            this.updating_settings = true;

            let method = this.$http.post;
            const client_id = this.clientId || this.$route.params.clientId
            let url = `/v1/companies/${this.$store.state.company.company.id}/sites`;

            if(this.new_site.id) {
              url += `/${this.new_site.id}`
              method = this.$http.put
            }

            const params = Object.assign({}, this.new_site, { client_id })

            method(url, params)
              .then(res => {
                this.$message.success('Successfully '+(this.new_site.id ? 'updated' : 'created')+' the site.')
                this.updating_settings = false;
                this.closeDialog()
                this.$emit('updated', res.data)
              })
              .catch((err) => {
                let message = formatResponseErrorMessage(err, 'updateSite')
                this.$message.error(message)
                console.error(err.toJSON(), message)
              })
              .finally(() => {
                this.updating_settings = false;
              })
          }
        })
      },
      deleteSite() {
        this.$confirm(`Are you sure you want to delete ${this.new_site.domain}?`, 'Are you sure?', {
          confirmButtonClass: 'el-button--danger'
        })
          .then(() => {
            this.updating_settings = true;
            this.$http.delete(`/v1/companies/${this.$store.state.company.company.id}/sites/${this.new_site.id}`)
              .then(() => {
                this.$message.success('Successfully deleted the site')
                this.closeDialog()
                this.$store.commit('site/setSite', {})
                this.$router.push(`/company/${this.$route.params.companyId}/clients/${this.$store.state.client.client.id}?tab=sites`)
              })
              .catch(() => {
                this.$message.error('Failed to delete the site. Please try again later')
              })
          })
          .catch(() => { /* do nothing */ })
          .finally(() => {
            this.updating_settings = false;
          })
      }
    }
  }
</script>
