<template>
  <trieste-dialog :show.sync="should_show" title="Edit target">
    <div class="p-6 pb-2">
      <create v-if="should_show" :visible.sync="should_show" :target-id="target.id" :show-buttons="false" ref="createWidget" />
    </div>
    <template v-slot:footer-left v-if="target.id && canDelete">
      <el-button type="danger" size="small" icon="el-icon-delete" @click="deleteTarget(target.id)">Permanently delete this target</el-button>
    </template>
    <template v-slot:footer-right>
      <el-button size="small" icon="el-icon-close" type="danger" @click="() => { $refs.createWidget.closeParentDialog() }">Cancel</el-button>
      <el-button size="small" icon="el-icon-edit-outline" type="success" @click="editTarget">Edit</el-button>
    </template>
  </trieste-dialog>
</template>
<script>
import Create from './Create'
export default {
  components: { Create },
  props: {
    show: {
      type: Boolean,
      default: false
    },
    onEdit: {
      type: Function,
      default: null
    },
    onDelete: {
      type: Function,
      default: null
    },
    target: {
      type: Object,
      default: () => {}
    },
    canDelete: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      should_show: this.show
    }
  },
  methods: {
    editTarget() {
      this.$refs.createWidget.createTarget((valid) => {
        //console.log('v', valid)
        if(valid) {
          if(typeof this.onEdit === 'function') {
            this.onEdit();
          }
          this.cancelRequest();
        }
      });
    },
    cancelRequest() {
      this.$refs.createWidget.closeParentDialog();
      this.should_show = false;
      this.$emit('update:show', false)
    },
    deleteTarget(targetId) {
      this.$confirm('Are you sure you want to delete this target?', 'Are you sure',{
        confirmButtonText: 'OK',
        cancelButtonText: 'Cancel',
        confirmButtonClass: 'el-button--danger'
      }).then(() => {
        const siteId = this.target.site_id || this.$route.params.siteId
        const urlPath = `/v1/companies/${this.$store.state.company.company.id}/sites/${siteId}/targets/${targetId}`;
        this.$http.delete(urlPath).then(() => {
          this.$message.success('Successfully deleted the site target.');
          if(typeof this.onDelete === 'function') {
            this.onDelete(target);
          }
          this.cancelRequest();
        }).catch(() => {
          this.$message.error('An error occurred deleting the target. Please try again later.')
        })
      }).catch(() => {
        //console.log('Error: could not update the site target.');
      })
    }
  },
  watch: {
    show: function(val) {
      if(this.should_show !== val) {
        this.should_show = val
      }
    },
    should_show: function(val) {
      if(this.show !== val) {
        this.$emit('update:show', val)
      }
    }
  }
}
</script>
