<template>
  <div v-loading="loading">
    <div class="page-title">
      <h1>
        Clients
      </h1>
    </div>
    <trieste-table
      item-type="clients"
      :end-point="`/v1/companies/${$store.state.company.company.id}/clients`"
      :fields="fields" ref="table"
      title="Clients"
      default-sort-prop="name"
      :filters="filters"
      :show-title="false"
      v-if="$store.getters['auth/hasPermission']('client.read')"
      saved-table-sort-name="client-index"
    >
      <template v-slot:header-right>
        <el-button type="success" size="medium" icon="el-icon-edit-outline" v-if="$store.getters['auth/hasPermission']('client.create')" @click="showCreateClient = true">Create</el-button>
      </template>
      <template v-slot:actions-slot="props">
        <a :href="`/company/${$store.state.company.company.id}/clients/${props.row.id}`">
        <el-button
          size="small"
          icon="el-icon-view"
          type="primary">
          View
        </el-button>
        </a>
      </template>
    </trieste-table>
    <trieste-dialog :show.sync="showCreateClient" :title="new_client.id ? 'Edit ' + new_client.name : 'Create a client'">
      <div class="p-6 pb-2">
      <el-form :rules="new_client_rules" :model="new_client" label-position="left" label-width="120px" ref="siteForm" v-loading="loading">
        <el-form-item label="Name" prop="name">
          <el-input type="text" v-model="new_client.name" />
        </el-form-item>
      </el-form>
      </div>
      <template v-slot:footer-right class="dialog-footer">
        <el-button @click="closeCreateClientDialog" type="danger" icon="el-icon-close" size="small">Cancel</el-button>
        <el-button type="success" size="small" icon="el-icon-edit-outline" v-if="$store.getters['auth/hasPermission']('client.create')" @click="createClient">Create</el-button>
     </template>
    </trieste-dialog>
  </div>
</template>
<script>
  import { formatResponseErrorMessage } from "@/utils/helpers";
  export default {
    metaInfo() {
      return {
        title: () => `${this.$store.state.company.company.name} Clients`
      }
    },
    data: function () {
      return {
        showCreateClient: false,
        new_client: {
          id: null,
          name: ''
        },
        loading: false,
        new_client_rules: {
          name: [
            {required: true, min: 3, trigger: 'blur', message: 'Please enter a name'}
          ],
        },
        fields: [
          {
            name: 'name',
            title: 'name',
            sortField: 'name'
          },
          {
            title: 'Sites',
            name: 'site_count',
            sortField: 'site_count',
            numberField: true
          },
          {
            title: 'Created at',
            name: 'created_at',
            dateField: true,
            sortField: 'created_at'
          },
          {
            title: '',
            name: 'actions-slot',
            sortField: 'created_at',
            width: '110px',
            fixed: 'right'
          },
        ],
        filters: [
          {
            label: 'Client',
          },
          {
            label: 'Created between',
            name: 'created_at',
            type: 'date',
            dateType: 'daterange'
          }
        ]
      }
    },
    mounted() {
      if(!this.$store.getters['auth/hasPermission']('client.read')) {
        this.$message.error('You do not have permissions to view clients.');
        this.$router.push('/');
      } else {
        this.$store.dispatch('company/getCompany', this.$route.params).then((company) => {
          this.$store.commit('global/setBreadcrumbs', [
            {
              path: `/`,
              label: company.name
            },
            {
              label: 'Clients'
            }
          ])
        })
      }
    },
    methods: {
      createClient() {
        this.$refs.siteForm.validate((valid) => {
          if(valid) {
            this.loading = true;

            let method = this.$http.post;
            let url = `/v1/companies/${this.$store.state.company.company.id}/clients`;

            if(this.new_client.id) {
              url += `/${this.new_client.id}`;
              method = this.$http.put;
            }

            method(url, this.new_client)
              .then(() => {
                this.$message.success('Successfully '+(this.new_client.id ? 'updated':'created')+' the client.');
                this.closeCreateClientDialog();
                this.$refs.table.getData()
              })
              .catch((err) => {
                let message = formatResponseErrorMessage(err)
                this.$message.error(message)
                console.error(err.toJSON(), message)
              })
              .finally(() => {
                this.loading = false;
              })
          }
        })
      },
      closeCreateClientDialog() {
        this.new_client = {
          id: null,
          name: ''
        };
        this.showCreateClient = false;
      },
    }
  }
</script>
