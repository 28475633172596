export default {
  table: {
    tableWrapper: '',
    tableHeaderClass: '',
    tableBodyClass: 'text-xs',
    tableClass: 'ui blue selectable unstackable celled table',
    loadingClass: 'loading',
    ascendingIcon: 'p-1 el-icon-caret-top',
    descendingIcon: 'p-1 el-icon-caret-bottom',
    ascendingClass: 'sorted-asc',
    descendingClass: 'sorted-desc',
    sortableIcon: ' ml-2 h-3 text-sm text-xs el-icon-d-caret',
    handleIcon: 'grey sidebar icon',
  },

  pagination: {
    wrapperClass: 'flex items-center justify-end p-4',
    activeClass: 'text-blue-800',
    disabledClass: 'text-gray-200',
    pageClass: 'w-8 h-8 text-xs m-1 inline-block flex items-center justify-center cursor-pointer font-bold text-sm text-gray-400',
    linkClass: 'w-8 h-8 text-xs m-1 inline-block flex items-center justify-center cursor-pointer font-bold text-sm',
    paginationClass: 'ui bottom attached segment grid',
    paginationInfoClass: 'left floated left aligned six wide column',
    dropdownClass: 'ui search dropdown',
    icons: {
      first: 'el-icon-d-arrow-left',
      prev: 'el-icon-arrow-left',
      next: 'el-icon-arrow-right',
      last: 'el-icon-d-arrow-right',
    }
  },

  paginationInfo: {
    infoClass: 'text-xs p-4',
  }
}
