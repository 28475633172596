import api from './../../utils/api';
export default {
  namespaced: true,
  state: {
    loading_company: false,
    company: {},
    companyUsers: [],
    requiresCompanySet: false
  },
  mutations: {
    setCompany: (state, company) => state.company = company,
    setLoadingCompany: (state, status) => state.loading_company = status,
    setRequiresCompanySet: (state, status) => state.requiresCompanySet = status,
  },
  actions: {
    getCompany({commit, state}, { companyId }) {
      // NOTE- this is the only module we dont need to refresh continuously.
      commit('setLoadingCompany', true)
      return new Promise((resolve, reject) => {
        if(state.company.id === companyId) {
          resolve(Object.assign({}, state.company))
        } else {
          api.get(`/v1/companies/${companyId}`).then(r => {
            commit('setCompany', r.data.data)
            commit('setLoadingCompany', false)
            commit('setRequiresCompanySet', false)
            resolve(Object.assign({}, r.data.data))
          }).catch(e => {
            reject(e)
          })
        }
      })
    }
  }
}
