<template>
  <div v-loading="loading || !loaded">
    <div class="page-title" v-if="showTitle">
      <h1>
        Content Item Report
      </h1>
      <div>
        <div class="inline-block mr-4">
          <el-switch v-model="user_view" inactive-text="Site View" active-text="User View" />
        </div>
        <el-button @click="options.show_options = !options.show_options" type="primary">
          {{ options.show_options ? 'Save options' : 'Show options' }}
        </el-button>
        <el-button type="primary" @click="downloadReport">
          Download
        </el-button>
      </div>
    </div>
    <options
      :date-start.sync="options.date_start"
      :date-end.sync="options.date_end"
      :projects.sync="options.projects"
      :sites.sync="options.sites"
      :site-targets.sync="options.site_targets"
      :users.sync="options.users"
      :user_groups.sync="options.user_groups"
      :show.sync="options.show_options"
      :status.sync="options.status"
      :type.sync="options.type"
      @on-close="refresh"
    />
    <div>
      <site-table :filters="options" v-if="!user_view" ref="siteTable" />
      <user-table :filters="options" ref="userTable" v-else />
    </div>
  </div>
</template>
<script>
  import SiteTable from "@/components/views/Reporting/Content/SiteTable";
  import UserTable from "@/components/views/Reporting/Content/UserTable";
  import Options from "@/components/views/Reporting/Options";
  const moment = require('moment')
  const FileDownload = require('js-file-download');
  export default {
    components: { SiteTable, UserTable, Options },
    props: {
      showTitle: {
        type: Boolean,
        default: true
      }
    },
    data: function() {
      return {
        loading: false,
        loaded: true,
        options: {
          date_start: null,
          date_end: null,
          projects: [],
          sites: [],
          site_targets: [],
          user_groups: [],
          users: [],
          site_link_users: [],
          site_link_user_groups: [],
          show_options: false,
          status: [],
          type: []
        },
        user_view: false
      }
    },
    methods: {
      refresh() {
        this.$refs.userTable.refresh()
        this.$refs.siteTable.refresh()
      },
      downloadReport() {
        this.loading = true
        this.$http.post(`/v1/companies/${this.$store.state.company.company.id}/reports/content/download`, this.options, {
          responseType: 'blob',
        }).then(r => {
          FileDownload(r.data, `Content items report.xlsx`)
        })
            .catch(e => {
              this.$message.error('Failed to download the report')
              console.error('The error', e)
            })
            .finally(() => {
              this.loading = false
            })
      },
    }
  }
</script>
