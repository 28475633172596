<template>
  <trieste-dialog :show.sync="show" title="Edit site" width="800px">
    <div class="p-6 pb-2">
      <el-form :rules="rules" :model="form" label-position="left" label-width="250px" ref="form" v-loading="loading">
      <el-form-item label="Site" prop="site_id">
        <el-select v-model="form.site_id" :loading="!resourcesLoaded" :disabled="!resourcesLoaded" filterable class="w-full">
          <el-option v-for="(site, i) in $store.state.site.sites" :key="i" :value="site.id" :label="site.base_url" />
        </el-select>
      </el-form-item>
      <el-form-item label="Clone email templates" prop="clone_templates">
        <el-switch v-model="form.clone_templates" />
      </el-form-item>
      <el-form-item label="Clone email accounts" prop="clone_email_accounts">
        <el-switch v-model="form.clone_email_accounts" />
      </el-form-item>
      </el-form>
    </div>
    <template v-slot:footer-left>
      <el-button size="small" icon="el-icon-delete" type="danger" @click="closeDialog">Cancel</el-button>
    </template>
    <template v-slot:footer-right class="dialog-footer">
      <el-button @click="closeDialog" icon="el-icon-close" type="danger" size="small">Cancel</el-button>
      <el-button type="success" size="small" icon="el-icon-edit-outline" @click="clone"
                 v-if="$store.getters['auth/hasPermission']('site.create')">
        Clone
      </el-button>
    </template>
  </trieste-dialog>
</template>
<script>
export default {
  props: {
    show: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      loading: false,
      form: {
        site_id: null,
        clone_templates: true,
        clone_email_accounts: true
      },
      rules: {
        site_id: [
          {required: true, message: 'Please select a site', trigger: 'blur'}
        ],
      },
      resourcesLoaded: false
    }
  },
  methods: {
    closeDialog() {
      this.$emit('update:show', false)
    },
    getResources() {
      //console.log('Getting resources')
      this.$store.dispatch('site/loadAllSites', {}).then(() => {
        //console.log('and got the sites', this.$store.state.site.loading_all_sites)
        this.resourcesLoaded = true
      })
    },
    clone() {
      this.$refs.form.validate(valid => {
        if(valid) {
          this.loading = true;
          const siteId = this.siteId || (this.site ? this.site.id : null) || this.$route.params.siteId || this.$store.state.site.site.id
          this.$http.post(`/v1/companies/${this.$store.state.company.company.id}/sites/${siteId}/copy`, this.form)
          .then(() => {
            this.$message.success('The site has been successfully cloned.')
            setTimeout(() => {
              window.location.reload()
            }, 600)
          }).catch((e) => {
            console.error(e)
            if(e && e.response && e.response.data && e.response.data.error) {
              this.$message.error(e.response.data.error)
            } else {
              this.$message.error('The was an error processing that request')
            }
          }).finally(() => {
            this.loading = false;
            this.closeDialog();
          })
        }
      })
    }
  },
  watch: {
    show(val) {
      //console.log('Here is the val', val)
      if(val && !this.resourcesLoaded) {
        //console.log('Lets do it')
        this.getResources()
      }
    }
  }
}
</script>
