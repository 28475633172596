<template>
  <el-dialog :visible.sync="showDialog" custom-class="transparent" :append-to-body="appendToBody" :width="width" :modal=false :show-close="showClose">
    <div class="tabbed-dialog">
      <el-tabs class="custom-tabs-v1" :active-name="activeTabName" v-if="loaded">
        <el-tab-pane v-for="(tab, i) in tabs" :key="'tab_' + i" :name="tab.name" :label="tab.label">
          <wrap-node :value="tab.value" />
        </el-tab-pane>
      </el-tabs>
    </div>
    <div class="bg-white flex flex-row justify-end p-4 rounded-br rounded-bl">
      <el-button @click="showDialog = false" type="danger" icon="el-icon-close" size="small">Close</el-button>
    </div>
  </el-dialog>
</template>
<script>
import WrapNode from "./WrapNode";
export default {
  components: {WrapNode},
  props: {
    show: {
      type: Boolean,
      default: false
    },
    width: {
      type: [String, Number],
      default: '600px'
    },
    loading: {
      type: Boolean,
      default: false
    },
    showClose: {
      type: Boolean,
      default: false
    },
    appendToBody: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      showDialog: this.show,
      tabs: [],
      activeTabName: null,
      loaded: false
    }
  },
  mounted() {
    this.filterSlots()
    this.loaded = true
    if(!this.activeTabName && this.tabs.length) {
      this.activeTabName = this.tabs[0].name;
    }
  },
  methods: {
    filterSlots() {
      const suitableSlots = this.$slots.default.filter(s => s.componentOptions && s.componentOptions.tag === "trieste-tabbed-dialog-section")
      suitableSlots.forEach(slot => {
        this.tabs.push({
          label: slot.componentOptions.propsData.label || slot.componentOptions.propsData.name,
          name: slot.componentOptions.propsData.name,
          value: slot
        })
      })
    }
  },
  watch: {
    show(val) {
      this.showDialog = val
    },
    showDialog(val) {
      this.$emit('update:show', val)
    }
  }
}
</script>
