module.exports = {
  data() {
    return {
      defaultFilters: {}
    }
  },
  mounted() {

  },
  methods: {

  }
}
