<template>
  <div>
    <div>
      <div>
        <span class="text-lg font-medium text-blue-900 mb-4 block">Date range</span>
      </div>
      <div class="-ml-4 mb-2">
        <el-date-picker type="daterange" v-model="v" :clearable="false" format="dd MMMM yyyy" @change="syncDates" />
      </div>
    </div>
  </div>
</template>
<script>
const moment = require('moment')
export default {
  props: {
    value: {
      type: Array,
      default: () => []
    },
    start: {
      type: String,
      default: null
    },
    end: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      v: [
        moment().subtract(1, 'month').startOf('month'),
        moment().subtract(1, 'month').endOf('month')
      ]
    }
  },
  mounted() {
    this.syncDates()
  },
  methods: {
    syncDates() {
      this.$emit('update:start', moment(this.v[0]).format('YYYY-MM-DD 00:00:00'))
      this.$emit('update:end', moment(this.v[1]).format('YYYY-MM-DD 23:59:59'))
    }
  }
}
</script>
<style lang="scss">
.el-date-editor--daterange {
  margin: .5em 0em .5em 1em
}
</style>
