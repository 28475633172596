<template>
  <div>
    <trieste-table
    :end-point="'/v1/companies/' + $store.state.company.company.id +'/sites/' + $route.params.siteId + '/targets'"
    :fields="fields"
    title="Site targets"
    item-type="site targets"
    ref="table"
    default-sort-prop="name"
    :show-title="false"
    saved-table-sort-name="target-index"
    >
      <template v-slot:header-right>
        <el-button
          icon="el-icon-edit-outline"
          size="medium"
          type="success"
          v-if="$store.getters['auth/hasPermission']('target.create')"
          @click="() => { targetEditId = null; showCreate = true }"
        >Create
        </el-button>
      </template>
      <template v-slot:monthly-links-slot="props">
        {{ props.row.monthly_links ? props.row.monthly_links.toLocaleString() : 0 }}<br/>
      </template>

      <template v-slot:total-links-slot="props">
        {{ props.row.total_links ? props.row.total_links.toLocaleString() : 0 }}<br/>
      </template>


      <template v-slot:actions-slot="props">
        <a
          :href="`/company/${$route.params.companyId}/site-targets/${props.row.id}`"
          v-if="$store.getters['auth/hasPermission']('target.read')"
          >
        <el-button
          size="small"
          icon="el-icon-view"
          type="primary">
          View
        </el-button>
        </a>
        <div class="inline-block ml-2" v-if="$store.getters['auth/hasPermission']('site.update')">
          <el-button
            @click="openEditDialog(props.row)"
            size="small"
            icon="el-icon-edit-outline"
            type="primary"
          >
            Edit
          </el-button>
        </div>
      </template>
    </trieste-table>
    <trieste-dialog :show.sync="showCreate" title="Create a target">
      <div class="p-6 pb-2">
        <create v-if="showCreate" :visible.sync="showCreate" :show-buttons="false" ref="createWidget" />
      </div>
      <template v-slot:footer-right>
        <el-button size="small" icon="el-icon-close" type="danger" @click="() => { $refs.createWidget.closeParentDialog() }">Cancel</el-button>
        <el-button size="small" icon="el-icon-edit-outline" type="success" @click="createTarget">Create</el-button>
      </template>
    </trieste-dialog>
    <site-target-update-widget :target="target" :on-delete="refresh" :on-edit="refresh" :show.sync="show_edit_dialog" />
  </div>
</template>

<script>
  import create from '../../../../components/views/SiteTargets/Create';
  import Styling from '../../../../components/vuetable.config';
  import VueTableControlsMixin from '../../../../components/mixins/vuetable-controls';
  import SiteTargetUpdateWidget from '@/components/views/SiteTargets/Update';
  export default {
    name: 'Targets',
    mixins: [VueTableControlsMixin],
    components: { create, SiteTargetUpdateWidget },
    data() {
      return {
        status: {
          options: [
            {value: 'all', label: 'All'},
            {value: 'active', label: 'Active'},
            {value: 'suspended', label: 'Suspended'}
          ],
          value: 'active'
        },
        loading: false,
        fields: [
          {
            title: 'Name',
            name: 'name',
            sortField: 'name',
          },
          {
            title: 'To Url',
            name: 'to_url',
            sortField: 'to_url',
          },
          {
            title: 'description',
            name: 'description',
            sortField: 'description',
          },
          {
            title: 'status',
            name: 'status',
            sortField: 'status',
          },
          {
            title: 'Content',
            name: 'content_count',
            sortField: 'content_count'
          },
          {
            title: 'Total Links',
            name: 'total_links',
            sortField: 'total_links',
          },
          {
            title: 'Monthly Links',
            name: 'monthly_links',
            sortField: 'monthly_links',
          },
          {
            title: 'Created',
            name: 'created_at',
            dateField: true,
            sortField: 'created_at',
          },
          {
            title: '',
            name: 'actions-slot',
            width: '200px',
            fixed: 'right'
          },
        ],
        sortOrder: [
          {
            field: 'name',
            direction: 'asc'
          }
        ],
        styling: {
          table: Styling.table,
          pagination: Styling.pagination
        },
        showCreate: false,
        targetEditId: null,
        target: {},
        show_edit_dialog: false
      }
    },
    beforeRouteEnter(to, from, next) {
      next(vm => {
        vm.$store.dispatch('site/getSite', { siteId: vm.$route.params.siteId, companyId: vm.$store.state.company.company.id} ).then(() => {
          vm.$store.commit('global/setBreadcrumbs', [
            {
              path: '/',
              label: 'Home'
            },
            {
              path: '/companies',
              label: 'Companies'
            },
            {
              path: `/companies/${vm.$store.state.company.company.id}`,
              label: vm.$store.state.company.company.name
            },
            {
              path: `/companies/${vm.$store.state.company.company.id}`,
              label: 'Sites'
            },
            {
              path: `/companies/${vm.$store.state.company.company.id}`,
              label: vm.$store.state.site.site.domain
            },
            {
              path: `/companies/${vm.$store.state.company.company.id}/targets`,
              label: 'Site targets'
            }
          ])
        })
      })
    },
    methods: {
      createTarget() {
        this.$refs.createWidget.createTarget((valid) => {
          if(valid) {
            this.$refs.table.getData()
          }
        });
      },
      closeShowCreate(shouldRefresh) {
        if(shouldRefresh !== false) {
          this.$refs.table.getData()
        }
        this.showCreate = false;
      },
      openEditDialog(row) {
        this.target = row;
        this.show_edit_dialog = true;
      },
      refresh() {
        this.$refs.table.getData();
      }
    }
  }
</script>
