<template>
  <div v-loading="loading">
    <div class="page-title">
      <h1>
        Email templates
      </h1>
    </div>
    <div class="columns">
      <div class="column w-full">
        <el-tabs class="custom-tabs-v1" v-model="activeTab">
          <el-tab-pane label="Templates" name="templates">
            <templates v-if="activeTab === 'templates'" />
          </el-tab-pane>
          <el-tab-pane label="Outreach groups" name="outreach-groups">
            <outreach-groups  v-if="activeTab === 'outreach-groups'"  />
          </el-tab-pane>
        </el-tabs>
      </div>
    </div>
  </div>
</template>
<script>
  import Templates from "@/views/Companies/EmailTemplates/Tabs/Templates";
  import OutreachGroups from "@/views/Companies/EmailTemplates/Tabs/OutreachGroup";
  export default {
    components: { Templates, OutreachGroups },
    data() {
      return {
        loading: false,
        activeTab: 'templates'
      }
    },
    mounted() {
      if(!this.$store.getters['auth/hasPermission']('template.read')) {
        this.$message.error('You do not have permissions to view email templates.');
        this.$router.push('/');
      } else {
        this.$store.dispatch('company/getCompany', this.$route.params).then(company => {
          this.$store.commit('global/setBreadcrumbs', [
            {
              path: '/',
              label: company.name
            },
            {
              label: 'Email templates'
            }
          ])
        })
      }
     }
  }
</script>
