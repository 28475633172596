<template>
  <div class="min-h-screen bg-gray-200 flex flex-col justify-center py-12 sm:px-6 lg:px-8" v-loading="running">
    <div class="sm:mx-auto sm:w-full sm:max-w-md">
      <img
        class="mx-auto h-12 w-auto"
        src="/imgs/logo.png"
        alt="Workflow"
      >
    </div>

    <div class="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
      <div class="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
        <form
          action="#"
          method="POST"
        >
          <div class="text-sm text-gray-600 mb-4">
            To reset your password, please enter your email address below. If valid, an email containing a link to reset your password will be sent out to you.
          </div>
          <div>
            <label
              for="email"
              class="block text-sm font-medium leading-5 text-gray-700"
            >
              Email address
            </label>
            <div class="mt-1 rounded-md shadow-sm">
              <el-input v-model="email" />
            </div>
          </div>

          <div class="mt-6">
            <span class="block w-full rounded-md shadow-sm">
              <el-button
                class="w-full"
                type="primary"
                @click="submit"
              >
                Request reset link
              </el-button>
            </span>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'ForgottenPassword',
  data () {
    return {
      email: '',
      running: false
    }
  },
    beforeRouteEnter(to, from, next) {
        next((vm) => {
            vm.$store.commit('auth/reset');
        });
    },
  methods: {
    submit() {
      if(!(/.+\@.+\..+/.test(this.email))) {
        return this.$message.error('Please enter a valid email address')
      }

      this.$http.post(`/v1/auth/forgotten-password`, {
        email: this.email
      }).then(() => {
        this.$message.success('If your account is valid, you will receive an email containing your reset link. Please check your spam folder.')
        this.message = ''
        this.$router.push('/login')
      }).catch(() => {
        this.$message.error('An error occurred processing that request. Please try again later or contact support.')
      })
    }
  }
}
</script>

<style scoped>

</style>
