<template>
  <div v-loading="loading">
    <trieste-table
      :title="getTitle"
      :item-type="`${customItemType || type} emails`"
      :end-point="getEndPoint"
      :params="getParams"
      :fields="getFields"
      :filters="getFilters"
      ref="table"
      v-if="ready"
      :default-filter-data="defaultFilters"
      :saved-table-sort-name="customFilterStoreName || ('emaillist-' + type)"
      :allow-selection="canMoveEmails" :selected-rows.sync="selectedRows"
      :row-class-function="type === 'all' ? isGreyedRow : null"
      :required-field-names="['id', 'ElsId']"
    >
      <template v-slot:header-right>
        <el-button size="medium" icon="el-icon-right" v-if="selectedRows.length && canMoveEmails" type="primary"
                   @click="show_move_dialog = true">Bulk move
        </el-button>
      </template>
      <template v-slot:site-link-slot="props">
        <div>
          <a
            :href="`/company/${$route.params.companyId}/leads/${props.row.site_link.id}`"
            v-if="props.row.site_link">
            <div class="flex flex-row items-center font-bold">
              <div class="mr-1">
                <logo-widget v-model="props.row.site_link.domain" :size="12"/>
              </div>
              {{ props.row.site_link.from_url }}
            </div>
          </a>
          <div v-else>
            <span class="text-gray-400">N/A</span>
          </div>
        </div>
      </template>
      <template v-slot:owner-slot="props">
        {{ props.row.owner ? `${props.row.owner.firstname} ${props.row.owner.lastname}` : 'N/A' }}
      </template>
      <template v-slot:site-slot="props">
        <div>
          <a
            :href="`/company/${$route.params.companyId}/sites/${props.row.site.id}`"
            v-if="props.row.site">
            <div class="flex flex-row items-center font-bold">
              <div class="mr-1">
                <logo-widget v-model="props.row.site.domain" :size="12"/>
              </div>
              {{ props.row.site.domain }}
            </div>
          </a>
          <div v-else>
            <span class="text-gray-400">N/A</span>
          </div>
        </div>
      </template>
      <template v-slot:actions-slot="props">
        <el-button type="primary" size="small" @click="openEmail(props.row.id)" icon="el-icon-view">View</el-button>
        <el-button type="primary" size="small"
                   @click="() => { move_link_id = props.row.id; show_move_dialog = true }"
                   v-if="!selectedRows.length && canMoveEmails" icon="el-icon-right">Move
        </el-button>
      </template>
    </trieste-table>
    <trieste-tabbed-dialog :show.sync="show_email_content" :key="showEmailContentKey" width="800px">
      <trieste-tabbed-dialog-section name="Content">
        <div v-html="renderEmailContent()" v-if="show_email_content"></div>
      </trieste-tabbed-dialog-section>
    </trieste-tabbed-dialog>
    <trieste-dialog :show.sync="show_move_dialog" title="Move to lead" width="900px">
      <div class="-mb-4" v-loading="updating_orphan">
        <link-widget active-only :custom-filters="move_widget_filters" :allow-batch-filters="false">
          <template v-slot:custom-actions="props">
            <el-button size="small" type="primary" icon="el-icon-right" @click="sortMove(props.row)">Move</el-button>
          </template>
        </link-widget>
      </div>
      <template v-slot:footer-right>
        <el-button type="danger" icon="el-icon-close" size="small"
                   @click="() => { move_link_id = null; show_move_dialog=false; custom_move_link_id = '' }">Close
        </el-button>
      </template>
      <template v-slot:header-right>
        <el-input placeholder="Lead ID" size="small" v-model="custom_move_link_id" class="input-with-select"
                  @submit="quickMoveToLead">
          <el-button slot="append" size="small" icon="el-icon-right" @click="quickMoveToLead">Move</el-button>
        </el-input>
      </template>
    </trieste-dialog>
  </div>
</template>
<script>
import LinkWidget from "@/views/Companies/Links/Index"
import LogoWidget from "@/components/incs/LogoWidget";

export default {
  components: {LogoWidget, LinkWidget},
  props: {
    type: {
      type: String,
      default: 'all'
    },
    accountId: {
      type: [String, Number],
      default: null
    },
    endPoint: {
      type: String,
      default: null
    },
    customTitle: {
      type: String,
      default: null
    },
    customItemType: {
      type: String,
      default: null
    },
    params: {
      type: Object,
      default: () => {
      }
    },
    includeSite: {
      type: Boolean,
      default: true
    },
    includeSiteLink: {
      type: Boolean,
      default: true
    },
    userType: {
      type: Boolean,
      default: false
    },
    customFilterStoreName: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      emailContent: {},
      loading: false,
      ready: false,
      show_email_content: false,
      opened_email: null,
      showEmailContentKey: 0,
      show_move_dialog: false,
      move_link_id: null,
      updating_orphan: false,
      defaultFilters: {},
      selectedRows: [],
      custom_move_link_id: '',
      filters: [
        {
          label: 'From'
        },
        {
          label: 'Subject'
        },
        {
          label: 'Date',
          type: 'date',
          dateType: 'daterange'
        },
        {
          label: 'URL',
          name: 'site_link'
        },
        {
          label: 'Lead ID',
          name: 'lead_id'
        },
        {
          label: 'Read',
          type: 'select',
          options: [{
            label: 'Read',
            value: true
          },
            {
              label: 'Unread',
              value: false
            }
          ],
        },
      ],
      move_widget_filters: [
        {
          label: 'id'
        },
        {
          label: 'Url'
        },
        /*{
          label: 'Batch ID',
          name: 'batch_id',
          type: 'select',
          multiple: true,
        },
        {
          label: 'Batch Name',
          name: 'batch_name',
          type: 'select',
          multiple: true,
        }*/
      ]
    }
  },
  computed: {
    getParams() {
      return {type: this.type, ...this.params}
    },
    getEndPoint() {
      return this.endPoint || `/v1/companies/${this.$store.state.company.company.id}/email-accounts/${this.accountId}/emails`
    },
    getFilters() {
      const filters = [...this.filters]
      if (this.type !== 'orphan') {
        filters.push({
          label: 'Sites',
          name: 'site',
          type: 'site',
          multiple: true
        }),
          filters.push({
            label: 'User',
            name: 'user_id',
            type: 'user',
            multiple: true
          })
      }
      return filters
    },
    getFields() {
      const fields = [];
      const toFormatter = (value, row) => {
        if((!value || (Array.isArray(value) && !value.length)) && !row.incoming) {
          if(row.site_link && row.site_link.contact_form_url) {
            return row.site_link.contact_form_url
          }
        }
        return value
      }

      const fromFormatter = (value, row) => {
        if((!value || (Array.isArray(value) && !value.length)) && !row.incoming) {
          if(row.site_link && row.site_link.contact_form_url) {
            return 'Contact form'
          }
        }
        return value
      }

      if (this.type !== 'all') {
        if (this.type === 'sent') {
          fields.push({name: 'to', sortField: 'to', title: 'To', formatter: toFormatter })
        } else {
          fields.push({name: 'from', sortField: 'from', title: 'From', formatter: fromFormatter})
        }
      } else {
        fields.push({name: 'to', sortField: 'to', title: 'To', formatter: toFormatter })
        fields.push({name: 'from', sortField: 'from', title: 'From', formatter: fromFormatter})
      }

      fields.push(
        {name: 'subject', sortField: 'subject', title: 'Subject'},
        {title: 'Date', name: 'date', sortField: 'date', dateField: true}
      );

      if (this.type !== 'orphan') {
        if (this.includeSite) {
          fields.push({title: 'Site', name: 'site-slot', fieldName: 'site'})
        }
        if (this.includeSiteLink) {
          fields.push({title: 'Site Link', name: 'site-link-slot', fieldName: 'site_link'})
        }
      }
      if ((this.type === 'sent' || this.type === 'incoming') && !this.userType) {
        fields.push({title: 'Owner', name: 'owner-slot', fieldName: 'owner'})
      }

      fields.push({name: 'actions-slot', width: '210px'})

      return fields;

    },
    getTitle() {
      return this.customTitle || `${this.type[0].toUpperCase()}${this.type.substring(1)} emails`
    },
    canMoveEmails() {
      if (this.type === 'orphan') {
        return true
      }

      if (this.type === 'sent' && this.$store.getters['auth/hasPermission']('emails.sent.move')) {
        return true
      }

      if (this.type === 'incoming' && this.$store.getters['auth/hasPermission']('emails.incoming.move')) {
        return true
      }

      return !!(this.$store.getters['auth/hasPermission']('emails.incoming.move') &&
        this.$store.getters['auth/hasPermission']('emails.sent.move'));
    }
  },
  mounted() {
    this.loading = false
    this.ready = true
    // if (this.type !== 'orphan') {
    //   this.$store.dispatch('site/loadAllSites', {}).then(() => {
    //     this.loading = false
    //     this.ready = true
    //   })
    // } else {
    //   this.loading = false
    //   this.ready = true
    // }
  },
  methods: {
    isGreyedRow(row) {
      return !row.incoming ? 'grey' : false;
    },
    openEmail(emailId) {
      if (!this.emailContent[emailId]) {
        this.loading = true;
        this.$http.get(`/v1/companies/${this.$store.state.company.company.id}/email-accounts/${this.accountId}/emails/${emailId}`)
          .then(r => {
            this.emailContent[emailId] = {
              htmlContent: r.data.data.htmlContent,
              textContent: r.data.data.textContent,
            }
            this.opened_email = emailId;
            this.showEmailContentKey++;
            this.show_email_content = true;
          })
          .catch(() => {
            this.$message.error('Failed to get the message contents.')
          })
          .finally(() => {
            this.loading = false;
          })
      } else {
        this.opened_email = emailId;
        this.showEmailContentKey++;
        this.show_email_content = true;
      }
    },
    quickMoveToLead() {
      //console.log('hello', this.custom_move_link_id, this.move_link_id)
      if (this.custom_move_link_id && this.custom_move_link_id !== '') {
        this.sortMove({
          id: this.custom_move_link_id
        })
      }
    },
    sortMove(link) {
      if (this.selectedRows.length) {
        this.bulkMoveLink(link)
      } else if (this.move_link_id) {
        this.moveLink(link)
      }
    },
    bulkMoveLink(link) {
      if (link && link.id && this.selectedRows.length) {
        this.updating_orphan = true;
        this.$http.put(`/v1/companies/${this.$store.state.company.company.id}/email-accounts/${this.accountId}/emails/bulk-move`, {
          site_link_id: link.id,
          emails: this.selectedRows.map(r => r.id)
        })
          .then(() => {
            this.$message.success('Successfully moved the emails')
            this.show_move_dialog = false
            this.$refs.table.clearSelection()
            this.custom_move_link_id = '';
            setTimeout(() => {
              this.$refs.table.getData()
            }, 600);
          })
          .catch((e) => {
            this.$message.error('An error occurred trying to move the emails')
            console.error(e)
          })
          .finally(() => {
            this.updating_orphan = false;
          })
      }
    },
    moveLink(link) {
      //console.log('here acount', link && link.id && this.move_link_id, link, link.id, this.move_link_id)
      if (link && link.id && this.move_link_id) {
        this.updating_orphan = true;
        this.$http.put(`/v1/companies/${this.$store.state.company.company.id}/email-accounts/${this.accountId}/emails/${this.move_link_id}`, {
          site_link_id: link.id
        })
          .then(() => {
            this.$message.success('Successfully moved the email.')
            this.show_move_dialog = false
            this.custom_move_link_id = '';
            setTimeout(() => {
              this.$refs.table.getData()
            }, 600);
          })
          .catch((e) => {
            this.$message.error('An error occurred trying to move the email.')
            console.error(e)
          })
          .finally(() => {
            this.updating_orphan = false;
          })
      }
    },
    renderEmailContent() {
      if (this.emailContent[this.opened_email]) {
        if (this.emailContent[this.opened_email].textContent) {
          return this.emailContent[this.opened_email].textContent.replace(/(\r)?\n/g, '<br />');
        }
        if (this.emailContent[this.opened_email].htmlContent) {
          return this.emailContent[this.opened_email].htmlContent.replace(/(\r)?\n/g, '<br />');
        }
      }
      return '';
    }
  }
}
</script>
