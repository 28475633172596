<template>
  <div>
    <trieste-table
      :end-point="'/v1/companies/' + $store.state.company.company.id + '/outreach-groups'"
      :fields="fields"
      title="Outreach groups"
      item-type="outreach groups"
      ref="table"
      :show-title="false"
      saved-table-sort-name="outreach-group"
    >
      <template v-slot:header-right>
        <el-button type="success" size="medium" v-if="$store.getters['auth/hasPermission']('template.create')"  icon="el-icon-edit-outline" @click="showCreateDialog = true">Create</el-button>
      </template>
      <template v-slot:actions-slot="props">
        <el-button
          v-if="$store.getters['auth/hasPermission']('template.update')"
          size="small"
          icon="el-icon-edit"
          @click="setupEdit(props.row)"
          type="primary">
          Edit
        </el-button>
      </template>

      <template v-slot:body-slot="props">
        {{props.row.body.length | shortenText(100) }}
      </template>
    </trieste-table>
    <trieste-dialog
      :show.sync="showCreateDialog"
      :title="outreachGroup.id ? 'Edit ' + outreachGroup.name : 'Create a new outreach group'"
      :show-close="false"
      :modal="false"
      width="800px"
    >
      <div class="p-6 pb-2">
        <el-form label-position="left" label-width="120px" :model="outreachGroup" :rules="form_rules" ref="outreachForm" v-loading="updating">
          <el-form-item label="Name" prop="name">
            <el-input v-model="outreachGroup.name" />
          </el-form-item>
          <el-form-item label="description" prop="description">
            <el-input v-model="outreachGroup.description" />
          </el-form-item>
          <el-form-item label="Active" prop="active">
            <el-switch v-model="outreachGroup.active" />
          </el-form-item>
          <el-form-item label="Email accounts" prop="email_accounts">
            <el-select v-model="outreachGroup.email_accounts" multiple filterable class="w-full">
              <el-option v-for="(ea, i) in email_accounts" :key="i" :value="ea.id" :label="ea.email" />
            </el-select>
          </el-form-item>
          <el-form-item label="Email templates" prop="email_templates">
            <el-select v-model="outreachGroup.email_templates" multiple filterable class="w-full">
              <el-option v-for="(ea, i) in email_templates" :key="i" :value="ea.id" :label="ea.name" />
            </el-select>
          </el-form-item>
        </el-form>
      </div>
      <template v-slot:footer-left>
        <el-button
          type="danger"
          icon="el-icon-delete"
          size="small"
          v-if="$store.getters['auth/hasPermission']('template.delete') && outreachGroup.id"
          @click="deleteOutreachGroup(outreachGroup)">
          Permanently delete this template
        </el-button>
      </template>
      <template v-slot:footer-right>
        <el-button type="danger" icon="el-icon-close" size="small" @click="closeCreateDialog">Cancel</el-button>
        <el-button type="info" icon="el-icon-edit-outline" size="small" @click="createOutreachGroup">{{ outreachGroup.id ? 'Edit' : 'Create' }}</el-button>
      </template>
    </trieste-dialog>
  </div>
</template>
<script>
  const blankOutReachGroup = {
    name: '',
    description: '',
    active: true,
    email_accounts: [],
    email_templates: []
  }
  export default {
    mounted() {
      this.getResources()
    },
    data: function () {
      return {
        fields: [
          {
            name: 'name',
            sortField: 'name',
            title: 'name',
          },
          {
            name: 'description',
            sortField: 'description',
            title: 'description',
          },
          {
            name: 'active',
            sortField: 'active',
            title: 'Active',
            booleanField: true,
          },
          {
            name: 'actions-slot',
            width: '110px'
          }
        ],
        outreachGroup: Object.assign({}, blankOutReachGroup),
        showCreateDialog: false,
        email_accounts: [],
        email_templates: [],
        updating: false,
        form_rules: {
          name: [
            {required: true, message: 'Please specify a name for this outreach' }
          ],
          email_accounts: [
            {min: 1, required: true, type: 'array', message: 'Please specify at least one email account.' }
          ],
          email_templates: [
            {min: 1, required: true, type: 'array', message: 'Please specify at least one email template.' }
          ]
        }
      }
    },
    methods: {
      setupEdit(item) {
        const i = Object.assign({}, item)
        //console.log(i)
        i.email_accounts = i.email_accounts.map(i => i.email_account_id)
        i.email_templates = i.email_templates.map(i => i.email_template_id)
        this.outreachGroup = i
        this.showCreateDialog = true
      },
      closeCreateDialog() {
        this.showCreateDialog = false
        this.outreachGroup = Object.assign({}, blankOutReachGroup);
      },
      getResources() {
        const requestParams = {
          params: {
            all:  true
          }
        }
        const requests = [
          this.$http.get(`/v1/companies/${this.$store.state.company.company.id}/email-accounts`, requestParams),
          this.$http.get(`/v1/companies/${this.$store.state.company.company.id}/email-templates`, requestParams)
        ]

        this.$http.all(requests).then(this.$http.spread((accounts, templates) => {
          this.email_accounts = accounts.data.data
          this.email_templates = templates.data.data
        })).catch(() => {
          this.$message.error('Failed to download the email accounts/templates.')
        })
      },
      createOutreachGroup() {
        this.$refs.outreachForm.validate(valid => {
          if(valid) {
            //console.log('wha?', valid);
            this.updating = true;
            let method = this.$http.post;
            let isCreate = true;
            let url = `/v1/companies/${this.$store.state.company.company.id}/outreach-groups`;
            if(this.outreachGroup.id) {
              method = this.$http.put;
              url += `/${this.outreachGroup.id}`;
              isCreate = false;
            }
            method(url, this.outreachGroup).then(() => {
              this.$message.success(`Successfully ${(isCreate ? 'created' : 'updated')} the outreach group.`)
              this.$refs.table.getData()
              this.closeCreateDialog()
            })
            .catch(() => {
              this.$message.error(`Failed to ${(isCreate ? 'create' : 'update')} the outreach group.`)
            })
            .finally(() => {
              this.updating = false
            })
          }
        })
      },
      deleteOutreachGroup() {
        this.$confirm('Are you sure you want to delete this outreach group?', 'Are you sure?', {
          confirmButtonClass: 'el-button--danger'
        }).then(() => {
          this.updating = true;
          this.loading = true
          this.$http.delete(`/v1/companies/${this.$store.state.company.company.id}/outreach-groups/${this.outreachGroup.id}`).then(() => {
            this.$message.success('Successfully deleted that outreach group')
            this.$refs.table.getData()
            this.closeCreateDialog()
          }).catch(() => {
            this.$message.error('An error occurred trying to delete that outreach group.')
          })
            .finally(() => {
              this.updating = false
            })
        }).catch(() => {

        })
      }
    }
  }
</script>
