module.exports = {
  name: 'Sites',
  single_name: 'site',
  fields: [
    {
      name: 'domain',
      sortField: 'domain',
      filterable: true,
      readOnly: true
    },
    {
      name: 'base_url',
      label: 'Base url',
      sortField: 'base_url',
      filterable: true,
      readOnly: true
    },
    {
      name: 'company_id',
      label: 'Company',
      sortField: 'company.name',
      model: 'company',
      filterable: true,
      val: (val) => {
        return val ? val.name : null
      },
      rules: [
        { required: true,  message: 'Please select a company'}
      ]
    },
    {
      name: 'client_id',
      label: 'Client',
      sortField: 'client.name',
      model: 'client',
      filterable: true,
      val: (val) => {
        return val ? val.name : null
      },
      rules: [
        { required: true,  message: 'Please select a client'}
      ],
      filterSelection: (form, data) => {
        if(form.company_id) {
          return data.filter(row => row.company_id === form.company_id)
        } else {
          return data
        }
      }
    },
    {
      name: 'user_id',
      label: 'User',
      sortField: 'user.firstname',
      model: 'user',
      filterable: true,
      val: (val) => val ? `${val.firstname} ${val.lastname}` : null,
      rules: [
        { required: true,  message: 'Please select a company'}
      ]
    },
    {
      name: 'status',
      label: 'Active',
      sortField: 'status',
      type: 'select',
      defaultValue: 'active',
      filterable: true,
      options: [
        {label: 'Active', value: 'active'},
        {label: 'Suspended', value: 'suspended'},
      ],
      rules: [
        { required: true,  message: 'Please select a status'}
      ]
    },
    {
      name: 'created_at',
      label: 'Created at',
      sortField: 'created_at',
      type: 'date',
      filterable: true,
      hideOnCreate: true,
    },
    {
      name: 'updated_at',
      sortField: 'updated_at',
      label: 'Updated at',
      type: 'date',
      filterable: true,
      hideOnCreate: true
    },
  ]
}

