<template>
  <div v-loading="loading">
    <trieste-card title="Email templates">
      <div class="p-4">
        <ul>
          <li v-for="(template, i) in emailTemplates" :key="i">
            <el-checkbox-group v-model="selectedItems" :disabled="!canUpdate">
              <el-checkbox :label="template.id" @change="(v) => startMultiSelect(v, i, selectedItems, emailTemplates)">{{ template.name }}</el-checkbox>
            </el-checkbox-group>
          </li>
        </ul>
      </div>
      <template v-slot:footer v-if="canUpdate">
        <div class="text-right">
          <el-button type="primary" icon="el-icon-edit-outline" size="small" @click="update">Update</el-button>
        </div>
      </template>
    </trieste-card>
  </div>
</template>
<script>
import shiftSelectCheckboxes from '@/components/mixins/shift_select_checkboxes'
  export default {
  mixins: [shiftSelectCheckboxes],
    props: {
      items: {
        type: Array,
        default: () => []
      },
      siteId: {
        type: [String, Number],
        required: true
      }
    },
    data() {
      return {
        emailTemplates: [],
        loading: false,
        selectedItems: this.items
      }
    },
    mounted() {
      this.getResources()
    },
    computed: {
      canUpdate() {
        return this.$store.getters['auth/hasPermission']('template.update')
      }
    },
    methods: {
      getResources() {
        this.loading = true
        const resourceCalls = [
          this.$http.get(`/v1/companies/${this.$route.params.companyId}/email-templates`, {
            params: {
              all: true
            }
          })
        ]

        this.$http.all(resourceCalls).then(this.$http.spread((emailTemplates) => {
          this.emailTemplates = emailTemplates.data.data
        }))
        .finally(() => {
          this.loading = false
        })
      },
      update() {
        this.loading = true
        this.$http.post(`/v1/companies/${this.$route.params.companyId}/sites/${this.siteId}/templates/bulkAssign`, {
          templates: this.selectedItems
        }).then(() => {
          this.$emit('on-update')
          this.$message.success('Successfully updated the allowed email templates.')
        }).catch(() => {
          this.$message.error('Failed to update the allowed email templates.')
        })
        .finally(() => {
          this.loading = false
        })
      }
    }
  }
</script>
