<template>
  <div v-loading="loading">
    <trieste-table
      item-type="Blacklisted domains"
      title="blacklisted domains"
      :end-point="`/v1/companies/${$store.state.company.company.id}/sites/${$route.params.siteId}/blacklisted-domains`"
      :fields="fields"
      ref="table"
      default-sort-prop="domain"
      default-sort-direction="asc"
      :show-title="false"
      saved-table-sort-name="blacklist-site-domains"
    >
      <template v-slot:header-right>
        <el-button type="success" size="medium" icon="el-icon-edit-outline" v-if="$store.getters['auth/hasPermission']('blacklist.create')" @click="showCreateBlacklist = true">Create</el-button>
      </template>

      <template v-slot:actions-slot="props">
        <el-button
          size="small"
          icon="el-icon-edit"
          @click="() => { new_blacklist = Object.assign({}, props.row); showCreateBlacklist = true }"
          type="primary">
          Edit
        </el-button>
      </template>
    </trieste-table>
    <trieste-dialog :show.sync="showCreateBlacklist" :title="new_blacklist.id ? 'Edit ' + new_blacklist.domain : 'Create a blacklisted domain'">
      <div class="p-6 pb-2">
        <el-form :rules="new_blacklist_rules" :model="new_blacklist" label-width="120px" label-position="left" ref="siteForm" v-loading="loading">
          <el-form-item label="domain" prop="domain">
            <el-input type="text" v-model="new_blacklist.domain" />
          </el-form-item>
          <el-form-item label="description" prop="description">
            <el-input type="text" v-model="new_blacklist.description" />
          </el-form-item>
        </el-form>
      </div>
      <template v-slot:footer-left v-if="new_blacklist.id">
        <el-button type="danger" size="small" icon="el-icon-delete" v-if="$store.getters['auth/hasPermission']('blacklist.delete')" @click="deleteBlacklist(new_blacklist)">Permanently delete this blacklisted domain</el-button>
      </template>
      <template v-slot:footer-right>
        <el-button @click="closeCreateBlacklistDialog" icon="el-icon-close" type="danger" size="small">Cancel</el-button>
        <el-button type="success" icon="el-icon-edit-outline" @click="createBlacklist" size="small">Confirm</el-button>
      </template>
    </trieste-dialog>
  </div>
</template>
<script>
import { elementDomainValidator, formatResponseErrorMessage } from "@/utils/helpers";

  export default {
    beforeRouteEnter(to, from, next) {
      next(vm => {
        vm.$store.commit('global/setBreadcrumbs', [
          {
            path: '/',
            label: 'Home'
          },
          {
            label: 'Blacklist'
          }
        ])
      })
    },
    mounted() {
      if(!this.$store.getters['auth/hasPermission']('blacklist.read')) {
        this.$message.error('You do not have permissions to view blacklisted contacts');
        this.$router.push('/');
      }
    },
    data: function () {
      return {
        selected_placeholder: null,
        showCreateBlacklist: false,
        new_blacklist: {
          id: null,
          domain: '',
          description: null
        },
        new_blacklist_rules: {
          domain: [
            { required: true, min: 3, trigger: 'blur', message: 'Please enter a domain' },
            { validator: elementDomainValidator, trigger: 'submit' },
          ],
        },
        query: {
          rpp: 20,
          page: 1
        },
        loading: false,
        fields: [
          {
            name: 'domain',
            title: 'domain',
            sortField: 'domain',
            titleClass: 'text-left',
            dataClass: 'text-left',
          },
          {
            name: 'description',
            title: 'description',
            sortField: 'description',
            titleClass: 'text-left',
            dataClass: 'text-left',
          },
          {
            title: 'Created at',
            name: 'created_at',
            dateField: true,
            sortField: 'created_at',
          },
          {
            title: '',
            name: 'actions-slot',
            width: '110px',
            fixed: 'right'
          },
        ]
      }
    },
    methods: {
      deleteBlacklist(blacklist) {
        if(blacklist.id && blacklist.domain) {
          this.$confirm('Are you sure you want to delete the blacklist '+blacklist.domain+'?', 'are you sure?', {
            confirmButtonClass: 'el-button--danger'
          })
            .then(() => {
              this.loading = true;
              //console.log('deleting?')
              this.$http.delete(`/v1/companies/${this.$store.state.company.company.id}/sites/${this.$route.params.siteId}/blacklisted-domains/${blacklist.id}`)
                .then(() => {
                  this.$message.success('The blacklist has successfully been deleted.');
                  this.$refs.table.getData()
                  this.showCreateBlacklist = false
                })
                .catch(() => {
                  this.$message.error('The was an error processing that request. Please try again later.')
                })
                .finally(() => {
                  this.loading = false
                })
            })
            .catch(() => {
              // do nothign
            })
        }
      },
      createBlacklist() {
        this.$refs.siteForm.validate((valid) => {
          if(valid) {
            this.loading = true;

            let method = this.$http.post;
            let url = `/v1/companies/${this.$store.state.company.company.id}/sites/${this.$route.params.siteId}/blacklisted-domains`;

            if(this.new_blacklist.id) {
              url += `/${this.new_blacklist.id}`;
              method = this.$http.put;
            }

            method(url, this.new_blacklist)
              .then(() => {
                this.$message.success('Successfully '+(this.new_blacklist.id ? 'updated':'created')+' the blacklisted domain.');
                this.closeCreateBlacklistDialog();
                this.$refs.table.getData()
              })
              .catch((err) => {
                let message = formatResponseErrorMessage(err, 'createBlacklist')
                this.$message.error(message)
                console.error(err.toJSON(), message)
              })
              .finally(() => {
                this.loading = false;
              })
          }
        })
      },
      closeCreateBlacklistDialog() {
        this.new_blacklist = {
          id: null,
          domain: '',
          description: null
        };
        this.showCreateBlacklist = false;
      },
    }
  }
</script>
