<template>
<div v-loading="loading">
  <trieste-card title="Revisions" collapsable collapsed>
    <div class="p-4">
      <div v-if="!data.length">
        <span class="text-sm font-medium text-gray-600">No revisions were found.</span>
      </div>
      <div v-else>
        <ul>
          <li v-for="(revision, i) in data" :key="i" class="border-b pb-4 mb-4 border-gray-200">
           <div class="flex flex-row items-center justify-between flex-wrap">
             <div>
               <span class="font-bold text-blue-800">{{ revision.created_at | momentLocal("ddd MMMM DD YYYY Z") }}</span><br/>
               <span class="text-xs">
                {{  revision.created_at | moment("from", "now") }}
                </span>
               <div class="text-xs text-green-600">
                 <div v-if="revision.title_changed"><span><i class="el-icon-check font-bold mr-1"></i>Title changed</span></div>
                 <div v-if="revision.body_changed"><span><i class="el-icon-check mr-1"></i>Body changed</span></div>
               </div>
               <div class="text-xs mt-4" v-if="revision.user">
                 <span class="font-bold">Revision by: </span>{{ `${revision.user.firstname} ${revision.user.lastname}` }}
               </div>
             </div>
             <div>
               <el-button type="primary" icon="el-icon-view" size="small" @click="loadContent(revision.id)">View</el-button>
             </div>
           </div>
          </li>
        </ul>
      </div>
    </div>
  </trieste-card>
  <trieste-dialog :show="showComparison" width="90%" title="Revision comparison">
    <div class="p-6">
      <div v-if="history.title" class="mb-4">
        <div>
          <span class="text-lg font-medium text-blue-900 mb-4">Title</span>
        </div>
        <div class="flex flex-row">
          <div class="w-1/2">
            <div><span class="font-bold text-gray-600">Current</span></div>
            <div v-html="currentTitle"></div>
          </div>
          <div class="w-1/2">
            <div><span class="font-bold text-gray-600">Previous</span></div>
            <div v-html="history.title"></div>
          </div>
        </div>
      </div>
      <div v-if="history.body"  class="mb-4">
        <div>
          <span class="text-lg font-medium text-blue-900 mb-4">Body</span>
        </div>
        <div class="flex flex-row">
          <div class="w-1/2">
            <div><span class="font-bold text-gray-600">Current</span></div>
            <div v-html="currentBody"></div>
          </div>
          <div class="w-1/2">
            <div><span class="font-bold text-gray-600">Previous</span></div>
            <div v-html="history.body"></div>
          </div>
        </div>
      </div>
    </div>
    <template v-slot:footer-left>
      <el-button type="danger" icon="el-icon-close" size="small" @click="closeComparison">Close</el-button>
    </template>
    <template v-slot:footer-right>
      <el-button type="primary" icon="el-icon-edit-outline" v-if="$store.getters['auth/hasPermission']('content.update')" size="small" @click="restoreRevision">Restore</el-button>
    </template>
  </trieste-dialog>
</div>
</template>
<script>
  export default {
    props: {
      contentId: {
        type: [String, Number],
        required: true
      },
      currentTitle: {
        type: String,
      },
      currentBody: {
        type: String,
      }
    },
    data() {
      return {
        loading: false,
        data: [],
        history: {},
        showComparison: false
      }
    },
    mounted() {
      this.getData()
    },
    methods: {
      getData() {
        this.loading = true;
        this.$http.get(`/v1/companies/${this.$route.params.companyId}/content/${this.contentId}/history`)
          .then((r) => {
            this.data = r.data.data
          })
          .catch(() => {
            this.$message.error('Failed to load the revisions')
          })
          .finally(() => {
            this.loading = false
          })
      },
      loadContent(id) {
        this.loading = true;
        this.$http.get(`/v1/companies/${this.$route.params.companyId}/content/${this.contentId}/history/${id}`)
          .then((r) => {
            this.history = r.data.data
            this.showComparison = true
          })
          .catch(() => {
            this.$message.error('Failed to load the revision content')
          })
          .finally(() => {
            this.loading = false
          })
      },
      restoreRevision() {
        this.$confirm('Are you sure you want to revert back to this revision?', 'Are you sure?', {
          confirmButtonClass: 'el-button--primary'
        }).then(() => {
          this.$emit('set', this.history)
          this.closeComparison()
        }).catch(() => {})
      },
      closeComparison() {
      this.history = {}
      this.showComparison = false
      }
    }
  }
</script>
