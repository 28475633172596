var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],ref:"top",staticClass:"h-full"},[_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column w-2/6 xxl:w-1/6"},[_c('div',{staticClass:"p-4 pr-0 -mr-2 mt-12"},[_c('ul',{staticClass:"itemList"},[_vm._l((_vm.components),function(comp,i){return _c('li',{key:i,staticClass:"cursor-pointer",class:{
          'active': _vm.visibleIndex === i,
          'visited': _vm.visibleIndex >= i || (_vm.$route.params.projectId &&  _vm.project.saved_index >= i)
          },on:{"click":function($event){return _vm.changeIndex(i)}}},[_c('i',{staticClass:"icon",class:{
            'el-icon-check': _vm.visibleIndex !== i,
            'el-icon-arrow-right': _vm.visibleIndex === i,
            }}),_vm._v(" "+_vm._s(comp.label)+" ")])}),(this.id && _vm.$store.getters['auth/hasPermission']('project.create'))?_c('li',[_c('a',{staticClass:"text-red-600 underline",attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.deleteProject.apply(null, arguments)}}},[_c('i'),_vm._v("Delete Project")])]):_vm._e()],2)])]),_c('div',{staticClass:"column w-4/6 xxl:w-5/6 bg-white relative"},[_c('div',{staticClass:"absolute top-0 left-0 bg-white w-full h-screen"}),_c('div',{staticClass:"max-w-screen-md relative"},[(_vm.mode === 'create' && _vm.visibleIndex === 0)?_c('cloner',{model:{value:(_vm.project),callback:function ($$v) {_vm.project=$$v},expression:"project"}}):_vm._e(),_vm._l((_vm.components),function(comp,i){return _c(comp.component,{directives:[{name:"show",rawName:"v-show",value:(i === _vm.visibleIndex),expression:"i === visibleIndex"}],key:i,ref:"projectComponents",refInFor:true,tag:"component",attrs:{"disabled":!comp.enabled,"month-allocations":_vm.months_allocations,"allowVariation":_vm.project.link_price_type === 'VARIABLE'},on:{"update:monthAllocations":function($event){_vm.months_allocations=$event},"update:month-allocations":function($event){_vm.months_allocations=$event}},model:{value:(_vm.project),callback:function ($$v) {_vm.project=$$v},expression:"project"}})}),_c('div',{staticClass:"text-right px-4 -mt-3"},[(_vm.showNextButton && !_vm.isLastPage)?_c('el-button',{attrs:{"size":"medium","type":"primary"},on:{"click":_vm.processForm}},[_vm._v(" Next ")]):_vm._e(),(_vm.canEditProject)?_c('el-button',{attrs:{"size":"medium","type":"primary"},on:{"click":function($event){return _vm.processForm(true)}}},[_vm._v(" Save ")]):_vm._e()],1)],2)])])])
}
var staticRenderFns = []

export { render, staticRenderFns }