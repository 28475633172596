module.exports = {
  name: 'Role permissions',
  resource_name: 'roles_permissions',
  single_name: 'role permission',
  showOnMenu: false,
  fields: [
    {
      name: 'role_id',
      label: 'Role',
      model: 'role',
      sortField: 'role.title',
      filterable: true,
      val: (val) => {
        return val ? val.title.replace(/_/g, ' ') : null
      },
      rules: [
        { required: true, message: 'Please select a role.' }
      ]
    },
    {
      name: 'permission_id',
      label: 'Permission',
      model: 'permission',
      filterable: true,
      sortField: 'permission.name',
      val: (val) => val ? `${val.name}` : null,
      rules: [
        { required: true, message: 'Please select a permission.' }
      ]
    }
  ]
}
