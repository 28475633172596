<template>
<div>
  <lottie :options="{animationData: animationData}" :height="spin_size" :width="spin_size" />
</div>
</template>

<script>
  import animationData from "./../loader.lottie.json";

    export default {
        name: "Loader",
        props: {
          size: {
                type: Number,
                default(){
                  return 100
                }
            }
        },
        data(){
            return {
              spin_size: this.size,
              animationData: animationData,
            }
        }
    }
</script>

<style scoped>

</style>
