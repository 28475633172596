import axios from 'axios'
import store from './../store/index'

axios.defaults.baseURL = process.env.VUE_APP_TRIESTE_API;

// Append the Access Token on all API Requests
axios.interceptors.request.use(function (config) {
  if (store.getters['auth/accessToken']) {
    config.headers.Authorization = 'Bearer ' + store.getters['auth/accessToken']
  }
  return config
}, (error) => {
  return Promise.reject(error)
})

let refreshing_token = false;
let refresh_token_failed = false;

async function waitForRefreshToken(error) {
  if (refresh_token_failed) {
    store.commit('auth/reset');
    // if (process.env.VUE_SHOULD_NOT_401_LOGOUT !== true) window.location = '/login?mode=session-expired&redirect='

    // For now, return the error- on success we will just refresh the page.
    return Promise.reject(error)
  }
  if (refreshing_token) {
    await new Promise(resolve => setTimeout(resolve, 500))
    return waitForRefreshToken(error)
  }
  return axios(error.config)
}


// Handle Refresh token here.
axios.interceptors.response.use((response) => {
  return response
}, (error) => {
  if (error.response) {
    if (error.response.status === 401) {

      // console.log('and starting look')
      if (refreshing_token) {
        if(error.config.url === '/v1/auth/refresh-token') {
          refresh_token_failed = true;
          refreshing_token = false;
        }
        return waitForRefreshToken(error)
      }
      const refresh_token = store.state.auth.refresh_token
      const access_token = store.state.auth.access_token

      if (!refresh_token || !access_token) {
        refresh_token_failed = true;
        return waitForRefreshToken(error)
      }
      refreshing_token = true;

      return axios.post('/v1/auth/refresh-token', {
        refresh_token,
        access_token
      }).then((response) => {
        store.commit('auth/setAccessToken', response.data.token);
        store.commit('auth/setRefreshToken', response.data.refresh_token);
        return axios(error.config)
      }).catch(() => {
        refresh_token_failed = true;
        return waitForRefreshToken(error);
      }).finally(() => {
        refreshing_token = false
      })
    }
  }

  return Promise.reject(error)
})

export default axios
