<template>
  <div v-loading="loading">
    <trieste-table
      item-type="Blacklisted contacts"
      title="blacklisted contacts"
      :end-point="'/v1/companies/' + $route.params.companyId + '/blacklisted-contacts'"
      :fields="fields"
      ref="table"
      default-sort-prop="email"
      :show-title="false"
      saved-table-sort-name="blacklisted-contacts"
      :filters="filters"
      :allow-selection="$store.getters['auth/hasPermission']('blacklist.delete')"
      :selected-rows.sync="selected"
    >
      <template v-slot:header-right>
        <el-button type="success" size="medium" icon="el-icon-edit-outline" v-if="$store.getters['auth/hasPermission']('blacklist.create')" @click="newBlacklist">Create</el-button>
        <el-button type="success" size="medium" icon="el-icon-edit-outline" v-if="$store.getters['auth/hasPermission']('blacklist.create')" @click="bulkBlacklist.show = true">Bulk create</el-button>
        <el-button type="danger" size="medium" icon="el-icon-delete" v-if="$store.getters['auth/hasPermission']('blacklist.delete') && selected.length" @click="bulkDelete">Bulk delete</el-button>
      </template>

      <template v-slot:actions-slot="props">
        <el-button
          v-if="$store.getters['auth/hasPermission']('blacklist.update')"
          size="small"
          icon="el-icon-edit"
          @click="() => { new_blacklist = Object.assign({}, props.row); showCreateBlacklist = true }"
          type="primary">
          Edit
        </el-button>
      </template>
    </trieste-table>
    <trieste-dialog :show.sync="showCreateBlacklist" :title="new_blacklist.id ? 'Edit ' + new_blacklist.email : 'Create a blacklist'">
      <div class="p-6 pb-2">
        <el-form :rules="new_blacklist_rules" :model="new_blacklist" label-width="120px" label-position="left" ref="siteForm" v-loading="loading">
          <el-form-item label="Email" prop="email">
            <el-input type="email" v-model="new_blacklist.email" />
          </el-form-item>
        </el-form>
      </div>
      <template v-slot:footer-left v-if="new_blacklist.id">
        <el-button type="danger" size="small" icon="el-icon-delete" v-if="$store.getters['auth/hasPermission']('blacklist.delete')" @click="deleteBlacklist(new_blacklist)">Permanently delete this blacklist contact</el-button>
      </template>
      <template v-slot:footer-right>
        <el-button @click="closeCreateBlacklistDialog" icon="el-icon-close" type="danger" size="small">Cancel</el-button>
        <el-button type="success" icon="el-icon-edit-outline" @click="createBlacklist" size="small">Confirm</el-button>
      </template>
    </trieste-dialog>
    <trieste-dialog :show.sync="bulkBlacklist.show" title="Bulk create">
      <div class="p-6 pb-2">
        <el-form :rules="bulkRules" :model="bulkBlacklist" label-width="120px" label-position="left" ref="bulkForm" v-loading="loading">
          <el-form-item label="Emails" prop="text">
            <el-input type="textarea" v-model="bulkBlacklist.text" rows="10" resizable="false" />
            <span class="text-xs">Enter each email on a new line.</span>
          </el-form-item>
        </el-form>
      </div>
      <template v-slot:footer-right>
        <el-button @click="bulkBlacklist.show = false" icon="el-icon-close" type="danger" size="small">Cancel</el-button>
        <el-button type="success" icon="el-icon-edit-outline" @click="createBulkList" size="small">Confirm</el-button>
      </template>
    </trieste-dialog>
  </div>
</template>
<script>
  import { emailRegex } from '@/utils/helpers'
  export default {
    beforeRouteEnter(to, from, next) {
      next(vm => {
        vm.$store.commit('global/setBreadcrumbs', [
          {
            path: '/',
            label: 'Home'
          },
          {
            label: 'Blacklist'
          }
        ])
      })
    },
    mounted() {
      if(!this.$store.getters['auth/hasPermission']('blacklist.read')) {
        this.$message.error('You do not have permissions to view blacklisted contacts');
        this.$router.push('/');
      }
    },
    data: function () {
      const bulkEmailValidator = (rule, value, callback) => {
        if(value) {
          const emails = value.split('\n').map(e => e.trim())
          let line = 1;
          for(const email of emails) {
            if(!emailRegex.test(email)) {
              return callback(new Error(`The email on line ${line} is invalid.`))
            }
            line++;
          }
          return callback()
        } else {
          return callback(new Error('Please enter at least one email.'))
        }
      }
      return {
        selected_placeholder: null,
        showCreateBlacklist: false,
        selected: [],
        bulkBlacklist: {
          text: '',
          show: false
        },
        new_blacklist: {
          id: null,
          email: ''
        },
        new_blacklist_rules: {
          email: [
            {required: true, min: 3, type: 'email', trigger: 'blur', message: 'Please enter a email'}
          ],
        },
        bulkRules: {
          text: [
            { required: true, validator: bulkEmailValidator, trigger: 'blur' }
          ]
        },
        query: {
          rpp: 20,
          page: 1
        },
        loading: false,
        fields: [
          {
            name: 'email',
            title: 'email',
            sortField: 'email',
            titleClass: 'text-left',
            dataClass: 'text-left',
          },
          {
            title: 'Created at',
            name: 'created_at',
            dateField: true,
            sortField: 'created_at',
          },
          {
            title: '',
            name: 'actions-slot',
            width: '110px',
            fixed: 'right'
          },
        ],
        sortOrder: [
          {
            field: 'email',
            direction: 'asc'
          }
        ],
        filters: [
          {
            label: 'Email',
          }
        ]
      }
    },
    methods: {
      newBlacklist() {
        this.new_blacklist = {
          id: null,
          email: ''
        }
        this.showCreateBlacklist = true
      },
      deleteBlacklist(blacklist) {
        if(blacklist.id && blacklist.email) {
          this.$confirm('Are you sure you want to delete the blacklist '+blacklist.email+'?', 'are you sure?', {
            confirmButtonClass: 'el-button--danger'
          })
            .then(() => {
              this.$http.delete( `/v1/companies/${this.$store.state.company.company.id}/blacklisted-contacts/${blacklist.id}`)
                .then(() => {
                  this.$message.success('The blacklist has successfully been deleted.');
                  this.$refs.table.getData()
                  this.showCreateBlacklist = false
                })
                .catch(() => {
                  this.$message.error('The was an error processing that request. Please try again later.')
                })
            })
            .catch(() => {
              // do nothign
            })
        }
      },
      bulkDelete() {
        if(this.selected.length) {
          this.$confirm('Are you sure you want to delete these contacts?').then(() => {
            this.loading = true;
            this.$http.delete(`/v1/companies/${this.$route.params.companyId}/blacklisted-contacts`, {
              data: {
                contacts: this.selected.map(s => s.id)
              }
            }).then(() => {
              this.$refs.table.clearSelection()
              this.$refs.table.getData()
            }).finally(() => {
              this.loading = false
            })
          }).catch(() => {
          })
        }
      },
      createBulkList() {
        this.$refs.bulkForm.validate((valid) => {
          if(valid) {
            this.loading = true
            this.$http.post(`/v1/companies/${this.$route.params.companyId}/blacklisted-contacts/bulk-create`, {
              emails: this.bulkBlacklist.text.split('\n').map(e => e.trim())
            }).then(() => {
              this.$message.success('Successfully created the items.')
              this.bulkBlacklist.show = false
              this.bulkBlacklist.text = ''
              this.$refs.table.getData()
            }).catch(() => {
              this.$message.error('Failed to create the bulk items')
            }).finally(() => {
              this.loading = false
            })
          }
        })
      },
      createBlacklist() {
        this.$refs.siteForm.validate((valid) => {
          if(valid) {
            this.loading = true;

            let method = this.$http.post;
            let url = `/v1/companies/${this.$store.state.company.company.id}/blacklisted-contacts`;

            if(this.new_blacklist.id) {
              url += `/${this.new_blacklist.id}`;
              method = this.$http.put;
            }

            method(url, this.new_blacklist)
              .then(() => {
                this.$message.success('Successfully '+(this.new_blacklist.id ? 'updated':'created')+' the blacklist.');
                this.closeCreateBlacklistDialog();
                this.$refs.table.getData()
              })
              .catch((err) => {
                if(err.response && err.response.data && err.response.data.error && err.response.data.error === 'exists') {
                  this.$message.error('Blacklist item already exists.')
                } else {
                  this.$message.error('An error has occurred.')
                }
              })
              .finally(() => {
                this.loading = false;
              })
          }
        })
      },
      closeCreateBlacklistDialog() {
        this.new_blacklist = {
          id: null,
          email: ''
        };
        this.showCreateBlacklist = false;
      },
    }
  }
</script>
