const domainRegex = /^([a-z0-9]+(-[a-z0-9]+)*\.)+[a-z]{2,}$/
const moment = require('moment')
module.exports.emailRegex = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
module.exports.urlInTextRegexOld = /((?:(?:https?|ftp):\/\/|\b(?:[a-z\d]+\.))(?:(?:[^\s()<>]+|\((?:[^\s()<>]+|(?:\([^\s()<>]+\)))?\))+(?:\((?:[^\s()<>]+|(?:\(?:[^\s()<>]+\)))?\)|[^\s`!()\[\]{};:'".,<>?«»“”‘’]))?)/g;
module.exports.urlInTextRegex = /(((?:http:\/\/|https:\/\/)[\w\.\\/-]+[^\.\W]))/g;
module.exports.domainRegex = domainRegex
module.exports.copyToClipboard = (text) => {
  const copyText = document.createElement("input");
  copyText.value = text;
  document.body.appendChild(copyText)
  copyText.select();
  copyText.setSelectionRange(0, 99999);
  document.execCommand("copy");
  document.body.removeChild(copyText);
}
module.exports.elementDomainValidator = (rule, value, callback) => {
  let valid = false;
  if(value && value.indexOf('.') !== -1) {
    try {
      if(domainRegex.test(value)) {
        valid = true
      }
    } catch(e) {}
  }

  if(value.startsWith('www.') || value.startsWith('http://') || value.startsWith('https://')) {
    return callback(new Error('Please do not include www., http://... etc'));
  }

  if(!valid) {
    return callback(new Error('Please enter a valid domain'));
  }

  return callback()
}
module.exports.datePickerOptions = {
  shortcuts: [
    {
      text: 'DTD',
      onClick(picker) {
        const endDate = moment().format('YYYY-MM-DD')
        picker.$emit('pick', [endDate, endDate]);
      }
    },
    {
      text: 'MTD',
      onClick(picker) {
        const endDate = moment().toISOString()
        const startDate = moment().startOf('month').format('YYYY-MM-DD')
        picker.$emit('pick', [startDate, endDate]);
      }
    }, {
      text: 'YTD',
      onClick(picker) {
        const endDate = moment().toISOString()
        const startDate = moment().startOf('year').format('YYYY-MM-DD')
        picker.$emit('pick', [startDate, endDate]);
      }
    }
  ]
}
module.exports.formatResponseErrorMessage = (err, location = '') => {

  if (location !== '') {
    location = ` in ${location}`
  }

  let message = `An error occurred${location}: ${err}`;
  if(err.response && err.response.data && err.response.data.error) {
    message += `\r\n Response: ${err.response.data.error}`
  }

  return message
}
