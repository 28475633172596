<template>
  <div>
    <div class="page-title">
      <h1>Publisher History</h1>
    </div>

    <div class="border border-solid border-gray-200 rounded shadow-sm bg-white p-6">
      <div class="grid grid-cols-8 gap-4 gap-x-8">
        <div class="col-span-6">
          <label class="uppercase text-sm font-bold text-gray-600 mb-2 block">Search Query</label>
          <el-input
            :disabled="running"
            @enter="() => fetch(1)"
            v-model="search.q" />
        </div>
        <div class="col-span-1">
          <label class="uppercase text-sm font-bold text-gray-600 mb-2 block">Sort by</label>
          <el-select
            :disabled="running"
            v-model="search.sort_by">
            <el-option label="Ahrefs rel" value="rel"/>
            <el-option label="Ahrefs Domain Rating" value="dr"/>
            <el-option label="Ahrefs Traffic" value="traffic"/>
            <el-option label="Majestic Trust Flow" value="tf"/>
          </el-select>
        </div>
        <div class="col-span-1">
          <label class="uppercase text-sm font-bold text-gray-600 mb-2 block">Per Page</label>
          <el-select
            :disabled="running"
            v-model="search.rpp">
            <el-option
              v-for="i of [50, 75, 100, 250]"
              :key="'rpp' + i"
              :label="i" :value="i"/>
          </el-select>
        </div>
        <div class="col-span-4">
          <div class="grid grid-cols-3 gap-4">

            <div class="flex items-center">
              <label class="uppercase text-sm font-bold text-gray-600">Ahrefs Domain Rating</label>
            </div>
            <div class="col-span-2">
              <div class="flex gap-x-5 items-center w-full">
                <span class="text-xs text-gray-500">Min</span>
                <el-input-number
                  :disabled="running"
                  v-model="search.min_dr"
                  :min="0" :max="100" />
                <span class="text-xs text-gray-500">Max</span>
                <el-input-number
                  :disabled="running"
                  v-model="search.max_dr"
                  :min="0" :max="100" />
              </div>
            </div>

            <div class="flex items-center">
              <label class="uppercase text-sm font-bold text-gray-600">Majestic Trust Flow</label>
            </div>
            <div class="col-span-2">
              <div class="flex gap-x-5 items-center w-full">
                <span class="text-xs text-gray-500">Min</span>
                <el-input-number
                  :min="0"
                  :disabled="running"
                 v-model="search.min_tf" />
                <span class="text-xs text-gray-500">Max</span>
                <el-input-number
                  :min="0"
                  :disabled="running"
                  v-model="search.max_tf" />
              </div>
            </div>

            <div class="flex items-center">
              <label class="uppercase text-sm font-bold text-gray-600">Ahrefs Traffic</label>
            </div>
            <div class="col-span-2">
              <div class="flex gap-x-5 items-center w-full">
                <span class="text-xs text-gray-500">Min</span>
                <el-input-number
                  :min="0"
                  :disabled="running"
                  v-model="search.min_traffic" />
                <span class="text-xs text-gray-500">Max</span>
                <el-input-number
                  :min="0"
                  :disabled="running"
                  v-model="search.max_traffic" />
              </div>
            </div>


            <div class="flex items-center">
              <label class="uppercase text-sm font-bold text-gray-600">Only Repeat Publishers</label>
            </div>
            <div class="col-span-2">
              <el-switch
                v-model="search.priority"
                active-color="#13ce66"
                inactive-color="#ff4949">
              </el-switch>
            </div>

          </div>
        </div>
        <div class="col-span-4">
          <div class="grid grid-cols-3 gap-4">
            <div>
              <label class="uppercase text-sm font-bold text-gray-600 mb-2 block">Domain</label>
            </div>
            <div class="col-span-2">
              <el-input
                :disabled="running"
                :clearable="true"
                v-model="search.domain" />
            </div>
            <div>
              <label class="uppercase text-sm font-bold text-gray-600 mb-2 block">Language</label>
            </div>
            <div class="col-span-2">
              <el-input
                :clearable="true"
                :disabled="running"
                v-model="search.lang" />
            </div>
            <div>
              <label class="uppercase text-sm font-bold text-gray-600 mb-2 block">Country (ISO)</label>
            </div>
            <div class="col-span-2">
              <el-input
                :clearable="true"
                :disabled="running"
                v-model="search.country" />
            </div>
            <div>
              <label class="uppercase text-sm font-bold text-gray-600 mb-2 block">
                ccTLD
              </label>
            </div>
            <div class="col-span-2">
              <el-input
                :clearable="true"
                :disabled="running"
                v-model="search.cctld" />
            </div>
          </div>
        </div>
        <div class="col-span-8 flex justify-end">
          <el-button
            :disabled="running"
            @click="() => fetch()"
            :loading="running"
            type="primary">Search</el-button>
        </div>
      </div>
      <div>

        <div class="flex items-center justify-center w-full p-20" v-if="running">
          <el-spinner />
        </div>
        <div v-else>
          <div v-if="results.length > 0">


            <div class="my-3">
              <div class="rounded-md bg-blue-100 p-4">
                <div class="flex">
                  <div class="ml-3 flex-1 md:flex md:justify-between">
                    <p class="text-sm text-blue-700">
                      {{ meta.totalResult ? meta.totalResult.toLocaleString() : 0 }} results found. Displaying page {{ meta.current ? meta.current : 0 }} of {{ meta.pageCount ? meta.pageCount : 0  }}
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div class="flex justify-end mb-3">
              <el-dropdown @command="download">
                <el-button type="primary">
                  Download <i class="el-icon-arrow-down el-icon--right"></i>
                </el-button>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item command="current">Current Page</el-dropdown-item>
                  <el-dropdown-item command="all">All</el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </div>


            <simple-pagination
              :current="meta.current ? meta.current : 1"
              :previous="meta.previous"
              :next="meta.next"
              @change="(p) => fetch(p)"
              :range="meta.range ? meta.range : []" />


            <ul role="list" class="divide-y divide-gray-100">
              <li v-for="(p, i) in results" class="text-gray-600 py-5" :key="'result-' + i">
                <div class="flex gap-5 w-full">
                  <div class="w-full flex-grow">
                    <h4 class="font-semibold text-sm flex items-center"> <img class="w-4 h-4 rounded mr-2" :src="'https://logo.clearbit.com/' + p.domain"> {{ p.domain }}</h4>
                    <a class="text-xs block mt-0 text-blue-600 underline hover:text-blue-900" target="_blank" :href="p.url">{{ p.url }}</a>
                    <p class="font-bold mt-2">{{ p.title }}</p>
                    <p class="leading-tight text-gray-600 mt-1 text-sm mt-2" v-html="p.snippet"></p>
                  </div>
                  <div class="w-72 no-grow flex-none">
                    <div class="p-4 text-xs bg-gray-100 shadow rounded">
                      <dl class="divide-y divide-gray-100">
                        <div class="sm:grid sm:grid-cols-2 sm:gap-2 sm:px-0">

                          <dt class="text-sm font-medium leading-1 tracking-tighter text-gray-900">Priority</dt>
                          <dd class="mt-1 text-sm leading-1 tracking-tighter text-gray-700 sm:mt-0 text-right">
                            <span
                              :class="{
                              'bg-green-100 text-green-600 border border-green-200': p.priority === 1,
                              'bg-red-100 text-red-600 border border-red-200': p.priority === 0
                              }"
                              class="p-1 px-2 rounded inline-block text-xs">
                              {{ p.priority === 1 ? 'Yes' : 'No' }}
                            </span>
                          </dd>


                          <dt class="text-sm font-medium leading-1 tracking-tighter text-gray-900">Ahrefs DR</dt>
                          <dd class="mt-1 text-sm leading-1 tracking-tighter text-gray-700 sm:mt-0 text-right">{{ p.dr ? p.dr.toLocaleString() : 0 }}</dd>

                          <dt class="text-sm font-medium leading-1 tracking-tighter text-gray-900">Ahrefs Traffic</dt>
                          <dd class="mt-1 text-sm leading-1 tracking-tighter text-gray-700 sm:mt-0 text-right">{{ p.at ? p.at.toLocaleString() : 0 }}</dd>

                          <dt class="text-sm font-medium leading-1 tracking-tighter text-gray-900">Majestic Trust flow</dt>
                          <dd class="mt-1 text-sm leading-1 tracking-tighter text-gray-700 sm:mt-0 text-right">{{ p.tf ? p.tf.toLocaleString() : 0 }}</dd>

                          <dt class="text-sm font-medium leading-1 tracking-tighter text-gray-900">Language</dt>
                          <dd class="mt-1 text-sm leading-1 tracking-tighter text-gray-700 sm:mt-0 text-right">{{ p.lang ? p.lang : 'N/A' }}</dd>

                          <dt class="text-sm font-medium leading-1 tracking-tighter text-gray-900">Country</dt>
                          <dd class="mt-1 text-sm leading-1 tracking-tighter text-gray-700 sm:mt-0 text-right flex items-center justify-end">
                            <flag class="text-lg shadow mr-2 rounded overflow-hidden" :iso="p.country.toLowerCase()" />
                            <span>{{ p.country ? p.country : 'N/A' }}</span>
                          </dd>


                        </div>
                        <div class="mt-4" v-if="p.more">
                          <el-button
                            class="w-full"
                            size="small"
                            @click="getDomainResults(p.domain)"
                            type="info">View  results for {{ p.domain }}</el-button>
                        </div>
                      </dl>
                    </div>
                  </div>
                </div>
              </li>
            </ul>


            <simple-pagination
              :current="meta.current ? meta.current : 1"
              :previous="meta.previous"
              :next="meta.next"
              @change="(p) => fetch(p)"
              :range="meta.range ? meta.range : []" />

          </div>
        </div>
      </div>
    </div>

  </div>
</template>
<script>

import SimplePagination from "@/components/incs/SimplePagination";

export default {
  components: {SimplePagination},
  data(){
   return {
     running: false,
     results: [],
     meta: {},
     search: {
       min_dr: 0,
       max_dr: 100,
       min_tf: 0,
       max_tf: 100,
       page: 1,
       rpp:  100,
       priority: false,
       cctld: null,
       domain: null,
       country: null,
       lang: null,
       sort_by: 'rel',
       q: ''
     }
   }
  },
  mounted() {
    this.$store.dispatch('company/getCompany', this.$route.params).then(company => {
      this.$store.commit('global/setBreadcrumbs', [
        {
          path: '/',
          label: company.name
        },
        {
          label: 'Publisher History'
        }
      ])
    })
  },
  methods: {
    download(event){
      this.fetch(1, event);
    },
    getDomainResults(domain){
      this.search.domain = domain;
      this.fetch(1)
    },
    fetch(page=1, download = null){
      const vm = this;
      vm.running = true;

      vm.search.page = page || 1;

      if(!download){
        vm.results = [];
      }

      let params = this.search;
      if(download !== null){
        params = Object.assign({
          export: true,
        }, this.search);

        if(download === 'all'){
          params = Object.assign(params,{
            rpp: 1000,
          });
        }
      }

      this.$http.get(`/v1/companies/${this.$store.state.company.company.id}/publisher-history`, {
        params
      })
        .then(r => {
          vm.running = false;

          if(download){
            window.open(r.data.data.url)
            return;
          }

          vm.results = r.data.data;
          vm.meta = r.data.meta;
        })
        .catch(() => {
          vm.running = false;
          this.$message.error('No results found')
        })
    }
  }
}
</script>
