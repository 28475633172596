module.exports = {
  name: 'Company Users',
  resource_name: 'company_users',
  single_name: 'company user',
  showOnMenu: false,
  fields: [
    {
      name: 'company_id',
      label: 'Company',
      model: 'company',
      sortField: 'company.name',
      filterable: true,
      val: (val) => {
        return val ? val.name : null
      },
      rules: [
        { required: true, message: 'Please select a company.' }
      ]
    },
    {
      name: 'user_id',
      label: 'User',
      model: 'user',
      filterable: true,
      sortField: 'user.firstname',
      val: (val) => val ? `${val.firstname} ${val.lastname}` : null,
      rules: [
        { required: true, message: 'Please select a user.' }
      ]
    }
  ]
}
