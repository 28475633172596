module.exports = {
  data() {
    return {
      allowedTabNames: [],
      tabRefFound: false
    }
  },
  mounted() {
    this.getAllowedTabs();
    this.checkLoadTab();
  },
  methods: {
    checkLoadTab() {
      const tab = this.$route.query.tab;
      if(tab) {
        if(this.tabRefFound) {
          if(this.allowedTabNames.includes(tab)) {
            this.activeTabName = tab
          }
        } else {
          this.activeTabName = tab
        }
      }
     },
    getAllowedTabs() {
      if(this.$refs.tabs) {
        this.tabRefFound = true
        if(this.$refs.tabs.panes) {
          //console.log('Got to here?');
          this.$refs.tabs.panes.forEach(pane => {
            this.allowedTabNames.push(pane.name)
          })
        }
      }
      if(!this.activeTabName && this.allowedTabNames.length) {
        this.activeTabName = this.allowedTabNames[0];
      }
    },
    tabsChanged() {
      this.$router.push({ path: this.$route.fullPath, query: { tab: this.activeTabName}});
      this.$store.dispatch('global/setLastBreadcrumb', this.activeTabName[0].toUpperCase() + this.activeTabName.substring(1))
    }
  },
  watch: {
    '$route': 'checkLoadTab'
  }
}

