module.exports = {
  name: 'External client approvers',
  resource_name: 'approval_client_user',
  single_name: 'External client approver',
  showOnMenu: false,
  fields: [
    {
      name: 'client_id',
      label: 'Client',
      model: 'client',
      sortField: 'client.name',
      filterable: true,
      val: (val) => {
        return val ? val.title.replace(/_/g, ' ') : null
      },
      rules: [
        { required: true, message: 'Please select a client.' }
      ]
    },
    {
      name: 'user_id',
      label: 'User',
      model: 'user',
      filterable: true,
      sortField: 'user.firstname',
      val: (val) => val ? `${val.firstname} ${val.lastname}` : null,
      rules: [
        { required: true, message: 'Please select a user.' }
      ]
    }
  ]
}
