<template>
  <div v-loading="loading" class="min-h-40">
    <div class="mb-4 flex flex-row justify-between">
      <span class="text-2xl font-bold text-blue-800">{{resource.label}}</span>
    </div>
    <p v-if="relationFilters.note">{{relationFilters.note}}</p>
    <div class="mb-8">
    </div>
    <trieste-card :title="resource.label" collapsable collapsed>
      <template v-slot:header-right>
        <div class="inline-block mr-2">
          <el-button type="primary" size="small" @click="updateSelectedFields">Save</el-button>
        </div>
      </template>
      <div class="bg-white p-2 border-b border-gray-200 flex flex-row justify-between">
        <el-checkbox v-model="selectAll" @change="selectOrUnselectAll"><span>{{ selectAll ? 'Unselect' : 'Select All' }}</span></el-checkbox>
        <span class="text-xs cursor-pointer font-medium text-red-600" @click="resetFields">Reset</span>
      </div>
      <div v-for="(item, i) in allItems" :key="i" class="bg-white p-2 border-b border-gray-200">
        <el-checkbox-group v-model="selectedItems">
          <el-checkbox :label="item.id"><span>{{ displayListItem(item) }}</span></el-checkbox>
        </el-checkbox-group>
      </div>
    </trieste-card>
  </div>
</template>
<script>
  export default {
    props: {
      resource: {
        type: Object,
        required: true
      },
      endpoint: {
        type: String,
        required: true
      },
      relationFilters: {
        type: Object,
        default: () => {}
      },
      displayFields: {
        type: [String, Array],
        default: ''
      },
      target: {
        type: Object,
        default: () => {}
      }
    },
    data() {
      return {
        ready: false,
        loading: true,
        relations: [],
        selectedItems: [],
        allItems: [],
        lastSavedSelectedItems: [],
        selectedTableItems: [],
        selectAll: false,
      }
    },
    mounted() {
      if(this.resource.relations) {
        this.getRelations();
      }
      this.getAllSelectedItems();
    },
    methods: {
      displayListItem (item) {
        //this.resource.fields

        // check if we have a custom naming function from the join
        if(typeof this.relationFilters.listDisplayName === 'function') {
          return this.relationFilters.listDisplayName(item);
        }

        let fieldArray = []
        if(this.displayFields) {
          if(Array.isArray(this.displayFields)) {
            fieldArray.push(...this.displayFields)
          } else if(this.displayFields === 'string') {
            fieldArray.push(this.displayFields)
          }
        } else {
          fieldArray = [this.resource.fields[0].name]
        }

        const data = []
        for(const fa of fieldArray) {
          if(item[fa]) {
            data.push(item[fa])
          }
        }

        return data.length ? data.join(' ') : 'N/A'
      },
      resetFields() {
        this.selectedItems = [].concat(...this.lastSavedSelectedItems)
        this.selectAll = !this.selectedItems.length
      },
      viewResource(item) {
        const resourceId = item.admin_relationship_id || item.id
        const resource = this.relationFilters ? (this.relationFilters.through || this.relationFilters.model) : this.resource.name
        window.location = `/admin/${resource}/${resourceId}`;
      },
      goToUrl() {
        let location = null
        if(this.relationFilters) {
          if (this.relationFilters.through) {
            location = `/admin/${this.relationFilters.through}/create?${this.relationFilters.pivotForeign}=${this.relationFilters.foreignValue}`;
          } else {
            location = `/admin/${this.relationFilters.model}/create?${this.relationFilters.column}=${this.relationFilters.value}`;
          }
        } else {
          location = `/admin/${this.resource.name}/create`;
        }
        if (location) {
          window.location = location
        }
        return false;
      },
      getRelations() {
        const requests = [];
        for(const relation of this.resource.relations) {
          const url = `${this.endpoint}/${relation}/index`;
          const params = { all: true };
          requests.push(this.$http.get(url, { params }));
        }

        this.$http.all(requests).then(this.$http.spread((...response) => {
          for(const key in response) {
            const r = this.resource.relations[key]
            this.relations[r] = response[key].data.data
          }
        }))
          .finally(() => {
            this.ready = true
            this.loading = false
          })
      },
      updateSelectedFields(showSuccessStatus = true) {
        const url = `/v1/admin/${this.relationFilters.resource}/${this.target.id}/mass-through-update`;
        this.loading = true;
        this.$http.post(url, {
          ids: this.selectedItems,
          model: this.relationFilters.model,
          through: this.relationFilters.through,
        }).then((res) => {
          if(showSuccessStatus) {
            this.$message.success('Update successful');
            this.getAllSelectedItems()
          }
        }).catch(e => {
          this.$message.error('An error occurred trying to make that update, please try again later.')
        }).finally(() => {
          this.loading = false
        })
      },
      getAllSelectedItems() {
        const requests = [
          this.$http.get(`${this.endpoint}/${this.resource.resource}/index`, {
            params: {
              relations: this.resource.relations,
              relation_filters: this.relationFilters,
              all: true
            }
          }),
          this.$http.get(`${this.endpoint}/${this.resource.resource}/index`, {
            params: {
              relations: this.resource.relations,
              all: true
            }
          })
        ]

        this.$http.all(requests).then(this.$http.spread((selected, all) => {
          this.selectedItems = selected.data.data.map(result => result.id)
          this.lastSavedSelectedItems = this.selectedItems
          this.allItems = all.data.data
          this.selectAll = !this.selectedItems.length
        }))
      },
      selectOrUnselectAll(selection) {
        const val = typeof selection === "boolean" ? selection : this.selectAll
        if(val) {
          this.selectedItems = this.allItems.map(i => i.id)
        } else {
          this.selectedItems = []
        }
      },
      deleteItem(item) {
        this.$confirm('Are you sure you want to delete this item?', 'Are you sure?', {
          confirmButtonClass: 'el-button--danger'
        }).then(() => {
          const resourceId = item.admin_relationship_id || item.id
          const resource = this.relationFilters ? (this.relationFilters.through || this.relationFilters.model) : this.resource.name
          this.loading = true
          this.$http.delete(`/v1/admin/${resource}/${resourceId}`).then(() => {
            this.$message.success('Successfully deleted that item');
            this.$refs.table.getData()
          }).catch(() => {
            this.$message.error('An error occurred trying to delete that item.')
          })
            .finally(() => {
              this.loading = false
            })
        }).catch(() => {

        })
      }
    }
  }
</script>
