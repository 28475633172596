<template>
  <div v-loading="loading">
    <div>
      <div class="p-4">
        <div class="page-title">
          <h1> Target information
          </h1>
          <span class="text-xs font-base text-blue-900">Fill in all sections marked with an <span class="text-red-600">*</span> </span>
        </div>
        <el-form
          ref="form"
          :model="project"
          :rules="rules"
          label-width="180px"
          label-position="left"
          :disabled="disabled"
        >
          <el-form-item label="TF" prop="target_tf">
            <el-input type="number" v-model.number="project.target_tf" />
          </el-form-item>
          <el-form-item label="CF" prop="target_cf">
            <el-input type="number" v-model.number="project.target_cf" />
          </el-form-item>
          <el-form-item label="TF/CF" prop="target_tf_cf">
            <el-input type="number" v-model.number="project.target_tf_cf" />
          </el-form-item>
          <el-form-item label="Referring domains" prop="target_referring_domains">
            <el-input type="number" v-model.number="project.target_referring_domains" />
          </el-form-item>
          <el-form-item label="SEMRUSH organic score" prop="target_tf">
            <el-input type="number" v-model.number="project.semrush_organic_score" />
          </el-form-item>
          <el-form-item label="SEMRUSH traffic score" prop="target_tf">
            <el-input type="number" v-model.number="project.semrush_traffic_score" />
          </el-form-item>
        </el-form>
        <el-form
          ref="form"
          :model="project"
          :rules="rules"
          label-width="300px"
          label-position="top"
        >
          <el-form-item label="Target region" prop="target_region">
            <vue-editor v-model="project.target_region" :disabled="disabled" />
          </el-form-item>
          <el-form-item label="Other information" prop="target_other_info">
            <vue-editor v-model="project.target_other_info" :disabled="disabled" />
          </el-form-item>
        </el-form>
      </div>
    </div>
  </div>
</template>
<script>
  const moment = require('moment')
  export default {
    components: {},
    props: {
      value: {
        type: Object,
        required: true
      },
      monthAllocations: {
        type: Array,
        default: () => []
      },
      disabled: {
        type: Boolean,
        default: false
      }
    },
    data() {
      return {
        project: this.value,
        loading: false,
        company_users: [],
        months_allocations: this.monthAllocations,
        rules: {
        },
        current_target: {},
        show_edit_target: false
      }
    },
    methods: {
      async validate() {
        return true
      },
    },
    watch: {
      project: {
        deep: true,
        handler: function(value) {
          this.$emit('update:input', value)
        }
      },
      value: {
        deep: true,
        handler: function(value) {
          this.project = value
        }
      }
    }
  }
</script>
