<template>
  <div v-loading="loading">
    <div class="page-title">
      <h1>
        <logo
          v-model="$store.state.site.site.base_url"
          class="mr-2"
          :size="30"
        />
        <span v-if="loaded_target">{{ target.name }}<span class="faded-text"><span class="faded-strike">/</span>Site Target</span></span>
      </h1>
      <div class="controls">
        <el-button type="success" icon="el-icon-edit" size="mini" @click="show_settings = true" v-if="$store.getters['auth/hasPermission']('site.update')">Edit</el-button>
      </div>
    </div>
    <div class="columns">
      <div class="column w-full">
        <el-tabs class="custom-tabs-v1">
          <el-tab-pane label="Content" v-if="$store.getters['auth/hasPermission']('content.read')">
            <target-content />
          </el-tab-pane>
          <el-tab-pane label="info">
            <trieste-card title="Details">
              <div class="p-4">
                <trieste-info-column label="Name" :value="target.name"  />
                <trieste-info-column label="Target URL" :value="target.to_url"  />
                <trieste-info-column label="status" :value="target.status"  />
                <trieste-info-column label="Monthly links" :value="target.monthly_links"  />
                <trieste-info-column label="Total links" :value="target.total_links"  />
                <trieste-info-column label="Created at">
                  <span class="text-xs font-light">{{ target.created_at | momentLocal("ddd MMMM DD YYYY Z") }}</span>
                </trieste-info-column>
                <trieste-info-column label="Updated at">
                  <span class="text-xs font-light">{{ target.updated_at | momentLocal("ddd MMMM DD YYYY Z") }}</span>
                </trieste-info-column>
              </div>
            </trieste-card>
          </el-tab-pane>
        </el-tabs>
      </div>
    </div>
    <site-target-update-widget :target="target" :on-delete="onDeleteCallback" :on-edit="onEditTarget" :show.sync="show_settings" />
  </div>
</template>
<script>
  import TargetContent from "./Tabs/Content";
  import SiteTargetUpdateWidget from '@/components/views/SiteTargets/Update'
  export default {
    components: { TargetContent, SiteTargetUpdateWidget },
    data: function () {
      return {
        loading: false,
        target: {},
        loaded_target: false,
        show_settings: false
      }
    },
    mounted() {
      this.getTarget()
    },
    methods: {
      getTarget() {
        this.loading = true;
        this.$http.get(`/v1/companies/${this.$store.state.company.company.id}/sites/${this.$route.params.siteId}/targets/${this.$route.params.targetId}`).then(r => {
          this.target = r.data.data;
          //console.log('target', this.target)
          this.loading = false;
          this.loaded_target = true;
          this.setBreadcrumbs()
        }).catch(() => {
          this.$message.error('Failed to load the target.')
        })
      },
      setBreadcrumbs() {
        this.$store.dispatch('company/getCompany', this.$route.params).then((company) => {
          this.$store.dispatch('site/getSite', { siteId: this.target.site_id }).then((site) => {
            this.$store.dispatch('client/getClient', { clientId: site.client_id }).then((client) => {
              this.$store.commit('global/setBreadcrumbs', [
                {
                  path: '/',
                  label: company.name
                },
                {
                  path: `/company/${company.id}/clients`,
                  label: 'clients'
                },
                {
                  path: `/company/${company.id}/clients/${client.id}`,
                  label: client.name
                },
                {
                  path: `/company/${company.id}/clients?tab=sites`,
                  label: 'sites'
                },
                {
                  path: `/company/${company.id}/sites/${site.id}`,
                  label: site.domain
                },
                {
                  path: `/company/${company.id}/sites/${site.id}?tab=site targets`,
                  label: 'Site targets'
                },
                {
                  label: this.target.name
                }
              ])
              this.loading = false
            })
          })
        })
      },
      onEditTarget() {
        return this.getTarget()
      },
      onDeleteCallback() {
        return this.$router.push(`/clients/${this.$route.params.clientId}/sites/${this.$route.params.siteId}`)
      },
      deleteTarget(targetId) {
        this.$confirm('Are you sure you want to delete this target?', 'Are you sure',{
          confirmButtonText: 'OK',
          cancelButtonText: 'Cancel',
          confirmButtonClass: 'el-button--danger'
        }).then(() => {
          const siteId = this.target.site_id || this.$route.params.siteId
          const urlPath = `/v1/companies/${this.$store.state.company.company.id}/sites/${siteId}/targets/${targetId}`;
          this.$http.delete(urlPath).then(() => {
            this.$message.success('Successfully deleted');
            this.$router.push(`/clients/${this.$route.params.clientId}/sites/${this.$route.params.siteId}`);
          }).catch(() => {
            this.$message.error('An error occurred deleting the target. Please try again later.')
          })
        }).catch(() => {
          //console.log('no heregot to here>');
        })
      }
    }
  }
</script>
