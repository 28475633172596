<template>
  <div class="columns">
    <div class="column w-full">
      <el-tabs class="custom-tabs-v1" @tab-click="tabsChanged" v-model="activeTabName" ref="tabs">
        <el-tab-pane label="Approve Links"  v-if="$store.getters['auth/hasPermission']('external.approver')" name="leads">
             <span slot="label">
               <a href="?tab=leads" @click.prevent>Approve Leads</a>
             </span>
          <links />
        </el-tab-pane>
        <el-tab-pane label="Approve Content"  v-if="$store.getters['auth/hasPermission']('external.approver')" name="content">
             <span slot="label">
               <a href="?tab=content" @click.prevent>Approve Content</a>
             </span>
          <Content />
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>
<script>
import Links from "@/components/views/ExternalApprover/Links"
import Content from "@/components/views/ExternalApprover/Content"
import tabPosition from '@/components/mixins/tab_position'
export default {
  mixins: [tabPosition],
  components: { Links, Content },
  data: function() {
    return {
      activeTabName: 'leads',
    }
  }
}
</script>
