<template>
  <div v-loading="loading" class="search-module">
    <div class="results-table overflow-y-auto" v-if="loaded" v-loading="loading">
      <div class="text-right border-b border-gray-200 pb-4">
        <el-form label-width="100px" label-position="left" v-show="show_filters">
          <el-form-item label="Type">
            <el-select v-model="filter.noteType" @change="getData" class="w-full">
              <el-option label="Tasks" value="Task"/>
              <el-option label="Notes" value="Note"/>
              <el-option label="All notes and tasks" :value="null"/>
            </el-select>
          </el-form-item>
          <el-form-item label="status" v-if="['Task',null].includes(filter.noteType)">
            <el-select v-model="filter.complete" @change="getData" class="w-full">
              <el-option label="All" :value="null"/>
              <el-option label="Complete" :value="true"/>
              <el-option label="Pending" :value="false"/>
            </el-select>
          </el-form-item>
          <el-form-item label="Resource">
            <el-select v-model="filter.resourceType" @change="getData" class="w-full">
              <el-option label="Batch" value="LinkLeadsBatch"/>
              <el-option label="Content Item" value="ContentItem"/>
              <el-option label="Link (farmed)" value="SiteLink"/>
              <el-option label="Lead (approved)" value="LinkLead"/>
              <el-option label="All types" :value="null"/>
            </el-select>
          </el-form-item>
          <el-form-item label="Cleared" v-if="false">
            <el-select v-model="filter.status" @change="getData" class="w-full">
              <el-option label="No" :value="null"/>
              <el-option label="Yes" value="cleared"/>
              <el-option label="Both" value="all"/>
            </el-select>
          </el-form-item>
          <el-form-item label="From">
            <el-select v-model="filter.from_me" @change="getData" class="w-full">
              <el-option label="All" :value="null"/>
              <el-option label="Myself" :value="true"/>
              <el-option label="Others" :value="false"/>
            </el-select>
          </el-form-item>
          <el-form-item label="Created">
            <el-date-picker
                v-model="datePicker"
                type="daterange"
                align="right"
                unlink-panels
                range-separator="To"
                start-placeholder="Start date"
                end-placeholder="End date"
                :picker-options="pickerOptions"
                :clearable="false"
                format="dd MMM yyyy"
                @change="setDateStartAndEnd"
                style="width: 300px"
            >
            </el-date-picker>
          </el-form-item>
        </el-form>
        <div class="flex flex-row justify-between">
          <a class="text-blue-700 font-medium text-xs" :href="`/company/${$store.state.company.company.id}/notes`">All
            notes</a>
          <a href="#" class="text-blue-700 font-medium text-xs"
             @click.prevent="show_filters = !show_filters">{{ show_filters ? 'Hide filters.' : 'Show filters.' }}</a>
        </div>
      </div>
      <ul v-if="data.length">
        <li v-for="(result, i) in data" :key="i" class="my-2 mb-4 text-sm border-gray-200 pb-4 relative"
            :class="{'border-b-2': i !== (data.length - 1) }">
          <div class="flex flex-row items-center justify-between">
            <div class="mr-2">
              <div class="text-xs my-2">
                <div>
                  <el-tag effect="dark" :type="getNiceBadgeColor(result)" size="mini">
                    <a :href="getAlertLink(result)">{{ getNiceResourceName(result) }}</a>
                  </el-tag>
                  <div class="ml-2 inline-flex flex-row items-center" v-if="result.urgent">
                    <span class="w-3 h-3 inline-block rounded-full bg-red-600 align-middle mr-1"></span>
                    <span class="font-medium text-red-600 align-middle">Urgent</span>
                  </div>
                </div>
              </div>
              <div class="text-sm text-gray-800 font-medium inline-block " v-html="result.body"
                   v-if="!result.preview || result.show_full" :class="{'line-through': result.completed}"></div>
              <div class="text-sm text-gray-800 font-medium inline-block" v-html="result.preview" v-else
                   :class="{'line-through': result.completed}"></div>
              <div class="text-left my-2">
                <span class="btn text-xs text-blue-800 font-bold rounded border border-blue-800 p-1 px-2 cursor-pointer"
                      v-if="result.preview" @click="result.show_full = !result.show_full">
                {{ result.show_full ? 'Hide' : 'Show all' }}
              </span>
              </div>
              <div class="text-xs my-2">
                <span>
                  <span class="text-xs text-gray-600">By </span>{{ result.owner ? `${result.owner.firstname} ${result.owner.lastname}` : 'System' }}
                  <span class="text-xs text-gray-600">On</span> {{ result.created_at | momentLocal("MMMM DD, YYYY hh:mm A Z") }}
                </span>
              </div>
              <div class="text-xs my-2" v-if="result.note_type.toLowerCase() === 'task' && !!result.alert_date">
                <span class="text-xs text-gray-600">Assigned for: </span>
                {{ result.alert_date | momentLocal("MMMM DD, YYYY Z") }}
              </div>
              <div v-if="result.note_type === 'Task'" class="text-xs my-2" @change="updateTask(result)">
                <div class="inline-block mr-2">
                  <el-checkbox v-model="result.completed" class="bigger"/>
                </div>
                <span class="text-blue-900 text-sm font-bold">Complete</span>
              </div>
            </div>
          </div>
          <div class="absolute top-0 right-0">
            <el-button size="mini" type="primary" icon="el-icon-close"
                       @click="() => {result.cleared_from_home = true; updateTask(result)}" circle></el-button>
          </div>
        </li>
      </ul>
      <div v-else class="py-4">
        <p class="text-gray-600 text-sm">No {{ itemType }} were found.</p>
      </div>
    </div>
    <div class="mt-auto border-t border-gray-300 pt-4 flex flex-row items-center" v-if="showClose || showClearAll">
      <a href="#" class="text-gray-600 font-medium" @click.prevent="$emit('update:visible', false)" v-if="showClose"><i
          class="el-icon-close font-bold mr-1"></i>Close</a>
      <div class="ml-auto" v-if="showClearAll">
          <el-dropdown split-button type="primary" @click="clearAll" @command="clearAll" v-if="data.length" class="rounded-full-force" icon="el-icon-close" size="mini">
            Clear all completed
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item command="clear">Clear and complete all</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
      </div>
    </div>
  </div>
</template>
<script>
import {datePickerOptions} from "@/utils/helpers";
import moment from 'moment';

const note_type_options = {
  LinkLead: 'lead',
  SiteLink: 'link',
  ContentItem: 'content',
  LinkLeadsBatch: 'batch',
  EmailAccount: 'email account'
}
export default {
  name: 'notifications',
  props: {
    visible: {
      type: Boolean,
      default: null
    },
    showClose: {
      type: Boolean,
      default: true,
    },
    showClearAll: {
      type: Boolean,
      default: true
    },
    itemType: {
      type: String,
      default: 'alert'
    },
    clearBell: {
      type: Boolean,
      default: false
    }
  },
  data: function () {
    return {
      datePicker: null,
      loading: true,
      loaded: false,
      data: [],
      pagination: false,
      filter: {
        noteType: null,
        resourceType: null,
        status: null,
        from_me: null,
        date_start: null,
        date_end: null,
        complete: null
      },
      show_filters: false,
      pickerOptions: datePickerOptions
    }
  },
  mounted() {
    this.getData()
  },
  methods: {
    getData() {
      const company_id = this.$store.state.company.company.id;
      this.data = [];
      this.loading = true;
      this.$http.get('/v1/user/alerts', {
        params: {
          company_id,
          resource_type: this.filter.resourceType,
          note_type: this.filter.noteType,
          status: this.filter.status,
          from_me: this.filter.from_me,
          date_start: this.filter.date_start || undefined,
          date_end: this.filter.date_end || undefined,
          complete: this.filter.complete || undefined,
          clear_bell: this.clearBell,
          all: true,
          _gmt_time: (new Date()).toString()
        }
      }).then(res => {
        this.data = res.data.data.map(i => {
          if (i.body && i.body.length > 150) {
            i.show_full = false;
            i.preview = i.body.substr(0, 147) + '...';
          }
          return i;
        });
        this.pagination = res.data.meta;
      })
          .finally(() => {
            this.loading = false;
            this.loaded = true;
            if(this.clearBell) this.$store.state.auth.alert_count = 0;
          })
    },
    setDateStartAndEnd() {
      this.filter.date_start = moment(this.datePicker[0]).format('YYYY-MM-DD')
      this.filter.date_end = moment(this.datePicker[1]).format('YYYY-MM-DD')
      this.getData()
    },
    renderLink(alert) {
      return '';
    },
    getAlertLink(alert) {
      if (['LinkLead', 'SiteLink'].includes(alert.resource_type) && alert.link_item) {
        return `/company/${this.$store.state.company.company.id}/${alert.link_item.lead_status === 'accepted' ? 'leads' : 'links'}/${alert.link_item.id}`;
      }
      if (alert.resource_type === 'LinkLeadsBatch' && alert.batch_item) {
        return `/company/${this.$store.state.company.company.id}/batch/${alert.batch_item.id}`;
      }
      if (alert.resource_type === 'EmailAccount' && alert.email_account) {
        return `/company/${this.$store.state.company.company.id}/email-accounts/${alert.email_account.id}`;
      }
      if (alert.resource_type === 'ContentItem') {
        return `/company/${this.$store.state.company.company.id}/content/${alert.resource_id}`;
      }
      return '/';
    },
    getNiceAlertType(name) {
      if (note_type_options[name]) {
        return note_type_options[name];
      }
      return name;
    },
    getNiceResourceName(alert) {
      if (['LinkLead', 'SiteLink'].includes(alert.resource_type)) {
        if (alert.link_item) return alert.link_item.id;
      }
      if (alert.resource_type === 'ContentItem') {
        if (alert.content_item) return alert.content_item.id || '{untitled}';
      }
      if (alert.resource_type === 'LinkLeadsBatch') {
        if (alert.batch_item) return alert.batch_item.name || 'N/A';
      }
      if (alert.resource_type === 'EmailAccount') {
        if (alert.email_account) return alert.email_account.email || 'N/A';
      }
      return 'N/A';
    },
    getNiceBadgeColor(alert) {
      if (['LinkLead', 'SiteLink'].includes(alert.resource_type)) {
        return 'primary';
      }
      if (alert.resource_type === 'ContentItem') {
        return 'warning'
      }
      if (alert.resource_type === 'LinkLeadsBatch') {
        return 'success'
      }
      if (alert.resource_type === 'EmailAccount') {
        return 'danger'
      }
      return false;
    },
    clearAll(a) {
      const clearAll = a === 'clear'
      const action = () => {
        this.loading = true;
        this.$http.post('/v1/user/alerts/clear-all', {
          company_id: this.$store.state.company.company.id,
          include_incomplete: clearAll,
          clear_and_complete: clearAll
        })
          .then(() => {
            this.$message.success('Cleared all alerts');
            this.getData()
            this.$store.dispatch('auth/getAlertCount', {})
          })
          .catch(() => {
            this.$message.error('Failed to clear all alerts')
          })
          .finally(() => {
            this.loading = false;
          })
      }

      if(clearAll) {
        return this.$confirm('Are you sure you want to complete and clear all tasks assigned to you?')
        .then(action)
        .catch(() => {})
      }
      return action()
    },
    updateTask(note) {
      this.loading = true;
      this.$http.put(`/v1/companies/${this.$store.state.company.company.id}/notes/${note.id}`, note)
          .then(() => {
            this.getData()
            this.$store.dispatch('auth/getAlertCount', {})
          })
          .catch(() => {
            this.$message.error('Failed to update the task')
          })
          .finally(() => {
            this.loading = false;
          })
    }
  }
}
</script>
<style lang="scss" scoped>
.search-module {
  @apply flex flex-col h-full;
  .results-table {
    .el-button {
      @apply rounded-full;
    }
  }
}
</style>
