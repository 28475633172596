<template>
  <div v-loading="loading" class="lead_relationship_container">
    <div v-for="(type, i) in ['email', 'domain']" :key="i">
      <trieste-card :title="`Related by ${type}`" :collapsable="true" :collapsed="collapsed" v-if="getRelationType(type).length">
      <div class="p-4 pt-0">
        <div v-for="(link, i) in getRelationType(type)" :key="i" class="text-sm border-b border-gray-300 py-4 flex flex-row">
          <div>
            <el-badge :value="link.TotalEmails" :type="['dropped', 'rejected'].includes(link.status) ? 'danger' : 'success'">
            <i class="el-icon-message text-4xl" />
            </el-badge>
          </div>
          <div class="text-xs text-gray-700 ml-8">
            <a :href="`/company/${$route.params.companyId}/leads/${link.id}`" class="break-all block">{{link.id}}</a>
            <div class="font-bold">
              {{getNiceStatusName(link.status)}} |
              <template>
                <span v-if="link.LastSentEmail && link.LastSentEmail.date">
                <span>{{ getMonthDifference(link.LastSentEmail.date)}} Month(s)</span>
                </span>
                <span v-else>
                  <span>{{ getMonthDifference(link.updated_at || link.created_at)}} Month(s)</span>
                </span>
              </template>
            </div>
            <div v-if="link.client">
              {{ link.client.name }}
              <span v-if="link.project"> | {{ link.project.name }}</span>
            </div>
          </div>
        </div>
      </div>
      </trieste-card>
    </div>
  </div>
</template>
<script>
  import moment from 'moment';
  export default {
    props: {
      value: {
        type: Object,
        default: () => {}
      },
      collapsed: {
        type: Boolean,
        default: false
      },
      id: {
        type: [String, Number]
      }
    },
    data() {
      return {
        data: [],
        loading: true,
        same_domain_active_links: []
      }
    },
    mounted() {
      this.getData()
    },
    methods: {
      getMonthDifference(d) {
        return Math.round(moment().diff(d, 'months', true))
      },
      getNiceStatusName(status) {
        const find = this.$store.state.link.statuses.find(s => s.value === status)
        if(find) {
          return find.label
        }
        return status
      },
      getRelationType (type) {
        const key = type === 'email' ? 'related_by_email' : 'related_by_domain'
        return this.data.filter(d => !!d[key])
      },
      setSameActiveDomains() {
        this.same_domain_active_links = this.getRelationType('domain').filter(d => d.status === 'active' && d.client_id === this.value.client_id)
        this.$emit('same-domains', this.same_domain_active_links)
      },
      getData() {
        this.loading = true
        const path = `/v1/companies/${this.$store.state.company.company.id}/links/${this.id}/relationship`
        this.$http.get(path).then((r) => {
          this.data = r.data.data
          this.setSameActiveDomains();
        })
        .catch(() => {
          this.$message.error('Failed to get the latest stats.')
        })
        .finally(() => {
          this.loading = false
        })
      }
    }
  }
</script>
<style lang="scss">
.lead_relationship_container {
  .el-badge__content {
    &.is-fixed {
      top: initial;
      bottom: -10px;
    }
  }
}
</style>
