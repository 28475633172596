<template>
 <div v-loading="loadingContacts">
   <trieste-card title="Create contact">
     <div class="p-6 pb-2">
       <el-form :rules="rules.contact" :model="create_contact" label-position="left" label-width="120px" ref="contactFormCreate" v-loading="creatingContact" @submit="createContact">
         <el-form-item prop="first_name" label="First name">
           <el-input type="text" placeholder="First name" v-model="create_contact.first_name" @keyup.enter.native="createContact" />
         </el-form-item>
         <el-form-item prop="last_name" label="Last name">
           <el-input type="text" placeholder="Last name" v-model="create_contact.last_name" @keyup.enter.native="createContact" />
         </el-form-item>
         <el-form-item prop="email" label="Email">
           <el-input type="text"  placeholder="Email address" v-model="create_contact.email" @keyup.enter.native="createContact" :disabled="create_contact.email_type" />
         </el-form-item>
         <el-form-item>
           <div class="text-right">
             <el-button type="success" icon="el-icon-edit-outline" size="small" @click="createContact">Create</el-button>
           </div>
         </el-form-item>
       </el-form>
     </div>
   </trieste-card>
   <trieste-card :title="loadingContacts ? 'Contacts' : `Contacts (${email_contacts.length})`" :collapsable="true" :collapsed.sync="is_collapsed">
     <template v-slot:header-right>
       <el-button
         size="small"
         type="primary"
         icon="el-icon-download"
         @click="syncHunterContacts"
       >
         Download from hunter
       </el-button>
       <el-button
         size="small"
         type="primary"
         icon="el-icon-edit-outline"
         @click="openContactDialog"
         v-if="false"
       >
         Add
       </el-button>
       <el-button
         v-if="false"
         @click="contactExpanded = !contactExpanded"
         :class="!contactExpanded ? 'el-icon-arrow-down' : 'el-icon-arrow-up' "
         type="primary"
         size="small" />
     </template>
     <div>
       <div class="" v-if="email_contacts.length !== 0">
         <el-table :data="email_contacts">
           <el-table-column label="Email" prop="email" min-width="250">
             <template slot-scope="scope">
               {{ scope.row.email }} <a href="#" class="text-red-800 font-bold text-xs" @click="blockEmail(scope.row.email)">(block)</a>
               <div v-if="scope.row.seen_sites">
                 <span class="text-red-700 text-xs font-bold">
                   This contact has been contacted in
                   {{ Object.keys(scope.row.seen_sites).length.toLocaleString() }} other sites.
                   <a href="#" class="text-gray-700" @click.prevent="scope.row.show_sites = !scope.row.show_sites">{{scope.row.show_sites ? 'Hide' : 'Show'}}</a>
                 </span>
                 <div v-for="(site_links, site) in scope.row.seen_sites" :key="site" v-show="scope.row.show_sites">
                   <ul>
                     <li v-for="(site_link, i) in site_links" :key="i">
                       <a :href="`/company/${site_link.company_id}/leads/${site_link.id}`" target="_blank">{{site}} - {{ site_link.id }}</a>
                     </li>
                   </ul>
                 </div>
               </div>
             </template>
           </el-table-column>
           <el-table-column label="Name" min-width="150">
             <template slot-scope="scope">
               {{ scope.row.first_name || scope.row.last_name ? `${scope.row.first_name} ${scope.row.last_name}` : 'N/A' }}
             </template>
           </el-table-column>
           <el-table-column label="Position" prop="position" min-width="150">
             <template slot-scope="scope">
               {{ scope.row.position || 'N/A' }}
             </template>
           </el-table-column>
           <el-table-column label="Type" prop="position" min-width="150">
             <template slot-scope="scope">
               {{ scope.row.email_type ? 'Hunter' : 'Custom' }}
             </template>
           </el-table-column>
           <el-table-column label="Number" prop="phone_number" min-width="150" v-if="false">
             <template slot-scope="scope">
               {{ scope.row.phone_number || 'N/A' }}
             </template>
           </el-table-column>
           <el-table-column width="100" fixed="right">
             <template slot-scope="scope">
               <div>
                 <el-button size="small" type="primary" @click="() => { contact = Object.assign({}, scope.row); contact_dialog_form = true }">Update</el-button>
               </div>
             </template>
           </el-table-column>
         </el-table>
       </div>
       <p class="text-sm text-gray-500 p-4" v-else>There are currently no contacts assigned to this site link</p>
     </div>
   </trieste-card>
   <trieste-dialog :show.sync="contact_dialog_form" title="Edit contact">
     <div class="p-6 pb-2">
       <el-form :rules="rules.contact" :model="contact" label-position="left" label-width="120px" ref="contactForm" v-loading="creatingContact" @submit="updateContact">
         <el-form-item prop="first_name" label="First name">
           <el-input type="text" placeholder="First name" v-model="contact.first_name" @keyup.enter.native="updateContact" />
         </el-form-item>
         <el-form-item prop="last_name" label="Last name">
           <el-input type="text" placeholder="Last name" v-model="contact.last_name" @keyup.enter.native="updateContact" />
         </el-form-item>
         <el-form-item prop="email" label="Email">
           <el-input type="text"  placeholder="Email address" v-model="contact.email" :disabled="contact.email_type" @keyup.enter.native="updateContact" />
         </el-form-item>
       </el-form>
     </div>
     <template v-slot:footer-left v-if="contact.id">
       <el-button type="danger" size="small" icon="el-icon-delete" @click="deleteContact">Permanently delete this contact</el-button>
     </template>
     <template v-slot:footer-right>
       <el-button type="danger" icon="el-icon-close" size="small" @click="cancelContactForm">Cancel</el-button>
       <el-button type="success" icon="el-icon-edit-outline" size="small" @click="updateContact">Edit</el-button>
     </template>
   </trieste-dialog>
 </div>
</template>
<script>
  export default {
    props: {
      id: {
        required: true,
        type: [String, Number]
      },
      collapsed: {
        type: Boolean,
        default: false
      },
      includeLinks: {
        type: Boolean,
        default: false
      },
      excludeDomain: {
        type: String,
        default: null
      }
    },
    data() {
      const nameOrEmail = (value, rule, callback) => {
        const form = this.contact.id ? this.contact : this.create_contact
        if(!form.first_name && !form.email) {
          return callback(new Error('Please specify either a name or email address for this contact.'));
        }
        return callback();
      }
      return {
        loadingContacts: false,
        email_contacts: [],
        contactExpanded: false,
        creatingContact: false,
        contact_dialog_form: false,
        is_collapsed: this.collapsed,
        contact: {
          id: null,
          email: null,
          email_type: null,
          confidence: null,
          first_name: null,
          last_name: null,
          position: null,
          department: null,
          linkedin: null,
          twitter: null,
          phone_number: null
        },
        create_contact: {
          id: null,
          email: null,
          email_type: null,
          confidence: null,
          first_name: null,
          last_name: null,
          position: null,
          department: null,
          linkedin: null,
          twitter: null,
          phone_number: null
        },
        rules: {
          contact: {
            first_name: [
              { validator: nameOrEmail }
            ],
            email: [
              { type: 'email', message: 'Please enter a valid email address', trigger: 'change'},
              { validator: nameOrEmail }
            ],
            email_type: [
              { required: true, message: 'Please select a email type', trigger: 'change'},
            ],
          },
        }
      }
    },
    mounted() {
      this.loadContacts();
    },
    methods: {
      openContactDialog() {
        this.contactExpanded = true;
        this.contact = {
          id: null,
          email: null,
          email_type: null,
          confidence: null,
          first_name: null,
          last_name: null,
          position: null,
          department: null,
          linkedin: null,
          twitter: null,
          phone_number: null
        }

        this.contact_dialog_form = true;
      },
      loadContacts() {
        this.loadingContacts = true;
        const params = {
          all: true,
          test: true
        };

        if(this.includeLinks) {
          params.include_site_links = true;
          if(this.excludeDomain) {
            params.exclude_site_link_domain = this.excludeDomain
          }
        }
        this.$http.get(`/v1/companies/${this.$store.state.company.company.id}/links/${this.id}/contacts`, { params }).then(r => {
          this.email_contacts = r.data.data.map(item => {
            if(item.site_links && item.site_links.length) {
              const clone = Object.assign({}, item)
              clone.seen_sites = {}
              clone.show_sites = false
              for (const sl of item.site_links) {
                if(!clone.seen_sites[sl.domain]) {
                  clone.seen_sites[sl.domain] = []
                }
                clone.seen_sites[sl.domain].push(sl)
              }
              return clone
            }

            return item;
          });
          this.$emit('update:contacts', this.email_contacts);
          this.$emit('loaded', this.email_contacts);
        }).catch(() => {
          this.$message.error('Failed to load the contacts')
        })
          .finally(() => {
            this.loadingContacts = false;
            if(this.email_contacts.length === 0) {
              this.send_email_disabled = true;
            }
          })
      },
      createContact() {
        this.$refs.contactFormCreate.validate((valid) => {
          // okay this form is valid.
          if(valid) {
            this.creatingContact = true;
            this.$http.post(`/v1/companies/${this.$store.state.company.company.id}/links/${this.id}/contacts`, this.create_contact).then(() => {
              this.$message.success('Successfully created the contact form.');
              setTimeout(() => {
                this.loadContacts()
                this.$refs.contactFormCreate.resetFields()
                this.is_collapsed = false
                this.$emit('on-update')
              }, 1000)
            }).catch((e) => {
              if(e.response && e.response.data && e.response.status === 400 && e.response.data.message) {
                this.$message.error(e.response.data.message);
              } else {
                this.$message.error('Failed to create the new contact record.');
              }
            }).finally(() => {
              this.creatingContact = false;
            })
          }
        });
      },
      updateContact() {
        this.$refs.contactForm.validate((valid) => {
          // okay this form is valid.
          if(valid) {
            this.creatingContact = true;
            this.$http.put(`/v1/companies/${this.$store.state.company.company.id}/links/${this.id}/contacts/${this.contact.id}`, this.contact).then(() => {
              this.$message.success('Successfully updated the contact.');
              setTimeout(() => {
                this.loadContacts()
                this.is_collapsed = false
                this.$emit('on-update')
              }, 1000)
            }).catch((e) => {
              if(e.response && e.response.data && e.response.status === 400 && e.response.data.message) {
                this.$message.error(e.response.data.message);
              } else {
                this.$message.error('Failed to update the contact record.');
              }
            }).finally(() => {
              this.creatingContact = false;
              this.cancelContactForm()
            })
          }
        });
      },
      syncHunterContacts() {
        this.loadingContacts = true;
        this.$http.post(`/v1/companies/${this.$store.state.company.company.id}/links/${this.$route.params.linkId}/sync-hunter-emails`).then(() => {
          this.loadContacts()
          this.is_collapsed = false
        })
        .catch(() => {
          this.loadingContacts = false
          this.$message.error('Failed to download the hunter email contacts.')
        })
      },
      cancelContactForm() {
        for (let contactKey in this.contact) {
          this.contact[contactKey] = null;
        }
        this.contact_dialog_form = false;
        this.$refs.contactForm.resetFields();
        this.$refs.contactForm.clearValidate();
      },
      deleteContact() {
        if(this.contact.id) {
          this.$confirm(`Are you sure you want to delete the contact '${this.contact.email}'`, 'warning')
            .then(() => {
              const url = `/v1/companies/${this.$store.state.company.company.id}/links/${this.$route.params.linkId}/contacts/${this.contact.id}`;
              this.$http.delete(url)
                .then(() => {
                  this.$message.success('Successfully deleted the contact.');
                  this.contact_dialog_form = false;
                  this.loadContacts();
                })
                .catch(() => {
                  this.$message.error('Failed to delete the contact. Please try again later.');
                })
            })
            .catch(() => {
              // do nothing.
            })
        }
      },
      blockEmail(email, callback) {
        this.$confirm('Are you sure you want to block the email ' + email + '? You will no longer be able to send/receive emails to this address')
          .then(() => {
            this.loadingContacts = true;
            this.$http.post(`/v1/companies/${this.$store.state.company.company.id}/blacklisted-contacts`, {
              email
            }).then(() => {
              this.$message.success(`Successfully blocked the email ${email}`)
              this.loadContacts()
              if(typeof callback === 'function') {
                callback()
              }
            })
            .catch(() => {
              this.$message.error(`An error occurred while trying to block the email ${email}. Please try again later.`)
            })
          }).catch(() => {})
      }
    }
  }
</script>
