<template>
  <div v-loading="loading">
    <div>
      <div class="p-4">
        <div class="page-title" v-if="showTitle">
          <h1> Link allocation
          </h1>
          <span class="text-xs font-base text-blue-900">Fill in all sections marked with an <span class="text-red-600">*</span> </span>
        </div>
        <el-form
          ref="form"
          :model="project"
          :rules="getLinkAllocationRules"
          label-width="300px"
          label-position="left"
          :disabled="disabled"
        >
          <el-form-item label="Links allocation" prop="links_target_allocation_type">
            <el-select v-model="project.links_target_allocation_type" class="w-full">
              <el-option value="FIXED" label="Monthly fixed" />
              <el-option value="SPREAD" label="Spread through period" v-if="false" />
              <el-option value="MONTHLY_ALLOCATED" label="Allocated by month" :disabled="!project.end_date" />
            </el-select>
            <small v-if="!project.end_date">To allocate number of links for each month, both a start date and end date need to be provided.</small>
          </el-form-item>
          <el-form-item :label="project.links_target_allocation_type !== 'SPREAD' ? 'Links per month' : 'Total links'"
                        prop="links_target_count" v-if="project.links_target_allocation_type !== 'MONTHLY_ALLOCATED'">
            <el-input v-model.number="project.links_target_count" />
          </el-form-item>
          <el-form-item label="Allow rollover?">
            <el-switch v-model="project.roll_over" />
          </el-form-item>
          <template v-if="project.links_target_allocation_type === 'MONTHLY_ALLOCATED'">
            <el-form-item :prop="m" :label="'Allocation for ' + m" v-for="(m, i) in monthAllocations" :key="i">
              <el-input type="number" v-model.number="project.links_monthly_allocation[m]" />
            </el-form-item>
          </template>
        </el-form>
      </div>
    </div>
  </div>
</template>
<script>
  const moment = require('moment')
  export default {
    props: {
      value: {
        type: Object,
        required: true
      },
      monthAllocations: {
        type: Array,
        default: () => []
      },
      disabled: {
        type: Boolean,
        default: false
      },
      showTitle: {
        type: Boolean,
        default: true
      }
    },
    data() {
      return {
        project: this.value,
        loading: false,
        company_users: [],
        rules: {
          base_link_price: [
            { required: true, min: 1, message: 'Please specify a base link price.' }
          ],
          links_target_allocation_type: [
            { required: true, message: 'Please specify a allocation type.' }
          ],
          links_target_count: [
            { required: true, type: 'number', message: 'Please a min link target count.' }
          ],
        }
      }
    },
    computed: {
      getLinkAllocationRules() {
        if (this.project.links_target_allocation_type === 'MONTHLY_ALLOCATED' && this.monthAllocations.length) {
          const additionalRules = {};
          const validator = this.associatedLinksValidator;
          this.monthAllocations.map(i => additionalRules[i] = [
            {
              validator,
              required: true,
              type: 'number'
            }
          ]);
          return Object.assign({}, this.rules, additionalRules);
        }
        return this.rules;
      },
    },
    mounted() {
      setTimeout(() => {
        this.$refs.form.clearValidate()
      }, 500)
    },
    methods: {
      async validate() {
        try {
          await this.$refs.form.validate()
          return true;
        } catch (e) {
          return false
        }
      },
      associatedLinksValidator(rule, value, callback) {
        const { field } = rule;
        if(!this.project.links_monthly_allocation[field]) {
          return callback(new Error('Please enter a link allocation for ' + field));
        }
        return callback();
      },
    },
    watch: {
      project: {
        deep: true,
        handler: function(value) {
          this.$emit('update:input', value)
        }
      },
      value: {
        deep: true,
        handler: function(value) {
          this.project = value
        }
      }
    }
  }
</script>
