<template>
  <div>
    <trieste-table
      :end-point="`/v1/companies/${$store.state.company.company.id}/dashboard/link-builder-tasks`" :fields="fields"
      :title="title || 'Link builder tasks'" :show-sub-title="false" :params="{ include_suspended: includeSuspendedSites, user_id: userId }" collapsable first-field-total
    >
      <template v-slot:domain="props">
        <span v-if="props.$index === 0" class="font-bold">Total</span>
        <a :href="`/company/${$store.state.company.company.id}/sites/${props.row.id}`" v-else>{{props.row.domain}}</a>
      </template>
      <template v-slot:client="props">
        <span v-if="props.$index === 0" class="font-bold">Total</span>
        <a :href="`/company/${$store.state.company.company.id}/clients/${props.row.client.id}`" v-else>{{props.row.client.name}}</a>
      </template>
      <template v-slot:negotiation-awaiting-reply-slot="props">
        <a :href='buildLeadLink(props.row.id, "negotiation_waiting_for_reply")'>
          {{ props.row.negotiation_awaiting_reply.toLocaleString() }}
        </a>
      </template>
      <template v-slot:negotiation-slot="props">
        <a :href='buildLeadLink(props.row.id, "negotiation")'>
          {{ props.row.negotiation.toLocaleString() }}
        </a>
      </template>
      <template v-slot:intial-reply-slot="props">
        <a :href='buildLeadLink(props.row.id, "waiting_for_initial_reply")'>
          {{ props.row.waiting_for_initial_reply.toLocaleString() }}
        </a>
      </template>
      <template v-slot:second-reply-slot="props">
        <a :href='buildLeadLink(props.row.id, "2nd_initial_sent")'>
          {{ props.row.second_initial_send.toLocaleString() }}
        </a>
      </template>
      <template v-slot:third-reply-slot="props">
        <a :href='buildLeadLink(props.row.id, "3nd_initial_sent")'>
          {{ props.row.third_initial_send.toLocaleString() }}
        </a>
      </template>
      <template v-slot:awaiting-placement-slot="props">
        <a :href='buildLeadLink(props.row.id, "awaiting_placement")'>
          {{ props.row.awaiting_placement.toLocaleString() }}
        </a>
      </template>
      <template v-slot:correction-slot="props">
        <a :href='buildLeadLink(props.row.id, "correction")'>
          {{ props.row.correction.toLocaleString() }}
        </a>
      </template>
      <template v-slot:correction-awaiting-reply-slot="props">
        <a :href='buildLeadLink(props.row.id, "correction_waiting_for_reply")'>
          {{ props.row.correction_waiting_for_reply.toLocaleString() }}
        </a>
      </template>
    </trieste-table>
  </div>
</template>
<script>
  export default {
    components: {  },
    props: {
      dateStart: {
        type: [String, Date]
      },
      dateEnd: {
        type: [String, Date]
      },
      title: {
        type: String,
        default: null
      },
      userId: {
        type: [String, Number],
        default: null
      },
      includeSuspendedSites: {
        type: Boolean,
        default: false
      }
    },
    data() {
      return {
        fields: [
          {
            title: 'domain',
            name: 'domain',
            sortField: 'domain',
          },
          {
            title: 'Client',
            name: 'client',
            sortField: 'client.name',
            formatter: (val) => val ? val.name : ''
          },
          {
            title: 'Negotiation awaiting reply',
            name: 'negotiation-awaiting-reply-slot'
          },
          {
            title: 'Negotiation',
            name: 'negotiation-slot'
          },
          {
            title: 'Initial reply',
            name: 'intial-reply-slot'
          },
          {
            title: 'Second reply',
            name: 'second-reply-slot'
          },
          {
            title: 'Third reply',
            name: 'third-reply-slot'
          },
          {
            title: 'Awaiting placement',
            name: 'awaiting-placement-slot'
          },
          {
            title: 'Correction',
            name: 'correction-slot'
          },
          {
            title: 'Correction awaiting reply',
            name: 'correction-awaiting-reply-slot'
          },
        ],
      }
    },
    methods: {
      buildLeadLink(siteId, status) {
        let baseLink  = `/company/${this.$store.state.company.company.id}/sites/${siteId}?tab=leads`;
        if(!siteId) {
          baseLink = `/company/${this.$store.state.company.company.id}/leads`;
        }

        const filters = {}
        filters['Owner'] = [this.$store.state.auth.user.id]
        if(status) {
          filters['status'] = [status]
        }

        return baseLink + (siteId ? '&' : '?') + 'filters=' + JSON.stringify(filters)
      },
    },
  }
</script>
