<template>
  <div v-loading="loading">
    <trieste-card :title="label" :collapsable="collapsable" :collapsed.sync="is_collapsed">
      <div class="p-4">
        <div v-if="site">
          <div class="requirement-row">
            <span class="title">
              Requirements
            </span>
            <span class="value" v-html="renderContent(site.requirements) || 'N/A'"></span>
          </div>
        </div>
      </div>
      <template v-slot:footer v-if="$store.getters['auth/hasPermission']('site.update')">
        <div class="flex justify-end flex-row">
          <el-button size="small"
                     type="success"
                     icon="el-icon-edit-outline"
                     @click="show_settings = true"
                     v-if="site && (!is_collapsed || !collapsable)">Update</el-button>
        </div>
      </template>
    </trieste-card>
    <site-create-edit v-if="$store.getters['auth/hasPermission']('site.update') && site" v-model="site" :show.sync="show_settings" />
  </div>
</template>
<script>
  import SiteCreateEdit from "@/components/views/Sites/CreateEdit";
  import { urlInTextRegex } from '@/utils/helpers';

  export default {
    components: { SiteCreateEdit },
    props: {
      siteId: {
        type: [String, Number],
        default: null
      },
      collapsable: {
        type: Boolean,
        default: true
      },
      collapsed: {
        type: Boolean,
        default: true
      },
      setSite: {
        type: Object,
        default: null
      },
      label: {
        type: String,
        default: 'Site requirements'
      },
      renderUrls: {
        type: Boolean,
        default: false
      }
    },
    data() {
      return {
        site: null,
        loading: false,
        is_collapsed: this.collapsed,
        show_settings: false
      }
    },
    mounted() {
      if(this.setSite && typeof this.setSite === "object" && this.setSite.id) {
        this.site = this.setSite
      } else {
        this.getSite()
      }
    },
    methods: {
      getSite() {
        const siteId = this.siteId || this.$route.params.siteId
        if(siteId) {
          this.loading = true
          this.$http.get(`/v1/companies/${this.$store.state.company.company.id}/sites/${siteId}`).then((res) => {
            this.site = res.data.data
          })
          .catch(() => {
            this.$message.error('Failed to load the site')
          })
          .finally(() => {
            this.loading = false
          })
        }
      },
      renderContent(content) {
        content = (content || '').replace(/\n/g, '<br />')
        if(this.renderUrls) {
          content = content.replace(/\n/g, '<br/>').replace(urlInTextRegex, '<a href="$1" class="font-medium text-blue-700" target="_blank">$1</a>')
        }
        return content
      }
    }
  }
</script>
<style lang="scss" scoped>
  .requirement-row {
    @apply flex flex-col flex-wrap mb-4;

    span {
      &.title {
        @apply font-medium text-base text-blue-900 mr-2;
      }
      &.value {
        @apply  text-sm text-gray-800;
      }
    }
  }
</style>
