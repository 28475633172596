<template>
  <div>
    <div class="p-4 text-right">
      <el-button type="primary" size="small" icon="el-icon-document-copy" :loading="loading" @click="openDialog">Clone project</el-button>
    </div>
    <trieste-dialog title="Clone project" :show.sync="show" v-loading="loading">
      <div class="p-4">
        <el-form label-position="left" label-width="120px" :model="clone">
          <el-form-item label="company" prop="company_id">
            <el-select v-model="clone.company_id" class="w-full" @change="companyChanged" filterable>
              <el-option v-for="(company, i) in companies" :key="i" :label="company.name" :value="company.id" />
            </el-select>
          </el-form-item>
          <el-form-item label="client" prop="client_id">
            <el-select v-model="clone.client_id" class="w-full" @change="clientChanged" filterable>
              <el-option v-for="(client, i) in clients" :key="i" :label="client.name" :value="client.id" />
            </el-select>
          </el-form-item>
          <el-form-item label="project" prop="project_id">
            <el-select v-model="clone.project_id" class="w-full" filterable>
              <el-option v-for="(project, i) in projects" :key="i" :label="project.name" :value="project.id" />
            </el-select>
          </el-form-item>
        </el-form>
      </div>
      <template v-slot:footer-right>
        <el-button type="primary" size="small" icon="el-icon-document-copy" :loading="loading" @click="cloneProject" :disabled="!clone.project_id">Clone</el-button>
      </template>
    </trieste-dialog>
  </div>
</template>
<script>
  const unallowedKeys = [ 'company_id', 'client_id', 'saved_index', 'created_at', 'updated_at', 'percent_complete', 'site_targets' ]
  export default {
    props: {
      value: {
        type: Object,
        default: () => {}
      }
    },
    data() {
      return {
        clone: {
          company_id: null,
          client_id: null,
          project_id: null,
        },
        companies: [],
        clients: [],
        projects: [],
        loading: false,
        hasLoaded: false,
        show: false
      }
    },
    methods: {
      openDialog() {
        if(this.hasLoaded) {
          this.show = true
        } else {
          this.initialSetUp(() => {
            this.show = true
          })
        }
      },
      initialSetUp(callback) {
        this.getCompanies(() => {
          this.clone.company_id = this.$route.params.companyId / 1
          this.getCompanyClients(() => {
            this.clone.client_id = this.$route.params.clientId / 1
            this.getClientProjects(() => {
              if(callback) {
                callback()
              }
            })
          })
        })
      },
      getCompanies(callback) {
        this.loading = true;
        this.$http.get('/v1/companies', {
          params: {
            all: true
          }
        }).then(res => {
          this.companies = res.data.data;
          if(callback) {
            callback();
          }
        }).catch(() => {
          this.$message.error('Failed to load the available companies.')
        })
        .finally(() => {
          this.loading = false
        })
      },
      getCompanyClients(callback) {
        this.loading = true;
        this.$http.get(`/v1/companies/${this.clone.company_id}/clients`, {
          params: {
            all: true
          }
        }).then(res => {
          this.clients = res.data.data;
          if(callback) {
            callback();
          }
        }).catch(() => {
          this.$message.error('Failed to load the available clients.')
        })
        .finally(() => {
          this.loading = false
        })
      },
      getClientProjects(callback) {
        this.loading = true;
        this.$http.get(`/v1/companies/${this.clone.company_id}/projects`, {
          params: {
            all: true,
            client_id: this.clone.client_id
          }
        }).then(res => {
          this.projects = res.data.data;
          if(callback) {
            callback();
          }
        }).catch(() => {
          this.$message.error('Failed to load the available projects.')
        })
        .finally(() => {
          this.loading = false
        })
      },
      companyChanged() {
        this.clone.client_id = null
        this.clients = []
        this.clone.project_id = null
        this.projects = null
        this.getCompanyClients()
      },
      clientChanged() {
        this.clone.project_id = null
        this.projects = null
        this.getClientProjects()
      },
      cloneProject() {

        const thisProject = Object.assign({}, this.value)
        const clonableProject = this.projects.find(p => p.id === this.clone.project_id)

        for(const key in clonableProject) {
          if(!unallowedKeys.includes(key)) {
            thisProject[key] = clonableProject[key]
          }
        }

        this.$emit('input', thisProject)

        this.$message.success('The project definition has been cloned.')
        this.show = false;
      }
    }
  }
</script>
