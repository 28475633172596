<template>
<trieste-card :title="name" :loading="loading">
  <div class="p-4">
    <slot></slot>
  </div>
  <template v-slot:header-right v-if="$slots['header-right']">
    <slot name="header-right"></slot>
  </template>
  <template v-slot:footer v-if="$slots.footer">
    <slot name="footer"></slot>
  </template>
</trieste-card>
</template>
<script>
export default {
  props: {
    show: {
      type: Boolean,
      default: false
    },
    name: {
      type: String,
      default: ''
    },
    loading: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      showDialog: this.show
    }
  },
  watch: {
    show(val) {
      this.showDialog = val
    },
    showDialog(val) {
      this.$emit('update:show', val)
    }
  }
}
</script>
