module.exports = {
  name: 'User Groups',
  resource_name: 'users_groups',
  single_name: 'user group',
  showOnMenu: true,
  fields: [
    {
      name: 'name',
      label: 'Name',
      filterable: true,
      rules: [
        { required: true, message: 'Please provide a name' }
      ]
    },
    {
      name: 'created_at',
      label: 'Created at',
      sortField: 'created_at',
      type: 'date',
      filterable: true,
      hideOnCreate: true,
    },
    {
      name: 'updated_at',
      label: 'Updated at',
      sortField: 'updated_at',
      type: 'date',
      filterable: true,
      hideOnCreate: true,
    },
  ],
  includes: [
    {
      model: 'user',
      type: 'manyThrough',
      through: 'userParticipation'
    },
  ]
}
