<template>
  <trieste-dialog title="Sort Fields" :show.sync="should_show">
    <div class="p-4">
      <draggable v-model="filtered_fields">
        <div v-for="(field, f) in filtered_fields" :key="f" class="p-2 bg-white border rounded text-xs font-bold text-gray-700 cursor-pointer mb-2">
          {{ field.title || field.name || 'N/A' }}
        </div>
      </draggable>
    </div>
  <template v-slot:footer-left class="dialog-footer">
    <el-button @click="$emit('update:show', false)" type="danger" icon="el-icon-close" size="small">Cancel</el-button>
  </template>
    <template v-slot:footer-right class="dialog-footer">
    <el-button @click="resetFields" type="danger" icon="el-icon-close" size="small" v-if="data.length">Reset</el-button>
    <el-button type="success" size="small" icon="el-icon-edit-outline" @click="setTableSort">Update</el-button>
  </template>
  </trieste-dialog>
</template>
<script>
import draggable from 'vuedraggable';
export default {
  components: { draggable },
  props: {
    show: {
      type: Boolean,
      default: true
    },
    value: {
      type: Array,
      default: () => []
    },
    fields: {
      type: Array,
      default: () => []
    },
    storeName: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      should_show: this.show,
      data: this.value,
      filtered_fields: []
    }
  },
  mounted() {
    this.setInitialOrder()
  },
  watch: {
    show: function(val) {
      this.should_show = val
    },
    value: function(val) {
      this.data = val
    }
  },
  methods: {
    setTableSort() {
      const tableOrder = this.filtered_fields.map(i => i.name)
      this.updateOrder(tableOrder)
    },
    resetFields() {
      this.updateOrder([]);
      this.setInitialOrder();
      //this.$emit('update:show', false);
    },
    updateOrder(fields) {
      if(this.storeName) {
        this.$store.dispatch('global/saveTableSorting', {
          name: this.storeName,
          order: fields
        })
      }
      this.$emit('input', fields)
      this.$emit('changed', fields)
      this.$emit('update:show', false)
    },
    setInitialOrder() {
      const allowedFields = this.fields.filter(f => !f.isMoreField && !f.isSelectionField && f.name !== 'actions-slot')
      if(this.storeName && this.$store.state.global.saved_table_sorts[this.storeName]) {
        this.$emit('input', this.$store.state.global.saved_table_sorts[this.storeName])
        this.orderByFieldName(allowedFields, this.$store.state.global.saved_table_sorts[this.storeName])
      } else {
        if (Array.isArray(this.value) && this.value.length > 0) {
          this.orderByFieldName(allowedFields, this.value)
        }
      }

      this.filtered_fields = allowedFields
    },
    orderByFieldName(data, order) {
      data.sort((a, b) => {
        const aKey = a.name ? order.findIndex(v => v === a.name) : -1;
        const bKey = b.name ? order.findIndex(v => v === b.name) : -1;
        if(aKey > bKey) {
          return 1;
        } else if(aKey < bKey) {
          return -1;
        }

        return 0;
      })
    }
  }
}
</script>
