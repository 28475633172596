<template>
  <div class="min-h-screen bg-gray-200 flex flex-col justify-center py-12 sm:px-6 lg:px-8" v-loading="running">
    <div class="sm:mx-auto sm:w-full sm:max-w-md logo">
      <img
        class="mx-auto h-12 w-auto"
        src="/imgs/logo.png"
        alt="Workflow"
      >
    </div>

    <div class="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
      <div class="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
        <form
          action="#"
          method="POST"
        >
          <div class="font-md mb-8 text-red-600" v-if="sessionExpired">
            Your session has expired. To continue, please sign in.
          </div>
          <div>
            <label
              for="email"
              class="block text-sm font-medium leading-5 text-gray-700"
            >
              Email address
            </label>
            <div class="mt-1 rounded-md shadow-sm">
              <el-input v-model="email" />
            </div>
          </div>

          <div class="mt-6">
            <label
              for="password"
              class="block text-sm font-medium leading-5 text-gray-700"
            >
              Password
            </label>
            <div class="mt-1 rounded-md shadow-sm">
              <el-input
                v-model="password"
                type="password"
                autocomplete="off"
                ref="loginForm"
              />
            </div>
          </div>

          <div class="mt-6 flex items-center justify-between">
            <div class="text-sm leading-5">
              <a
                href="/forgotten-password"
                class="font-medium text-indigo-600 hover:text-indigo-500 focus:outline-none focus:underline transition ease-in-out duration-150"
              >
                Forgot your password?
              </a>
            </div>
          </div>

          <div class="mt-6">
            <span class="block w-full rounded-md shadow-sm">
              <el-button
                class="w-full"
                type="primary"
                @click="login"
              >
                Sign in
              </el-button>
            </span>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>

import { authenticate } from '@/utils/auth'
import store from "@/store";

export default {
  name: 'Login',
  data () {
    return {
      email: '',
      password: '',
      running: false,
      rules: {
        email: [
          { required: true,  message: 'Please enter an account email.'}
        ],
        password: [
          { required: true,  message: 'Please enter an account password.'}
        ]
      }
    }
  },
  props: {
    onLoginSuccess: {
      type: Function,
      default: null
    },
    shouldResetAuth: {
      type: Boolean,
      default: true
    },
    sessionExpired: {
      type: Boolean,
      default: false
    }
  },
  mounted() {
    this.$refs.loginForm.$el.addEventListener('keyup', (e) => {
      if(e.keyCode === 13) {
        this.login()
      }
    })
  },
  methods: {
    login () {
      const vm = this
      vm.running = true
      authenticate(vm.email, vm.password)
        .then((response) => typeof this.onLoginSuccess === 'function' ? this.onLoginSuccess(response) : this.defaultOnLoginSuccess(response))
        .catch((e) => {
          console.error(e)
          this.$message.error('Your email and/or password was incorrect. Please try again.')
          this.password = '';
        })
        .then(() => {
          vm.running = false
        })
    },
    defaultOnLoginSuccess(response) {
      this.$store.commit('global/setTableSorting', response.data.data.tableOrder)
      if(response.data.data.user.last_viewed_company_id) {
        this.$store.dispatch('company/getCompany', {companyId: response.data.data.user.last_viewed_company_id}).then(() => {
          this.$store.dispatch('auth/setLastViewedCompany', {companyId: response.data.data.user.last_viewed_company_id}).then(() => {
            // this.$store.dispatch('auth/getAlertCount', {
            //   alert_tasks: true
            // })
            if(this.$route.query.redirect) {
              this.$router.push(this.$route.query.redirect)
            } else {
              this.$router.push('/')
            }
          })
        })
          .catch((e) => {
            this.$message.error('Failed to the request. Please try again later or contact admin.')
            console.error(e)
          })
      } else {
        this.$store.commit('company/setRequiresCompanySet', true)
        //console.log('done')
        this.$router.push('/')
      }
    }
  }
}
</script>

<style scoped>

</style>
