<template>
    <div>




      <trieste-dialog width="900px" title="Add New Monthly Target" :show="monthly_target.show">
        <template #default>
          <div class="p-5">

            <div class="mb-3">
              <el-alert :closable="false">You are currently adding targets for the current month <strong>{{  getActiveMonth }}</strong></el-alert>
            </div>

            <div class="mb-3">
              <el-input v-model="monthly_target.query" />
            </div>

            <el-table
              height="350"
              :data="filteredTargets"
              style="width: 100%">
              <el-table-column
                prop="project"
                label="Project"
                width="190">
                <template slot-scope="scope">
               <small>{{ scope.row.client_name }}</small><br />
                   {{ scope.row.project_name }}<br />
                </template>
              </el-table-column>
              <el-table-column
                prop="per_sow"
                label="Per SOW"
                width="80">
                <template slot-scope="scope">
                  <el-input
                    :disabled="monthly_target.adding"
                    v-model="scope.row.per_sow" />
                </template>
              </el-table-column>

              <el-table-column
                prop="underbilled"
                label="Underbilled"
                width="80">
                <template slot-scope="scope">
                  <el-input
                    :disabled="monthly_target.adding"
                    v-model="scope.row.underbilled" />
                </template>
              </el-table-column>
              <el-table-column
                prop="link_price_usd"
                label="Link Price $"
                width="80">
                <template slot-scope="scope">
                  <el-input
                    :disabled="monthly_target.adding"
                    v-model="scope.row.link_price" />
                </template>
              </el-table-column>
            </el-table>

          </div>
        </template>
        <template #footer-right>
          <el-button
            @click="() => monthly_target.show = false"
            :disabled="monthly_target.adding"
          >Close</el-button>
          <el-button
            @click="updateTargets"
            :disabled="monthly_target.adding"
            :loading="monthly_target.adding"
            type="primary">Update Targets</el-button>
        </template>
      </trieste-dialog>

      <div class="page-title">
        <h1>
          Project Targets & Stats
        </h1>
      </div>
      <el-tabs class="custom-tabs-v1">

        <el-tab-pane label="Targets">
          <div class="grid grid-cols-1 gap-6">
            <div class="grid grid-cols-2">
              <div>
                <div class="flex items-center gap-3">
                  <div class="flex items-center">
                    <label class="font-semibold text-xs">Month</label>
                  </div>
                  <div>
                    <el-date-picker
                      :disabled="results.monthly_targets.loading"
                      :clearable="false"
                      format="MMM yyyy"
                      v-model="monthly_target.month"
                      type="month"
                      placeholder="Pick a month" />
                  </div>
                </div>
              </div>
              <div>
                <p class="text-right">
                  <el-button
                    :disabled="results.monthly_targets.loading"
                    @click="() => monthly_target.show = true"
                    type="primary">Manage Targets</el-button>
                </p>
              </div>
            </div>
          </div>

          <div class="my-8">
            <div v-if="results.monthly_targets.loading">
              <loader />
            </div>
            <div v-else>
              <div class="max-w-7xl">
                <div class="w-full relative mb-2 text-xs">

                  <div class="title-width absolute flex z-30 bg-red-500 overflow-visible shadow-lg top-0 left-0">

                    <div class="flex divide-y divide-gray-400 name-width flex-wrap z-10">
                      <div class="px-2 py-1 w-full bg-gray-300 leading-tight tracking-tight flex items-center justify-start bg-gray-900 text-gray-100 font-semibold h-12">
                        Project
                      </div>
                      <div
                        :key="'p-' + i"
                        v-for="(r, i) of results.monthly_targets.data.table"
                        class="p-2 w-full bg-gray-300">
                        {{ r.project_name }}
                      </div>
                    </div>

                    <div class="divide-y divide-gray-400 flex w-24 flex-wrap z-10">
                      <div class="px-2 py-1 w-full bg-gray-300 leading-tight tracking-tight flex items-center justify-end bg-gray-900 text-gray-100 font-semibold h-12">
                        <span class="text-right">Per SOW</span>
                      </div>
                      <div
                        :key="'p-' + i"
                        v-for="(r, i) of results.monthly_targets.data.table"
                        class="p-2 w-full bg-gray-300 text-right">
                        {{ r.per_sow ? r.per_sow : '-' }}
                      </div>
                    </div>

                    <div class="divide-y divide-gray-400 flex w-24 flex-wrap z-10">
                      <div class="px-2 py-1 w-full bg-gray-300 leading-tight tracking-tight flex items-center justify-end bg-gray-900 text-gray-100 font-semibold h-12">
                        <span class="text-right">Per SOW + Underbuild</span>
                      </div>
                      <div
                        :key="'p-' + i"
                        v-for="(r, i) of results.monthly_targets.data.table"
                        class="p-2 w-full bg-gray-300 text-right">
                        {{ r.per_sow_plus_underbuild ? r.per_sow_plus_underbuild : '-' }}
                      </div>
                    </div>


                  </div>




                  <div class="flex flex-grow relative w-full overflow-x-scroll">
                    <div class="bg-gray-300 name-width p-2 flex-shrink-0"></div>
                    <div class="bg-gray-300 w-24 p-2 flex-shrink-0"></div>
                    <div class="bg-gray-300 w-24 p-2 flex-shrink-0"></div>
                    <div>
                      <div class="w-3"></div>
                    </div>

                    <div class="flex divide-y divide-gray-400  flex-wrap w-24 flex-shrink-0">
                      <div class="px-2 py-1 w-full bg-gray-300 leading-tight tracking-tight flex items-center justify-end bg-gray-900 text-gray-100 font-semibold h-12">
                        <span class="text-right">Built Actual</span>
                      </div>
                      <div
                        :key="'p-' + i"
                        v-for="(r, i) of results.monthly_targets.data.table"
                        class="w-full bg-gray-300 p-2 text-right">
                        {{ r.built_actual ? r.built_actual : '-' }}
                      </div>
                    </div>
                    <div class="flex divide-y divide-gray-400  flex-wrap w-24 flex-shrink-0">
                      <div class="px-2 py-1 w-full bg-gray-300 leading-tight tracking-tight flex items-center justify-end bg-gray-900 text-gray-100 font-semibold h-12">
                        <span class="text-right">Built Adjusted</span>
                      </div>
                      <div
                        :key="'p-' + i"
                        v-for="(r, i) of results.monthly_targets.data.table"
                        class="w-full bg-gray-300 p-2 text-right">
                        {{ r.built_adjusted ? r.built_adjusted : '-' }}
                      </div>
                    </div>
                    <div class="flex divide-y divide-gray-400  flex-wrap w-24 flex-shrink-0">
                      <div class="px-2 py-1 w-full bg-gray-300 leading-tight tracking-tight flex items-center justify-end bg-gray-900 text-gray-100 font-semibold h-12">
                        <span class="text-right">Completion %</span>
                      </div>
                      <div
                        :key="'p-' + i"
                        v-for="(r, i) of results.monthly_targets.data.table"
                        class="w-full bg-gray-300 p-2 text-right">
                        {{ r.completion_percentage ? r.completion_percentage + '%' : '-' }}
                      </div>
                    </div>

                    <div>
                      <div class="w-3"></div>
                    </div>
                    <div class="flex divide-y divide-gray-400  flex-wrap w-24 flex-shrink-0">
                      <div class="px-2 py-1 w-full bg-gray-300 leading-tight tracking-tight flex items-center justify-end bg-gray-900 text-gray-100 font-semibold h-12">
                        <span class="text-right">Daily Target</span>
                      </div>
                      <div
                        :key="'p-' + i"
                        v-for="(r, i) of results.monthly_targets.data.table"
                        class="w-full bg-gray-300 p-2 text-right">
                        {{ r.daily_target ? r.daily_target : '-' }}
                      </div>
                    </div>
                    <div class="flex divide-y divide-gray-400  flex-wrap w-24 flex-shrink-0">
                      <div class="px-2 py-1 w-full bg-gray-300 leading-tight tracking-tight flex items-center justify-end bg-gray-900 text-gray-100 font-semibold h-12">
                        <span class="text-right">MTD Target</span>
                      </div>
                      <div
                        :key="'p-' + i"
                        v-for="(r, i) of results.monthly_targets.data.table"
                        class="w-full bg-gray-300 p-2 text-right">
                        {{ r.mtd_target ? r.mtd_target : '-' }}
                      </div>
                    </div>
                    <div class="flex divide-y divide-gray-400  flex-wrap w-24 flex-shrink-0">
                      <div class="px-2 py-1 w-full bg-gray-300 leading-tight tracking-tight flex items-center justify-end bg-gray-900 text-gray-100 font-semibold h-12">
                        <span class="text-right">Behind By</span>
                      </div>
                      <div
                        :key="'p-' + i"
                        v-for="(r, i) of results.monthly_targets.data.table"
                        class="w-full bg-gray-300 p-2 text-right">
                        {{ r.behind_by === null ? '-' :  r.behind_by }}
                      </div>
                    </div>

                    <div class="flex divide-y divide-gray-400  flex-wrap w-24 flex-shrink-0">
                      <div class="px-2 py-1 w-full bg-gray-300 leading-tight tracking-tight flex items-center justify-end bg-gray-900 text-gray-100 font-semibold h-12">
                        <span class="text-right">Status</span>
                      </div>
                      <div
                        :key="'p-' + i"
                        v-for="(r, i) of results.monthly_targets.data.table"
                        :class="{
                  'bg-gray-300': r.status === null,
                  'bg-green-300': r.status === 'On Pace',
                  'bg-red-300': r.status === 'Behind',
                }"
                        class="w-full bg-gray-300 p-2 text-right">
                        {{ r.status === null ? '-' : r.status }}
                      </div>
                    </div>

                    <div class="flex divide-y divide-gray-400  flex-wrap w-24 flex-shrink-0">
                      <div class="px-2 py-1 w-full bg-gray-300 leading-tight tracking-tight flex items-center justify-end bg-gray-900 text-gray-100 font-semibold h-12">
                        <span class="text-right">% Status</span>
                      </div>
                      <div
                        :key="'p-' + i"
                        v-for="(r, i) of results.monthly_targets.data.table"
                        class="w-full bg-gray-300 p-2 text-right">
                        {{ r.percentage_status ? r.percentage_status + '%' : '-' }}
                      </div>
                    </div>

                    <div>
                      <div class="w-3"></div>
                    </div>

                    <div class="flex divide-y divide-gray-400  flex-wrap w-24 flex-shrink-0">
                      <div class="px-2 py-1 w-full bg-gray-300 leading-tight tracking-tight flex items-center justify-end bg-gray-900 text-gray-100 font-semibold h-12">
                        <span class="text-right"> Underbuild</span>
                      </div>
                      <div
                        :key="'p-' + i"
                        v-for="(r, i) of results.monthly_targets.data.table"
                        class="w-full bg-gray-300 p-2 text-right" :class="{
                          'bg-yellow-100': r.monthly_target_set === 0
                        }">
                        {{ r.underbuild ? r.underbuild : 0 }}
                      </div>
                    </div>

                    <div class="flex divide-y divide-gray-400  flex-wrap w-24 flex-shrink-0">
                      <div class="px-2 py-1 w-full bg-gray-300 leading-tight tracking-tight flex items-center justify-end bg-gray-900 text-gray-100 font-semibold h-12">
                        <span class="text-right"> Rollover Links</span>
                      </div>
                      <div
                        :key="'p-' + i"
                        v-for="(r, i) of results.monthly_targets.data.table"
                        class="w-full bg-gray-300 p-2 text-right">
                        {{ r.rolllover_links ? r.rolllover_links : '-' }}
                      </div>
                    </div>

                    <div class="flex divide-y divide-gray-400  flex-wrap w-24 flex-shrink-0">
                      <div class="px-2 py-1 w-full bg-gray-300 leading-tight tracking-tight flex items-center justify-end bg-gray-900 text-gray-100 font-semibold h-12">
                        <span class="text-right"> Billed Early</span>
                      </div>
                      <div
                        :key="'p-' + i"
                        v-for="(r, i) of results.monthly_targets.data.table"
                        class="w-full bg-gray-300 p-2 text-right">
                        {{ r.billed_early ? r.billed_early : '-' }}
                      </div>
                    </div>

                    <div class="flex divide-y divide-gray-400  flex-wrap w-24 flex-shrink-0">
                      <div class="px-2 py-1 w-full bg-gray-300 leading-tight tracking-tight flex items-center justify-end bg-gray-900 text-gray-100 font-semibold h-12">
                        <span class="text-right"> Billed</span>
                      </div>
                      <div
                        :key="'p-' + i"
                        v-for="(r, i) of results.monthly_targets.data.table"
                        class="w-full bg-gray-300 p-2 text-right">
                        {{ r.billed ? r.billed : '-' }}
                      </div>
                    </div>

                    <div class="flex divide-y divide-gray-400  flex-wrap w-24 flex-shrink-0">
                      <div class="px-2 py-1 w-full bg-gray-300 leading-tight tracking-tight flex items-center justify-end bg-gray-900 text-gray-100 font-semibold h-12">
                        <span class="text-right">Approved</span>
                      </div>
                      <div
                        :key="'p-' + i"
                        v-for="(r, i) of results.monthly_targets.data.table"
                        class="w-full bg-gray-300 p-2 text-right">
                        {{ r.approved ? r.approved : '-' }}
                      </div>
                    </div>


                    <div class="flex divide-y divide-gray-400  flex-wrap w-24 flex-shrink-0">
                      <div class="px-2 py-1 w-full bg-gray-300 leading-tight tracking-tight flex items-center justify-end bg-gray-900 text-gray-100 font-semibold h-12">
                        <span class="text-right">Pending</span>
                      </div>
                      <div
                        :key="'p-' + i"
                        v-for="(r, i) of results.monthly_targets.data.table"
                        class="w-full bg-gray-300 p-2 text-right">
                        {{ r.pending ? r.pending : '-' }}
                      </div>
                    </div>

                    <div class="flex divide-y divide-gray-400  flex-wrap w-24 flex-shrink-0">
                      <div class="px-2 py-1 w-full bg-gray-300 leading-tight tracking-tight flex items-center justify-end bg-gray-900 text-gray-100 font-semibold h-12">
                        <span class="text-right">Postponed</span>
                      </div>
                      <div
                        :key="'p-' + i"
                        v-for="(r, i) of results.monthly_targets.data.table"
                        class="w-full bg-gray-300 p-2 text-right">
                        {{ r.postponed ? r.postponed : '-' }}
                      </div>
                    </div>

                    <div class="flex divide-y divide-gray-400  flex-wrap w-24 flex-shrink-0">
                      <div class="px-2 py-1 w-full bg-gray-300 leading-tight tracking-tight flex items-center justify-end bg-gray-900 text-gray-100 font-semibold h-12">
                        <span class="text-right">Correction</span>
                      </div>
                      <div
                        :key="'p-' + i"
                        v-for="(r, i) of results.monthly_targets.data.table"
                        class="w-full bg-gray-300 p-2 text-right">
                        {{ r.in_correction ? r.in_correction : '-' }}
                      </div>
                    </div>

                    <div class="flex divide-y divide-gray-400  flex-wrap w-24 flex-shrink-0">
                      <div class="p-2 w-full bg-gray-300 leading-tight tracking-tight flex items-center bg-gray-900 text-gray-100 font-semibold h-12">
                        <span class="text-right">Rejected</span>
                      </div>
                      <div
                        :key="'p-' + i"
                        v-for="(r, i) of results.monthly_targets.data.table"
                        class="w-full bg-gray-300 p-2 text-right">
                        {{ r.rejected ? r.rejected : '-' }}
                      </div>
                    </div>

                    <div>
                      <div class="w-3"></div>
                    </div>

                    <div class="flex divide-y divide-gray-400  flex-wrap w-24 flex-shrink-0">
                      <div class="px-2 py-1 w-full bg-gray-300 leading-tight tracking-tight flex items-center justify-end bg-gray-900 text-gray-100 font-semibold h-12">
                        <span class="text-right">Overbuild</span>
                      </div>
                      <div
                        :key="'p-' + i"
                        v-for="(r, i) of results.monthly_targets.data.table"
                        class="w-full bg-gray-300 p-2 text-right">
                        {{ r.overbuild ? r.overbuild : '-' }}
                      </div>
                    </div>

                    <div class="flex divide-y divide-gray-400  flex-wrap w-24 flex-shrink-0">
                      <div class="px-2 py-1 w-full bg-gray-300 leading-tight tracking-tight flex items-center justify-end bg-gray-900 text-gray-100 font-semibold h-12">
                        <span class="text-right">Trailing</span>
                      </div>
                      <div
                        :key="'p-' + i"
                        v-for="(r, i) of results.monthly_targets.data.table"
                        class="w-full bg-gray-300 p-2 text-right">
                        {{ r.trailing ? r.trailing : '-' }}
                      </div>
                    </div>

                    <div>
                      <div class="w-5"></div>
                    </div>

                    <div class="flex divide-y divide-gray-400  flex-wrap w-24 flex-shrink-0">
                      <div class="px-2 py-1 w-full bg-gray-300 leading-tight tracking-tight flex items-center justify-end bg-gray-900 text-gray-100 font-semibold h-12">
                        <span class="text-right">Billable</span>
                      </div>
                      <div
                        :key="'p-' + i"
                        v-for="(r, i) of results.monthly_targets.data.table"
                        class="w-full bg-gray-300 p-2 text-right">
                        {{ r.billable ? r.billable : '-' }}
                      </div>
                    </div>

                    <div>
                      <div class="w-5"></div>
                    </div>

                    <div class="flex divide-y divide-gray-400  flex-wrap w-24 flex-shrink-0">
                      <div class="px-2 py-1 w-full bg-gray-300 leading-tight tracking-tight flex items-center justify-end bg-gray-900 text-gray-100 font-semibold h-12">
                        <span class="text-right">Link Price USD</span>
                      </div>
                      <div
                        :key="'p-' + i"
                        v-for="(r, i) of results.monthly_targets.data.table"
                        :class="{
                          'bg-yellow-100': r.monthly_target_set === 0
                          }"
                        class="w-full bg-gray-300 p-2 text-right">
                        {{ r.link_price_usd ? '$' + r.link_price_usd.toLocaleString() : '-' }}
                      </div>
                    </div>


                    <div class="flex divide-y divide-gray-400  flex-wrap w-24 flex-shrink-0">
                      <div class="px-2 py-1 w-full bg-gray-300 leading-tight tracking-tight flex items-center justify-end bg-gray-900 text-gray-100 font-semibold h-12">
                        <span class="text-right">Total Billed</span>
                      </div>
                      <div
                        :key="'p-' + i"
                        v-for="(r, i) of results.monthly_targets.data.table"
                        class="w-full bg-gray-300 p-2 text-right">
                        {{ r.total_billed ? '$' + r.total_billed.toLocaleString() : '-' }}

                      </div>
                    </div>

                    <div class="flex divide-y divide-gray-400  flex-wrap w-24 flex-shrink-0">
                      <div class="px-2 py-1 w-full bg-gray-300 leading-tight tracking-tight flex items-center justify-end bg-gray-900 text-gray-100 font-semibold h-12">
                        <span class="text-right">Total Billable</span>
                      </div>
                      <div
                        :key="'p-' + i"
                        v-for="(r, i) of results.monthly_targets.data.table"
                        class="w-full bg-gray-300 p-2 text-right">
                        {{ r.total_billable ? '$' + r.total_billable.toLocaleString() : '-' }}

                      </div>
                    </div>
                    <div class="flex divide-y divide-gray-400  flex-wrap w-24 flex-shrink-0">
                      <div class="px-2 py-1 w-full bg-gray-300 leading-tight tracking-tight flex items-center justify-end bg-gray-900 text-gray-100 font-semibold h-12">
                        <span class="text-right">Shortfall Revenue</span>
                      </div>
                      <div
                        :key="'p-' + i"
                        v-for="(r, i) of results.monthly_targets.data.table"
                        class="w-full bg-gray-300 p-2 text-right">
                        {{ r.short_fall ? '$' + r.short_fall.toLocaleString() : '-' }}
                      </div>
                    </div>

                  </div>
                </div>
              </div>

            </div>
          </div>

        </el-tab-pane>

            <el-tab-pane label="Acquired Links">
              <div class="grid grid-cols-1 gap-6">

                <div>
                  <div class="grid grid-cols-4 gap-6">
                    <div class="bg-white rounded shadow border border-gray-300 p-4">
                      <h4 class="font-bold text-center">Last 30 Days</h4>
                      <loader v-if="results.acquired_stats.loading" />
                      <div class="text-3xl semi-bold text-center my-3" v-else>
                        {{ results.acquired_stats.data.last_30_days }}
                      </div>
                    </div>
                    <div class="bg-white rounded shadow border border-gray-300 p-4">
                      <h4 class="font-bold text-center">This Month</h4>
                      <loader v-if="results.acquired_stats.loading" />
                      <div class="text-3xl semi-bold text-center my-3" v-else>
                        {{ results.acquired_stats.data.this_month }}
                      </div>
                    </div>
                    <div class="bg-white rounded shadow border border-gray-300 p-4">
                      <h4 class="font-bold text-center">Last 7 Days</h4>
                      <loader v-if="results.acquired_stats.loading" />
                      <div class="text-3xl semi-bold text-center my-3" v-else>
                        {{ results.acquired_stats.data.last_7_days }}
                      </div>
                    </div>
                    <div class="bg-white rounded shadow border border-gray-300 p-4">
                      <h4 class="font-bold text-center">Last 24 Hours</h4>
                      <loader v-if="results.acquired_stats.loading" />
                      <div class="text-3xl semi-bold text-center my-3" v-else>
                        {{ results.acquired_stats.data.last_24_hours }}
                      </div>
                    </div>
                  </div>
                </div>

                <div class="bg-white rounded shadow border border-gray-300">
                  <div class="p-3 border-b border-gray-300">
                    <h4 class="font-semibold">Links Acquired by Day</h4>
                  </div>
                  <div>
                    <div v-if="results.links_by_day.loading">
                      <loader />
                    </div>
                    <div v-else>
                      <div class="max-h-96 overflow-scroll">
                        <table>
                          <thead>
                          <tr>
                            <th>Date</th>
                            <th class="w-32 text-center">Total</th>
                            <th class="w-32">Paid</th>
                            <th class="w-32">Free</th>
                          </tr>
                          </thead>
                          <tbody>
                          <tr
                            :key="'result-' + i "
                            v-for="(r, i) of results.links_by_day.data">
                            <td>{{ r.date }}</td>
                            <td class="text-center">{{ r.total }}</td>
                            <td class="text-center">{{ r.paid }}</td>
                            <td class="text-center">{{ r.free }}</td>
                          </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="bg-white rounded shadow border border-gray-300">
                  <div class="p-3 border-b border-gray-300">
                    <h4 class="font-semibold">Links Acquired by Client</h4>
                  </div>
                  <div>
                    <div v-if="results.links_by_client.loading">
                      <loader />
                    </div>
                    <div v-else>
                      <div class="max-h-96 overflow-scroll">
                        <table>
                          <thead>
                          <tr>
                            <th>Client</th>
                            <th class="w-32 text-center">Acquired TM</th>
                            <th class="w-40 flex-shrink-0">Acquired LM</th>
                            <th class="w-40 flex-shrink-0">Difference</th>
                          </tr>
                          </thead>
                          <tbody>
                          <tr
                            :key="'result-' + i "
                            v-for="(r, i) of results.links_by_client.data">
                            <td>{{ r.client_name }}</td>
                            <td class="text-center">{{ r.total_this_month }}</td>
                            <td class="text-center">{{ r.total_last_month }}</td>
                            <td class="text-center">{{ r.difference }}</td>
                          </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="bg-white rounded shadow border border-gray-300">
                  <div class="p-3 border-b border-gray-300">
                    <h4 class="font-semibold">Links Acquired by Project</h4>
                  </div>
                  <div>
                    <div v-if="results.links_by_client_project.loading">
                      <loader />
                    </div>
                    <div v-else>
                      <div class="max-h-96 overflow-scroll">
                        <table>
                          <thead>
                          <tr>
                            <th>Client</th>
                            <th>Project</th>
                            <th class="w-32 text-center">Acquired TM</th>
                            <th class="w-40 flex-shrink-0">Acquired LM</th>
                            <th class="w-40 flex-shrink-0">Difference</th>
                          </tr>
                          </thead>
                          <tbody>
                          <tr
                            :key="'result-' + i "
                            v-for="(r, i) of results.links_by_client_project.data">
                            <td>{{ r.client_name }}</td>
                            <td>{{ r.name }}</td>
                            <td class="text-center">{{ r.total_this_month }}</td>
                            <td class="text-center">{{ r.total_last_month }}</td>
                            <td class="text-center">{{ r.difference }}</td>
                          </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>

              </div>

            </el-tab-pane>
            <el-tab-pane label="Publishers">
              <div class="bg-white rounded shadow border border-gray-300">
                <div class="p-3 border-b border-gray-300">
                  <h4 class="font-semibold">Repeat Publishers vs New Publishers</h4>
                </div>
                <div>
                  <div v-if="results.repeat_publishers.loading">
                    <loader />
                  </div>
                  <div v-else>
                    <div class="max-h-96 overflow-scroll">
                      <table>
                        <thead>
                        <tr>
                          <th class="w-32">Is Repeat?</th>
                          <th>Domain</th>
                          <th class="w-32 text-center">Total</th>
                          <th class="w-40 flex-shrink-0">First Acquired</th>
                          <th class="w-40 flex-shrink-0">Last Acquired</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr
                          :key="'result-' + i "
                          v-for="(r, i) of results.repeat_publishers.data">
                          <td>{{ r.is_repeat_publisher }}</td>
                          <td>{{ r.domain }}</td>
                          <td class="text-center">{{ r.total_articles }}</td>
                          <td>{{ r.first_acquired_date }}</td>
                          <td>{{ r.last_acquired_date }}</td>
                        </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </el-tab-pane>
            <el-tab-pane label="Emails">
            </el-tab-pane>
            <el-tab-pane label="Email Accounts">
            </el-tab-pane>
            <el-tab-pane label="Batches">
            </el-tab-pane>
      </el-tabs>

    </div>
  </template>
<script>
import moment from "moment"
export default {
  name: "ProjectTargets",
  data(){
    return {
      monthly_target: {
        query: '',
        month: moment().startOf('month').toDate(),
        selected: {
          project: null,
          target: 100
        },
        show: false,
        adding: false,
        options: {
          projects: []
        }
      },
      results: {
        monthly_targets: {
          data: {
            table: [],
            stats: {}
          },
          loading: true
        },
        repeat_publishers: {
          data: [],
          loading: true
        },
        links_by_client: {
          data: [],
          loading: true
        },
        links_by_day: {
          data: [],
          loading: true
        },
        links_by_client_project: {
          data: [],
          loading: true
        },
        latest_projects: {
          data: [],
          loading: true
        },
        acquired_stats: {
          data: {},
          loading: true
        }
        // acquired-links-by-project-client
      }
    }
  },
  mounted(){
    this.fetchStats('repeat-publishers', 'repeat_publishers')
    this.fetchStats('acquired-links-by-day', 'links_by_day')
    this.fetchStats('acquired-links-by-client', 'links_by_client')
    this.fetchStats('acquired-links-by-project-client', 'links_by_client_project')
    this.fetchStats('acquired-stats', 'acquired_stats', true)
    this.fetchStats('manage-targets', 'latest_projects', false, {
      month: moment(this.monthly_target.month).format('YYYY-MM-DD')
    })
    this.fetchStats('monthly-targets', 'monthly_targets', false, {
      month: moment(this.monthly_target.month).format('YYYY-MM-DD')
    })
  },
  watch: {
    'monthly_target.month'(){
      this.fetchStats('monthly-targets', 'monthly_targets', false, {
        month: moment(this.monthly_target.month).format('YYYY-MM-DD')
      })
      this.fetchStats('manage-targets', 'latest_projects', false, {
        month: moment(this.monthly_target.month).format('YYYY-MM-DD')
      })
    }
  },
  computed: {
    filteredTargets(){
      let vm = this, q = vm.monthly_target.query.toLowerCase()
      return this.results.latest_projects.data.filter((x) => {
        return x.project_name.toLowerCase().includes(q) ||
          x.client_name.toLowerCase().includes(q);
      })
    },
    getActiveMonth(){
      return moment(this.monthly_target.month).format('MMM YYYY')
    }
  },
  methods: {
    deleteTarget(id){
      const vm = this;
      vm.$http.delete(`/v1/companies/${this.$route.params.companyId}/project-target-stats/${id}`).then(() => {
        vm.$message('Monthly target deleted')
        vm.fetchStats('monthly-targets', 'monthly_targets', false, {
          month: moment(vm.monthly_target.month).format('YYYY-MM-DD')
        })
      }).catch(() => {
        vm.$message('Sorry, an error has occurred')
      })

    },
    updateTargets(){
      const vm = this;
      vm.monthly_target.adding = true;

      vm.$http.post(`/v1/companies/${this.$route.params.companyId}/project-target-stats`, {
        month: moment(vm.monthly_target.month).format('YYYY-MM-DD'),
        targets: vm.results.latest_projects.data.map((x) => {
          return {
            project_id: x.project_id,
            billed_early: x.billed_early,
            link_price: x.link_price,
            per_sow: x.per_sow,
            rollover: x.rollover,
            underbilled: x.underbilled
          }
        })
      }).then(() => {
        vm.$message('Monthly targets added')
        vm.monthly_target.adding = false;
        vm.fetchStats('monthly-targets', 'monthly_targets', false, {
          month: moment(vm.monthly_target.month).format('YYYY-MM-DD')
        })
      }).catch(() => {
        vm.$message('Sorry, an error has occurred')
        vm.monthly_target.adding = false;
      })


    },
    addTarget(){
      const vm = this;
      vm.monthly_target.adding = true;
      vm.$http.post(`/v1/companies/${this.$route.params.companyId}/project-target-stats`, {
        project_id: vm.monthly_target.selected.project,
        target: vm.monthly_target.selected.target
      }).then(() => {
        vm.monthly_target.show = false;
        vm.monthly_target.selected.project = null;
        vm.monthly_target.selected.target = 1;
        vm.$message('Monthly target added')
        vm.monthly_target.adding = false;
        vm.fetchStats('monthly-targets', 'monthly_targets', false, {
          month: moment(vm.monthly_target.month).format('YYYY-MM-DD')
        })
      }).catch(() => {
        vm.$message('Sorry, an error has occurred')
        vm.monthly_target.adding = false;
      })

    },
    fetchStats(mode, key, single = false, params = {}){
      const vm = this;
      vm.results[key].loading = true;
      vm.$http.get(`/v1/companies/${this.$route.params.companyId}/project-target-stats`, {
        params: Object.assign({
          mode
        }, params)
      }).then((response) => {
        if(single){
          vm.results[key].data = response.data[0] || {};
        }else{
          vm.results[key].data = response.data;
        }
        vm.results[key].loading = false;
      })
    }
  }
}
</script>

<style>
.title-width {
  width: 30rem;
}
.name-width {
  width: 18rem;
}
</style>
