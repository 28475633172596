<template>
  <div>
    <div class="p-4">
      <div class="page-title">
        <h1> Price
        </h1>
        <span class="text-xs font-base text-blue-900">Fill in all sections marked with an <span class="text-red-600">*</span> </span>
      </div>
      <el-form
        ref="form"
        :model="project"
        :rules="rules.price"
        label-width="300px"
        label-position="left"
        :disabled="disabled"
      >
        <el-form-item label="Base link price" prop="base_link_price">
          <el-input type="number" v-model.number="project.base_link_price" />
        </el-form-item>
        <el-form-item label="Price type" prop="link_price_type">
          <el-select v-model="project.link_price_type" class="w-full">
            <el-option value="FIXED" label="Fixed" />
            <el-option value="VARIABLE" label="Variable" />
          </el-select>
        </el-form-item>
        <el-form-item v-if="project.link_price_type === 'VARIABLE'" label-width="0px" prop="variableItems">
          <div>
            <div class="text-right mb-2">
              <el-button type="info" size="mini" icon="el-icon-setting" v-if="project.link_price_type === 'VARIABLE'" @click="show_add_price_dialog = true">
                Add variation
              </el-button>
            </div>
            <div  v-if="project.price_variables && project.price_variables.length">
              <el-table :data="project.price_variables" v-if="false">
                <el-table-column prop="price" label="price" width="150px" sortable>
                  <template slot-scope="scope">
                    <el-input v-model.number="scope.row.price" size="small" />
                  </template>
                </el-table-column>
                <el-table-column prop="rules" label="Rules" min-width="300px">
                  <template slot-scope="scope">
                    <div class="text-right">
                      <el-select placeholder="add rule" :value="null" @change="(val) => { addRuleField(val, scope.row) }">
                        <el-option v-for="(rule, ri) in allowedFilters" :key="ri" :value="rule.value" :label="rule.label" />
                      </el-select>
                    </div>
                    <div v-if="scope.row.rules && scope.row.rules.length">
                      <div v-for="(rule, ri) in scope.row.rules" :key="ri" class="mt-4">
                        <span class="font-xs font-bold">{{ allowedFilters.find(i => i.value === rule.type).label }}</span>
                        <el-input v-model="rule.value" class="numbers-input">
                          <el-select v-model="rule.comparison" slot="prepend" placeholder="Select">
                            <el-option label="=" value="="></el-option>
                            <el-option label="<=" value="<="></el-option>
                            <el-option label="=>" value="=>"></el-option>
                          </el-select>
                          <el-button slot="append" icon="el-icon-delete" @click="deletePriceRule(scope.row.rules, ri)"></el-button>
                        </el-input>
                      </div>
                    </div>
                  </template>
                </el-table-column>
                <el-table-column prop="threshold" label="Link threshold >=" width="200px" sortable>
                  <template slot-scope="scope">
                    <el-input v-model.number="scope.row.threshold" size="small" />
                  </template>
                </el-table-column>
                <el-table-column width="110px" fixed="right">
                  <template slot-scope="scope">
                    <el-button size="small" icon="el-icon-edit-outline" type="danger" @click="deletePriceVariable(scope.$index)">delete</el-button>
                  </template>
                </el-table-column>
              </el-table>
              <div v-for="(rule, i) in project.price_variables" :key="i" class="border rounded shadow-sm p-6 my-6 relative">
                <i class="absolute el-icon-error text-red-600 cursor-pointer deleteicon" @click="deletePriceVariable(i)"></i>
                <el-form inline>
                  <el-form-item label="price">
                    <el-input v-model.number="rule.price" size="mini" />
                  </el-form-item>
                  <el-form-item label="Threshold">
                    <el-input v-model.number="rule.threshold" size="mini" />
                  </el-form-item>
                  <el-form-item label="Rule">
                    <el-select placeholder="add rule" :value="null" @change="(val) => { addRuleField(val, rule) }" size="mini">
                    <el-option v-for="(filter, ri) in allowedFilters" :key="ri" :value="filter.value" :label="filter.label" />
                  </el-select>
                  </el-form-item>
                </el-form>
                <div v-if="rule.rules && rule.rules.length">
                  <div v-for="(r, ri) in rule.rules" :key="ri" class="mt-4">
                    <span class="font-xs font-bold">{{ allowedFilters.find(i => i.value === r.type).label }}</span>
                    <el-input v-model="r.value" class="numbers-input">
                      <el-select v-model="r.comparison" slot="prepend" placeholder="Select">
                        <el-option label="=" value="="></el-option>
                        <el-option label="<=" value="<="></el-option>
                        <el-option label="=>" value="=>"></el-option>
                      </el-select>
                      <el-button slot="append" icon="el-icon-delete" @click="deletePriceRule(rule.rules, ri)"></el-button>
                    </el-input>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </el-form-item>
      </el-form>
    </div>
    <trieste-dialog :show.sync="show_add_price_dialog" title="Add price variable">
      <div class="p-6 pb-2">
        <el-form :rules="rules.price_variable_rules" :model="priceVariable" label-width="190px" label-position="left" ref="priceVariableForm">
          <el-form-item label="price" prop="price">
            <el-input type="number" v-model="priceVariable.price" />
          </el-form-item>
          <el-form-item label="Threshold >=" prop="threshold">
            <el-input type="number" v-model="priceVariable.threshold" />
          </el-form-item>
        </el-form>
      </div>
      <template v-slot:footer-right>
        <el-button @click="show_add_price_dialog = false" icon="el-icon-close" type="danger" size="small">Cancel</el-button>
        <el-button type="success" icon="el-icon-edit-outline" @click="addPriceRule" size="small">Confirm</el-button>
      </template>
    </trieste-dialog>
  </div>
</template>
<script>
export default {
  props: {
    value: {
      default: {
        price_variables: []
      }
    },
    allowVariation: {
      type: Boolean,
      default: true
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    const variableItems = (rule, value, callback) => {
      //console.log('I have been called?')
      this.project.price_variables.forEach((priceRule, index) => {
        if(!priceRule.price || !(priceRule.price + '').length) {
          //console.log('The price rule', priceRule)
          return callback(new Error('Please ensure a valid price is entered for each row.'));
        }
        (priceRule.rules || []).forEach(rule => {
          if(!rule.comparison || (!rule.value && rule.value !== 0 )) {
            return callback(new Error('Please ensure each rule has a value and comparison.'));
          }
        });

        // check the other items.
        const otherPriceRules = [...this.project.price_variables.slice(0, index), ...this.project.price_variables.slice(index + 1)];
        for(const otherPrice of otherPriceRules) {
          if(otherPrice.threshold === priceRule.threshold) {
            if(otherPrice.rules.length === priceRule.rules.length) {
              let foundUnique = false;
              for(const rule of priceRule.rules) {
                const find = otherPrice.rules.find(i => {
                  return i.comparison === rule.comparison && i.type === rule.type && i.value === rule.value
                });
                if(!find) {
                  foundUnique = true;
                  break;
                }
              }

              if(!foundUnique) {
                return callback(new Error('2 or more rules conflict with eachother.'));
              }
            }
          }
        }
      });
      return callback();
    };
    return {
      show_add_price_dialog: false,
      ruleOption: null,
      allowedFilters: [
        { label: "Domain Rating", value: "domain_rating" },
        { label: "SEMRush traffic score", value: "semrush_traffic_score" },
        { label: "Domain refs", value: "domain_refs" },
        { label: "Domain trust flow", value: "domain_trust_flow" },
        { label: "Domain citation", value: "domain_citation" },
        { label: "Domain flow metrics ratio", value: "domain_flow_metrics_ratio" },
        { label: "Host trust flow", value: "host_trust_flow" },
        { label: "Host citation", value: "host_citation" },
        { label: "Host flow metrics ratio", value: "host_flow_metrics_ratio" },
        { label: "Nofollow links", value: "nofollow_links" },
  ],
      priceVariable: {
        price: null,
        dr: null,
        traffic: null,
        rules: [],
        threshold: null
      },
      testKey: 0,
      project: this.value,
      rules: {
        price_variable_rules: {
          price: [
            { required: true, min: 1, message: 'Please specify a price for this threshold.' }
          ]
        },
        price: {
          base_link_price: [
            { required: true, message: 'Please specify a base link price.' }
          ],
          link_price_type: [
            { required: true, message: 'Please specify a price type.' }
          ],
          variableItems: [
            { validator: variableItems }
          ]
        },
      }
    }
  },
  methods: {
    async validate() {
      try {
        //console.log('Calling the validation form')
        await this.$refs.form.validate()
        return true;
      } catch (e) {
        return false
      }
    },
    addRuleField(value, price_variables) {
      if(!Array.isArray(price_variables.rules)) {
        price_variables.rules = [];
      }
      price_variables.rules.push({
        type: value,
        value: null,
        comparison: '='
      });
      //console.log('Rule added')
    },
    deletePriceRule(rules, index) {
      rules.splice(index, 1)
    },
    addPriceRule() {
      this.$refs.priceVariableForm.validate((valid) => {
        if(valid) {
          if(!Array.isArray(this.project.price_variables)) {
            this.project.price_variables = [];
          }

          /*const tryFind = this.project.price_variables.find(i => {
            return i.dr === this.priceVariable.dr && i.traffic === this.priceVariable.traffic && i.threshold === this.priceVariable.threshold
          })

          if(tryFind) {
            return this.$message.error('Could not add that price variation as it conflicts with an existing one.');
          }*/

          this.project.price_variables.push(Object.assign({}, this.priceVariable));
          this.priceVariable = {
            price: null,
            dr: null,
            traffic: null,
            rules: [],
            threshold: null
          }
          this.$refs.priceVariableForm.resetFields();
          this.show_add_price_dialog = false;
        }
      })
    },
    deletePriceVariable(key) {
      this.project.price_variables.splice(key, 1);
    },
  },
  watch: {
    project: function (val)  {
      //console.log('Updating from the child', val)
      this.$emit('input', val)
    },
    value: function (val) {
      //console.log('Updating from the parent', val)
      this.project = val;
    }
  }
}
</script>
<style lang="scss">
  .numbers-input {
    .el-select .el-input {
      width: 65px;
    }

    .el-input-group__prepend {
      background-color: #fff;
    }
    input[type=number] {
      width: 200px;
      border: 1px solid #DCDFE6;
    }
  }
  .deleteicon {
    font-size: 2em;
    top: -.5em;
    z-index: 10;
    right: -.5em;
    background: #fff;
  }
</style>
