<template>
  <login-form />
</template>

<script>

// import LoginForm from '@/components/views/Login/Form';


export default {
  name: 'Login',
  components: { LoginForm: () => import('@/components/views/Login/Form') },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.$store.commit('auth/reset');
    });
  },
}
</script>

<style scoped>
</style>
