<template>
  <div v-loading="loading || !loaded">
    <div class="page-title" v-if="showTitle">
      <h1>
        Leads
      </h1>
      <div class="flex flex-row">
        <div class="mr-2">
          <el-select v-model="options.sort_by" @change="refresh">
            <el-option value="acquired_date" label="Acquired date"  />
            <el-option value="created_date" label="Created date"  />
            <el-option value="updated_date" label="Updated date"  />
            <el-option value="payment_date" label="payment date"  />
          </el-select>
        </div>
        <div class="mr-2">
          <el-select v-model="helpers.month" @change="quickMonthDate" v-if="false">
            <el-option v-for="(v, k) in 10" :key="k" :value="v" :label="getMonthDisplay(v)" />
          </el-select>
        </div>
        <div>
          <el-button @click="toggleOptions" type="primary">
            {{ options.show_options ? 'Save options' : 'Show options' }}
          </el-button>
        </div>
      </div>
    </div>
    <options
      :date-start.sync="options.date_start"
      :date-end.sync="options.date_end"
      :projects.sync="options.projects"
      :sites.sync="options.sites"
      :site-targets.sync="options.site_targets"
      :users.sync="options.users"
      :user_groups.sync="options.user_groups"
      :show.sync="options.show_options"
      :status.sync="options.status"
      :type.sync="options.type"
      ref="options"
      @on-close="refresh"
    />
    <div v-loading="table_loading || stats_loading">
      <ul class="text-sm mb-4">
      <li>Average time to acquire: <b>{{ summary.avg_time_to_acquire }}</b></li>
        <li>Initial Response Rate: <b>{{ summary.initial_response_rate }}</b>%</li>
        <li>Success rate: <b>{{ summary.success_rate }}</b>%</li>
        <li><b>{{ summary.initial_emails_sent }}</b> Initial Emails Sent</li>
        <li><b>{{ summary.initial_emails_replied }}</b> Initial Replies Received</li>
        <li><b>{{ summary.non_initial_emails_sent }}</b> Non-initial Emails Sent</li>
        <li><b>{{ summary.emails_replied }}</b> Emails Received</li>
        <li><b>{{ summary.active_links }}</b> Active Links</li>
        <li><b>{{ summary.reviewed_count }}</b> Leads Reviewed</li>
      <li>Average paid price: <b>{{ summary.avg_price_paid }}</b></li>
        <li>Active or Pending in Suspended Sites and Skipped Targets:  <b>{{ summary.active_in_suspended_site_or_target }}</b></li>
      </ul>
    </div>
    <div>
      <trieste-table title="Lead Summary" :show-sub-title="false" :show-pagination="false" :params="options" v-if="options.date_end" ref="table"
                     :refresh-on-param-change="false"
                     @loading="(v) => table_loading = v"
                     :fields="fields" :end-point="`/v1/companies/${this.$route.params.companyId}/reports/leads`" :show-title="false" @on-load="setSummaryData"
                     is-post
      >
        <template v-slot:header-right>
          <el-button type="primary" size="medium" icon="el-icon-download" @click="download">Download links</el-button>
        </template>
      </trieste-table>
    </div>
  </div>
</template>
<script>
  import Options from '@/components/views/Reporting/Options'
  import moment from 'moment';

  const FileDownload = require('js-file-download');
  export default {
    components: {
      Options
    },
    props: {
      showTitle: {
        type: Boolean,
        default: true
      }
    },
    data: function() {
      return {
        loading: false,
        loaded: true,
        table_loading: false,
        stats_loading: false,
        summary: {
          active_links: null,
          avg_price_paid: null,
          avg_time_to_acquire: null,
          emails_replied: null,
          emails_sent: null,
          initial_emails_replied: null,
          initial_emails_sent: null,
          initial_response_rate: null,
          links_renewed: null,
          non_initial_emails_sent: null,
          reviewed_count: null,
          site_links: null,
          site_links_count: null,
          success_rate: null,
          active_in_suspended_site_or_target: null
        },
        options: {
          date_start: null,
          date_end: null,
          projects: [],
          sites: [],
          site_targets: [],
          user_groups: [],
          users: [],
          show_options: false,
          sort_by: 'acquired_date',
          status: [],
          type: []
        },
        helpers: {
          month: 2
        },
        user_view: false,
        fields: [
          { title: "Acquired", name: "acquiredOn" },
          { title: "Link url", name: "url" },
          { title: "Client", name: "client" },
          { title: "Site", name: "site" },
          { title: "Project", name: "project" },
          { title: "Total Price", name: "usdPrice" },
          { title: "Invoice number", name: "invoiceNumber" },
          { title: "Invoice date", name: "invoiceDate" },
          { title: "Original Price", name: "price" },
          { title: "Original Currency", name: "currency" },
          { title: "Anchor Text", name: "anchorText" },
          { title: "Anchor URL", name: "anchorUrl" },
          { title: "Link Type", name: "type" },
          { title: "Source", name: "source" },
          { title: "Lead ID", name: "id" },
          { title: "Host IP", name: "ip" },
          { title: "DR", name: "dr" },
          { title: "TF", name: "tf" },
          { title: "CF", name: "cf" },
          { title: "S-TS", name: "semrush_traffic" },
          { title: "SEMRush Volume Score", name: "semrush_volume" },
          { title: "status", name: "status" },
          { title: "Target", name: "targetName" },
          { title: "Created By", name: "createdBy" },
          { title: "Updated By", name: "updatedBy" },
          { title: "Acquired By", name: "acquiredBy" },
          { title: "Owner", name: "ownedBy" },
          { title: "Content Item owner", name: "contentItemOwner" },
          { title: "Content Item requestee", name: "contentItemRequestee" },
          { title: "Date Awaiting Payment", name: "dateAwaitingPayment" },
          { title: "Date Created", name: "dateCreated" },
          { title: "Content Request Date", name: "contentItemCreatedDate" },
          { title: "Paypal", name: "paypal" },
          { title: "TF/CF", name: "tfcf" },
          { title: "Content ID", name: "contentItemId" },
          { title: "First contact date", name: "firstEmailContent" },
          { title: "Campaign", name: "campaignName" },
          { title: "Contacts", name: "contacts" }
        ]
      }
    },
    mounted() {
      this.quickMonthDate(2)
    },
    methods: {
      setSummaryData(d) {
        this.stats_loading = true;
        this.$http.post(`/v1/companies/${this.$route.params.companyId}/reports/leads`, {
          ...this.options,
          stats: true
        }).then(r => {
          this.summary = r.data.stats
        })
          .catch((e) => this.$message.error(e.toString()))
          .finally(() => this.stats_loading = false)
      },
      getMonthDisplay(v) {
        return moment().subtract(v - 1, 'month').format('MMM YYYY')
      },
      quickMonthDate(v) {
        const month = moment.utc().subtract(v - 1, 'month')
        this.$refs.options.setDates(month.clone().startOf('month').format('YYYY-MM-DD 00:00:00'), month.clone().endOf('month').format('YYYY-MM-DD 23:59:59'))
        this.refresh()
      },
      download() {
        this.loading = true
        const url = `/v1/companies/${this.$route.params.companyId}/reports/leads/download`
        const options = {
          responseType: 'blob',
        };

        let downloadName = 'leads-export-report.csv'
        this.loading = true;
        this.$http.post(url, {...this.options, ...{ offset: new Date().getTimezoneOffset() }}, options).then(r => {
          FileDownload(r.data, downloadName)
        })
        .finally(() => {
          this.loading = false;
        })
      },
      refresh() {
        if(this.$refs?.table) this.$refs.table.getData()
      },
      toggleOptions() {
        if(this.options.show_options) {
          this.options.show_options = false;
          this.refresh()
        } else {
          this.options.show_options = true
        }
      }
    }
  }
</script>
