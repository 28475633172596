<template>
  <div id="app">
    <div
      v-if="isAllowedPage">
      <router-view />
    </div>
    <div
      v-if="isLoggedIn && !isAllowedPage"
      v-loading="!ready"
    >
      <div v-if="ready" class="flex overflow-hidden bg-white min-h-screen">
        <!-- Off-canvas menu for mobile -->

        <!-- Static sidebar for desktop -->
        <sidebar-menu />
        <div class="flex flex-col w-0 flex-1 bg-gray-100" :class="{'pb-8': this.$route.name !== 'Home' && !this.$route.meta.noPaddingBottom }">
          <top-bar />

          <main class="flex-1 relative z-0 overflow-y-auto py-6 focus:outline-none h-full flex flex-col">
            <div class="p-4 py-0" v-if="$store.state.global.notifications.length">
              <div v-for="(notification, i) in $store.state.global.notifications"
                   :key="i" class="mb-4">
                <el-alert
                  :closable="notification.closable || false"
                  :type="notification.type || 'success'"
                >{{ notification.message }}</el-alert>
              </div>
            </div>
            <div class="max-w-7xl mx-auto px-4 sm:px-6 md:px-8 h-full w-full flex flex-col" v-if="!$store.state.company.requiresCompanySet">
              <!-- Replace with your content -->
              <router-view />

              <!-- /End replace -->
            </div>
          </main>
        </div>
      </div>
    </div>
    <LoginModel v-if="showLoginForm" />
  </div>
</template>

<script>

import SidebarMenu from './components/incs/SidebarMenu'
import TopBar from './components/incs/TopBar'
import Breadcrumbs from './components/incs/Breadcrumbs'
import Search from "@/components/incs/Search";
import LoginModel from '@/components/views/Login/Model';

export default {
  name: 'App',
  components: {
    SidebarMenu,
    TopBar,
    LoginModel
  },
  metaInfo: {
    // if no subcomponents specify a metaInfo.title, this title will be used
    title: 'Trieste V2',
    // all titles will be injected into this template
    titleTemplate: '%s | Trieste V2'
  },
  data() {
    return {
      ready: false
    }
  },
  computed: {
    isLoggedIn () {
      return this.$store.state.auth.access_token !== null && !this.$store.state.auth.show_login_model
    },
    showLoginForm() {
      if(this.$route.name === 'Login') return false;

      return this.$store.state.auth.show_login_model ||
        (!this.isLoggedIn && !this.isAllowedPage)
    },
    isAllowedPage () {
      return ['404', 'Login', 'ForgottenPassword', 'ResetPassword', 'LegacyGmailLoginRedirect', 'GoogleSignInReturn',
        'LegacyGmailLoginReturn', 'LoginTest'].includes(this.$route.name) || !this.$route.meta.requiresAuth
    }
  },
  mounted() {
    this.loadCompany()
  },
  methods: {
    loadCompany() {
      if(this.$route.params.companyId) {
        this.$store.dispatch('company/getCompany', this.$route.params).then(() => {
          this.ready = true
       })
      } else {
        this.ready = true
      }
    }
  }
}
</script>
