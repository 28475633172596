<template>
  <div v-loading="loading">
    <div>
      <div class="p-4">
        <div class="page-title" v-if="showTitle">
          <h1> {{ value.id ? value.name : 'Start a project' }}
          </h1>
          <span class="text-xs font-base text-blue-900">Fill in all sections marked with an <span class="text-red-600">*</span> </span>
        </div>
        <el-form
          ref="form"
          :model="project"
          :rules="rules"
          label-width="300px"
          label-position="left"
          :disabled="disabled"
        >
          <el-form-item label="Name" prop="name" v-if="canShowField('name')">
            <el-input v-model="project.name" />
          </el-form-item>
          <el-form-item label="Campaign type" prop="campaign_type" v-if="canShowField('campaign_type')">
            <el-select v-model="project.campaign_type" filterable class="w-full">
              <el-option value="paid_link_development" label="Paid link development" />
              <el-option value="free_link_development" label="Free link development" />
            </el-select>
          </el-form-item>
          <el-form-item label="Billable" prop="billable" v-if="canShowField('billable')">
            <el-switch v-model="project.billable" />
          </el-form-item>
          <el-form-item label="SEO director" prop="director_id" v-if="canShowField('director_id')">
            <el-select v-model="project.director_id" filterable class="w-full">
              <el-option :value="null" label="N/A" />
              <el-option
                v-for="(cu, cui) in company_users"
                :key="'cu_'+cui"
                :value="cu.id"
                :label="`${cu.firstname} ${cu.lastname} (${cu.email})`"
              />
            </el-select>
          </el-form-item>
          <el-form-item label="Senior SEO consultant" prop="senior_consultant_id" v-if="canShowField('senior_consultant_id')">
            <el-select v-model="project.senior_consultant_id" filterable class="w-full">
              <el-option :value="null" label="N/A" />
              <el-option
                v-for="(cu, cui) in company_users"
                :key="'cu_'+cui"
                :value="cu.id"
                :label="`${cu.firstname} ${cu.lastname} (${cu.email})`"
              />
            </el-select>
          </el-form-item>
          <el-form-item label="SEO consultant" prop="consultant_id" v-if="canShowField('consultant_id')">
            <el-select v-model="project.consultant_id" filterable class="w-full">
              <el-option :value="null" label="N/A" />
              <el-option
                v-for="(cu, cui) in company_users"
                :key="'cu_'+cui"
                :value="cu.id"
                :label="`${cu.firstname} ${cu.lastname} (${cu.email})`"
              />
            </el-select>
          </el-form-item>
          <el-form-item label="Date start" prop="start_date" v-if="canShowField('start_date')">
            <el-date-picker class="w-full" format="dd MMMM yyyy" type="date" value-format="yyyy-MM-dd" @change="setAllocationMonthRange" v-model="project.start_date" />
          </el-form-item>
          <el-form-item label="Date end" prop="end_date" v-if="canShowField('end_date')">
            <el-date-picker class="w-full" format="dd MMMM yyyy" type="date" value-format="yyyy-MM-dd" @change="setAllocationMonthRange" v-model="project.end_date" />
          </el-form-item>
          <el-form-item label="Currency" prop="currency" v-if="canShowField('currency')">
            <el-select v-model="project.currency" class="w-full">
              <el-option value="USD" label="USD" />
              <el-option value="GBP" label="GBP" />
              <el-option value="CAD" label="CAD" />
              <el-option value="EUR" label="EURO" />
            </el-select>
          </el-form-item>
          <el-form-item label="Monthly media budget" prop="media_budget" v-if="canShowField('media_budget')">
            <el-input type="number" v-model.number="project.media_budget" />
          </el-form-item>
          <el-form-item label="Free days" prop="free_days" v-if="canShowField('free_days')">
            <el-input type="number" v-model="project.free_days" />
          </el-form-item>
          <el-form-item label="Pages indexed on google" prop="pages_indexed_on_google" v-if="canShowField('pages_indexed_on_google')">
            <el-input v-model="project.pages_indexed_on_google" />
          </el-form-item>
          <el-form-item label="Backlinks to website" prop="backlinks_count" v-if="canShowField('backlinks_count')">
            <el-input v-model="project.backlinks_count" />
          </el-form-item>
          <el-form-item label="Website traffic" prop="min_website_traffic" v-if="canShowField('min_website_traffic')">
            <el-input v-model="project.min_website_traffic" />
          </el-form-item>
          <el-form-item label="X paid links/articles in X published on site" prop="paid_links_ratio" v-if="canShowField('paid_links_ratio')">
            <el-input v-model="project.paid_links_ratio" />
          </el-form-item>
          <el-form-item label="GEO Restrictions" prop="geo_restrictions" v-if="canShowField('geo_restrictions')">
            <el-input v-model="project.geo_restrictions" />
          </el-form-item>
          <el-form-item label="Free tier billing count" prop="urgent" v-if="canShowField('urgent') && $store.getters['auth/hasPermission']('project.billing_count.update')">
            <el-select v-model="project.free_tier_billing_count">
              <el-option :value="1" label="1" />
              <el-option :value="2" label="2" />
            </el-select>
          </el-form-item>
          <el-form-item label="Paid tier billing count" prop="urgent" v-if="canShowField('urgent') && $store.getters['auth/hasPermission']('project.billing_count.update')">
            <el-select v-model="project.paid_tier_billing_count">
              <el-option :value="1" label="1" />
              <el-option :value="2" label="2" />
            </el-select>
          </el-form-item>
          <el-form-item label="Status">
            <el-select v-model="project.status">
              <el-option value="incomplete">-</el-option>
              <el-option value="active">active</el-option>
              <el-option value="suspended">suspended</el-option>
            </el-select>
          </el-form-item>
        </el-form>
      </div>
    </div>
  </div>
</template>
<script>
  const moment = require('moment')
  export default {
    props: {
      value: {
        type: Object,
        required: true
      },
      monthAllocations: {
        type: Array,
        default: () => []
      },
      disabled: {
        type: Boolean,
        default: false
      },
      showTitle: {
        type: Boolean,
        default: true
      },
      allowedFields: {
        type: Array,
        default: () => []
      },
      datesOnly: {
        type: Boolean,
        default: null
      }
    },
    mounted() {
      this.getResources()
    },
    data() {
      return {
        project: this.value,
        loading: false,
        company_users: [],
        months_allocations: this.monthAllocations,
        rules: {
          name: [
            { required: true, min: 2, message: 'Please specify a project name' }
          ],
          currency: [
            { required: true, message: 'Please specify currency.' }
          ],
          campaign_type: [
            { required: true, message: 'Please specify campaign type.' }
          ],
          start_date: [
            { required: true, message: 'Please specify start date.' }
          ],
          base_link_price: [
            { required: true, min: 1, message: 'Please specify a base link price.' }
          ],
          links_target_allocation_type: [
            { required: true, message: 'Please specify a allocation type.' }
          ],
          links_target_count: [
            { required: true, type: 'number', message: 'Please a min link target count.' }
          ],
        }
      }
    },
    methods: {
      async validate() {
        try {
          await this.$refs.form.validate()
          return true;
        } catch (e) {
          return false
        }
      },
      canShowField(fieldName) {
        if(this.datesOnly === true && !['start_date', 'end_date'].includes(fieldName)) {
         return false
        } else if(this.allowedFields.length > 0 && !this.allowedFields.includes(fieldName)) {
          return false
        }
        return true
      },
      getResources() {
        this.loading = true;
        this.$store.dispatch('users/loadAll', {}).then(r => {
          this.company_users = r;
          setTimeout(() => {
            if (this.project.start_date && this.project.end_date) {
              this.setAllocationMonthRange()
            }
          }, 600)
        }).finally(() => {
          this.loading = false
        })
      },
      setAllocationMonthRange() {
        //console.log('Updating monthly range?')
        this.months_allocations = [];
        if (this.project.start_date && this.project.end_date) {
          const dateStart = moment(this.project.start_date);
          const dateEnd = moment(this.project.end_date);

          const minDate = dateStart.unix() > dateEnd.unix() ? dateEnd : dateStart;
          const maxDate = dateStart.unix() > dateEnd.unix() ? dateStart : dateEnd;

          const months = [];
          let runWhile = true;
          while(runWhile) {
            months.push(minDate.format('MMMM YYYY'));
            if(minDate.format('MMM YYYY') === maxDate.format('MMM YYYY')) {
              runWhile = false;
              break;
            } else {
              minDate.add(1, 'M');
            }
          }

          this.months_allocations = months;
          this.$emit('update:monthAllocations', this.months_allocations)
        }
      },
    },
    watch: {
      project: {
        deep: true,
        handler: function(value) {
          this.$emit('update:input', value)
        }
      },
      value: {
        deep: true,
        handler: function(value) {
          const isDifferentDate = value.date_start !== this.project.date_start || value.date_end !== this.project.date_end
          this.project = value
          if(isDifferentDate) {
            this.setAllocationMonthRange()
          }
        }
      }
    }
  }
</script>
