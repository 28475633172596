<template>
  <div class="home-area">
    <div class="page-title">
      <h1>
        <logo
          v-model="$store.state.company.company.url"
          class="mr-2"
          :size="30"
        />
        <span>{{ $store.state.company.company.name }}</span>
      </h1>
    </div>
    <main-view v-if="!isExternalApprover" />
    <external-approver-view v-else />
  </div>
</template>
<script>
import MainView from '@/components/views/Home/Views/Main'
import ExternalApproverView from '@/components/views/Home/Views/ExternalApprover'
import CreateGoogleAuthenticationLink from '@/components/mixins/create_google_authentication_link'
export default {
  components: { MainView, ExternalApproverView },
  mixins: [CreateGoogleAuthenticationLink],
  computed: {
    isExternalApprover() {
      return this.$store.getters['auth/isExternalUser']();
    }
  },
  metaInfo: {
    title: 'Dashboard'
  },
  mounted() {
    // check is finance only
    this.isFinanceOnly();
  },
  methods: {
    isFinanceOnly() {
      if(this.$store.state.auth.user.roles.length === 1 && this.$store.state.auth.user.roles[0] === 'finance team') {
        this.$router.push('/link-building')
      }
    }
  }
}
</script>
