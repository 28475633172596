<template>
  <el-avatar
    v-if="!hide"
    class="el-logo"
    :fit="fit"
    shape="square"
    :size="size"
    :src="'https://logo.clearbit.com/' + $options.filters.extractDomain(logo)"
    @error="()=> { return true; }"
  >
    <svg
      :width="size + 'px'"
      viewBox="0 0 44 33"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
    >
      <g
        stroke="none"
        stroke-width="1"
        fill="none"
        fill-rule="evenodd"
      >
        <g
          transform="translate(-22.000000, -854.000000)"
          fill="#347ad5"
        >
          <path
            id="Fill-1"
            d="M57.5224891,861.676364 L42.742999,861.676364 C37.75795,861.676364 34.5224125,862.196277 33.0403073,863.237671 C32.4160979,863.730138 31.9040267,864.286124 31.5033093,864.90798 C31.1033762,865.529053 30.8438117,866.172083 30.7254002,866.83707 L57.5224891,866.83707 L57.5224891,861.676364 Z M65.7132767,886.7012 L57.5224891,886.7012 L57.5224891,874.035866 L30.1905876,874.035866 L30.1905876,886.7012 L21.9998,886.7012 L21.9998,870.263948 C21.9998,867.720059 22.3707184,865.442793 23.1125552,863.432933 C23.8543919,861.423857 24.94519,859.71826 26.3841651,858.315357 C27.8239244,856.913238 29.5820619,855.843613 31.6593617,855.105698 C33.7366615,854.368566 36.1111665,854 38.7820925,854 L65.7132767,854 L65.7132767,886.7012 Z"
          />
        </g>
      </g>
    </svg>
  </el-avatar>
</template>

<script>
export default {
  props: {
    value: {
      type: String,
      default: ''
    },
    fit: {
      type: String,
      default: 'scale-down'
    },
    size: {
      type: Number,
      default: 50
    }
  },
  data () {
    return {
      hide: false,
      logo: this.value
    }
  },
  watch: {
    value (val) {
      const vm = this
      vm.hide = true
      setTimeout(() => {
        vm.logo = val
        vm.hide = false
      }, 100)
    }
  }
}
</script>

<style scoped lang="scss">
    .el-logo {
        background: #fff;
        display: flex;
    }
</style>
