<template>
  <div>
    <div class="page-title">
      <h1>
        Blacklist
      </h1>
    </div>
    <el-tabs class="custom-tabs-v1">
      <el-tab-pane label="Contacts">
        <contacts />
      </el-tab-pane>
      <el-tab-pane label="Stop words">
        <stop-words />
      </el-tab-pane>
      <el-tab-pane label="Domains">
        <domains />
      </el-tab-pane>
    </el-tabs>
  </div>
</template>
<script>
  import Contacts from "./tabs/Contacts";
  import StopWords from "./tabs/StopWords";
  import Domains from "./tabs/Domains";
  export default {
    mounted() {
      this.$store.dispatch('company/getCompany', this.$route.params).then(company => {
        this.$store.commit('global/setBreadcrumbs', [
          {
            path: '/',
            label: company.name
          },
          {
            label: 'Blacklist'
          }
        ])
      })
    },
    components: { StopWords, Contacts, Domains },
  }
</script>
