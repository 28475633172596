import api from './../../utils/api';
export default {
  namespaced: true,
  state: {
    loading: false,
    account: {}
  },
  mutations: {
    update: (state, payload) => state[payload[0]] = payload[1]
  },
  actions: {
    getAccount({commit, state, rootState}, {companyId, accountId}) {
      commit('update', ['loading', true]);
      return new Promise((resolve, reject) => {
        if(state.account && state.account.id && state.account.id === accountId) {
          commit('update', ['loading', false]);
          return resolve();
        }
        companyId = companyId || rootState.company.company.id;
        api.get(`/v1/companies/${companyId}/email-accounts/${accountId}`).then(r => {
          commit('update', ['account', r.data.data]);
          commit('update', ['loading', false]);
          resolve(Object.assign({}, r.data.data))
        }).catch(e => {
          reject(e)
        })
      })
    },
  }
}
