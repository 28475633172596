<template>
  <el-dialog
    :visible.sync="showDialog" :custom-class="'transparent ' + customClass" :append-to-body="appendToBody" :width="width" :modal=false
    :show-close="showClose" :close-on-click-modal="closeOnBackgroundClick">
    <div class="rounded-tl rounded-tr overflow-hidden" v-loading="loading">
      <div class="bg-white px-4 py-5 border-b border-gray-200 sm:px-6 flex flex-row items-center justify-between header td-header">
        <span class="text-lg leading-6 font-medium text-blue-900">
          {{ title }}
        </span>
        <div v-if="$slots['header-right']">
          <slot name="header-right"></slot>
        </div>
      </div>
      <div class="bg-white shadow overflow-hidden overflow-y-auto td-body" :style="{'max-height': maxBodyHeight }">
        <slot></slot>
      </div>
    </div>
    <div
      class="flex justify-between border-t border-solid border-gray-300 items-center p-4 rounded-br rounded-bl bg-white td-footer">
      <div>
        <slot name="footer-left"></slot>
      </div>
      <div>
        <slot name="footer-right"></slot>
      </div>
    </div>
    <div class="mt-4" v-if="$slots['footer-bottom']">
      <slot name="footer-bottom"></slot>
    </div>
  </el-dialog>
</template>
<script>
  export default {
    props: {
      show: {
        type: Boolean,
        default: false
      },
      width: {
        type: [String, Number],
        default: '600px'
      },
      loading: {
        type: Boolean,
        default: false
      },
      customClass: {
        type: String,
        default: ''
      },
      maxBodyHeight: {
        type: String,
        default: '50vh'
      },
      showClose: {
        type: Boolean,
        default: false
      },
      appendToBody: {
        type: Boolean,
        default: false
      },
      title: {
        type: String,
        default: ''
      },
      closeOnBackgroundClick: {
        type: Boolean,
        default: false
      }
    },
    data() {
      return {
        showDialog: this.show
      }
    },
    methods: {
      beforeClose(done) {
        done()
      }
    },
    watch: {
      show(val) {
        this.showDialog = val
      },
      showDialog(val) {
        this.$emit('update:show', val)
      }
    }
  }
</script>
