module.exports = {
  data() {
    return {
      shift_key_active: false,
      startIndex: null,
      endIndex: null,
      startingValue: null
    }
  },
  mounted() {
    this.bindShiftKeyDown()
    this.bindShiftKeyUp()
    this.bindWindowBlur()
  },
  methods: {
    bindShiftKeyDown() {
      window.addEventListener('keydown', (e) => {
        if(e.key.toLowerCase() === 'shift') {
          this.shift_key_active = true
        }
      })
    },
    bindShiftKeyUp() {
      window.addEventListener('keyup', (e) => {
        if(e.key.toLowerCase() === 'shift') {
          //console.log('De activate')
          this.shift_key_active = false
          this.clearSelection()
        }
      })
    },
    bindWindowBlur() {
      //console.log('on blur')
      window.addEventListener('blur', (e) => {
        this.shift_key_active = false
        this.clearSelection()
      })
    },
    startMultiSelect(value, index, selection, availableItems, matchingProperty = 'id') {
      // if(this.shift_key_active) {
        //console.log('start index: ' + this.startIndex)
        //console.log('This index: ' + index + ' and the value ' + value)
        if (this.startIndex === null || !this.shift_key_active) {
          this.startingValue = value;
          return this.startIndex = index;
          //console.log('starting')
        } else {
          this.endIndex = index;
          // okay so lets try and
          let start = (this.startIndex > this.endIndex ? this.endIndex : this.startIndex) + 1
          const end = (this.startIndex > this.endIndex ? this.startIndex : this.endIndex);
          if ((end - start) > 0) {
            // lets do this.
            for (start; start <= end; start++) {
              if (availableItems[start]) {
                const item = availableItems[start];
                const itemIdentifier = item[matchingProperty];
                const findIndex = selection.findIndex(s => s === itemIdentifier);

                // this is to match
                if(this.startingValue && findIndex === -1) {
                  selection.push(itemIdentifier)
                } else if (!this.startingValue && findIndex > -1) {
                  selection.splice(findIndex, 1);
                }

                // The following line is to make them the opposite prior
                // findIndex === -1 ? selection.push(itemIdentifier) : selection.splice(findIndex, 1);
              }
            }
          }
          this.clearSelection();
        }
      // }
    },
    clearSelection() {
      //console.log('Cleared')
      this.startIndex = null
      this.endIndex = null
    }
  }
}
