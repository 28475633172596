<template>
  <div>
    <div v-show="options.show_options">
      <date-range-selector :start.sync="options.date_start" :end.sync="options.date_end" />
      <div>
        <div>
          <span class="text-lg font-medium text-blue-900 mb-4 block">Include suspended</span>
        </div>
        <div class="mb-4 -mt-2">
          <el-switch v-model="options.include_suspended" />
        </div>
      </div>
      <div class="flex flex-row flex-wrap -mx-2" v-if="first_show">
        <div class="w-2/6 p-2 flex-shrink-0 xxl:w-1/6">
          <project-selector v-model="options.projects" @selected-sites="projectSelectedSites" :include-suspended="options.include_suspended" @selected-site-targets="projectSelectedSiteTargets" default-column="full" />
        </div>
        <div class="w-2/6 p-2 flex-shrink-0 xxl:w-1/6">
          <site-selector v-model="options.sites" @change="setTargetsBySiteIds"  :include-suspended="options.include_suspended" default-column="full" />
        </div>
        <div class="w-2/6 p-2 flex-shrink-0 xxl:w-1/6">
        <site-target-selector v-model="options.site_targets" ref="stsComponent"  :include-suspended="options.include_suspended" default-column="full"  />
        </div>
        <div class="w-2/6 p-2 flex-shrink-0 xxl:w-1/6">
          <user-group-selector v-model="options.user_groups" @change="setUsersFromGroups" default-column="full" />
        </div>
        <div class="w-2/6 p-2 flex-shrink-0 xxl:w-1/6">
          <user-selector v-model="options.users" ref="userComponent"  :include-suspended="options.include_suspended" default-column="full" />
        </div>
        <div class="w-2/6 p-2 flex-shrink-0 xxl:w-1/6">
          <status-selector v-model="options.status" default-column="full" />
        </div>
        <div class="w-2/6 p-2 flex-shrink-0 xxl:w-1/6">
          <type-selector v-model="options.type" default-column="full" />
        </div>
      </div>
      <div class="text-right my-4">
        <el-button @click="close" type="primary">
          Save Options
        </el-button>
      </div>
    </div>
  </div>
</template>
<script>
import ProjectSelector from "@/components/views/Reporting/ProjectSelector";
import SiteSelector from "@/components/views/Reporting/SiteSelector";
import SiteTargetSelector from "@/components/views/Reporting/SiteTargetSelector";
import UserGroupSelector from "@/components/views/Reporting/UserGroupSelector";
import UserSelector from "@/components/views/Reporting/UserSelector";
import DateRangeSelector from "@/components/views/Reporting/DateSelector";
import StatusSelector from '@/components/views/Reporting/StatusSelector.vue';
import TypeSelector from '@/components/views/Reporting/TypeSelector.vue';
export default {
   components: {
     TypeSelector,
     StatusSelector,
    UserSelector, UserGroupSelector, SiteTargetSelector, SiteSelector, ProjectSelector, DateRangeSelector,
  },
  props: {
    show: {
      type: Boolean,
      default: false
    },
    dateStart: {
      type: [String, Date],
      default: null
    },
    dateEnd: {
      type: [String, Date],
      default: null
    },
    sites: {
      type: Array,
      default: () => []
    },
    siteTargets: {
      type: Array,
      default: () => []
    },
    users: {
      type: Array,
      default: () => []
    },
    userGroups: {
      type: Array,
      default: () => []
    },
    projects: {
      type: Array,
      default: () => []
    },
    status: {
      type: Array,
      default: () => []
    },
    type: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      options: {
        date_start: this.dateStart,
        date_end: this.dateEnd,
        projects: this.projects,
        sites: this.sites,
        site_targets: this.siteTargets,
        user_groups: this.userGroups,
        users: this.users,
        show_options: this.show,
        include_suspended: true,
        type: this.type,
        status: this.status,
      },
      first_show: false
    }
  },
  methods: {
    projectSelectedSites(sites) {
      this.options.sites = sites
    },
    projectSelectedSiteTargets(targets) {
      this.options.site_targets = targets
    },
    setTargetsBySiteIds() {
      this.$refs.stsComponent.setTargetsBySiteIds(this.options.sites)
    },
    setUsersFromGroups() {
      this.$refs.userComponent.selectByUserGroups(this.options.user_groups)
    },
    close () {
      this.options.show_options = false
      this.$emit('on-close', true)
    },
    setDates(start, end) {
      this.options.date_start = start;
      this.options.date_end = end;
    },
    checkHasLoaded() {
      if(this.options.show_options && !this.first_show) {
        this.first_show = true
      }
    }
  },
  mounted() {
    this.checkHasLoaded()
  },
  watch: {
    options: {
      deep: true,
      handler: function() {
        if(this.options.date_start !== this.dateStart) this.$emit('update:dateStart', this.options.date_start)
        if(this.options.date_end !== this.dateEnd) this.$emit('update:dateEnd', this.options.date_end)
        if(this.options.projects !== this.projects) this.$emit('update:projects', this.options.projects)
        if(this.options.sites !== this.sites) this.$emit('update:sites', this.options.sites)
        if(this.options.site_targets !== this.siteTargets) this.$emit('update:siteTargets', this.options.site_targets)
        if(this.options.users !== this.users) this.$emit('update:users', this.options.users)
        if(this.options.user_groups !== this.userGroups) this.$emit('update:userGroups', this.options.user_groups)
        if(this.options.show_options !== this.show) this.$emit('update:show', this.options.show_options)
        if(this.options.status !== this.status) this.$emit('update:status', this.options.status)
        if(this.options.type !== this.type) this.$emit('update:type', this.options.type)
      }
    },
    show: {
      handler: function(val) {
        if(this.options.show_options !== val) {
          this.options.show_options = val
          this.checkHasLoaded()
        }
      }
    },
    dateStart: {
      handler: function(val) {
        if(this.options.date_start !== val) {
          this.options.date_start = val
        }
      }
    },
    dateEnd: {
      handler: function(val) {
        if(this.options.date_end !== val) {
          this.options.date_end = val
        }
      }
    },
    sites: {
      handler: function(val) {
        if(this.options.sites !== val) {
          this.options.sites = val
        }
      }
    },
    siteTargets: {
      handler: function(val) {
        if(this.options.site_targets !== val) {
          this.options.site_targets = val
        }
      }
    },
    users: {
      handler: function(val) {
        if(this.options.users !== val) {
          this.options.users = val
        }
      }
    },
    userGroups: {
      handler: function(val) {
        if(this.options.user_groups !== val) {
          this.options.user_groups = val
        }
      }
    },
    projects: {
      handler: function(val) {
        if(this.options.projects !== val) {
          this.options.projects = val
        }
      }
    },
    status: {
      handler: function(val) {
        if(this.options.status !== val) {
          this.options.status = val
        }
      }
    },
    type: {
      handler: function(val) {
        if(this.options.type !== val) {
          this.options.type = val
        }
      }
    }
  }
}
</script>
