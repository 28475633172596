import axios from 'axios'
import store from './../store/index'

export function authenticate (email, password) {
  return new Promise((resolve, reject) => {
    axios.post(process.env.VUE_APP_TRIESTE_API + '/v1/auth/login', {
      email: email,
      password: password
    }).then((response) => {
      store.commit('auth/setAccessToken', response.data.data.token)
      store.commit('auth/setRefreshToken', response.data.data.refresh_token)
      store.commit('auth/setUser', { user: response.data.data.user })
      return resolve(response)
    })
    .catch((e) => {
      console.error(e)
      return reject(e)
    })
  })
}
