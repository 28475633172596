<template>
  <div v-loading="loading">
    <trieste-table
      item-type="projects"
      :end-point="`/v1/companies/${$store.state.company.company.id}/projects`"
      :fields="fields" ref="table"
      title="Projects"
      default-sort-prop="name"
      :filters="filters"
      :show-title="false"
      :params="{client_id: clientId}"
      saved-table-sort-name="project-index"
    >
      <template v-slot:header-right>
        <a v-if="$store.getters['auth/hasPermission']('project.create')"
           :href="`/company/${$route.params.companyId}/clients/${$route.params.clientId}/projects/create`">
          <el-button type="success" size="medium" icon="el-icon-edit-outline">Create</el-button>
        </a>
      </template>
      <template v-slot:status="props">
        {{ props.row.status }}
        <div v-if="props.row.status === 'incomplete' && !Number.isNaN(props.row.percent_complete / 1)">
          <el-progress :percentage="props.row.percent_complete / 1" :show-text="false" />
          <span class="text-xs"><span class="font-bold">Next:</span> {{ getNextType(props.row.saved_index) }}</span>
        </div>
      </template>
      <template v-slot:actions-slot="props">
        <a v-if="$store.getters['auth/hasPermission']('project.read')"
           :href="`/company/${$route.params.companyId}/projects/${props.row.id}`">
        <el-button
          size="small"
          icon="el-icon-view"
          type="primary">
          View
        </el-button>
        </a>
      </template>
    </trieste-table>
  </div>
</template>
<script>
  export default {
    beforeRouteEnter(to, from, next) {
      next(vm => {
        vm.$store.commit('global/setBreadcrumbs', [
          {
            path: `/`,
            label: 'Home'
          },
          {
            label: 'Projects'
          }
        ])
      })
    },
    props: {
      clientId: {
        type: [Number, String],
        default: null
      }
    },
    data: function () {
      return {
        loading: false,
        query: {
          rpp: 20,
          page: 1
        },
        fields: [
          {
            name: 'name',
            title: 'name',
            sortField: 'name'
          },
          {
            title: 'status',
            name: 'status',
            sortField: 'status'
          },
          {
            title: 'Created at',
            name: 'created_at',
            dateField: true,
            sortField: 'created_at'
          },
          {
            title: '',
            name: 'actions-slot',
            sortField: 'created_at',
            width: '110px',
            fixed: 'right'
          },
        ],
        filters: [
          {
            label: 'Name',
          },
          {
            label: 'status',
            type: 'select',
            multiple: true,
            options: [
              {
                label: 'Active',
                value: 'active'
              },
              {
                label: 'Suspended',
                value: 'suspended'
              }
            ]
          },
          {
            label: 'Created between',
            name: 'created_at',
            type: 'date',
            dateType: 'daterange'
          }
        ]
      }
    },
    methods: {
      getNextType(index) {
        switch(index) {
          case 1: return 'Link allocation';
          case 2: return 'Sites and targets';
          case 3: return 'Target information';
          case 4: return 'Content information';
          default: return 'price';
        }
      }
    }
  }
</script>
